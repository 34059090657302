import React from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import type RapidBoardConfigApiType from '@atlassian/jira-router-resources-classic-projects/src/services/config-api/get-data/index.tsx';
import type RapidBoardDataApiType from '@atlassian/jira-router-resources-classic-projects/src/services/data-api/get-data/index.tsx';
import type RapidBoardResourceType from '@atlassian/jira-router-resources-classic-projects/src/services/rapidboard-resource/get-data/index.tsx';
import type RapidBoardWrmBundleResourceType from '@atlassian/jira-router-resources-classic-projects/src/services/wrm-bundle/get-data/index.tsx';
import type RapidBoardType from '@atlassian/jira-spa-apps-rapid-board/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { RouteContext } from '@atlassian/react-resource-router';

export const UIFBoardSpa = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-uif-board", jiraSpaEntry: "async-uif-board" */ '@atlassian/jira-spa-apps-next-gen-board'
		),
);

export const UIFBacklogSpa = lazyForPaint(
	() =>
		import(
			/* webpackChunkName: "async-uif-backlog", jiraSpaEntry: "async-uif-backlog" */ '@atlassian/jira-spa-apps-next-gen-backlog'
		),
);

export const BoardSettingsSpa = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-uif-board-settings", jiraSpaEntry: "async-uif-board-settings" */ '@atlassian/jira-router-routes-software-classic-board-settings-switcher'
	).then((module) => module.BoardConfigSwitcher),
);

/**
 * Route page component for Jira Software RapidBoard CMP boards and backlogs, legacy and UIF.
 */
export const RapidBoardSpaPage = (props: RouteContext) => {
	const { route, query, match } = props;
	const basePageId = (route && route.name) || 'rapid-board';
	const chartParam = query.chart ? `-${query.chart}` : '';
	const boardId = query.rapidView ?? match.params.boardId;
	const pageId = `${basePageId}-${boardId}${chartParam}`;

	if (
		['rapidboard-board', 'rapidboard-board-embed', 'rapidboard-user-board'].includes(route.name) &&
		!query.config
	) {
		return <LazyPage Page={UIFBoardSpa} pageId={pageId} shouldShowSpinner={false} />;
	}

	if (
		['rapidboard-backlog', 'rapidboard-user-backlog', 'rapidboard-backlog-embed'].includes(
			route.name,
		) &&
		!query.config
	) {
		return <LazyPage Page={UIFBacklogSpa} pageId={pageId} shouldShowSpinner={false} />;
	}

	if (
		[
			'rapidboard-backlog',
			'rapidboard-user-backlog',
			'rapidboard-board',
			'rapidboard-board-embed',
			'rapidboard-user-board',
			'rapidboard-backlog-embed',
		].includes(route.name) &&
		query.config &&
		fg('simplified-boards-milestone-2_07hbk')
	) {
		return <LazyPage Page={BoardSettingsSpa} pageId={pageId} shouldShowSpinner={false} />;
	}

	return <LazyPage Page={LazyRapidBoard} pageId={pageId} shouldShowSpinner={false} />;
};

export const LazyRapidBoard = lazyForPaint<typeof RapidBoardType>(
	() =>
		import(
			/* webpackChunkName: "async-rapid-board", jiraSpaEntry: "async-rapid-board" */ '@atlassian/jira-spa-apps-rapid-board'
		),
);

// @ts-expect-error - TS2344 - Type '({ match, route }: RouterDataContext, { tenantContext }: ResourceStoreContext) => Promise<any>' does not satisfy the constraint 'ComponentType<any>'.
export const LazyRapidBoardWrmBundleResource = lazyForPaint<typeof RapidBoardWrmBundleResourceType>(
	() =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-wrm-bundle", jiraSpaEntry: "async-resource-rapidboards-wrm-bundle" */ '@atlassian/jira-router-resources-classic-projects/src/services/wrm-bundle/get-data'
		),
);

// @ts-expect-error - TS2344 - Type '({ query, route, match }: RouterDataContext, { tenantContext }: ResourceStoreContext) => Promise<{ data: Promise<any>; route: string; } | null>' does not satisfy the constraint 'ComponentType<any>'.
export const LazyRapidBoardDataApiResource = lazyForPaint<typeof RapidBoardDataApiType>(
	() =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-data-api", jiraSpaEntry: "async-resource-rapidboards-data-api" */ '@atlassian/jira-router-resources-classic-projects/src/services/data-api/get-data'
		),
);

// @ts-expect-error - TS2344 - Type '({ query, route, match }: RouterDataContext, { tenantContext }: ResourceStoreContext) => Promise<{ data: any; boardId: string; route: string; }>' does not satisfy the constraint 'ComponentType<any>'.
export const LazyRapidBoardConfigApiResource = lazyForPaint<typeof RapidBoardConfigApiType>(
	() =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-config-api", jiraSpaEntry: "async-resource-rapidboards-config-api" */ '@atlassian/jira-router-resources-classic-projects/src/services/config-api/get-data'
		),
);

// @ts-expect-error - TS2344 - Type '({ query, match }: RouterDataContext, { tenantContext }: ResourceStoreContext) => Promise<any>' does not satisfy the constraint 'ComponentType<any>'.
export const LazyRapidBoardResource = lazyForPaint<typeof RapidBoardResourceType>(
	() =>
		import(
			/* webpackChunkName: "async-resource-rapidboards-rapidboard-resource-data", jiraSpaEntry: "async-resource-rapidboards-rapidboard-resource-data" */ '@atlassian/jira-router-resources-classic-projects/src/services/rapidboard-resource/get-data'
		),
);

/**
 * @hack This is not a component, but it needs to be preloaded.
 * Rapid boards imports from /entry because they cannot move the app install logic while rapidboard lives inside and outside the SPA
 * This hack should be removed when they finish with the migration
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const LazyRapidBoardApps = lazyForPaint<any>(
	() =>
		import(
			/* webpackChunkName: "async-rapid-board-apps" */ '@atlassian/jira-entry-rapid-board-wrm-apps'
		),
);
