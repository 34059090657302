import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { polarisSettingsAccessRoute } from '@atlassian/jira-router-routes-polaris-routes/src/polarisSettingsAccessRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { AccessSettingsPage, LazyAccessSettingsPage } from './ui/settings/access/index.tsx';

export const polarisSettingsAccessRouteEntry: Route = createEntry(
	polarisSettingsAccessRoute,
	() => ({
		isStrictModeEnabled: false,
		group: 'global-settings',
		component: AccessSettingsPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],
		forPaint: [LazyAtlassianNavigation, LazyAccessSettingsPage],
	}),
);
