import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskAutomationSummaryRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationSummaryRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import ServiceDeskAutomationSummary, {
	LazyProjectSettingsServiceDeskAutomationSummary,
} from './ui/service-desk-automation/summary/index.tsx';

export const projectSettingsServicedeskAutomationSummaryRouteEntry = createEntry(
	projectSettingsServicedeskAutomationSummaryRoute,
	() => ({
		group: 'project-settings-servicedesk',
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.summary',
		component: ServiceDeskAutomationSummary,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationSummary],
	}),
);
