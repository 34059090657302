import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { classicProjectSettingsSoftwareProformaFormsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/classicProjectSettingsSoftwareProformaFormsRoute.tsx';
import { classicSoftwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';
import ProformaProjectForms, {
	LazyProjectSettingsProformaProjectForms,
} from './ui/project-settings/proforma-project-forms/index.tsx';

export const classicProjectSettingsSoftwareProformaFormsRouteEntry: Route = createEntry(
	classicProjectSettingsSoftwareProformaFormsRoute,
	() => ({
		group: 'project-settings-software',
		component: ProformaProjectForms,
		layout: classicSoftwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarSoftwareClassic,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsProformaProjectForms],
	}),
);
