import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { genericConnectSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getServiceDeskNavigationSingleResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { connectGeneralServiceDeskProjectRoute } from '@atlassian/jira-router-routes-connect-routes/src/connectGeneralServiceDeskProjectRoute.tsx';
import type { RouteContext, Route } from '@atlassian/react-resource-router';
import { getGeneralConnectRouteResources } from './common/index.tsx';
import ConnectGeneralPage, { LazyGeneralConnect } from './ui/spa/connect/general/index.tsx';

export const connectGeneralServiceDeskProjectRouteEntry: Route = createEntry(
	connectGeneralServiceDeskProjectRoute,
	() => ({
		group: 'connect',
		layout: createLayout(),
		navigation: {
			menuId: MENU_ID.ADDONS,
			sidebar: genericConnectSidebars,
		},
		component: ConnectGeneralPage,
		resources: [...getGeneralConnectRouteResources(), ...getServiceDeskNavigationSingleResources()],
		canTransitionIn: (current: RouteContext, next: RouteContext) => {
			const { query } = next.match;

			// must be in sync with com.atlassian.jira.web.filters.frontend.ConnectGeneralPagesFrontendRoute::matchesRequest
			// block transitions to mobile version
			if (
				query.mobile === 'true' ||
				query.includeWebResources === 'true' ||
				query.classifier === 'json'
			) {
				return false;
			}

			// block transitions to marketplace app as it requires specialised Connect API -
			const emceeKeyRegex =
				/com\.atlassian\.(jira|confluence)\.emcee($|\.local|\.staging|\.development\..*)/g;
			const addonKey = next.match.params.addonKey || '';
			if (emceeKeyRegex.test(addonKey)) {
				return false;
			}
			return true;
		},
		forPaint: [LazyAtlassianNavigation, LazyGeneralConnect],
		ufoName: 'jira-connect',
	}),
);
