import React from 'react';
import { Stack, xcss } from '@atlaskit/primitives';
import { SkeletonCompiled } from '@atlassian/jira-common-components-skeleton/src/styled.tsx';

const LoadingState = () => (
	<Stack space="space.200" xcss={loadingStateStyles}>
		<SkeletonCompiled height="32px" width="160px" />
		<SkeletonCompiled height="32px" width="120px" />
	</Stack>
);

export default LoadingState;
const loadingStateStyles = xcss({ marginBlockStart: 'space.050' });
