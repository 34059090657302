import React from 'react';
import type PlanTeamsType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-teams/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/**
 * Plan Teams page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanTeams = lazyForPaint<typeof PlanTeamsType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-teams", jiraSpaEntry: "async-plan-teams" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-teams/index-old.tsx'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <LazyPage Page={LazyPlanTeams} pageId="plan-teams" shouldShowSpinner />;
