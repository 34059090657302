/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AppSkeletonImage } from '../../common/ui/AppSkeletonImage.tsx';
import incidentsPage from './assets/incidents-page-app-skeleton.tpl.svg';
import incidentsTable from './assets/incidents-page-table-skeleton.tpl.svg';

const incidentsPageSkeletonStyles = css({
	width: `calc(100% - ${token('space.500')} - ${token('space.500')})`,
	height: '100%',
	paddingTop: token('space.300'),
	paddingLeft: token('space.500'),
	paddingRight: token('space.500'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		border: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		border: 'none',
	},
});

const incidentsTableSkeletonStyles = css({
	width: '100%',
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	object: {
		border: 'none',
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	img: {
		border: 'none',
	},
});

export const IncidentsPageSkeleton = () => (
	<div
		css={incidentsPageSkeletonStyles}
		data-testid="skeletons.ui.incidents-page.app-skeleton-image-incidents-page"
	>
		<AppSkeletonImage src={incidentsPage} />
	</div>
);

export const IncidentsTableSkeleton = () => (
	<div
		css={incidentsTableSkeletonStyles}
		data-testid="skeletons.ui.incidents-page.app-skeleton-image-incidents-table"
	>
		<AppSkeletonImage src={incidentsTable} />
	</div>
);
