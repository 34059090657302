import React, { type ChangeEvent } from 'react';
import { ButtonItem, MenuGroup, Section } from '@atlaskit/menu';
import { Radio } from '@atlaskit/radio';
import { useDisplaySettings } from '../../display-settings-provider/index.tsx';
import type { ViewOptionsListProps, ViewOptionsType } from './types.tsx';

export const ViewOptionsList = ({ onChange }: ViewOptionsListProps) => {
	const { viewOptionsList, selectedViewOption, setSelectedViewOption } = useDisplaySettings();

	const onViewOptionSelect = (e: ChangeEvent<HTMLInputElement>): void => {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const value = e.target.value as ViewOptionsType;
		setSelectedViewOption(value);
		onChange(e.nativeEvent);
	};

	return (
		<MenuGroup>
			<Section isList>
				{viewOptionsList.map((viewOption) => {
					const { value, label } = viewOption;
					const isSelected = selectedViewOption === value;
					return (
						<ButtonItem
							key={value}
							isSelected={isSelected}
							aria-checked={isSelected}
							aria-labelledby={`label-${value}`}
							role="menuitemradio"
						>
							<Radio
								id={`label-${value}`}
								name="view-option"
								value={value}
								label={label}
								isChecked={isSelected}
								onChange={onViewOptionSelect}
							/>
						</ButtonItem>
					);
				})}
			</Section>
		</MenuGroup>
	);
};
