export const SOFTWARE_CONTOURED_THEME_ROUTES = [
	'issue',
	'software-backlog',
	'rapidboard-backlog',
	'software-timeline',
	'software-timeline-classic',
	'software-issue-navigator',
	'issue-navigator-classic',
];

export const SOFTWARE_FULL_THEME_ROUTES = [
	'software-boards',
	'rapidboard-board',
	'software-calendar',
	'software-calendar-classic',
];

export const SOFTWARE_THEME_ROUTES = [
	...SOFTWARE_CONTOURED_THEME_ROUTES,
	...SOFTWARE_FULL_THEME_ROUTES,
];
