/**
 * @generated SignedSource<<2b6d624fe77f1baaf695aff0d10d9bb4>>
 * @relayHash 8ffd927f0f103f31e5561c425591e1e2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b2183d07682b1d2b84301e78433e2d367dd07a7e346b442d9910734f7ca26eee

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { JourneyConfigurationPageQuery } from './JourneyConfigurationPageQuery.graphql';

const node: PreloadableConcreteRequest<JourneyConfigurationPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "b2183d07682b1d2b84301e78433e2d367dd07a7e346b442d9910734f7ca26eee",
    "metadata": {},
    "name": "JourneyConfigurationPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
