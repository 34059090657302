/**
 * @generated SignedSource<<ddb8a1bbce6fff611878abb761dbf404>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
export type JiraAppType = "CONNECT" | "FORGE" | "%future added value";
import type { FragmentRefs } from "relay-runtime";
export type Nav4AppsContent$data = {
  readonly globalAppNavigationItems: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly appId?: string | null | undefined;
        readonly appType?: JiraAppType | null | undefined;
        readonly id: string;
        readonly label?: string | null | undefined;
        readonly " $fragmentSpreads": FragmentRefs<"Nav4ConnectMenuItem" | "Nav4ForgeMenuItem">;
      };
    } | null | undefined>;
  } | null | undefined;
  readonly " $fragmentType": "Nav4AppsContent";
};
export type Nav4AppsContent$key = {
  readonly " $data"?: Nav4AppsContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4AppsContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4AppsContent",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        }
      ],
      "concreteType": "JiraNavigationItemConnection",
      "kind": "LinkedField",
      "name": "globalAppNavigationItems",
      "plural": false,
      "selections": [
        {
          "kind": "RequiredField",
          "field": {
            "concreteType": "JiraNavigationItemEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "kind": "RequiredField",
                "field": {
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "RequiredField",
                      "field": {
                        "kind": "ScalarField",
                        "name": "id"
                      },
                      "action": "THROW",
                      "path": "globalAppNavigationItems.edges.node.id"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "kind": "ScalarField",
                          "name": "label"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "appId"
                        },
                        {
                          "kind": "ScalarField",
                          "name": "appType"
                        }
                      ],
                      "type": "JiraAppNavigationItem"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "Nav4ConnectMenuItem"
                    },
                    {
                      "kind": "FragmentSpread",
                      "name": "Nav4ForgeMenuItem"
                    }
                  ]
                },
                "action": "THROW",
                "path": "globalAppNavigationItems.edges.node"
              }
            ]
          },
          "action": "THROW",
          "path": "globalAppNavigationItems.edges"
        }
      ]
    }
  ],
  "type": "JiraQuery"
};

(node as any).hash = "6074e52ca012374d836fe32977dd1a14";

export default node;
