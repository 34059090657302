import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { softwareFormSubmitRoute } from '@atlassian/jira-router-routes-software-form-submit-routes/src/softwareFormSubmitRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { formSubmitEntrypoint } from '@atlassian/jira-spa-apps-software-form-submit/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

const formSubmitPageEntrypoint = createPageEntryPoint({
	main: formSubmitEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const softwareFormSubmitRouteEntry: Route = createEntry(softwareFormSubmitRoute, () => ({
	group: 'software',
	ufoName: 'next-gen-form-submit',
	layout: chromelessLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		// eslint-disable-next-line camelcase
		DEPRECATED_DO_NOT_USE_projectDetailsResource,
	],

	entryPoint: () => {
		return expVal('forms_for_jsw_experiment', 'isEnabled', false)
			? formSubmitPageEntrypoint
			: notFoundPageEntrypoint;
	},
}));
