import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

export const servicedeskReportsSummaryRoute: InvariantRoute = {
	// HACK - the reports backbone code tries to refresh the page
	// in order to re-render the view. This route allows us to replicate that
	// behaviour in React.
	name: 'servicedesk-reports-summary',
	path: '(/jira/servicedesk/projects/[^/]+/reports/.+)/summary',
	exact: true,
};
