import { createResource, useResource } from '@atlassian/react-resource-router';
import { QUERY } from './services/license-details/gql.tsx';
import { fetchLicenseDetails } from './services/license-details/index.tsx';
import type { LicenseDetailsResourceType } from './types.tsx';

/**
 * @deprecated Do not use this resource. It has been deprecated and will eventually be removed.
 * Instead, fetch license details through Relay.
 */
export const licenseDetailsResource_DEPRECATED_DO_NOT_USE =
	createResource<LicenseDetailsResourceType>({
		type: 'BUSINESS_LICENSE_DETAILS',
		getKey: () => 'BUSINESS_LICENSE_DETAILS',
		getData: async (_routerContext, { tenantContext: { cloudId, isAnonymous } }) => {
			// do not fetch the resource for anonymous access
			if (isAnonymous) {
				return null;
			}

			return fetchLicenseDetails(QUERY, {
				cloudId,
			});
		},
		maxAge: Infinity,
	});

/**
 * @deprecated Do not use this resource's hook. It has been deprecated and will eventually be removed.
 * Instead, fetch the license details through Relay.
 */
export const useIsPremiumUserSeat = (): boolean => {
	const { data } = useResource(licenseDetailsResource_DEPRECATED_DO_NOT_USE);

	return Boolean(data?.isPremiumUserSeat);
};
