/** @jsx jsx */
import React, { useCallback, type ReactNode } from 'react';
import { cssMap, jsx } from '@compiled/react';
import { Box } from '@atlaskit/primitives';
import PerformanceMark from '@atlassian/jira-common-performance/src/set-performance-mark.tsx';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { useIsChromeless } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/chromeless/index.tsx';
import { useIsFullscreen } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/fullscreen/index.tsx';
import { LayoutRemoteStateUpdater } from '@atlassian/jira-layout-controller/src/controllers/layout-controller/consumers/layout-remote-state-updater/index.tsx';
import { GlobalBottomRightCornerContainer } from '@atlassian/jira-layout-controller/src/ui/bottom-right-corner/container/index.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import {
	PageLayout,
	Content,
	LeftPanel,
	Main,
} from '@atlassian/jira-navigation-system/src/index.tsx';
import {
	GLOBAL_CONTAINERS_MARK,
	GLOBAL_CONTAINERS_START_MARK,
	GLOBAL_CONTAINERS_END_MARK,
} from '@atlassian/jira-page-container-constants/src/index.tsx';
import { useBannerJsmPackagingUpdates } from '@atlassian/jira-platform-ui-banners-jsm-packaging-updates/src/controllers/index.tsx';
import { BannerJsmPackagingUpdateAsync } from '@atlassian/jira-platform-ui-banners-jsm-packaging-updates/src/ui/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import { useProjectTheme } from '@atlassian/jira-project-theme-page-container/src/controllers/project-theme/index.tsx';
import { sharedLayout } from '@atlassian/jira-shared-layout/src/sharedLayout.tsx';
import { setInitialPageLoadTimingFromPerformanceMarks } from '@atlassian/jira-spa-performance-breakdown/src/utils/performance-marks-tools/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { Banner } from '../banner/index.tsx';
import { GlobalComponents } from '../global-components/index.tsx';
import { GlobalContainers } from '../global-containers/main.tsx';
import { HorizontalNavWrapper, HorizontalNav } from '../horizontal-nav/index.tsx';
import { RightSidebar } from '../right-sidebar/index.tsx';
import { Sidebar } from '../sidebar/index.tsx';
import { TopNav } from '../topnav/index.tsx';
import { FullHeight } from './styled.tsx';
import { ThemeSetter, GlobalThemeSetter } from './theme-setter/index.tsx';
import { ProjectThemeSetter } from './project-theme-setter/ProjectThemeSetter.tsx';
import type { PageContainerProps } from './types.tsx';

const mainWrapperStyles = cssMap({
	root: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		' > span[data-ep-placeholder-id="route_entry_point"]': {
			// EntryPointPlaceholder span element will stop the height from being 100%.
			// this makes content height can be set to 100% even if the span is present.
			// https://jplat.jira.atlassian.cloud/browse/BLU-3362
			display: 'contents',
		},
	},
	themed: {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		background: Tokens.ELEVATION_SURFACE,
		// When an image background is applied via the background css var the image
		// will be darkened in dark mode by blending with a black transparent background.
		backgroundBlendMode: 'darken',
	},
});

/**
 * These test IDs are used in pollinator tests. Do not change lightly.
 */
const ROOT_TEST_ID = 'page-layout.root';
const MAIN_TEST_ID = 'page-layout.main';

/* IMPORTANT:
 * When making any changes to the Nav3 component, please ensure that you also make the same changes to the
 * Nav4 component. The feature flag used is part of the Navigation Refresh work and we need to ensure that
 * we do not introduce tech debt for the roll-out by only updating the current component that is due to be
 * removed.
 *
 * If you have any concerns or questions please reach out to the #jira-navigation channel. Thanks!
 */

const DefaultLayoutNav4 = ({ children }: PageContainerProps) => {
	const [isChromeless] = useIsChromeless();
	const [isFullscreen] = useIsFullscreen();
	const { hasTheming } = useProjectTheme();
	const { isEnabled: isJsmPackagingUpdatesEnabled } = useBannerJsmPackagingUpdates();

	const mainWrapper = (content: ReactNode) => (
		<Main isFixed testId={MAIN_TEST_ID}>
			<div css={[mainWrapperStyles.root, hasTheming && mainWrapperStyles.themed]}>
				{isJsmPackagingUpdatesEnabled && <BannerJsmPackagingUpdateAsync />}
				{content}
			</div>
		</Main>
	);

	const showBanner = !isChromeless && !isFullscreen;
	const showTopNav = !isChromeless && !isFullscreen;
	const showSidebar = !isChromeless && !isFullscreen;
	const showHorizontalNav = !isChromeless;
	const showRightSidebar = !isChromeless && !isFullscreen;
	const showLayoutRemoteStateUpdater = !isChromeless && !isFullscreen;

	return (
		<>
			<PageLayout skipLinksLabel="" testId={ROOT_TEST_ID}>
				{showBanner && (
					<JSErrorBoundary id="banner" packageName="jiraPageContainerV2" fallback="unmount">
						<Banner />
					</JSErrorBoundary>
				)}
				{showTopNav && (
					<JSErrorBoundary id="top-nav" packageName="jiraPageContainerV2" fallback="unmount">
						<TopNav />
					</JSErrorBoundary>
				)}
				{showSidebar && (
					<JSErrorBoundary
						id="sidebar"
						packageName="jiraPageContainerV2"
						fallback={fg('blu-5989-show-flag-on-sidebar-error-boundary') ? 'flag' : 'unmount'}
					>
						<Sidebar />
					</JSErrorBoundary>
				)}
				{mainWrapper(
					<>
						{showHorizontalNav && <HorizontalNav />}
						{children}
					</>,
				)}
				{showRightSidebar && (
					<JSErrorBoundary id="right-sidebar" packageName="jiraPageContainerV2" fallback="unmount">
						<RightSidebar />
					</JSErrorBoundary>
				)}
				{showLayoutRemoteStateUpdater && (
					<JSErrorBoundary
						packageName="jiraPageContainerV2"
						id="layout-remote-state-updater"
						fallback="unmount"
					>
						<LayoutRemoteStateUpdater />
					</JSErrorBoundary>
				)}
			</PageLayout>
		</>
	);
};

const DefaultLayoutNav3 = ({ children }: PageContainerProps) => {
	const HorizontalNavComponent = useHorizontalNavComponent();
	const { isEnabled: isJsmPackagingUpdatesEnabled } = useBannerJsmPackagingUpdates();
	let contentChildren = isJsmPackagingUpdatesEnabled ? (
		<Box>
			<BannerJsmPackagingUpdateAsync />
			{children}
		</Box>
	) : (
		<>{children}</>
	);

	if (HorizontalNavComponent != null) {
		contentChildren = (
			<HorizontalNavWrapper navComponent={HorizontalNavComponent}>
				{isJsmPackagingUpdatesEnabled && <BannerJsmPackagingUpdateAsync />}
				{children}
			</HorizontalNavWrapper>
		);
	}

	return (
		<FullHeight>
			<PageLayout skipLinksLabel="">
				<JSErrorBoundary id="banner" packageName="page-container" fallback="unmount">
					<Banner />
				</JSErrorBoundary>
				<JSErrorBoundary id="top-nav" packageName="page-container" fallback="unmount">
					<TopNav />
				</JSErrorBoundary>
				<LeftPanel width={0}>
					<></>
				</LeftPanel>
				<Content>
					<JSErrorBoundary id="sidebar" packageName="page-container" fallback="unmount">
						<Sidebar />
					</JSErrorBoundary>
					{contentChildren}
					<JSErrorBoundary id="right-sidebar" packageName="page-container" fallback="unmount">
						<RightSidebar />
					</JSErrorBoundary>
				</Content>
				<JSErrorBoundary
					packageName="page-container"
					id="layout-remote-state-updater"
					fallback="unmount"
				>
					<LayoutRemoteStateUpdater />
				</JSErrorBoundary>
			</PageLayout>
		</FullHeight>
	);
};

const useRouterRoute = createRouterSelector((state) => state.route);
const LayoutNav4 = (props: PageContainerProps) => {
	const { LayoutOverrideComponent } = useRouterRoute();
	if (LayoutOverrideComponent !== undefined) {
		return <LayoutOverrideComponent>{props.children}</LayoutOverrideComponent>;
	}
	return <DefaultLayoutNav4 {...props} />;
};

const LayoutNav3 = (props: PageContainerProps) => {
	const { LayoutOverrideComponent } = useRouterRoute();
	if (LayoutOverrideComponent !== undefined) {
		return <LayoutOverrideComponent>{props.children}</LayoutOverrideComponent>;
	}
	return <DefaultLayoutNav3 {...props} />;
};

const PageContainerNav4 = (props: PageContainerProps) => {
	const { id, locale, baseUrl, messages, Router, routerLinkComponent } = props;
	const pageContainerPerformanceTiming = useCallback(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			GLOBAL_CONTAINERS_MARK,
			GLOBAL_CONTAINERS_START_MARK,
			GLOBAL_CONTAINERS_END_MARK,
			true,
			false,
		);
	}, []);

	const { hasTheming, isInset } = getIsSoftwareThemingEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useProjectTheme()
		: { hasTheming: false, isInset: false };

	return (
		<>
			<PerformanceMark metricKey={GLOBAL_CONTAINERS_START_MARK} />
			{fg('jira_theming_relay_migration') ? <GlobalThemeSetter /> : <ThemeSetter />}
			<GlobalContainers
				id={id}
				locale={locale}
				baseUrl={baseUrl}
				messages={messages}
				Router={Router}
				routerLinkComponent={routerLinkComponent}
			>
				<PerformanceMark
					metricKey={GLOBAL_CONTAINERS_END_MARK}
					onSet={pageContainerPerformanceTiming}
				/>
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="navigationNext">
					{fg('jira_theming_relay_migration') && <ProjectThemeSetter />}
					{fg('assets_as_an_app_kill_switch') ? (
						<DefaultLayoutNav4 {...props} />
					) : (
						<LayoutNav4 {...props} />
					)}
					<GlobalComponents sharedGlobalComponents={sharedLayout.globalComponents} />
					<GlobalBottomRightCornerContainer
						{...(hasTheming && isInset && { bottomOffset: 12, rightOffset: 12 })}
					/>
				</ContextualAnalyticsData>
			</GlobalContainers>
		</>
	);
};

/**
 * Serves as a comprehensive layout manager, orchestrating the assembly of global containers,
 * navigation components, and performance metrics into a cohesive page structure. It enhances user
 * interface by conditionally incorporating additional UI elements and ensures a uniform
 * appearance and behavior across pages through global styling. Moreover, it facilitates
 * performance optimization and analytics integration for improved user experience and
 * insights.
 */
const PageContainerNav3 = (props: PageContainerProps) => {
	const { id, locale, baseUrl, messages, Router, routerLinkComponent } = props;
	const pageContainerPerformanceTiming = useCallback(() => {
		setInitialPageLoadTimingFromPerformanceMarks(
			GLOBAL_CONTAINERS_MARK,
			GLOBAL_CONTAINERS_START_MARK,
			GLOBAL_CONTAINERS_END_MARK,
			true,
			false,
		);
	}, []);

	const { hasTheming, isInset } = getIsSoftwareThemingEnabled()
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useProjectTheme()
		: { hasTheming: false, isInset: false };

	return (
		<>
			<PerformanceMark metricKey={GLOBAL_CONTAINERS_START_MARK} />
			{fg('jira_theming_relay_migration') ? <GlobalThemeSetter /> : <ThemeSetter />}
			<GlobalContainers
				id={id}
				locale={locale}
				baseUrl={baseUrl}
				messages={messages}
				Router={Router}
				routerLinkComponent={routerLinkComponent}
			>
				<PerformanceMark
					metricKey={GLOBAL_CONTAINERS_END_MARK}
					onSet={pageContainerPerformanceTiming}
				/>
				<ContextualAnalyticsData sourceType={SCREEN} sourceName="navigationNext">
					{fg('jira_theming_relay_migration') && <ProjectThemeSetter />}
					{fg('assets_as_an_app_kill_switch') ? (
						<DefaultLayoutNav3 {...props} />
					) : (
						<LayoutNav3 {...props} />
					)}
					<GlobalComponents sharedGlobalComponents={sharedLayout.globalComponents} />
					<GlobalBottomRightCornerContainer
						{...(hasTheming && isInset && { bottomOffset: 12, rightOffset: 12 })}
					/>
				</ContextualAnalyticsData>
			</GlobalContainers>
		</>
	);
};

export const PageContainer = componentWithCondition(
	getWillShowNav4,
	PageContainerNav4,
	PageContainerNav3,
);
