import { useEffect, useState } from 'react';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { useIsFirstActivationDateWithinLast14DaysMemo } from '../is-first-activation-date-within-last-14-days-memo/index.tsx';
import { getSampleDataBulkDeleteFeature } from '../../services/get-sample-data-bulk-delete-feature/index.tsx';

export const useShouldShowClearAllTasksCta = () => {
	const [sampleDataBulkFeatureEnabled, setSampleDataBulkFeatureEnabled] = useState(false);

	const cloudId = useCloudId();
	const {
		data: {
			user: { accountId },
		},
	} = useCurrentUser();
	const { data: projectData } = useProjectContext();

	useEffect(() => {
		if (accountId && projectData?.projectId) {
			getSampleDataBulkDeleteFeature(accountId, cloudId, projectData?.projectId).then((result) =>
				setSampleDataBulkFeatureEnabled(result?.status === 'ENABLED'),
			);
		}
	}, [accountId, cloudId, projectData]);

	const pageRoutes = new Set([
		'software-summary',
		'software-boards',
		'software-list',
		'software-calendar',
		'software-timeline',
		'software-issue-navigator',
		'software-backlog',
		'classic-business-summary',
		'classic-business-board',
		'classic-business-list',
		'classic-business-calendar',
		'classic-business-timeline',
		'issue-navigator-core',
	]);

	const route = useCurrentRoute();

	const isActiveRoute = pageRoutes.has(route.name);

	const isActiveWithin14Days = useIsFirstActivationDateWithinLast14DaysMemo();

	return isActiveWithin14Days && isActiveRoute && sampleDataBulkFeatureEnabled;
};
