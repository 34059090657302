import { useMemo } from 'react';
import difference from 'lodash/difference';
import type { JiraConfigurableNavigationItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { USER_NAVIGATION_CONFIGURATION_DEFAULT_MENU_IDS } from '../../common/constants/index.tsx';

// Add back any menu ids missing from the nav items.
// Add them to the end with a default visibility of true.
export const useRefreshL1MenuItems = (
	navItems: readonly JiraConfigurableNavigationItem[] = [],
): JiraConfigurableNavigationItem[] =>
	useMemo(() => {
		const missingMenuIds = difference(
			USER_NAVIGATION_CONFIGURATION_DEFAULT_MENU_IDS,
			navItems.map(({ menuId }) => menuId),
		);

		return [...navItems, ...missingMenuIds.map((menuId) => ({ menuId, isVisible: true }))];
	}, [navItems]);
