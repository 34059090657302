import React, { useCallback } from 'react';
import { useInvitePeopleModal as useInvitePeopleModalPTC } from '@atlassian/invite-people/InvitePeopleModalContext';
import type { OnSubmitData } from '@atlassian/invite-people/types';
import { useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { projectTypeToSubProduct } from '@atlassian/jira-invite-people-drawer/src/ui/content/utils.tsx';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Flag } from '@atlassian/people-teams';

export const useInvitePeopleModal = () => {
	const { showFlag } = useFlagsService();
	const { data: projectSidebarNavigationData } = useProjectSidebarNavigation();
	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();
	const { software } = useAppEditions();

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const continueUrl = window.location.href;
	const subProduct = projectTypeToSubProduct(projectSidebarNavigationData?.projectType);

	const addFlag = useCallback(
		({ appearance, ...flagProps }: Flag) => {
			const Flag = getFlagComponent(appearance);
			showFlag((serviceProps) => (
				<Flag
					{...flagProps}
					{...serviceProps}
					messageId={`navigation-apps-sidebar-nav4-sidebars-content-teams.ui.flag.showFlag.${appearance}.${flagProps.id}`}
					messageType="transactional"
				/>
			));
		},
		[showFlag],
	);

	const { openModal: invitePeopleModalTrigger } = useInvitePeopleModalPTC({
		continueUrl,
		enableThirdParty: true,
		displayInfiSpotlight: true,
		orgId,
		resourceAri: `ari:cloud:jira::site/${cloudId}`,
		showFlag: addFlag,
		source: 'peopleMenu',
		subProduct,
		experimental_applicationEdition: software,
		navigation: { directoryContextEntryPoint: '/jira' },
		onSubmit: async (data: OnSubmitData) => {
			await data.defaultOnSubmit();
		},
	});

	return invitePeopleModalTrigger;
};

const getFlagComponent = (appearance: Flag['appearance']) => {
	switch (appearance) {
		case 'warning':
			return WarningFlag;
		case 'error':
			return ErrorFlag;
		case 'success':
		case 'normal':
		default:
			return SuccessFlag;
	}
};
