import React, { type ReactNode, type ComponentType } from 'react';
import { token } from '@atlaskit/tokens';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { messages } from './messages.tsx';

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;

interface Props {
	target: string;
	actionsBeforeElement: ReactNode;
	actions: (arg0: { learnMoreURL: string }) => SpotlightProps['actions'];
	headingAfterElement: ReactNode;
}

export const ViewsSpotlight = ({
	target,
	actionsBeforeElement,
	actions,
	headingAfterElement,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<JiraSpotlight
			target={target}
			targetRadius={0}
			targetBgColor={token('elevation.surface')}
			heading={formatMessage(messages.heading)}
			actionsBeforeElement={actionsBeforeElement}
			actions={actions({
				learnMoreURL:
					'https://support.atlassian.com/jira-software-cloud/docs/how-do-i-navigate-advanced-roadmaps/',
			})}
			headingAfterElement={headingAfterElement}
			messageId="portfolio-3-onboarding.ui.onboarding.spotlight-views.jira-spotlight"
			messageType="transactional"
		>
			<FormattedMessage {...messages.description} />
		</JiraSpotlight>
	);
};
