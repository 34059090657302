/**
 * @generated SignedSource<<c3191247b5c75c60a61a1fec51272b27>>
 * @relayHash f34a04ee03a83741f50650a7022072e5
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b25892c4ffb94e0308a97e602409dd2a93bf85afe5b20ffbf51186d3cabe1780

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JswExpandableProjectQuery$variables = {
  cloudId: string;
  noOfBoardsToFetch: number;
  projectKey: string;
};
export type JswExpandableProjectQuery$data = {
  readonly jira: {
    readonly jiraProjectByKey: {
      readonly " $fragmentSpreads": FragmentRefs<"JswBoards">;
    };
  };
};
export type JswExpandableProjectQuery = {
  response: JswExpandableProjectQuery$data;
  variables: JswExpandableProjectQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "noOfBoardsToFetch"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v3 = [
  {
    "kind": "Variable",
    "name": "cloudId",
    "variableName": "cloudId"
  },
  {
    "kind": "Variable",
    "name": "key",
    "variableName": "projectKey"
  }
],
v4 = {
  "kind": "ScalarField",
  "name": "id"
},
v5 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v6 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v7 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "JswExpandableProjectQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v3/*: any*/),
                "concreteType": "JiraProject",
                "kind": "LinkedField",
                "name": "jiraProjectByKey",
                "plural": false,
                "selections": [
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "noOfBoardsToFetch",
                        "variableName": "noOfBoardsToFetch"
                      }
                    ],
                    "kind": "FragmentSpread",
                    "name": "JswBoards"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.jiraProjectByKey"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "JswExpandableProjectQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v3/*: any*/),
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jiraProjectByKey",
            "plural": false,
            "selections": [
              {
                "alias": "projectKey",
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "first",
                    "variableName": "noOfBoardsToFetch"
                  }
                ],
                "concreteType": "JiraBoardConnection",
                "kind": "LinkedField",
                "name": "boards",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "JiraBoardEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "concreteType": "JiraBoard",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          (v6/*: any*/),
                          {
                            "kind": "LinkedField",
                            "name": "defaultNavigationItem",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "url"
                                  }
                                ],
                                "type": "JiraNavigationItem",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              (v8/*: any*/)
                            ]
                          },
                          {
                            "concreteType": "JiraFavouriteValue",
                            "kind": "LinkedField",
                            "name": "favouriteValue",
                            "plural": false,
                            "selections": [
                              (v5/*: any*/),
                              (v4/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "boardType"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "canEdit"
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "__id"
                              }
                            ]
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "kind": "LinkedField",
                "name": "mostRecentlyViewedBoard",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "type": "JiraBoard"
                  },
                  (v8/*: any*/)
                ]
              },
              {
                "kind": "ScalarField",
                "name": "softwareBoardCount"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b25892c4ffb94e0308a97e602409dd2a93bf85afe5b20ffbf51186d3cabe1780",
    "metadata": {},
    "name": "JswExpandableProjectQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c86c96423726df260003e830175ef141";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
