/**
 * @fileOverview
 * All variables that get passed in to the mainIssueAggQuery are defined here.
 * The abstraction is necessary because there are multiple call paths to mainIssueAggQuery,
 * so having a single source of truth for the variables eliminates a vector for
 * bugs and inconsistencies when they are invoked.
 *
 * This file will be removed (and therefore the need for the linting exemption) once we reduce
 * the number of discrete call paths for mainIssueAggQuery to one. That work will be done in
 * https://jira.atlassian.com/browse/JIV-19486.
 *
 * Sorry, Tom.
 *
 * <3 Daz
 */
/* eslint-disable jira/ff/inline-usage */
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { getProductOfferingKeyOrThrow } from '@atlassian/jira-growth-utils/src/services/get-product-offering-key/index.tsx';
import { SOFTWARE } from '@atlassian/jira-shared-types/src/application-key.tsx';
import { STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import mainIssueAggQuery from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery$parameters';
import {
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	ISSUE_ACTION_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_PANEL_MODULE,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
	UI_MODIFICATIONS_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { getPermalinkId, getPermalinkIdFromURL, COMMENTS } from '../../common/utils/permalinks.tsx';

const _unused = () => fg('jiv-19486-allow-fg-usage-in-early-scripts');

type ProvidedVar = (typeof mainIssueAggQuery)['params']['providedVariables'];
type ProvidedVariables = {
	[k in keyof ProvidedVar]: unknown;
};

export const getMainIssueViewAggProvidedVariables = (): ProvidedVariables => {
	const providerVariables: Record<ProvidedVar, { get: () => unknown }> =
		mainIssueAggQuery?.params?.providedVariables ?? {};
	return Object.entries(providerVariables).reduce(
		(acc: Record<ProvidedVar, unknown>, [key, provider]) => {
			acc[key] = provider.get();
			return acc;
		},
		{},
	);
};

export const getMainIssueViewAggQueryFeatureFlagVariables = () => ({
	isCompassComponentsEnabled: true,
	giraAGGMigrationCommentsFlag:
		fg('jiv-14067-issue-details-agg-migration-comments') ||
		fg('issue-jiv-14530-comments-consistency-check'),

	jiraCommentsAGGPagination:
		fg('jira_comments_agg_pagination') || fg('jira_comments_agg_pagination_consistency_check'),

	issueViewRelayConfigurationUrlFlag: fg('jiv-replace-configuration-button-in-context-panel'),

	jsmSentimentCustomFieldFlag: true,
	isJPDPanelEnabled: fg('jpd_idea_panel_in_issue_view'),
	issueViewRelayPriorityFieldFlag: fg('relay-migration-issue-fields-priority'),

	// if assignee/reporter/user is true, it represents SingleSelectUserPicker being used
	issueViewRelayAssigneeFieldFlag:
		fg('relay-migration-issue-fields-assignee-fg') ||
		fg('relay-migration-issue-fields-reporter') ||
		fg('relay-migration-issue-fields-user-fg'),

	issueViewRelayNumberFieldFlag: fg('relay-migration-issue-fields-number'),
	issueViewRelaySprintFieldFlag: fg('relay-migration-issue-fields-sprint'),
	issueViewRelayAttachmentsFlag: fg('relay-migration-issue-view-attachments-'),

	issueViewRelaySingleLineTextFieldFlag:
		fg('relay-migration-issue-fields-single-line-text-fg') ||
		fg('relay-migration-issue-fields-single-line-text-msg'),

	issueViewRelayDateFieldFlag: fg('relay-migration-issue-fields-date-sg'),

	issueViewRelayParentFieldFlag: fg('relay-migration-issue-header-and-parent'),
	issueViewRelayProjectFieldFlag: fg('relay-migration-issue-fields-project-select'),
	issueViewRelayDateTimeFieldFlag: fg('relay-migration-issue-fields-date-time-fg'),
	issueViewRelayMetadataFieldFlag: fg('relay-migration-issue-fields-metadata'),
	issueViewDesignsPanelFlag: expVal(
		'collapsed_designs_panel',
		'isCollapsedDesignsPanelEnabled',
		false,
	),

	isJPDDeliveryLinkTypeFilteringEnabled: fg('jpd_idea_panel_in_issue_view'),

	issueViewRelayUrlFieldFlag: fg('relay-migration-issue-fields-url'),

	issueViewRemoveSingleVersionFieldOptionsFlag: fg('relay-migration-issue-fields-issue-version'),

	issueViewRemoveComponentsFieldFlag: fg('jiv-19656-remove-field-options'),
	issueViewRemoveCheckboxSelectFieldOptionsFlag: fg('remove_issue_field_options_main_query'),

	issueViewRelaySummaryFieldFlag: fg('relay-migration-issue-fields-summary'),

	issueViewRelayColorFieldFlag: fg('relay-migration-issue-fields-color'),

	issueViewRelayHeaders: fg('relay-migration-issue-header-and-parent'),

	issueViewRelayPeopleAndApproversFieldFlag:
		fg('relay-migration-issue-fields-people-fg') || fg('relay-migration-issue-fields-approvers-fg'),

	issueViewRelayTimeTrackingFieldFlag: fg('relay-migration-issue-fields-time-tracking'),

	issueViewRemoveRadioSelectFieldOptionsFlag: fg('relay-migration-issue-fields-radio'),

	customFieldConfigurationFlag: fg('issue_view_field_config_edit'),

	isPinFieldConfigurabilityEnabled: true,

	jcsIssueLayoutEapFlag: fg('jcs_issue_layout_eap'),

	issueViewSmartRepliesSettingsFlag: fg('smart-replies-system-setting') ?? false,

	issueViewStopFetchingJiraQueryIsAiEnabledForIssue: fg(
		'jiv-19647-stop-fetching-jira-isaienabledforissue',
	),

	issueViewMoveJiraQueryIsAiEnabledProjectField: fg(
		'issue_view_move_isaienable_from_project_field',
	),

	dateFieldsMessageEnabled: expValEquals('jsw_start_and_due_dates', 'cohort', 'variation'),

	chinEmbedQueryToAgg: fg('issue-jiv-19838-chin-api-lookup-through-issue-key'),
	jiraThreadedCommentsEnabled: fg('threaded_comments_fetch_only'),
	queryRankFieldInMainIssueAggFlag: fg('issue-jiv-19928-query-rank-field-mainissueaggquery'),
	issueViewActivityLayoutFieldEnabled: expVal(
		'issue-view-side-panel-activity',
		'isActivityInSidePanel',
		false,
	),
});

// JIV-19906: retrieve query string from URL -- only expected to be used by client side code
// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const getURLFromWindow = () => new URL(window.location.href);

// JIV-19906: retrieve query string from url
// server-side: expect url to be passed
// client-side: read from window
export const getMainIssueViewAggQueryVariables = (
	issueKey: string | null,
	url: URL = getURLFromWindow(),
) => ({
	...getMainIssueViewAggProvidedVariables(),
	...getMainIssueViewAggQueryFeatureFlagVariables(),
	...(fg('jiv-19906-fix-targered-permalink-on-server')
		? getCommentQueriesFromURL(url)
		: getCommentQueries()),
	...getStorageBannerVariables(),
	...getIssueSearchForChildIssueQueryVariables(issueKey),
	...getForgeSpecificVariables(),
});

const getIssueSearchForChildIssueQueryVariables = (issueKey: string | null) => ({
	// will have to put jql order by rank later
	issueSearchInput: { childIssuesInput: { parentIssueKey: issueKey || '' } },
	amountOfColumns: 500,
	namespace: 'CHILD_ISSUE_PANEL',
	fieldSetIds: [],
	shouldQueryFieldSetsById: false,
	fieldSetsInput: {
		viewInput: {
			namespace: 'CHILD_ISSUE_PANEL',
			context: {
				issueContext: {
					issueKey,
				},
			},
		},
	},
	fieldSetsContext: { issueContext: { issueKey } },
	filterId: null,
	viewId: null,
});

export const getCommentQueries = () => {
	const commentTargetId =
		fg('jiv-14067-issue-details-agg-migration-comments') ||
		fg('issue-jiv-14530-comments-consistency-check')
			? getPermalinkId(COMMENTS) || null
			: null;

	return {
		commentTargetId,
		hasCommentTargetId: !!commentTargetId,
	};
};

export const getCommentQueriesFromURL = (url: URL) => {
	let commentTargetId = null;

	if (
		!fg('jiv-14067-issue-details-agg-migration-comments') &&
		!fg('issue-jiv-14530-comments-consistency-check')
	) {
		commentTargetId = null;
	} else if (fg('jiv-19906-fix-targered-permalink-on-server')) {
		commentTargetId = getPermalinkIdFromURL(COMMENTS, url);
	} else {
		commentTargetId = getPermalinkId(COMMENTS);
	}

	return {
		commentTargetId,
		hasCommentTargetId: !!commentTargetId,
	};
};

const getStorageBannerVariables = () => ({
	issueViewStorageLimitsBannerFlag: fg('move_storage_banner_data_fetch_to_main_issue_agg'),
	offeringKey: getProductOfferingKeyOrThrow(SOFTWARE, STANDARD_EDITION),
	productKey: SOFTWARE,
});

const getForgeSpecificVariables = () => {
	return {
		forgeInMainIssueAggFlag: fg('move_forge_data_fetch_to_main_issue_agg'),
		forgeTypes: fg('move_forge_data_fetch_to_main_issue_agg')
			? [
					ISSUE_ACTION_MODULE,
					ISSUE_GLANCE_MODULE,
					ISSUE_CONTEXT_MODULE,
					ISSUE_PANEL_MODULE,
					ISSUE_ACTIVITY_MODULE,
					CUSTOM_FIELD_MODULE,
					CUSTOM_FIELD_TYPE_MODULE,
					ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
					UI_MODIFICATIONS_MODULE,
				]
			: [],
		includeUserAccessForge: fg('forge_query_include_user_access'),
	};
};
