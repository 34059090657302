import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncDirectoryNav } from '@atlassian/jira-horizontal-nav-jsm-directory/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { customerServiceProductsQueryResource } from '@atlassian/jira-router-resources-service-desk-customer-service-products/src/services/index.tsx';
import { customerServiceProductsRoute } from '@atlassian/jira-router-routes-servicedesk-customer-service-products-routes/src/customerServiceProductsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { ProductsAppLayout as ServicedeskCustomerServiceProductsType } from '@atlassian/jira-spa-apps-servicedesk-customer-service-products/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyServicedeskCustomerServiceProducts = lazyForPaint<
	typeof ServicedeskCustomerServiceProductsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customer-service-products", jiraSpaEntry: "async-servicedesk-customer-service-products" */ '@atlassian/jira-spa-apps-servicedesk-customer-service-products'
		),
	),
);

const ServicedeskCustomerServiceProducts = () => (
	<LazyPage
		Page={LazyServicedeskCustomerServiceProducts}
		pageId="spa-apps/servicedesk/customer-service-products"
		shouldShowSpinner
	/>
);

export const customerServiceProductsRouteEntry = createEntry(customerServiceProductsRoute, () => ({
	group: 'servicedesk',
	layout: serviceProjectLayout,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncDirectoryNav,
		// TODO: Remove this during jira_nav4 cleanup
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		customerServiceProductsQueryResource,
	],

	component: ServicedeskCustomerServiceProducts,
	perfMetricKey: 'jsm-customer-service-products-component-load',
	forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerServiceProducts],

	meta: {
		reporting: {
			id: APP_NAMES.CUSTOMER_SERVICE_PRODUCTS,
			packageName: toPackageName(APP_NAMES.CUSTOMER_SERVICE_PRODUCTS),
			teamName: 'boysenberry',
		},
	},
}));
