/* eslint-disable jira/import-whitelist */
import { loadQuery, type PreloadedQuery } from 'react-relay';
import { JiraFilterAri } from '@atlassian/ari/jira/filter';
import { expVal, expValEquals } from '@atlassian/jira-feature-experiments';
import {
	isCustomFilter,
	isFilterId,
} from '@atlassian/jira-issue-navigator-actions-common/src/utils/filters/index.tsx';
import {
	getMaxIssuesPerPage,
	ISSUE_SEARCH_NAMESPACE,
	MAX_AMOUNT_OF_COLUMNS,
	NATURAL_LANGUAGE_TO_JQL,
	NIN_GLOBAL_OPERATION_SCOPE,
	NIN_GLOBAL_SCHEMA_REFACTOR_OPERATION_SCOPE,
	NIN_PROJECT_OPERATION_SCOPE,
	NIN_PROJECT_SCHEMA_REFACTOR_OPERATION_SCOPE,
} from '@atlassian/jira-issue-navigator/src/common/constants.tsx';
import { createAri } from '@atlassian/jira-platform-ari/src/index.tsx';
import getRelayEnvironment from '@atlassian/jira-relay-environment/src/index.tsx';
import { QueryPromisesMap } from '@atlassian/jira-relay-query-promises/src/index.tsx';
import { RELAY_RESOURCE_TYPE } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import HydrationRelayQuery, {
	type hydrateJqlBuilderQuery as HydrationRelayQueryType,
	type hydrateJqlBuilderQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/hydrateJqlBuilderQuery.graphql';
import HeaderFilterRelayQuery, {
	type main_FilterQuery as FilterRelayQueryType,
} from '@atlassian/jira-relay/src/__generated__/main_FilterQuery.graphql';
import IssueNavigatorRelayQuery, {
	type JiraAtlassianIntelligenceFeatureEnum,
	type JiraIssueSearchOperationScope,
	type main_IssueNavigatorQuery as IssueNavigatorRelayQueryType,
	type main_IssueNavigatorQuery$variables,
} from '@atlassian/jira-relay/src/__generated__/main_IssueNavigatorQuery.graphql';
import { createResource } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { isRefactorNinToViewSchemaEnabled } from '@atlassian/jira-issue-navigator-rollout/src/is-refactor-nin-to-view-schema-enabled.tsx';
import {
	RESOURCE_TYPE_ISSUE_NAVIGATOR_RELAY,
	RESOURCE_TYPE_ISSUE_NAVIGATOR_FILTER_RELAY,
	RESOURCE_TYPE_ISSUE_NAVIGATOR_HYDRATION_RELAY,
} from './constants.tsx';
import { getJqlOrFilterId, getParams } from './controllers/get-data/utils.tsx';

/**
 * We are using `createResource` instead of `createRelayResource` for two main reasons:
 * 1. The `getKey` provided by `createRelayResource` triggers this resource to reload every time a URL parameters change
 *    causes the query variables to change, preventing us from using our own custom reload hooks that are further down
 *    in Issue Navigator. This results in a full-page loading state any time one of the page params changes.
 * 2. Disabling this resource for SSR+Anonymous users (see comment below in `getData`)
 */
export const issueNavigatorRelayResource = createResource<
	PreloadedQuery<IssueNavigatorRelayQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_ISSUE_NAVIGATOR_RELAY}`,
	getKey: () => 'issue-navigator-relay',
	// @ts-expect-error - might return null
	getData: async (routerContext, { tenantContext }) => {
		// SSR/AGG does not currently work for anonymous users and returns a 403. We should enable anonymous SSR once
		// the underlying auth issues have been fixed. See more details here https://hello.atlassian.net/wiki/spaces/~hkim/pages/1853841608/Why+are+Jira+SSR+requests+to+AGG+not+working+for+anonymous+users
		if (__SERVER__ && tenantContext.isAnonymous) {
			return null;
		}

		const { projectKey, issueKey, jql: jqlParam, defaultJql, filter } = getParams(routerContext);

		const jqlOrFilterId = getJqlOrFilterId(defaultJql, jqlParam, filter, projectKey);

		const maxIssuesPerPage = getMaxIssuesPerPage();

		const viewConfigInput =
			projectKey !== undefined && projectKey !== null
				? { viewInput: { namespace: ISSUE_SEARCH_NAMESPACE } }
				: { staticViewInput: { isHierarchyEnabled: false } };

		let operationScope: JiraIssueSearchOperationScope;

		if (projectKey !== undefined && projectKey !== null) {
			operationScope = isRefactorNinToViewSchemaEnabled()
				? NIN_PROJECT_SCHEMA_REFACTOR_OPERATION_SCOPE
				: NIN_PROJECT_OPERATION_SCOPE;
		} else {
			operationScope = isRefactorNinToViewSchemaEnabled()
				? NIN_GLOBAL_SCHEMA_REFACTOR_OPERATION_SCOPE
				: NIN_GLOBAL_OPERATION_SCOPE;
		}

		const variables: main_IssueNavigatorQuery$variables = {
			cloudId: tenantContext.cloudId,
			first: issueKey == null ? maxIssuesPerPage : null,
			issueSearchInput: jqlOrFilterId,
			namespace: ISSUE_SEARCH_NAMESPACE,
			fieldSetsInput: expVal('jira_spreadsheet_component_m1', 'isInfiniteScrollingEnabled', false)
				? {
						viewInput: {
							namespace: ISSUE_SEARCH_NAMESPACE,
							filterId: Boolean(filter) && isCustomFilter(filter) ? filter : null,
						},
					}
				: null,
			after: null,
			options: { issueKey },
			fieldSetIds: [],
			shouldQueryFieldSetsById: false,
			filterId: Boolean(filter) && isCustomFilter(filter) ? filter : null,
			amountOfColumns: MAX_AMOUNT_OF_COLUMNS,
			shouldQueryHasChildren:
				projectKey !== null &&
				projectKey !== undefined &&
				expVal('jsc_m2_hierarchy', 'isHierarchyEnabled', false),
			...(fg('jsc_nin_operation_scope_fe') && {
				scope: { operationScope },
			}),
			...(expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
				expVal('jsc_m2_hierarchy', 'isHierarchyEnabled', false) && {
					viewConfigInput,
				}),
			atlassianIntelligenceProductFeature:
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				NATURAL_LANGUAGE_TO_JQL as JiraAtlassianIntelligenceFeatureEnum,
			projectKey: projectKey ?? '',
			shouldQueryProject:
				Boolean(projectKey) &&
				((expValEquals('jira_spreadsheet_component_m1', 'cohort', 'experiment') &&
					expVal('jira_list_realtime_updates', 'isEnabled', false)) ||
					(expVal('jira_spreadsheet_component_m1', 'isHierarchyEnabled', false) &&
						expVal('jsc_m2_hierarchy', 'isHierarchyEnabled', false))),
			groupBy: null,
		};

		const queryReference = loadQuery<IssueNavigatorRelayQueryType>(
			getRelayEnvironment(),
			IssueNavigatorRelayQuery,
			variables,
			{
				fetchPolicy: 'network-only',
				networkCacheConfig: {
					metadata: { META_SLOW_ENDPOINT: true },
				},
			},
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
});

/**
 * We are using `createResource` instead of `createRelayResource` for two main reasons:
 * 1. The `getKey` provided by `createRelayResource` triggers this resource to reload every time a URL parameters change
 *    causes the query variables to change, preventing us from using our own custom reload hooks that are further down
 *    in Issue Navigator. This results in a full-page loading state any time one of the page params changes.
 * 2. Disabling this resource for SSR+Anonymous users (see comment below in `getData`)
 */
export const issueNavigatorFilterRelayResource = createResource<
	PreloadedQuery<FilterRelayQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_ISSUE_NAVIGATOR_FILTER_RELAY}`,
	getKey: () => 'issue-navigator-filter-relay',
	// @ts-expect-error - might return null
	getData: async (routerContext, { tenantContext }) => {
		// SSR/AGG does not currently work for anonymous users and returns a 403. We should enable anonymous SSR once
		// the underlying auth issues have been fixed. See more details here https://hello.atlassian.net/wiki/spaces/~hkim/pages/1853841608/Why+are+Jira+SSR+requests+to+AGG+not+working+for+anonymous+users
		if (__SERVER__ && tenantContext.isAnonymous) {
			return null;
		}

		const { filter } = getParams(routerContext);
		const includeFilter = Boolean(filter) && isFilterId(filter);

		const variables = {
			includeFilter,
			filterAri: includeFilter
				? createAri({
						resourceOwner: 'jira',
						cloudId: tenantContext.cloudId,
						resourceType: 'filter',
						resourceId: filter,
						activationId: tenantContext.activationId,
					})
				: '',
		};
		const options = { fetchPolicy: includeFilter ? 'network-only' : 'store-only' } as const;

		const queryReference = loadQuery(
			getRelayEnvironment(),
			HeaderFilterRelayQuery,
			variables,
			options,
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
});

/**
 * We are using `createResource` instead of `createRelayResource` for two main reasons:
 * 1. The `getKey` provided by `createRelayResource` triggers this resource to reload every time a URL parameters change
 *    causes the query variables to change, preventing us from using our own custom reload hooks that are further down
 *    in Issue Navigator. This results in a full-page loading state any time one of the page params changes.
 * 2. Disabling this resource for SSR+Anonymous users (see comment below in `getData`)
 */
export const issueNavigatorHydrationRelayResource = createResource<
	PreloadedQuery<HydrationRelayQueryType>
>({
	type: `${RELAY_RESOURCE_TYPE}_${RESOURCE_TYPE_ISSUE_NAVIGATOR_HYDRATION_RELAY}`,
	getKey: () => 'issue-navigator-hydration-relay',
	// @ts-expect-error - might return null
	getData: async (routerContext, { tenantContext }) => {
		// SSR/AGG does not currently work for anonymous users and returns a 403. We should enable anonymous SSR once
		// the underlying auth issues have been fixed. See more details here https://hello.atlassian.net/wiki/spaces/~hkim/pages/1853841608/Why+are+Jira+SSR+requests+to+AGG+not+working+for+anonymous+users
		if (__SERVER__ && tenantContext.isAnonymous) {
			return null;
		}

		const { projectKey, jql: jqlParam, defaultJql, filter } = getParams(routerContext);

		const jqlOrFilterId = getJqlOrFilterId(defaultJql, jqlParam, filter, projectKey);

		const variables: hydrateJqlBuilderQuery$variables = {
			accountId: tenantContext.atlassianAccountId ?? '',
			cloudId: tenantContext.cloudId,
			includeUser: true,
			includeJira: true,
			isFilter: 'filterId' in jqlOrFilterId,
			jql: ('jql' in jqlOrFilterId && jqlOrFilterId.jql) || '',
			filterAri:
				('filterId' in jqlOrFilterId &&
					JiraFilterAri.create({
						siteId: tenantContext.cloudId,
						filterId: filter,
						activationId: tenantContext.activationId,
					}).toString()) ||
				'',
			viewContext: null,
		};

		const queryReference = loadQuery<HydrationRelayQueryType>(
			getRelayEnvironment(),
			HydrationRelayQuery,
			variables,
			{
				fetchPolicy: 'network-only',
				networkCacheConfig: {
					metadata: { META_SLOW_ENDPOINT: true },
				},
			},
		);

		if (__SERVER__ && queryReference.id != null) {
			await QueryPromisesMap.get(queryReference.id);
		}

		return queryReference;
	},
	maxAge: 0,
	isBrowserOnly: true,
});
