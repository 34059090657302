import React, { type FC } from 'react';
import { MenuGroup } from '@atlaskit/menu';
import { BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import { FIXED_ITEM_BUTTON } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { LEVEL_ONE } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { SECTION_ITEM_TEAMS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import {
	type ConfigurableMenuId,
	L1_MENU_ID,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import { MEATBALL_DROPDOWN } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/constants.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { MenuLinkItem } from '@atlassian/navigation-system';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import type { StubProps } from './types.tsx';

export const Stub: FC<StubProps> = ({ menuId, href }) => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(menuId);
	const location = useLocation();

	const path = location.pathname + location.search;
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	return (
		<MenuLinkItem
			elemBefore={icon}
			href={href}
			isSelected={path === href}
			actionsOnHover={<ActionButtons menuId={menuId} />}
			onClick={() => {
				if (menuId === L1_MENU_ID.TEAMS) {
					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.TEAMS,
						sectionItem: SECTION_ITEM_TEAMS,
					});
				}
			}}
		>
			{formattedMessage}
		</MenuLinkItem>
	);
};

type ActionButtonsProps = {
	menuId: ConfigurableMenuId;
};

function ActionButtons({ menuId }: ActionButtonsProps) {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<MoreNavMenuButton
			onOpen={() => {
				fireUIAnalytics(createAnalyticsEvent({}), {
					action: 'clicked',
					actionSubject: BUTTON,
					actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[menuId],
					attributes: {
						level: LEVEL_ONE,
						componentType: FIXED_ITEM_BUTTON,
						itemId: MEATBALL_DROPDOWN,
					},
				});
			}}
			MenuItems={() => (
				<MenuGroup>
					<HideL1MenuItemSection menuId={menuId} hasSeparator={false} />
				</MenuGroup>
			)}
		/>
	);
}
