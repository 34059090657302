/**
 * @generated SignedSource<<2161716a6a9ddd596df65d397a277cb5>>
 * @relayHash 4f557a85d513d521d9088e7217fed3ad
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 15dd5fffb84db9cd2e32bf1439d87098cf295ac5f7e1bcaae80f031fdad2d763

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummaryBusinessEmbedQuery } from './src_jiraSummaryBusinessEmbedQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummaryBusinessEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "15dd5fffb84db9cd2e32bf1439d87098cf295ac5f7e1bcaae80f031fdad2d763",
    "metadata": {},
    "name": "src_jiraSummaryBusinessEmbedQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
