import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	showMenuItemLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.show-action.show-menu-item-label',
		defaultMessage: 'Show',
		description: 'Used as the button label text for the Show menu item',
	},
	starredAndRecentProjectsLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.show-action.starred-and-recent-projects-label',
		defaultMessage: 'Starred & recent projects',
		description: 'Used as the label text for the Starred & recent projects option',
	},
	starredProjectsLabel: {
		id: 'navigation-apps-sidebar-nav4-display-settings.show-action.starred-projects-label',
		defaultMessage: 'Starred projects',
		description: 'Used as the label text for the Starred projects option',
	},
	listBulletedIcon: {
		id: 'navigation-apps-sidebar-nav4-display-settings.show-action.list-bulleted-icon',
		defaultMessage: 'List bulleted icon',
		description: 'The label for the list bulleted icon',
	},
	chevronRightIcon: {
		id: 'navigation-apps-sidebar-nav4-display-settings.show-action.chevron-right-icon',
		defaultMessage: 'Chevron right icon',
		description: 'The label for the chevron right icon',
	},
});
