/**
 * @generated SignedSource<<ce6af81d1c78a2a96628dde876158682>>
 * @relayHash f31e9e579795e01b9614f54b2db7ea26
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cafe23f9428a9add80e3022c3bcd12d7434fe4ed34b1cdbd6b9b11634ffe320c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavJiraInnerQuery } from './HorizontalNavJiraInnerQuery.graphql';

import isJiraCustomTemplatesUxEnabled_provider from '@atlassian/jira-relay-provider/src/jira-custom-templates-ux-fg.relayprovider';

const node: PreloadableConcreteRequest<HorizontalNavJiraInnerQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "cafe23f9428a9add80e3022c3bcd12d7434fe4ed34b1cdbd6b9b11634ffe320c",
    "metadata": {},
    "name": "HorizontalNavJiraInnerQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider": isJiraCustomTemplatesUxEnabled_provider
    }
  }
};

export default node;
