import React from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import PlanArchivingAsync from '@atlassian/jira-portfolio-3-plan-archiving/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = {
	planId: number;
	scenarioId: number;
	planTitle: string;
	isArchived: boolean;
	setClosed: () => void;
	setPlanArchiveStatus: () => void;
};

export const ArchivePlanModal = ({
	planId,
	scenarioId,
	planTitle,
	isArchived,
	setClosed,
	setPlanArchiveStatus,
}: Props) => {
	const { showFlag } = useFlagsService();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();

	const onComplete = () => {
		setPlanArchiveStatus();

		const title = isArchived ? messages.restored : messages.archived;
		showFlag({
			title,
			type: 'success',
			isAutoDismiss: false,
			messageId: 'jet-plan-action-menu.ui.archive-modal.plan-archiving-async.showFlag.onComplete',
			messageType: 'transactional',
		});

		push(`/jira/plans/${planId}/scenarios/${scenarioId}`);
	};

	const onError = () => {
		setClosed();

		showFlag({
			id: isArchived ? 'restorePlanError' : 'archivePlanError',
			title: isArchived ? messages.restoreErrorTitle : messages.archiveErrorTitle,
			description: isArchived
				? formatMessage(messages.restoreErrorDescription, { planTitle })
				: formatMessage(messages.archiveErrorDescription, {
						planTitle,
					}),
			type: 'error',
			messageId: 'jet-plan-action-menu.ui.archive-modal.plan-archiving-async.showFlag.onError',
			messageType: 'transactional',
		});
	};

	return (
		<PlanArchivingAsync
			planId={planId}
			planTitle={planTitle}
			isArchived={isArchived}
			onClose={setClosed}
			onComplete={onComplete}
			onError={onError}
			fallback={null}
		/>
	);
};
