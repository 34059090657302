/**
 * Get the hostname for the Pillar development server.
 *
 * In the always-on RDE, it will have a proper DNS name. In local development, it
 * will default to `localhost`.
 *
 * @returns The hostname for the development server.
 */
export const getDevHostname = (): string => {
	const envDns = process.env.RDE_DNS;
	return typeof envDns === 'string' && envDns.trim() ? envDns.trim() : 'localhost';
};

export function getProxyServerPort() {
	if (process.env.__PILLAR_PROXY_SERVER_PORT) {
		return parseInt(process.env.__PILLAR_PROXY_SERVER_PORT, 10);
	}

	return 9000;
}
