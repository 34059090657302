import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { getNextgenSettingsIssueTypesUrl } from '@atlassian/jira-project-settings-apps-common/src/urls.tsx';
import { legacyProjectSettingsWorkflowsRoute } from '@atlassian/jira-router-routes-legacy-project-settings-workflows-routes/src/legacyProjectSettingsWorkflowsRoute.tsx';
import { projectSettingsServicedeskTicketTypesConfigRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-ticket-types-config-routes/src/projectSettingsServicedeskTicketTypesConfigRoute.tsx';
import { projectsRedirectRoute } from '@atlassian/jira-router-routes-projects-redirect-routes/src/projectsRedirectRoute.tsx';
import { generatePath } from '@atlassian/react-resource-router';

export const getProjectUrl = (projectKey: string): string =>
	generatePath(projectsRedirectRoute.path, { projectKey });

const getNextgenIssueTypesUrl = (projectKey: string, issueTypeId?: string) =>
	getNextgenSettingsIssueTypesUrl(SERVICE_DESK_PROJECT, projectKey, issueTypeId);

export const getRequestTypesUrl = (projectKey: string, isSimplified = false): string =>
	isSimplified
		? getNextgenIssueTypesUrl(projectKey)
		: generatePath(projectSettingsServicedeskTicketTypesConfigRoute.path, {
				projectKey,
			});

export const getChangeRequestTypesUrl = (projectKey: string, isSimplified = false): string =>
	isSimplified
		? getNextgenIssueTypesUrl(projectKey)
		: `${getRequestTypesUrl(projectKey, isSimplified)}/category/changes`;

export const getProjectSettingsPageUrl = (projectKey: string, relativeUrl: string) =>
	`/jira/servicedesk/projects/${projectKey}/settings/${relativeUrl}`;

export const getProjectSettingsUrl = (projectKey: string, isSimplified = false): string => {
	const classicSettingsUrl = `/plugins/servlet/project-config/${projectKey}`;
	return isSimplified ? getNextgenIssueTypesUrl(projectKey) : classicSettingsUrl;
};

export const getWorkflowSettingsUrl = (
	projectKey: string,
	issueTypeId: string | null,
	isSimplified = false,
): string => {
	if (!issueTypeId) {
		return isSimplified
			? getNextgenIssueTypesUrl(projectKey)
			: generatePath(legacyProjectSettingsWorkflowsRoute.path, { projectKey });
	}

	return isSimplified
		? getNextgenIssueTypesUrl(projectKey, issueTypeId)
		: `/plugins/servlet/project-config/${projectKey}/issuetypes/${issueTypeId}/workflow`;
};

export const getJsmChangeEditionUrl = (): string =>
	'/admin/billing/applications/change-edition/jira-servicedesk.ondemand';

export const getUserManagementUrl = (): string => '/admin/users?referrerName=JIRA';
