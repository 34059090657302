import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { globalIssueNavigatorBrowseIssueRedirectRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/globalIssueNavigatorBrowseIssueRedirectRoute.tsx';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { getCommonGlobalNavigation } from './common/getCommonGlobalNavigation.tsx';
import { getCommonGlobalResources } from './common/getCommonGlobalResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import GinBrowseIssuesRedirect from './ui/gin-browse-issue-redirect/index.tsx';
import { LazyGlobalIssueNavigator } from './ui/index.tsx';

export const globalIssueNavigatorBrowseIssueRedirectRouteEntry = createEntry(
	globalIssueNavigatorBrowseIssueRedirectRoute,
	() => ({
		group: 'issue-navigator',
		...getIssueNavigatorRouteEntryCommon(),
		component: GinBrowseIssuesRedirect,
		skeleton: GlobalIssueNavigatorSkeleton,
		forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
		ufoName: 'global-issue-navigator-jfe',
		navigation: getCommonGlobalNavigation(),
		resources: [...getCommonGlobalResources()],
		layout: globalLayout,
	}),
);
