import type { CloudId } from '@atlassian/jira-shared-types/src/general.tsx';

export const canFetchForgeModules = ({
	isAnonymous,
	cloudId,
}: {
	isAnonymous: boolean;
	cloudId: CloudId;
}): boolean => {
	/**
	 * SMT-1142
	 * A tenant named `bob` is most likely a local/ci instance started with `./jmake run ci`.
	 * It wasn't possible to find the hostname in a reasonable timeframe so this is the most feasible solution.
	 * In case more tenants like this spawn in the future we'd need to mark these instances instead and block here.
	 */
	if (String(cloudId) === 'bob') return false;

	/**
	 * Similarly to the `bob` tenant situation above, we don't want to fetch Forge modules on monolith webdriver tests,
	 * that are also started with `./jmake run ci`, but this time with a valid `cloudId`.
	 * The reason for that is the heartbeat service that is not available in there, which leads to the login page redirects.
	 */
	if (!__SERVER__ && getLocationHostname().endsWith('.atl-test.space')) return false;

	// There is no anonymous access for now
	return !isAnonymous;
};

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
export const getLocationHostname = () => window.location.hostname;
