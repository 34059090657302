import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { newIssueViewLockInStatusResource } from '@atlassian/jira-router-resources-classic-projects/src/services/new-issue-view-lock-in-status/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskReportsTypeRoute } from '@atlassian/jira-router-routes-servicedesk-reports-routes/src/servicedeskReportsTypeRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getReportsWithKbResources } from './common/getReportsWithKbResources.tsx';
import ServiceDeskReports, { LazyServicedeskReports } from './ui/async-page/index.tsx';

export const servicedeskReportsTypeRouteEntry: Route = createEntry(
	servicedeskReportsTypeRoute,
	() => ({
		group: 'servicedesk',
		component: ServiceDeskReports,
		layout: composeLayouts(
			serviceProjectLayout,
			embeddedIssuePreloadLayoutBuilder([
				{
					params: [
						{
							key: 'issueKey',
						},
					],
				},
			]),
		),
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getReportsWithKbResources(),
			newIssueViewLockInStatusResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskReports],
	}),
);
