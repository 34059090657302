import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICES_ROUTE_SUFFIX,
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICE_HASHED_ARI,
} from './common/constants.tsx';

export const serviceDeskServiceDetailsWithoutProjectRoute: InvariantRoute = {
	name: 'servicedesk-service-details-without-project',
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICES_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};
