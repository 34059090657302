/**
 * @generated SignedSource<<a87b32dfff0117c772754c0ebb34b3fe>>
 * @relayHash 22ad87806c588e550963f41872bb555e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fe6e47f1f0a23dcd7457ec1b77217d229916e48d041abbc2ae765f3340074428

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListEmbedQuery } from './src_jiraSoftwareListEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraSoftwareListEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "fe6e47f1f0a23dcd7457ec1b77217d229916e48d041abbc2ae765f3340074428",
    "metadata": {},
    "name": "src_jiraSoftwareListEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
