/**
 * @generated SignedSource<<f3ba4e44c8e9245beb75073cf01b9608>>
 * @relayHash 53ebcedfde32e6f696cc7d8a2593a5b4
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f205f5060df8334465ed389c690fad308d9c1c3556c874884043528322963b4f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery } from './src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery.graphql';

const node: PreloadableConcreteRequest<src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f205f5060df8334465ed389c690fad308d9c1c3556c874884043528322963b4f",
    "metadata": {},
    "name": "src_projectsDirectoryTemplatesSidebar_RecommendationsInProjectListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
