import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncKbNav } from '@atlassian/jira-horizontal-nav-jsm-knowledge-base/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { knowledgeHubSuggestedArticlesRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubSuggestedArticlesRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import {
	ServiceDeskKnowledgeHubSuggestedArticles,
	LazyServicedeskKnowledgeHubSuggestedArticles,
} from './ui/suggested-articles/index.tsx';

export const knowledgeHubSuggestedArticlesRouteEntry: Route = createEntry(
	knowledgeHubSuggestedArticlesRoute,
	() => ({
		group: 'servicedesk',
		component: ServiceDeskKnowledgeHubSuggestedArticles,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncKbNav,
			// TODO: Remove this during jira_nav4 cleanup
			onlyShowHorizontalOnNav4: true,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubSuggestedArticles],
		perfMetricKey: 'service-management.kb-suggested-articles',
		meta: {
			reporting: {
				id: APP_NAMES.KB_SUGGESTED_ARTICLES,
				packageName: toPackageName(APP_NAMES.KB_SUGGESTED_ARTICLES),
				teamName: 'jsm-nebula',
			},
		},
	}),
);
