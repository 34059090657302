import React, { useEffect, type ReactNode } from 'react';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { useFlagService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import { isHelpCenterSettingsPath } from '../../utils/is-help-center-settings-path/index.tsx';
import { useIsJCS } from '../../utils/use-is-jcs/index.tsx';
import messages from './messages.tsx';

type Page =
	| 'kb'
	| 'customer-notifications'
	| 'email'
	| 'ticket-types-config'
	| 'ticket-type-fields';

type Props = {
	page: Page;
	children: ReactNode;
};

export const getPageForAnalytics = (page: Page, pathname: string) => {
	if (page === 'customer-notifications') {
		const customerNotificationsPage = pathname.split('/settings/customer-notifications')[1];

		if (customerNotificationsPage === '/template') {
			return 'customer-notifications-template';
		}
		if (customerNotificationsPage === '/account-notification/invite-customer') {
			return 'customer-notifications-invite-customer';
		}
		if (/rule\/\d+/.test(customerNotificationsPage)) {
			return 'customer-notifications-rule';
		}
	}

	return page;
};

// This component is used for redirecting users from project settings pages to
// their help center settings equivalents
export const HelpCenterSettingsRedirect = ({ page, children }: Props) => {
	const { showFlag } = useFlagService();
	const { formatMessage } = useIntl();
	const useLocation = createRouterSelector((state) => state.location);
	const location = useLocation();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const isJCS = useIsJCS();

	useEffect(() => {
		if (isJCS && !isHelpCenterSettingsPath()) {
			fireTrackAnalytics(
				createAnalyticsEvent({}),
				'projectSettingsToHelpCenterSettings redirected',
				{
					page: getPageForAnalytics(page, location.pathname),
				},
			);

			showFlag({
				type: 'error',
				title: formatMessage(messages.errorTitle),
				description: formatMessage(messages.errorDescription),
				isAutoDismiss: true,
			});
		}
	}, [createAnalyticsEvent, formatMessage, isJCS, location.pathname, page, showFlag]);

	if (isJCS && !isHelpCenterSettingsPath()) {
		return <ScreenRedirect to="/jira/helpcenter" />;
	}

	return children;
};
