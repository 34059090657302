/**
 * @generated SignedSource<<5c7203292bd5b069bb868106a50fcc3d>>
 * @relayHash 1673a82caa5b4f05b467356eeeaa21d2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 846b3d9cd87d4a46e948fa9dde705891fa46e2d5908f0c0beec23d6efbaf7af7

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineQuery } from './src_jiraBusinessTimelineQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "846b3d9cd87d4a46e948fa9dde705891fa46e2d5908f0c0beec23d6efbaf7af7",
    "metadata": {},
    "name": "src_jiraBusinessTimelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
