import { useMemo } from 'react';
import {
	COMPOUND_OPERATOR_AND,
	JastBuilder,
	creators,
	print,
	OPERATOR_IN,
} from '@atlaskit/jql-ast';
import { PROJECT_FIELD_JQL_TERM } from '@atlassian/jira-jql-builder-basic/src/common/constants.tsx';

type Props = {
	jql: string;
	projectKeys?: string[];
};

const isProjectKey = (projectKey: string | undefined): projectKey is string => Boolean(projectKey);

export const useContextJql = ({ jql, projectKeys }: Props) => {
	return useMemo(() => {
		/**
		 * context jql based on projectKeys
		 */
		const sanitizedProjectKeys = projectKeys?.filter(isProjectKey) ?? [];
		if (sanitizedProjectKeys.length === 0) {
			return jql;
		}

		const jast = new JastBuilder().build(jql);
		jast.query?.appendClause(
			creators.terminalClause(
				creators.field(PROJECT_FIELD_JQL_TERM),
				creators.operator(OPERATOR_IN),
				creators.listOperand(sanitizedProjectKeys.map(creators.valueOperand)),
			),
			COMPOUND_OPERATOR_AND,
		);

		return print(jast, {
			operatorCase: 'upper',
		});
	}, [jql, projectKeys]);
};
