export const GLOBAL_APP_MENU_ID = {
	GLOBAL_APP_GOALS: 'jira.sidebar.globalApp.goals',
	GLOBAL_APP_ASSETS: 'jira.sidebar.globalApp.assets',
} as const;

// These IDs are used with the user-customisable navigation service.
export const CONFIGURABLE_MENU_ID = {
	APPS: 'jira.sidebar.apps',
	ASSETS: 'jira.sidebar.assets',
	DASHBOARDS: 'jira.sidebar.dashboards',
	FILTERS: 'jira.sidebar.filters',
	GOALS: 'jira.sidebar.goals',
	OPERATIONS: 'jira.sidebar.operations',
	OVERVIEWS: 'jira.sidebar.overviews',
	PLANS: 'jira.sidebar.plans',
	PROJECTS: 'jira.sidebar.projects',
	RECENT: 'jira.sidebar.recent',
	STARRED: 'jira.sidebar.starred',
	TEAMS: 'jira.sidebar.teams',
	ROADMAPS: 'jira.sidebar.roadmaps',
	CUSTOMER_SERVICE: 'jira.sidebar.customerService',
	CUSTOMER_SERVICE_ANALYTICS: 'jira.sidebar.customerServiceAnalytics',
} as const;

export type ConfigurableMenuId = (typeof CONFIGURABLE_MENU_ID)[keyof typeof CONFIGURABLE_MENU_ID];

// The entity types are used to uniquely identify an entity instance.
// The entity's id should be in the URL
export const ENTITY_ID = {
	ALARM_QUEUE: (id: string) => `alrm_${id}`,
	APP: (id: string) => `app_${id}`,
	BOARD: (id: string) => `boar_${id}`,
	DASHBOARD: (id: string) => `dash_${id}`,
	FILTER: (id: string) => `filt_${id}`,
	FORM: (id: string) => `form_${id}`,
	OVERVIEW: (id: string) => `over_${id}`,
	PLAN: (id: string) => `plan_${id}`,
	PROJECT: (id: string) => `proj_${id}`,
	PROJECT_CALENDAR: (projectKey: string) => `proj-${projectKey}-cal`,
	PROJECT_CHANGE_CALENDAR: (projectKey: string) => `proj-${projectKey}-chgcal`,
	PROJECT_ESCALATIONS: (projectKey: string) => `proj-${projectKey}-escl`,
	PROJECT_DIRECTORY: (projectKey: string) => `proj-${projectKey}-dir`,
	PROJECT_FILTERS: (projectKey: string) => `proj-${projectKey}-filters`,
	PROJECT_ISSUE_FILTER: (projectKey: string, filterId: string) =>
		`proj-${projectKey}-issf-${filterId}`,
	PROJECT_JSM_GETTING_STARTED: (projectKey: string) => `proj-${projectKey}-jsm-gtst`,
	PROJECT_KNOWLEDGE_BASE: (projectKey: string) => `proj-${projectKey}-kb`,
	PROJECT_NOTIFICATION_LOGS: (projectKey: string) => `proj-${projectKey}-nlog`,
	PROJECT_ONCALL_SCHEDULE: (projectKey: string) => `proj-${projectKey}-oncs`,
	PROJECT_PRACTICE_QUEUE: (projectKey: string, practice: string) =>
		`proj-${projectKey}-pract-${practice}`,
	PROJECT_QUEUES: (projectKey: string) => `proj-${projectKey}-queues`,
	PROJECT_REPORTS: (projectKey: string) => `proj-${projectKey}-rprt`,
	PROJECT_SERVICES: (projectKey: string) => `proj-${projectKey}-srvc`,
	PROJECT_SUMMARY: (projectKey: string) => `proj-${projectKey}-summary`,
	PROJECT_VIEWS: (projectKey: string) => `proj-${projectKey}-views`,
	ROADMAP: (id: string) => `roadmap_${id}`,
	QUEUE: (id: string) => `queu_${id}`,
	ISSUE: (id: string) => `issue_${id}`,
	ARCHIVED_ISSUES: (id: string) => `archived_issues_${id}`,
} as const; // We MUST use the same IDs for the same components, otherwise we run into trouble (especially with peeking / more-menu)

export const MENU_ID_APPS = CONFIGURABLE_MENU_ID.APPS;
export const MENU_ID_APP_MARKETPLACE_DISCOVER = 'app_mrkt';
export const MENU_ID_ASSETS = CONFIGURABLE_MENU_ID.ASSETS;
export const MENU_ID_ASSETS_SCHEMAS = 'asst_schm';
export const MENU_ID_ASSETS_REPORTS = 'asst_rprt';
export const MENU_ID_BOARDS_VIEW_ALL = 'board-view-all';
export const MENU_ID_DASHBOARDS = CONFIGURABLE_MENU_ID.DASHBOARDS;
export const MENU_ID_DASHBOARDS_VIEW_ALL = 'dash-view';
export const MENU_ID_DASHBOARDS_VIEW_ALL_STARRED = 'dash-view-starred';
export const MENU_ID_FILTERS = CONFIGURABLE_MENU_ID.FILTERS;
export const MENU_ID_FILTERS_SEARCH = 'filt-search';
export const MENU_ID_FILTERS_VIEW_ALL = 'filt-view-all';
export const MENU_ID_FILTERS_VIEW_ALL_STARRED = 'filt-view-starred';
export const MENU_ID_GOALS = CONFIGURABLE_MENU_ID.GOALS;
export const MENU_ID_OPERATIONS = CONFIGURABLE_MENU_ID.OPERATIONS;
export const MENU_ID_OPS_ALERTS = 'ops-alrt';
export const MENU_ID_OPS_OVERVIEW = 'ops-over';
export const MENU_ID_OPS_REPORTS = 'ops-rprt';
export const MENU_ID_OPS_ON_CALL = 'ops-oncl';
export const MENU_ID_OVERVIEWS = CONFIGURABLE_MENU_ID.OVERVIEWS;
export const MENU_ID_PLANS = CONFIGURABLE_MENU_ID.PLANS;
export const MENU_ID_PLANS_VIEW_ALL = 'plan-view-all';
export const MENU_ID_PROJECT_APPS = 'proj-app';
export const MENU_ID_PROJECTS = CONFIGURABLE_MENU_ID.PROJECTS;
export const MENU_ID_PROJECTS_VIEW_ALL = 'proj-view-all';
export const MENU_ID_PROJECTS_VIEW_ALL_STARRED = 'proj-view-starred';
export const MENU_ID_RECENT = CONFIGURABLE_MENU_ID.RECENT;
export const MENU_ID_ROADMAPS = CONFIGURABLE_MENU_ID.ROADMAPS;
export const MENU_ID_ROADMAPS_VIEW_ALL = 'roadmaps-view-all';
export const MENU_ID_STARRED = CONFIGURABLE_MENU_ID.STARRED;
export const MENU_ID_TEAMS = CONFIGURABLE_MENU_ID.TEAMS;
export const MENU_ID_CUSTOMER_SERVICE = CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE;
export const MENU_ID_CUSTOMER_SERVICE_ANALYTICS = CONFIGURABLE_MENU_ID.CUSTOMER_SERVICE_ANALYTICS;
export const MENU_ID_YOUR_WORK = 'yrwk';

// JSM Project Settings Menu IDs
// Menu Groups
export const MENU_ID_SD_SETTINGS_ACCESS = 'sd-sett-acc';
export const MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT = 'sd-sett-req-man';
export const MENU_ID_SD_SETTINGS_REQUEST_MANAGEMENT_REQUEST_TYPES = 'sd-sett-req-typs';
export const MENU_ID_SD_SETTINGS_AUTOMATION = 'sd-sett-aut';
export const MENU_ID_SD_SETTINGS_CHANNELS_AND_SELF_SERVICE = 'sd-sett-ch-slf-srv';
export const MENU_ID_SD_SETTINGS_NOTIFICATIONS = 'sd-sett-not';
export const MENU_ID_SD_SETTINGS_APPS = 'sd-sett-apps';
export const MENU_ID_SD_SETTINGS_OPERATIONS = 'sd-sett-ops';
