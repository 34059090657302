/**
 * @generated SignedSource<<cbd5e9532d64c41387a350557124c714>>
 * @relayHash f31e9e579795e01b9614f54b2db7ea26
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID cafe23f9428a9add80e3022c3bcd12d7434fe4ed34b1cdbd6b9b11634ffe320c

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraContainerNavigationQueryInput = {
  projectKeyQuery?: JiraContainerNavigationByProjectKeyQueryInput | null | undefined;
  scopeId?: string | null | undefined;
};
export type JiraContainerNavigationByProjectKeyQueryInput = {
  cloudId: string;
  projectKey: string;
};
export type HorizontalNavJiraInnerQuery$variables = {
  boardId: string;
  cloudId: string;
  containerNavigationInput: JiraContainerNavigationQueryInput;
  first: number;
  isTMPBoardRoute: boolean;
  projectIdOrKey: string;
  withBoard: boolean;
  withProject: boolean;
};
export type HorizontalNavJiraInnerQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"JiraHorizontalNavigationContent">;
};
export type HorizontalNavJiraInnerQuery = {
  response: HorizontalNavJiraInnerQuery$data;
  variables: HorizontalNavJiraInnerQuery$variables;
};
({
  "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider": isJiraCustomTemplatesUxEnabled_provider
} as {
  readonly __relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider: {
    readonly get: () => boolean;
  };
});

import isJiraCustomTemplatesUxEnabled_provider from '@atlassian/jira-relay-provider/src/jira-custom-templates-ux-fg.relayprovider';

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "containerNavigationInput"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isTMPBoardRoute"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectIdOrKey"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withBoard"
},
v7 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "withProject"
},
v8 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v9 = {
  "kind": "ScalarField",
  "name": "id"
},
v10 = {
  "kind": "ScalarField",
  "name": "url"
},
v11 = {
  "kind": "ScalarField",
  "name": "label"
},
v12 = {
  "kind": "ScalarField",
  "name": "iconUrl"
},
v13 = {
  "kind": "ScalarField",
  "name": "styleClass"
},
v14 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
},
v15 = [
  {
    "kind": "ScalarField",
    "name": "message"
  },
  {
    "kind": "LinkedField",
    "name": "extensions",
    "plural": true,
    "selections": [
      (v8/*: any*/),
      {
        "kind": "ScalarField",
        "name": "statusCode"
      }
    ]
  }
],
v16 = {
  "kind": "ScalarField",
  "name": "canEdit"
},
v17 = {
  "kind": "InlineFragment",
  "selections": [
    (v9/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v18 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v19 = {
  "kind": "ScalarField",
  "name": "boardId"
},
v20 = {
  "kind": "ScalarField",
  "name": "name"
},
v21 = {
  "kind": "ScalarField",
  "name": "boardType"
},
v22 = {
  "concreteType": "JiraFavouriteValue",
  "kind": "LinkedField",
  "name": "favouriteValue",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isFavourite"
    },
    (v9/*: any*/)
  ]
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/),
      (v7/*: any*/)
    ],
    "kind": "Fragment",
    "name": "HorizontalNavJiraInnerQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "JiraHorizontalNavigationContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v5/*: any*/),
      (v7/*: any*/),
      (v0/*: any*/),
      (v6/*: any*/),
      (v4/*: any*/),
      {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider"
      }
    ],
    "kind": "Operation",
    "name": "HorizontalNavJiraInnerQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "containerNavigationInput"
              }
            ],
            "kind": "LinkedField",
            "name": "containerNavigation",
            "plural": false,
            "selections": [
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isJiraContainerNavigationResult"
              },
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v9/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "scopeId"
                  },
                  {
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "first",
                        "variableName": "first"
                      }
                    ],
                    "concreteType": "JiraNavigationItemConnection",
                    "kind": "LinkedField",
                    "name": "navigationItems",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "JiraNavigationItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "isDefault"
                              },
                              (v10/*: any*/),
                              (v9/*: any*/),
                              {
                                "kind": "ScalarField",
                                "name": "typeKey"
                              },
                              (v11/*: any*/),
                              {
                                "kind": "TypeDiscriminator",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "canRename"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "canRemove"
                              },
                              {
                                "kind": "ScalarField",
                                "name": "canSetAsDefault"
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "appId"
                                  },
                                  {
                                    "concreteType": "JiraAppSection",
                                    "kind": "LinkedField",
                                    "name": "sections",
                                    "plural": true,
                                    "selections": [
                                      {
                                        "concreteType": "JiraAppNavigationItemNestedLink",
                                        "kind": "LinkedField",
                                        "name": "links",
                                        "plural": true,
                                        "selections": [
                                          (v10/*: any*/),
                                          (v11/*: any*/),
                                          (v12/*: any*/),
                                          (v13/*: any*/)
                                        ]
                                      },
                                      (v11/*: any*/)
                                    ]
                                  },
                                  (v12/*: any*/),
                                  (v13/*: any*/),
                                  {
                                    "kind": "ScalarField",
                                    "name": "envLabel"
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      (v14/*: any*/)
                                    ],
                                    "type": "JiraNavigationItem",
                                    "abstractKey": "__isJiraNavigationItem"
                                  }
                                ],
                                "type": "JiraAppNavigationItem"
                              },
                              (v14/*: any*/)
                            ]
                          },
                          (v8/*: any*/)
                        ]
                      },
                      {
                        "concreteType": "QueryError",
                        "kind": "LinkedField",
                        "name": "errors",
                        "plural": true,
                        "selections": (v15/*: any*/)
                      }
                    ]
                  },
                  (v16/*: any*/),
                  {
                    "kind": "ScalarField",
                    "name": "scopeUrl"
                  }
                ],
                "type": "JiraContainerNavigation"
              },
              {
                "kind": "InlineFragment",
                "selections": (v15/*: any*/),
                "type": "QueryError"
              },
              (v17/*: any*/)
            ]
          },
          {
            "args": [
              (v18/*: any*/)
            ],
            "concreteType": "JiraWorkManagementLicensing",
            "kind": "LinkedField",
            "name": "jwmLicensing",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "currentUserSeatEdition"
              }
            ]
          },
          {
            "condition": "withBoard",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "id",
                    "variableName": "boardId"
                  }
                ],
                "kind": "LinkedField",
                "name": "jiraBoard",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v19/*: any*/),
                      (v20/*: any*/),
                      (v21/*: any*/),
                      (v16/*: any*/),
                      (v22/*: any*/),
                      (v14/*: any*/)
                    ],
                    "type": "JiraBoard"
                  },
                  (v17/*: any*/)
                ]
              }
            ]
          }
        ]
      },
      {
        "condition": "withProject",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": [
              (v18/*: any*/),
              {
                "kind": "Variable",
                "name": "idOrKey",
                "variableName": "projectIdOrKey"
              }
            ],
            "concreteType": "JiraProject",
            "kind": "LinkedField",
            "name": "jira_projectByIdOrKey",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "projectType"
              },
              {
                "kind": "ScalarField",
                "name": "projectId"
              },
              {
                "concreteType": "JiraAvatar",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "large"
                  }
                ]
              },
              {
                "alias": "canEditProjectConfig",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "type",
                    "value": "EDIT_PROJECT_CONFIG"
                  }
                ],
                "concreteType": "JiraProjectAction",
                "kind": "LinkedField",
                "name": "action",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "canPerform"
                  }
                ],
                "storageKey": "action(type:\"EDIT_PROJECT_CONFIG\")"
              },
              (v20/*: any*/),
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "projectStyle"
              },
              {
                "kind": "ScalarField",
                "name": "softwareBoardCount"
              },
              (v9/*: any*/),
              {
                "condition": "isTMPBoardRoute",
                "kind": "Condition",
                "passingValue": false,
                "selections": [
                  {
                    "alias": "projectKey",
                    "kind": "ScalarField",
                    "name": "key"
                  },
                  {
                    "kind": "LinkedField",
                    "name": "mostRecentlyViewedBoard",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v19/*: any*/),
                          (v20/*: any*/),
                          (v16/*: any*/),
                          (v21/*: any*/),
                          (v22/*: any*/),
                          (v14/*: any*/)
                        ],
                        "type": "JiraBoard"
                      },
                      (v17/*: any*/)
                    ]
                  }
                ]
              },
              {
                "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider",
                "kind": "Condition",
                "passingValue": true,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "isLiveTemplate"
                  }
                ]
              },
              (v14/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "cafe23f9428a9add80e3022c3bcd12d7434fe4ed34b1cdbd6b9b11634ffe320c",
    "metadata": {},
    "name": "HorizontalNavJiraInnerQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcjiracustomtemplatesuxfgrelayprovider": isJiraCustomTemplatesUxEnabled_provider
    }
  }
};
})();

(node as any).hash = "cfedd5347d81ca2f36fa810c25c638f4";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
