import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskClassicAppsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskClassicAppsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyAppDiscoverySettings } from './ui/connect-app-controls/app-discovery-settings/index.tsx';
import { ConnectAppsRedirect } from './ui/connect-app-controls/index.tsx';
import { SERVICE_DESK_PRODUCT } from './ui/product-routes/common/index.tsx';

export const projectSettingsServicedeskClassicAppsRouteEntry: Route = createEntry(
	projectSettingsServicedeskClassicAppsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'service-management.app-discovery',
		component: ConnectAppsRedirect(`/jira/${SERVICE_DESK_PRODUCT}`),
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		isRedirect: true,
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		forPaint: [LazyAtlassianNavigation, LazyAppDiscoverySettings],
		meta: {
			reporting: {
				id: APP_NAMES.APP_DISCOVERY,
				packageName: toPackageName(APP_NAMES.APP_DISCOVERY),
				teamName: 'it-solutions-hermes',
			},
		},
	}),
);
