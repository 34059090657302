import { createLayout, type Layout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import type IssuePageType from '@atlassian/jira-spa-apps-issue/src/index.tsx';
import { markAsBentoBundleReady } from '@atlassian/jira-spa/src/services/mark-bento-bundle-as-ready/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { lazyAfterPaint, lazyForPaint } from '@atlassian/react-loosely-lazy';
import { createRouterSelector, matchQuery } from '@atlassian/react-resource-router';
import type ModalIssueAppType from '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app.tsx';
import { getViewModeFromLocalStorage } from '@atlassian/jira-issue-context-service/src/utils.tsx';
import { isLocalStorageAvailable } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/utils.tsx';

type PreloadContract = {
	query?: string[];
	params?: {
		key: string;
		value?: string;
	}[];
}[];

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyIssuePage = lazyAfterPaint<typeof IssuePageType>(
	() =>
		markAsBentoBundleReady(
			import(
				/* webpackChunkName: "async-issue-app--full-page", jiraSpaEntry: "spa-issue" */ '@atlassian/jira-spa-apps-issue'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const AsyncIssueAppComponent = lazyAfterPaint(
	() =>
		markAsBentoBundleReady(
			import(
				/* webpackChunkName: "async-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-app'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueModal = lazyForPaint<typeof ModalIssueAppType>(
	() =>
		import(
			/* webpackChunkName: "async-modal-issue-app" */ '@atlassian/jira-issue-view/src/views/issue-details/modal-issue-app'
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const IssueModalContents = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-modal-issue-app--issue-view" */ '@atlassian/jira-issue-view/src/views/issue-details/issue-view'
		),
	{ ssr: false },
);

const imports = {
	fullPage: () => LazyIssuePage.preload(),
	embedded: () => AsyncIssueAppComponent.preload(),
	pageLoad: () => {
		if (isLocalStorageAvailable()) {
			// issue view that opens as a modal
			if (getViewModeFromLocalStorage() !== 'SIDEBAR') {
				IssueModal.preload();
				IssueModalContents.preload();
			}
			// issue view that opens as a side panel
			else if (expValEquals('preload_board_issue_view_panel', 'isEnabled', true)) {
				AsyncIssueAppComponent.preload();
			}
		}
	},
} as const;

const PrefetchCBBResourcesComponent = lazyAfterPaint(
	() =>
		import(
			/* webpackChunkName: "async-classic-resources" */ '@atlassian/jira-router-resources-classic-projects/src/services/prefetch-wrm-bundle'
		),
);

// We can start the evaluation at module level if we ran the component in SSR
// This needs to be an empty string because the router breaks if it's null or undefined
let serverIssuePreloadIdentifier = '';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
if (!__SERVER__ && window.SPA_STATE?.serverIssuePreload) {
	// @ts-expect-error - TS7053 - Element implicitly has an 'any' type because expression of type 'any' can't be used to index type '{ readonly fullPage: () => Promise<typeof import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/spa-apps/issue/src/index")>; readonly embedded: () => Promise<...>; }'.

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const importSupplier = imports[window.SPA_STATE.serverIssuePreload];

	importSupplier && importSupplier();
}

const ISSUE_PRELOAD_COMPONENT_MARK = 'IssuePreloadComponent:rendered';
const useParamsSelector = createRouterSelector((state) => ({
	query: state.query,
	params: state.match.params,
}));

const IssuePreloadComponentBuilder =
	// @ts-expect-error - TS7006 - Parameter 'preloadSupplier' implicitly has an 'any' type.
	(identifier: string, preloadSupplier, preloadContract?: PreloadContract) => () => {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const { query, params } = useParamsSelector();

		if (
			// If we have an undefined contract, assume we want to preload
			!preloadContract ||
			// Preload if we match the contract
			preloadContract.some(
				(preloadingMatch) =>
					// Match query with router function
					// @ts-expect-error - TS2554 - Expected 3 arguments, but got 2.
					(!preloadingMatch.query || matchQuery(preloadingMatch.query, query)) &&
					// Match path params with simple object structure, where if value is undefined
					// we assume we match all values
					(!preloadingMatch.params ||
						preloadingMatch.params.every(
							({ key, value }) =>
								params &&
								params[key] !== undefined &&
								(value === undefined || params[key] === value),
						)),
			)
		) {
			if (!__SERVER__) {
				preloadSupplier();
			}

			if (__SERVER__) {
				serverIssuePreloadIdentifier = identifier;
			} else {
				performance.mark(ISSUE_PRELOAD_COMPONENT_MARK);
			}
		}

		return null;
	};

export const getServerEvaluatedIssuePreload = () => serverIssuePreloadIdentifier;

const FullPageIssuePreloadComponent = IssuePreloadComponentBuilder('fullPage', imports.fullPage);
// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type '() => null'.
FullPageIssuePreloadComponent.displayName = 'FullPageIssuePreloadComponent';

export const fullPageIssuePreloadLayout: Layout = createLayout({
	globalComponents: [FullPageIssuePreloadComponent, PrefetchCBBResourcesComponent],
});

export const embeddedIssuePreloadLayoutBuilder = (preloadContract?: PreloadContract) => {
	const EmbeddedIssuePreloadComponent = IssuePreloadComponentBuilder(
		'embedded',
		imports.embedded,
		preloadContract,
	);
	// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type '() => null'.
	EmbeddedIssuePreloadComponent.displayName = 'EmbeddedIssuePreloadComponent';
	return createLayout({
		globalComponents: [EmbeddedIssuePreloadComponent],
	});
};

export const pageLoadIssuePreloadLayoutBuilder = (preloadContract?: PreloadContract) => {
	const PageLoadIssuePreloadComponent = IssuePreloadComponentBuilder(
		'pageLoad',
		imports.pageLoad,
		preloadContract,
	);
	// @ts-expect-error - TS2339 - Property 'displayName' does not exist on type '() => null'.
	PageLoadIssuePreloadComponent.displayName = 'PageLoadIssuePreloadComponent';
	return createLayout({
		globalComponents: [PageLoadIssuePreloadComponent],
	});
};
