import { JiraSiteAri } from '@atlassian/ari/jira/site';
// eslint-disable-next-line jira/import-whitelist
import { extractProjectKey } from '@atlassian/jira-issue-fetch-services-common/src/common/utils/extract-project-key.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	getMainIssueViewAggQueryVariables,
	getMainIssueViewAggQueryFeatureFlagVariables,
} from '@atlassian/jira-issue-fetch-services-common/src/services/issue-agg-data/utils.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
// eslint-disable-next-line jira/import-whitelist
import mainIssueAggQuery from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery$parameters';
import type { mainIssueAggQuery as mainIssueAggQueryType } from '@atlassian/jira-relay/src/__generated__/mainIssueAggQuery.graphql';
import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { browserOnlyResourceWithCondition } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { IS_SSR_BIG_BUNDLE } from '@atlassian/jira-conditional-ssr-render/src/constants.tsx';
import { createRelayResourceDeprecated } from '../../utils.tsx';

export const issueViewRelayResource = browserOnlyResourceWithCondition(
	() => {
		if (IS_SSR_BIG_BUNDLE) {
			// not "browser only" if FF is on
			return !fg('issue-view-ssr-perf-m1');
		}
		return true;
	},
	createRelayResourceDeprecated<mainIssueAggQueryType>({
		type: 'ISSUE_RELAY_DATA',
		isBrowserOnly: true,
		captureErrors: true,
		captureTraceIds: !__SERVER__,
		getQuery: (routerContext, { tenantContext: { cloudId } }) => {
			const { match } = routerContext;
			const issueKey = getIssueKey(match);
			const siteAri = JiraSiteAri.create({ siteId: cloudId }).toString();

			const projectKey = issueKey ? extractProjectKey(issueKey) : '';
			const queryVariables = {
				cloudId,
				issueKey: issueKey || '',
				projectKey,
				siteAri,
			};

			return {
				parameters: mainIssueAggQuery,
				variables: {
					...queryVariables,
					...getMainIssueViewAggQueryVariables(issueKey),
				},
				// Pass over the variables that actually would trigger a reload when they are changed=
				keyVariables: {
					...queryVariables,
					...getMainIssueViewAggQueryFeatureFlagVariables(),
				},
				options: { fetchPolicy: 'store-and-network' },
			};
		},
	}),
);

// No need to FF usage of this hook, the resource is internally FF'd
export const useIssueViewRelayRouteResource = (options: { suspendWhenLoading?: boolean }) =>
	useRelayResource<mainIssueAggQueryType>(issueViewRelayResource, options);
