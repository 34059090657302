import React from 'react';
import debounce from 'lodash/debounce';
import noop from 'lodash/noop';
import FilterIcon from '@atlaskit/icon/core/migration/filter';
import { Flex } from '@atlaskit/primitives';
import type { SingleValue } from '@atlaskit/react-select';
import { AsyncSelect } from '@atlaskit/select';
import EnterEscapeHandler from '@atlassian/jira-common-components-enter-escape-handler/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { PICKER_SEARCH_DEBOUNCE_TIME } from '../../../../../common/constants.tsx';
import type {
	FieldValueSinglePickerProps,
	GenericPickerOption,
} from '../../../../../common/types.tsx';
import { useLoadSavedFiltersController } from '../../../../../controllers/use-load-saved-filters-controller/index.tsx';
import messages from './messages.tsx';

export type SavedFilterPickerProps = {
	label: string;
} & FieldValueSinglePickerProps<SingleValue<GenericPickerOption>>;

const formatOptionLabel = (option: GenericPickerOption) => (
	<Flex alignItems="center" gap="space.100">
		<FilterIcon spacing="spacious" label={option.label} />
		{option.label}
	</Flex>
);

export const SavedFilterPicker = ({
	onChange,
	value,
	isInvalid,
	label,
}: SavedFilterPickerProps) => {
	const { loadOptions, isLoading } = useLoadSavedFiltersController();
	const { formatMessage } = useIntl();

	const debouncedLoadOptions = debounce(
		(searchString, callback) => loadOptions(searchString).then((options) => callback(options)),
		PICKER_SEARCH_DEBOUNCE_TIME,
	);

	return (
		/* Prevent ESC press on Select bubbling up to the modal */
		fg('nav4_simplified_board_create_beta') ? (
			<EnterEscapeHandler onEscape={noop}>
				<AsyncSelect
					aria-label={label}
					isLoading={isLoading}
					formatOptionLabel={formatOptionLabel}
					defaultOptions
					loadOptions={debouncedLoadOptions}
					onChange={onChange}
					value={value}
					placeholder={formatMessage(messages.savedFilterPickerPlaceholder)}
					menuPosition="fixed"
					classNamePrefix="source-value-picker"
					isInvalid={isInvalid}
				/>
			</EnterEscapeHandler>
		) : (
			<AsyncSelect
				aria-label={formatMessage(messages.savedFilterPickerAriaLabel)}
				isLoading={isLoading}
				formatOptionLabel={formatOptionLabel}
				defaultOptions
				loadOptions={debouncedLoadOptions}
				onChange={onChange}
				value={value}
				placeholder={formatMessage(messages.savedFilterPickerPlaceholder)}
				menuPosition="fixed"
				classNamePrefix="source-value-picker"
				isInvalid={isInvalid}
			/>
		)
	);
};
