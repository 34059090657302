import {
	getUserProperty,
	setUserProperties,
} from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import { trackFetchErrors } from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';

export const rovoOnboardingkey = 'rovoVideoPlayerSeen';

export const getRovoVideoOnboardingHash = async (
	accountId: string | null,
): Promise<string | null> => {
	try {
		return accountId != null
			? (
					await getUserProperty<{
						hashId: string;
					}>(accountId, rovoOnboardingkey)
				).hashId
			: null;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		// 404 - property has not been previously set
		if (error.statusCode === 404) {
			return null;
		}

		trackFetchErrors({
			error,
			id: 'rovoUserDismissGet',
			packageName: 'jiraRovoVideoModal',
		});
		throw error;
	}
};

export const setRovoVideoOnboardingSeen = async (
	accountId: string | null,
	hashId: string,
): Promise<void> => {
	try {
		if (accountId != null) {
			return await setUserProperties(accountId, rovoOnboardingkey, JSON.stringify({ hashId }));
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		trackFetchErrors({
			error,
			id: 'rovoUserDismissSet',
			packageName: 'jiraRovoVideoModal',
		});
		throw error;
	}
};
