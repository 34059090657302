/**
 * @generated SignedSource<<97e415e43407ba5f0407e139193aab36>>
 * @relayHash 54b13b9ea6b0698dc7cc248d48ba0efd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 649ba31509a73647b9dbc96aecfe455eb71b66136a414d15b43b8ae4bfbcad54

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type recentMenuQuery$variables = {
  cloudId: string;
  count: number;
  keyword?: string | null | undefined;
};
export type recentMenuQuery$data = {
  readonly jira: {
    readonly recent: {
      readonly pageInfo: {
        readonly startCursor: string | null | undefined;
      };
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"recentMenu_navigationAppsSidebarNav4SidebarsContentRecent">;
};
export type recentMenuQuery = {
  response: recentMenuQuery$data;
  variables: recentMenuQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cloudId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": "",
  "kind": "LocalArgument",
  "name": "keyword"
},
v3 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v4 = {
  "kind": "Variable",
  "name": "keyword",
  "variableName": "keyword"
},
v5 = {
  "kind": "Literal",
  "name": "types",
  "value": [
    "PROJECT",
    "FILTER",
    "DASHBOARD",
    "BOARD",
    "QUEUE",
    "ISSUE",
    "PLAN"
  ]
},
v6 = [
  (v3/*: any*/),
  {
    "fields": [
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "first",
    "value": 1
  },
  (v5/*: any*/)
],
v7 = {
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "kind": "ScalarField",
      "name": "startCursor"
    }
  ]
},
v8 = [
  (v3/*: any*/),
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "includeArchivedProjects",
        "value": false
      },
      (v4/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v5/*: any*/)
],
v9 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v10 = {
  "kind": "ScalarField",
  "name": "id"
},
v11 = {
  "kind": "ScalarField",
  "name": "lastViewedTimestamp"
},
v12 = {
  "kind": "InlineFragment",
  "selections": [
    (v10/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v13 = [
  (v10/*: any*/),
  (v11/*: any*/),
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": "filterName",
        "kind": "ScalarField",
        "name": "name"
      },
      {
        "kind": "ScalarField",
        "name": "filterUrl"
      },
      {
        "kind": "ScalarField",
        "name": "filterId"
      }
    ],
    "type": "JiraFilter",
    "abstractKey": "__isJiraFilter"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "name": "recentMenuQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "alias": "recent",
                "args": (v6/*: any*/),
                "concreteType": "JiraSearchableEntityConnection",
                "kind": "LinkedField",
                "name": "recentItems",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": (v7/*: any*/),
                    "action": "THROW",
                    "path": "jira.recent.pageInfo"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.recent"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      },
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          }
        ],
        "kind": "FragmentSpread",
        "name": "recentMenu_navigationAppsSidebarNav4SidebarsContentRecent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "recentMenuQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "recent",
            "args": (v6/*: any*/),
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              (v7/*: any*/)
            ]
          },
          {
            "alias": "_recentItems",
            "args": (v8/*: any*/),
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "concreteType": "JiraSingleLineTextField",
                            "kind": "LinkedField",
                            "name": "summaryField",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "text"
                              },
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "alias": "issueWebUrl",
                            "kind": "ScalarField",
                            "name": "webUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "issueId"
                          }
                        ],
                        "type": "JiraIssue"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": "title",
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "key"
                          },
                          {
                            "kind": "LinkedField",
                            "name": "defaultNavigationItem",
                            "plural": false,
                            "selections": [
                              (v9/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "kind": "ScalarField",
                                    "name": "url"
                                  }
                                ],
                                "type": "JiraNavigationItem",
                                "abstractKey": "__isJiraNavigationItem"
                              },
                              (v12/*: any*/)
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "description"
                          },
                          {
                            "concreteType": "JiraAvatar",
                            "kind": "LinkedField",
                            "name": "avatar",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "large"
                              }
                            ]
                          },
                          {
                            "kind": "ScalarField",
                            "name": "projectId"
                          }
                        ],
                        "type": "JiraProject"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "planId"
                          },
                          {
                            "concreteType": "JiraScenario",
                            "kind": "LinkedField",
                            "name": "scenario",
                            "plural": false,
                            "selections": [
                              {
                                "kind": "ScalarField",
                                "name": "scenarioId"
                              },
                              (v10/*: any*/)
                            ]
                          },
                          {
                            "alias": "planTitle",
                            "kind": "ScalarField",
                            "name": "title"
                          }
                        ],
                        "type": "JiraPlan"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "kind": "ScalarField",
                            "name": "name"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "boardUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "boardId"
                          }
                        ],
                        "type": "JiraBoard"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": "dashboardTitle",
                            "kind": "ScalarField",
                            "name": "title"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "dashboardUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "dashboardId"
                          }
                        ],
                        "type": "JiraDashboard"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "JiraSystemFilter"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v13/*: any*/),
                        "type": "JiraCustomFilter"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v10/*: any*/),
                          (v11/*: any*/),
                          {
                            "alias": "queueTitle",
                            "kind": "ScalarField",
                            "name": "title"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "queueUrl"
                          },
                          {
                            "kind": "ScalarField",
                            "name": "queueId"
                          }
                        ],
                        "type": "JiraServiceManagementQueue"
                      },
                      (v12/*: any*/)
                    ]
                  },
                  {
                    "kind": "ScalarField",
                    "name": "cursor"
                  }
                ]
              },
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  },
                  {
                    "kind": "ScalarField",
                    "name": "endCursor"
                  }
                ]
              }
            ]
          },
          {
            "alias": "_recentItems",
            "args": (v8/*: any*/),
            "filters": [
              "cloudId",
              "types",
              "filter"
            ],
            "handle": "connection",
            "key": "RecentMenuContent__recentItems",
            "kind": "LinkedHandle",
            "name": "recentItems"
          }
        ]
      }
    ]
  },
  "params": {
    "id": "649ba31509a73647b9dbc96aecfe455eb71b66136a414d15b43b8ae4bfbcad54",
    "metadata": {},
    "name": "recentMenuQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "b81d123360d86456f0af905baa34e827";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
