import { MAX_COLUMNS } from '@atlassian/jira-native-issue-table/src/common/constants.tsx';

export const sortDirections = {
	ASC: 'ASC',
	DESC: 'DESC',
	NONE: 'NONE',
} as const;

export const FilterTypes = {
	ID: 'filterId',
	JQL: 'filterJql',
} as const;

export type FilterTypes = (typeof FilterTypes)[keyof typeof FilterTypes];

export const SearchInputTypes = {
	JQL: 'jql',
	FILTER: 'filter',
	FILTER_AND_JQL: 'filterAndJql',
} as const;

export type SearchInputTypes = (typeof SearchInputTypes)[keyof typeof SearchInputTypes];

export const views = {
	list: 'list-view',
	detail: 'split-view',
} as const;

export const defaultOrderFields = {
	created: 'created',
	lastviewed: 'lastviewed',
	updated: 'updated',
} as const;

export const ISSUE_SEARCH_NAMESPACE = 'ISSUE_NAVIGATOR';
export const DEFAULT_ORDER_FIELD = defaultOrderFields.created;
export const DEFAULT_SORT_DIRECTION = sortDirections.DESC;
export const DEFAULT_VIEW_ID = 'list_default';
export const MAX_ISSUES_PER_PAGE = 50;
export const getMaxIssuesPerPage = () => MAX_ISSUES_PER_PAGE;

export const ANALYTICS_SOURCE = 'issue-navigator-next' as const;
export const PACKAGE_NAME = 'jiraIssueNavigator';
export const TEAM_NAME = 'empanada';
export const EXPERIENCE_NAME = 'showPinIssueList';

export const ANALYTICS_LIST_VIEW_SOURCE_NAME = 'issueNavigatorListView';
export const ANALYTICS_DETAIL_VIEW_SOURCE_NAME = 'issueNavigatorDetailView';

export const LIST_VIEW_ID = 'list_default';
export const MAX_AMOUNT_OF_COLUMNS = MAX_COLUMNS;

export const COLUMN_TYPE_EPIC_LINK = 'com.pyxis.greenhopper.jira:gh-epic-link';
export const COLUMN_TYPE_PARENT_LINK = 'com.atlassian.jpo:jpo-custom-field-parent';

export const NATURAL_LANGUAGE_TO_JQL = 'NATURAL_LANGUAGE_TO_JQL';

export const NIN_PROJECT_OPERATION_SCOPE = 'NIN_PROJECT';
export const NIN_GLOBAL_OPERATION_SCOPE = 'NIN_GLOBAL';
export const NIN_PROJECT_SCHEMA_REFACTOR_OPERATION_SCOPE = 'NIN_PROJECT_SCHEMA_REFACTOR';
export const NIN_GLOBAL_SCHEMA_REFACTOR_OPERATION_SCOPE = 'NIN_GLOBAL_SCHEMA_REFACTOR';
export const NIN_PROJECT_SHADOW_REQUEST_OPERATION_SCOPE = 'NIN_PROJECT_SHADOW_REQUEST';

export const NIN_SIDEBAR_SCOPE = 'NIN_SIDEBAR';

/**
 * Hardcoded collection of fields which are selectable from the order dropdown in the DETAIL VIEW.
 * We also maintain a list of known aliases so the user can order by any term interchangably.
 * In the long term all this data should be sourced from the BE.
 */
type OrderFieldWithAlias = { jqlTerm: string; fieldType: string; aliases: string[] };

export const supportedOrderFields: {
	[key: string]: OrderFieldWithAlias | undefined;
} = {
	created: { jqlTerm: 'created', fieldType: 'created', aliases: [] },
	key: { jqlTerm: 'key', fieldType: 'issuekey', aliases: ['issuekey'] },
	lastviewed: { jqlTerm: 'lastviewed', fieldType: 'lastviewed', aliases: [] },
	priority: { jqlTerm: 'priority', fieldType: 'priority', aliases: [] },
	resolved: { jqlTerm: 'resolved', fieldType: 'resolutiondate', aliases: [] },
	status: { jqlTerm: 'status', fieldType: 'status', aliases: [] },
	updated: { jqlTerm: 'updated', fieldType: 'updated', aliases: [] },
	issuetype: { jqlTerm: 'issuetype', fieldType: 'issuetype', aliases: ['type'] },
	assignee: { jqlTerm: 'assignee', fieldType: 'assignee', aliases: [] },
	duedate: { jqlTerm: 'duedate', fieldType: 'duedate', aliases: [] },
	reporter: { jqlTerm: 'reporter', fieldType: 'reporter', aliases: [] },
	summary: { jqlTerm: 'summary', fieldType: 'summary', aliases: [] },
};

export const getSupportedFieldForAlias = (alias: string): string | undefined =>
	Object.keys(supportedOrderFields).find((key) =>
		supportedOrderFields[key]?.aliases.includes(alias),
	);

export const isJiraIssueSearchViewMetadata = (data: { __typename: string }) =>
	['JiraIssueSearchView', 'JiraDetailedView', 'JiraListView', 'JiraGroupedListView'].includes(
		data.__typename,
	);

export const isJiraSpreadsheetView = (data: { __typename: string }) =>
	['JiraListView', 'JiraGroupedListView'].includes(data.__typename);
