/**
 * @generated SignedSource<<37952b45cd15a0551c68e3e39fcc6055>>
 * @relayHash 4d18fcb1c42e00bfa3f11ddd155c373e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 66eb79e0bbddd55d9e9908ec4f4f73d0d72e4a23120245a1c5496d50739c7671

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiVersionDetailsQuery } from './uiVersionDetailsQuery.graphql';

const node: PreloadableConcreteRequest<uiVersionDetailsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "66eb79e0bbddd55d9e9908ec4f4f73d0d72e4a23120245a1c5496d50739c7671",
    "metadata": {},
    "name": "uiVersionDetailsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
