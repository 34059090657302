import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { softwareFormSubmitEmbedClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareFormSubmitEmbedClassicRoute.tsx';
import { formSubmitEmbedEntrypoint } from '@atlassian/jira-spa-apps-software-form-submit-embed/entrypoint.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

const formSubmitEmbedPageEntrypoint = createChromelessEntryPoint({
	main: formSubmitEmbedEntrypoint,
});

export const softwareFormSubmitEmbedClassicRouteEntry = createEntry(
	softwareFormSubmitEmbedClassicRoute,
	() => ({
		group: 'classic-software',
		ufoName: 'classic-form-submit-embed',
		layout: chromelessLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		// eslint-disable-next-line camelcase
		resources: [DEPRECATED_DO_NOT_USE_projectDetailsResource],

		entryPoint: () => {
			return fg('jsw_forms_embed') ? formSubmitEmbedPageEntrypoint : notFoundPageEntrypoint;
		},
	}),
);
