import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { classicBusinessProformaIssueFormsDirectEmbedRoute } from '@atlassian/jira-router-routes-proforma-routes/src/classicBusinessProformaIssueFormsDirectEmbedRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsDirectEmbed, {
	LazyProformaIssueFormsDirectEmbed,
} from './ui/issue-forms-direct/embed/index.tsx';

export const classicBusinessProformaIssueFormsDirectEmbedRouteEntry: Route = createEntry(
	classicBusinessProformaIssueFormsDirectEmbedRoute,
	() => ({
		group: 'classic-business',
		layout: chromelessLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirectEmbed,
		forPaint: [LazyProformaIssueFormsDirectEmbed],
		perfMetricKey: 'issue-forms-direct-embed',
	}),
);
