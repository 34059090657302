import React, { useCallback, useMemo, useEffect, type ReactNode } from 'react';
import {
	Behaviours,
	Prompts,
	TargetTabs,
	createProvisioningPageExtensions,
} from '@atlassiansox/cross-flow-extensions';
import { Journeys, Targets, useCrossFlow } from '@atlassiansox/cross-flow-support';
import Button from '@atlaskit/button';
import { JiraProductDiscoveryIcon } from '@atlaskit/logo';
import Lozenge from '@atlaskit/lozenge';
import { Text } from '@atlaskit/primitives';
import { setUserProperties } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { PROJECTS_NAVIGATION_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-id.tsx';
import {
	DROPDOWN_ITEM,
	NAVIGATION_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM,
	CUSTOM_ITEM_BUTTON,
	CUSTOM_ITEM_DROPDOWN_ACTION,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import {
	DISMISS_RECOMMENDATION,
	MEATBALL_DROPDOWN,
	NEW_DISCOVERY_PROJECT,
	WHY_AM_I_SEEING_THIS,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { PROJECT_RECOMMENDATION } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-type.tsx';
import {
	LEVEL_ONE,
	LEVEL_THREE,
	LEVEL_TWO,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import {
	type UIAnalyticsEvent,
	fireUIAnalytics,
	fireOperationalAnalytics,
	useAnalyticsEvents,
} from '@atlassian/jira-product-analytics-bridge';
import type { JiraLinkConfig } from '@atlassian/jira-router-components/src/ui/router-link-config/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useFireCrossFlowAnalyticsEvents } from '@atlassian/surface-analytics';
import { expValEquals, expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import type { AppRecommendations, RecommendationContext } from '../../common/types.tsx';
import { RecommendationActionMenu } from '../../common/ui/recommendation-action-menu/recommendation-action-menu.tsx';
import { DATA_PRIVACY_LINK, PACKAGE_NAME } from '../../constants.tsx';
import {
	JPD_ENTITY_ID,
	JPD_RECOMMENDATION_DISMISS_USER_PROPERTY,
	JPD_RECOMMENDATION_KEY,
	JPD_ROADMAP_KEY,
	JPD_KEY,
	getProductTypeCcpReferenceIdMapForEnv,
} from './constants.tsx';
import messages from './messages.tsx';
import { hasJpdLicense } from './utils.tsx';
import ProductRoadmapProjectAvatar from './illustrations/product-roadmap-project-avatar.tsx';

export const JpdRecommendation = ({
	recommendationKey,
	dismissRecommendation,
}: {
	recommendationKey: string;
	dismissRecommendation: () => void;
}) => {
	const { formatMessage } = useIntl();
	const crossFlow = useCrossFlow();
	const accountId = useAccountId();
	const environment = useEnvironment();
	const jpdCloudCCPProductID = getProductTypeCcpReferenceIdMapForEnv(environment).PRODUCT_DISCOVERY;
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { fireViewedUIEvent, fireInteractedUIEvent } = useFireCrossFlowAnalyticsEvents();

	useEffect(() => {
		if (crossFlow.isEnabled) {
			fireViewedUIEvent(
				{
					action: 'viewed',
					actionSubject: 'section',
					actionSubjectId: 'projectsMenu',
				},
				{
					recommendedProductIds: [jpdCloudCCPProductID],
					source: 'projectsMenuSection',
				},
				{
					navigationVersion: 'v4',
					jpdContentChangeInProjectsMenuCohort: expVal(
						'new_nav_project_menu_jpd_reco_content_change',
						'cohort',
						'not-enrolled',
					),
				},
			);
		} else {
			fireErrorAnalytics({
				error: new Error(crossFlow.reason),
				meta: {
					id: crossFlow.reason,
					packageName: 'jiraAtlassianNavigationRecommendations',
				},
			});
		}
	}, [createAnalyticsEvent, crossFlow, fireViewedUIEvent, jpdCloudCCPProductID]);

	const jpdRecommendationContent = useMemo(() => {
		return expValEquals('new_nav_project_menu_jpd_reco_content_change', 'cohort', 'variation')
			? {
					projectTitle: messages.projectTitleProductRoadmap,
					projectKey: JPD_ROADMAP_KEY,
					projectAvatar: ProductRoadmapProjectAvatar,
				}
			: {
					projectTitle: messages.projectTitle,
					projectKey: JPD_KEY,
					projectAvatar: JiraProductDiscoveryIcon,
				};
	}, []);

	const onLearnMoreClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: DROPDOWN_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_THREE,
				componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: NEW_DISCOVERY_PROJECT,
				itemId: WHY_AM_I_SEEING_THIS,
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
		);
		fireInteractedUIEvent(
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'learnMore',
			},
			{
				source: 'projectsMenuSection',
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
			{
				navigationVersion: 'v4',
			},
		);
	}, [createAnalyticsEvent, fireInteractedUIEvent, jpdCloudCCPProductID]);

	const onDismissClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: DROPDOWN_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_THREE,
				componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: NEW_DISCOVERY_PROJECT,
				itemId: DISMISS_RECOMMENDATION,
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
		);
		fireInteractedUIEvent(
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'dismissRecommendation',
			},
			{
				source: 'projectsMenuSection',
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
			{
				navigationVersion: 'v4',
			},
		);
		dismissRecommendation();
		if (accountId) {
			setUserProperties(accountId, JPD_RECOMMENDATION_DISMISS_USER_PROPERTY, 'true').catch(
				(error) => {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'dismissJpdRecommendation',
							packageName: PACKAGE_NAME,
							teamName: 'navigation',
						},
					});
				},
			);
		}
	}, [
		accountId,
		createAnalyticsEvent,
		dismissRecommendation,
		fireInteractedUIEvent,
		jpdCloudCCPProductID,
	]);

	const openCrossFlow = useCallback(
		async (templateId: string) => {
			const extensions = createProvisioningPageExtensions({
				actions: {
					primary: {
						behaviour: Behaviours.LAND,
						prompt: Prompts.NEXT,
						options: {
							targetTab: TargetTabs.SAME,
							urlParams: { templateId },
						},
					},
				},
			});

			if (crossFlow.isEnabled) {
				crossFlow.api.open({
					journey: Journeys.GET_STARTED,
					targetProduct: Targets.JIRA_PRODUCT_DISCOVERY,
					sourceComponent: 'projectsMenu',
					sourceContext: 'tryThisRecommendation',
					extensions,
				});
			}
		},
		[crossFlow],
	);

	const onRecommendationClicked = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: NAVIGATION_ITEM,
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_ONE,
				componentType: CUSTOM_ITEM,
				itemType: PROJECT_RECOMMENDATION,
				itemId: NEW_DISCOVERY_PROJECT,
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
		);
		fireInteractedUIEvent(
			{
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'tryThisRecommendation',
			},
			{
				source: 'projectsMenuSection',
				targetProductId: jpdCloudCCPProductID,
				recommendedProductIds: [jpdCloudCCPProductID],
			},
			{
				navigationVersion: 'v4',
			},
		);
		openCrossFlow(jpdRecommendationContent.projectKey);
	}, [
		createAnalyticsEvent,
		fireInteractedUIEvent,
		jpdCloudCCPProductID,
		jpdRecommendationContent.projectKey,
		openCrossFlow,
	]);

	const recommendationActionMenuClickHandler = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				actionSubject: 'button',
				action: 'clicked',
			}),
			PROJECTS_NAVIGATION_ITEM,
			{
				level: LEVEL_TWO,
				componentType: CUSTOM_ITEM_BUTTON,
				parentItemType: PROJECT_RECOMMENDATION,
				parentItemId: NEW_DISCOVERY_PROJECT,
				itemId: MEATBALL_DROPDOWN,
			},
		);
	}, [createAnalyticsEvent]);

	return (
		<>
			<Nav4MenuLinkItem<JiraLinkConfig>
				elemBefore={<jpdRecommendationContent.projectAvatar appearance="brand" size="small" />}
				elemAfter={
					<Lozenge appearance="new" isBold={false}>
						{formatMessage(messages.lozengeText)}
					</Lozenge>
				}
				href=""
				menuId={recommendationKey}
				onClick={onRecommendationClicked}
				actionsOnHover={
					<RecommendationActionMenu
						onClick={recommendationActionMenuClickHandler}
						onLearnMoreClicked={onLearnMoreClicked}
						onDismissClicked={onDismissClicked}
						primaryCta={formatMessage(messages.reasonsModalButtonOk)}
						body={
							<>
								{formatMessage(messages.reasonsModalExplanation, {
									strong: (chunks: ReactNode[]) => <Text as="strong">{chunks}</Text>,
								})}
								<ul>
									<li>{formatMessage(messages.reasonsModalBulletOneSpork)}</li>
									<li>{formatMessage(messages.reasonsModalBulletTwo)}</li>
								</ul>
								<br />
								<Button appearance="link" spacing="none" href={DATA_PRIVACY_LINK} target="_blank">
									{formatMessage(messages.privacyPolicy)}
								</Button>
							</>
						}
						title={formatMessage(messages.reasonsModalTitle)}
					/>
				}
			>
				{formatMessage(jpdRecommendationContent.projectTitle)}
			</Nav4MenuLinkItem>
		</>
	);
};

const checkEligibility = (
	recommendationContext: RecommendationContext,
	appRecommendations: AppRecommendations,
) => {
	const { areProductSuggestionsEnabled, licensedProducts, accountId, jpdRecommendationDismissed } =
		recommendationContext;

	if (fg('grw-1467_jpd_reco_eligibility_override_gate')) {
		return { isEligible: true, reason: 'Eligibility override gate is enabled' };
	}

	if (!accountId) {
		return { isEligible: false, reason: 'Account Id is null' };
	}

	if (!areProductSuggestionsEnabled) {
		return { isEligible: false, reason: 'Product suggestions are disabled' };
	}

	if (hasJpdLicense(licensedProducts)) {
		return { isEligible: false, reason: 'Instance has JPD license' };
	}

	if (jpdRecommendationDismissed) {
		return { isEligible: false, reason: 'JPD recommendation dismissed' };
	}

	if (!appRecommendations?.some((entityId) => entityId === JPD_ENTITY_ID)) {
		return { isEligible: false, reason: 'JPD was not in the recommend apps' };
	}

	return { isEligible: true, reason: 'Pass all checks' };
};

export const jpdRecommendation = {
	key: JPD_RECOMMENDATION_KEY,
	shouldRender: (
		recommendationContext: RecommendationContext,
		analyticsEvent: UIAnalyticsEvent,
		appRecommendations: AppRecommendations,
	) => {
		const { isEligible, reason } = checkEligibility(recommendationContext, appRecommendations);

		if (fg('pm_jpd_reco_operational_event_activated')) {
			fireOperationalAnalytics(
				analyticsEvent,
				'jiraAtlassianNavigation.loadJpdRecommendation succeeded',
				{
					reason,
					isEligible,
				},
			);
		}

		return isEligible;
	},
	Component: JpdRecommendation,
};
