import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const assetsSearchEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(/* webpackChunkName: "assets-app-search" */ './src/ui').then(
			(module) => module.AssetsAppSearchEntrypointWrapper,
		),
	),
	getPreloadProps: () => ({}),
});
