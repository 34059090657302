/** @jsx jsx */

import { css, jsx } from '@compiled/react';
import { Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { JiraBoardType } from '@atlassian/jira-relay/src/__generated__/useBoardCreateMutation.graphql';
import commonMessages from '../../../common/messages.tsx';
import { descriptionMapping } from '../../../common/utils.tsx';

export type ModalHeaderTypes = {
	type: JiraBoardType;
};

export const ModalHeader = ({ type }: ModalHeaderTypes) => {
	const { formatMessage } = useIntl();

	return (
		<Stack space="space.100">
			{/* eslint-disable-next-line @atlaskit/design-system/use-heading */}
			<h4 css={modalHeaderStyles}>{formatMessage(commonMessages.boardCreationTitle)}</h4>
			<Text size="medium" color="color.text.accent.gray.bolder">
				{descriptionMapping[type] && formatMessage(descriptionMapping[type])}
			</Text>
			<Text as="em" size="small" color="color.text.subtlest">
				{formatMessage(commonMessages.requiredFieldsHeader)}
			</Text>
		</Stack>
	);
};

const modalHeaderStyles = css({
	font: token('font.heading.medium'),
	fontWeight: token('font.weight.bold'),
	color: token('color.text'),
});
