/**
 * @generated SignedSource<<cec605c2ff2940bd6f5b27fc2b0a9065>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Recommendations$data = {
  readonly growthRecommendations?: {
    readonly recommendations: {
      readonly data?: ReadonlyArray<{
        readonly entityId?: string;
      }> | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly jira: {
    readonly canCreateProject: boolean | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "Nav4Recommendations";
};
export type Nav4Recommendations$key = {
  readonly " $data"?: Nav4Recommendations$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Recommendations">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    }
  ],
  "kind": "Fragment",
  "name": "Nav4Recommendations",
  "selections": [
    {
      "concreteType": "JiraQuery",
      "kind": "LinkedField",
      "name": "jira",
      "plural": false,
      "selections": [
        {
          "alias": "canCreateProject",
          "args": [
            {
              "kind": "Variable",
              "name": "cloudId",
              "variableName": "cloudId"
            },
            {
              "kind": "Literal",
              "name": "type",
              "value": "CREATE_PROJECT"
            }
          ],
          "kind": "ScalarField",
          "name": "canPerform"
        }
      ]
    },
    {
      "condition": "__relay_internal__pv__atlassianjirarelayprovidersrcgrowthrecommendationsrelayprovider",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "GrowthRecQuery",
          "kind": "LinkedField",
          "name": "growthRecommendations",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "fields": [
                    {
                      "kind": "Literal",
                      "name": "product",
                      "value": "jira"
                    },
                    {
                      "kind": "Variable",
                      "name": "tenantId",
                      "variableName": "cloudId"
                    },
                    {
                      "kind": "Literal",
                      "name": "useCase",
                      "value": "jiraProjectMenuRecommendations"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "context"
                },
                {
                  "kind": "Literal",
                  "name": "first",
                  "value": 2
                }
              ],
              "kind": "LinkedField",
              "name": "recommendations",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "kind": "LinkedField",
                      "name": "data",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            {
                              "kind": "ScalarField",
                              "name": "entityId"
                            }
                          ],
                          "type": "GrowthRecJiraTemplateRecommendation"
                        }
                      ]
                    }
                  ],
                  "type": "GrowthRecRecommendations"
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "8a95cceadfe457dafa1d3f67150d4a1b";

export default node;
