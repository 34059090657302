/** @jsx jsx */
import React, { memo, useMemo } from 'react';
import { jsx } from '@compiled/react';
import ShortcutScope from '@atlassian/jira-common-components-keyboard-shortcuts/src/shortcut-scope.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { JiraModal as Modal } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import {
	ContextualAnalyticsData,
	MODAL,
	FireScreenAnalytics,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import commonMessages from '../../common/messages.tsx';
import type { ModalClosehandler } from '../../common/types.tsx';
import { descriptionMapping } from '../../common/utils.tsx';
import { BoardCreateContext } from '../../controllers/use-board-create-context/index.tsx';
import { BoardCreateForm, BoardCreateFormFallback } from './form/index.tsx';
import messages from './messages.tsx';
import type { BoardCreateModalProps } from './types.tsx';

const MODAL_WIDTH = 1000;
const MODAL_HEIGHT = 551;

export const BoardCreateModalFallback = () => {
	const { formatMessage } = useIntl();
	return (
		<Modal
			height={MODAL_HEIGHT}
			width={MODAL_WIDTH}
			label={formatMessage(messages.loadingLabel)}
			messageId="software-board-create.ui.modal.modal.BoardCreateModalFallback"
			messageType="transactional"
		>
			<BoardCreateFormFallback />
		</Modal>
	);
};

const BoardCreateModal = ({
	boardType,
	projectId,
	onClose,
	triggerRef,
}: Omit<BoardCreateModalProps, 'projectAvatar' | 'projectKey'>) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const { formatMessage } = useIntl();

	const handleOnClose: ModalClosehandler = (_mouseEvent, _analyticEvent, didSubmit) => {
		const event = createAnalyticsEvent({
			action: 'closed',
			actionSubject: 'modalDialog',
		});

		fireUIAnalytics(event, 'createNewBoardModal', {
			didSubmit: !!didSubmit,
			boardType: boardType.toLowerCase(),
		});
		onClose?.();
	};

	return (
		<Modal
			testId="software-board-create.ui.modal"
			onClose={handleOnClose}
			width={MODAL_WIDTH}
			height={MODAL_HEIGHT}
			messageId="software-board-create.ui.modal.modal.BoardCreateModal"
			messageType="transactional"
			label={
				fg('nav4_simplified_board_create_beta')
					? `${formatMessage(commonMessages.boardCreationTitle)}. ${formatMessage(descriptionMapping[boardType])}`
					: formatMessage(messages.createBoardDialogLabel, {
							boardDescription: formatMessage(descriptionMapping[boardType]),
						})
			}
			{...(fg('nav4_simplified_board_create_beta') && { shouldReturnFocus: triggerRef })}
		>
			<BoardCreateForm projectId={projectId} boardType={boardType} onClose={handleOnClose} />
			<FireScreenAnalytics attributes={{ boardType: boardType.toLowerCase() }} />
		</Modal>
	);
};

const BoardCreateModalWithContext = ({
	props: { projectAvatar, projectKey, ...restProps },
}: {
	props: BoardCreateModalProps;
}) => {
	const value = useMemo(
		() => ({ projectId: restProps.projectId, projectAvatar, projectKey }),
		[restProps.projectId, projectAvatar, projectKey],
	);

	return (
		<BoardCreateContext.Provider value={value}>
			<ShortcutScope>
				<ContextualAnalyticsData sourceName="createNewBoard" sourceType={MODAL}>
					<BoardCreateModal {...restProps} />
				</ContextualAnalyticsData>
			</ShortcutScope>
		</BoardCreateContext.Provider>
	);
};

export default memo(BoardCreateModalWithContext);
