const PRODUCT_HEADER = 'x-product';
const EXPERIENCE_ID_HEADER = 'x-experience-id';
export const CLOUD_ID_HEADER = 'x-cloudid';

// Hardcode this for now for the MVP
const CONFLUENCE_PRODUCT = 'confluence';
const CSM_AI_EXPERIENCE_ID = 'csm-ai';

export function createHeaders(init?: HeadersInit): Headers {
	return new Headers({
		[PRODUCT_HEADER]: CONFLUENCE_PRODUCT,
		[EXPERIENCE_ID_HEADER]: CSM_AI_EXPERIENCE_ID,
		...(init || {}),
	});
}

export function getRequestHeadersForAnnotation(cloudId?: string) {
	const headers = createHeaders({
		...(cloudId
			? {
					[CLOUD_ID_HEADER]: cloudId,
				}
			: {}),
		'Content-Type': 'application/json',
	});
	return headers;
}
