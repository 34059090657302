import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsForgeAppsNextgenRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsForgeAppsNextgenRoute.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ForgeProjectSettingsPage, {
	LazyForgeProjectSettingsPage,
	forgeProjectSettingsPageResources,
} from './ui/project-settings/forge/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsForgeAppsNextgenRouteEntry: Route = createEntry(
	projectSettingsForgeAppsNextgenRoute,
	() => ({
		group: 'project-settings-software',
		component: ForgeProjectSettingsPage,
		layout: softwareProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources(), ...forgeProjectSettingsPageResources()],
		forPaint: [LazyAtlassianNavigation, LazyForgeProjectSettingsPage],
	}),
);
