import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	AsyncJSMOperationsHorizontalNav,
	LazyJSMOperationsHorizontalNav,
} from '@atlassian/jira-horizontal-nav-jsm-operations/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { jsmRepackagingBannerResource } from '@atlassian/jira-router-resources-jsm-repackaging-banner/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskChangeCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-change-calendar-routes/src/servicedeskChangeCalendarRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ChangeCalendar, { LazyChangeCalendar } from './ui/calendar/index.tsx';

export const servicedeskChangeCalendarRouteEntry: Route = createEntry(
	servicedeskChangeCalendarRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'service-management.change-calendar',
		component: ChangeCalendar,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: AsyncJSMOperationsHorizontalNav,
			onlyShowHorizontalOnNav4: true,
		},

		layout: serviceProjectLayout,

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			jsmRepackagingBannerResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyChangeCalendar, LazyJSMOperationsHorizontalNav],

		meta: {
			reporting: {
				id: 'changeCalendar',
				packageName: toPackageName(APP_NAMES.CHANGE_CALENDAR),
				teamName: 'jenga',
			},
		},
	}),
);
