/**
 * @generated SignedSource<<4e26b9254ba51ddf47dd2a135bb7e3d1>>
 * @relayHash 95c05c2e4cfc866291c2609eefc83f84
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 4581815a0ec6d97729ac800d5c73933f40d36436d5645ae4bd5d98aa7b5235bc

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessListQuery } from './src_jiraBusinessListQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "4581815a0ec6d97729ac800d5c73933f40d36436d5645ae4bd5d98aa7b5235bc",
    "metadata": {},
    "name": "src_jiraBusinessListQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
