import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import { performCmdbGetRequest } from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import { isCMDBEnabledInEnvironment } from '@atlassian/jira-servicedesk-cmdb-fedramp/src/index.tsx';
import {
	type WorkspaceId,
	toWorkspaceId,
} from '@atlassian/jira-servicedesk-insight-shared-types/src/common/types/shared-types/index.tsx';
import { PREMIUM_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { TenantContext } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import type { RouterDataContext, ResourceStoreContext } from '@atlassian/react-resource-router';
import { SERVICE_DESK } from '@atlassian/jira-shared-types/src/application-key.tsx';

export const getWorkspaceUrl = (baseUrl: string) =>
	`${baseUrl}/rest/servicedesk/cmdb/latest/workspace`;

type ResultType = {
	id: string;
};

export type WorkspaceQueryResponse = {
	size: number;
	hasNextPage: boolean;
	results: ResultType[];
};

type UserJsmLicenceState =
	| { siteLicence: 'unlicensed' }
	| { siteLicence: 'licensed'; siteIsPremium: boolean; userLicensed: boolean };

export const getUserJsmLicenceState = (tenantContext: TenantContext): UserJsmLicenceState => {
	const { licensedProducts, appPermissions } = tenantContext;
	const hasJsmLicence = licensedProducts && licensedProducts[SERVICE_DESK];
	const isJsmLicensedUser = appPermissions && appPermissions.hasServiceDeskAccess;

	if (!hasJsmLicence) {
		return { siteLicence: 'unlicensed' };
	}

	return {
		siteLicence: 'licensed',
		siteIsPremium: isPremiumInstance(tenantContext),
		userLicensed: isJsmLicensedUser,
	};
};

export const isPremiumInstance = (tenantContext: TenantContext) => {
	const {
		appEditions: { serviceDesk: serviceDeskAppEdition },
	} = tenantContext;

	const isPremium = serviceDeskAppEdition === PREMIUM_EDITION;

	return isPremium;
};

export const isLicensedPremiumUser = (tenantContext: TenantContext) => {
	const { appPermissions } = tenantContext;

	const isPremium = isPremiumInstance(tenantContext);
	const isLicensedUser = appPermissions && appPermissions.hasServiceDeskAccess;

	return isPremium && isLicensedUser;
};

const transformResponse = (response: WorkspaceQueryResponse): WorkspaceId | null => {
	const ids = response.results ?? [];
	return ids.length ? toWorkspaceId(ids[0].id) : null;
};

export const fetchWorkspaceId = (
	routerContext: RouterDataContext,
	{ tenantContext }: ResourceStoreContext,
) => {
	const { baseUrl } = tenantContext;

	if (isLicensedPremiumUser(tenantContext) === false || !isCMDBEnabledInEnvironment()) {
		return null;
	}
	return performCmdbGetRequest(getWorkspaceUrl(baseUrl))
		.then(transformResponse)
		.catch((error) => {
			log.safeErrorWithoutCustomerData(
				'spa.view.routes.servicedesk.workspace-id',
				'Error while fetching servicedesk Insight workspace id',
				error,
			);
			return null;
		});
};

export default fetchWorkspaceId;
