import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

const jiraIssueFormCreateEndpoint =
	'projects/:projectKey/forms/form/direct/:templateFormId/:issueTypeId?'; // the 1 query param is "requestTypeId"

export const softwareClassicProformaIssueFormsDirectEmbedRoute: InvariantRoute = {
	name: 'classic-software-proforma-issue-forms-direct-embed',
	path: `/jira/software/c/${jiraIssueFormCreateEndpoint}/embed`,
	exact: true,
};
