import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { softwareGoalsClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareGoalsClassicRoute.tsx';
import AtlasGoalsPage from './ui/goals/index.tsx';

export const softwareGoalsClassicRouteEntry = createEntry(softwareGoalsClassicRoute, () => ({
	group: 'classic-software',
	component: AtlasGoalsPage,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarSoftwareClassic,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(), // these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
	],
}));
