import React from 'react';
import { MenuGroup } from '@atlaskit/menu';
import { MENU_ID_CUSTOMER_SERVICE_ANALYTICS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { HideL1MenuItemSection } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/hide-l1-menu-item-action/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';

export const CUSTOMER_SERVICE_ANALYTICS_HREF = '/jira/helpcenter/analytics';

export const CustomerServiceAnalyticsMenuLinkItem = () => {
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(
		L1_MENU_ID.CUSTOMER_SERVICE_ANALYTICS,
	);

	return (
		<Nav4MenuLinkItem
			testId="navigation-apps-sidebar-nav4-sidebars-content-customer-service-analytics.ui.sidenav-customer-service"
			href={CUSTOMER_SERVICE_ANALYTICS_HREF}
			menuId={MENU_ID_CUSTOMER_SERVICE_ANALYTICS}
			elemBefore={icon}
			actionsOnHover={
				<MoreNavMenuButton
					MenuItems={() => (
						<MenuGroup>
							<HideL1MenuItemSection
								menuId={MENU_ID_CUSTOMER_SERVICE_ANALYTICS}
								hasSeparator={false}
							/>
						</MenuGroup>
					)}
				/>
			}
		>
			{formattedMessage}
		</Nav4MenuLinkItem>
	);
};
