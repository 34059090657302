import { showFlag } from '@atlassian/jira-flags/src/services';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import messages from './messages.tsx';

// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
const reloadPage = () => window.location.reload();
const sendAnalyticEvent = (operationName: string): void => {
	getAnalyticsWebClientPromise().then((client) => {
		const analyticsClient = client.getInstance();
		if (!analyticsClient) {
			return;
		}

		analyticsClient.sendTrackEvent({
			action: 'showed',
			actionSubject: 'unauthorizedFlag',
			attributes: {
				operation: operationName,
			},
			source: 'jiraRelayFetch',
		});
	});
};

export const showUnauthenticatedFlag = (operationName: string) => {
	showFlag({
		type: 'error',
		title: messages.title,
		description: messages.description,
		messageId: 'relay-fetch.services.error-flags.showUnauthenticatedFlag',
		messageType: 'transactional',
		actions: [
			{
				content: messages.login,
				onClick: reloadPage,
			},
		],
	});
	sendAnalyticEvent(operationName);
};
