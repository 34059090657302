/**
 * @generated SignedSource<<067fa7fbcebd00e597b347ca915f6034>>
 * @relayHash fd0d1d5dce03064b407037920fb2720b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID e73190d907fbed87beb0dcedf5843143674119a51c7aba6c42a0159b955a42d1

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiServiceHubDetailsPageQuery } from './uiServiceHubDetailsPageQuery.graphql';

const node: PreloadableConcreteRequest<uiServiceHubDetailsPageQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "e73190d907fbed87beb0dcedf5843143674119a51c7aba6c42a0159b955a42d1",
    "metadata": {},
    "name": "uiServiceHubDetailsPageQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
