import React from 'react';
import { PLAN_CALENDAR } from '@atlassian/jira-capabilities/src/constants.tsx';
import { setCapability } from '@atlassian/jira-capabilities/src/services/active-capability-tracker/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { arjCalendarEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/arjCalendarEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { CalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/calendar/CalendarSkeleton.tsx';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-calendar/entrypoint.tsx';
import type { Route } from '@atlassian/react-resource-router';

const planCalendarEmbedRouteEntry = createChromelessEntryPoint({
	main: calendarEntryPoint,
});

export const arjCalendarEmbedRouteEntry: Route = createEntry(arjCalendarEmbedRoute, () => ({
	group: 'software',
	ufoName: 'software-advanced-roadmaps-calendar-embed',
	// Remove 'component' when cleanup 'smart_links_for_plans' FG
	component: ErrorPagesNotFound,

	skeleton: () => {
		if (fg('smart_links_for_plans')) {
			return <CalendarSkeleton />;
		}
		return undefined;
	},

	entryPoint() {
		if (fg('smart_links_for_plans')) {
			setCapability(PLAN_CALENDAR);
			return planCalendarEmbedRouteEntry;
		}
		return undefined;
	},

	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		themePreferenceResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	meta: {
		capability: PLAN_CALENDAR,
	},

	forPaint: [],
}));
