import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { gridSize, borderRadius } from '@atlassian/jira-common-styles/src/main.tsx';

export type NavigationComponentWrapperType = {
	children: ReactNode;
	navModuleLabelsHidden?: boolean;
	context?: string;
	submenuActive?: boolean;
	nestedAppOpen?: boolean;
	// No clue why the below is required - I get a eslint error about no unused props
	// even though i'm explicitly deconstructing the prop in the NavigationHeaderWrapper
	nestedMenuOpen?: boolean;
};

export type ShortCutsAndAppsWrapperType = {
	children: ReactNode;
};

export type ExpandableProjectSettingsWrapperType = {
	children: ReactNode;
};

export type NavigationHeaderWrapperType = {
	children: ReactNode;
};

export const NavigationHeaderWrapper = (props: NavigationHeaderWrapperType) => (
	<NavigationHeaderWrapperContainer>{props.children}</NavigationHeaderWrapperContainer>
);

export const NavigationExpandableContentWrapper = (props: NavigationComponentWrapperType) => {
	const { nestedAppOpen, submenuActive, children } = props;
	return (
		<NavigationExpandableContentWrapperContainer
			nestedAppOpen={nestedAppOpen}
			submenuActive={submenuActive}
		>
			{children}
		</NavigationExpandableContentWrapperContainer>
	);
};

export const NavigationContentWrapper = (props: NavigationComponentWrapperType) => (
	<NavigationContentWrapperContainer
		submenuActive={props.submenuActive}
		nestedMenuOpen={props.nestedMenuOpen}
		data-component-selector="NavigationContentWrapperContainer"
	>
		{props.children}
	</NavigationContentWrapperContainer>
);

export const NextGenIssueTypesWrapper = (props: NavigationComponentWrapperType) => (
	<NextGenIssueTypesWrapperContainer>{props.children}</NextGenIssueTypesWrapperContainer>
);

export const ExpandableProjectSettingsWrapper = (props: ExpandableProjectSettingsWrapperType) => (
	<ExpandableProjectContainer>{props.children}</ExpandableProjectContainer>
);

export const ShortCutsAndAppsWrapper = (props: ShortCutsAndAppsWrapperType) => (
	<ShortCutsAndAppsContainer>{props.children}</ShortCutsAndAppsContainer>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavigationHeaderWrapperContainer = styled.div({
	marginLeft: token('space.100'),
	/*
        !important is required here as we have to force a line height of 1.2 to override styles coming from atlaskit
        which are throwing out the line height and making the text alignment off center.
    */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-item-title], [data-item-description]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
		lineHeight: '1.2 !important',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavigationExpandableContentWrapperContainer = styled.div<{
	submenuActive?: boolean;
	nestedAppOpen?: boolean;
}>(
	/*
        If the nested menu is open, adjust the height accordingly
        auto - if we're dealing with a app forge app that has a nested menu or something like project settings
        100% - if we're not dealing with the former.
        without 'auto' we get some jank duing the transition from main menu to project settings / app menu
    */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => props.submenuActive && { height: props.nestedAppOpen ? 'auto' : '100%' },
	{
		marginLeft: token('space.100'),
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"div[role='group']": {
			/*  the below selectors target all variations of menu items <MenuItem /> which can be
                a, button or either nested inside a span -> if the nested menu is not open, the left negative margin
                (which accounts for the expand button) needs to be removed so menu items appear correctly in nested
                menus.
            */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > a, & > span > a, & > button, & > span > button': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				width: `calc(100% + ${gridSize}px)`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				paddingLeft: `${2.125 * gridSize}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				marginRight: `${0.875 * gridSize}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				marginLeft: (props) =>
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					props.submenuActive ? `-${0.75 * gridSize}px` : `-${2.125 * gridSize}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				borderRadius: `${Math.round(borderRadius * 1.333)}px`,
			},
		},
	},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NavigationContentWrapperContainer = styled.div<{
	submenuActive?: boolean;
	nestedMenuOpen?: boolean;
}>(
	{
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		height: (props) =>
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			props.submenuActive ? `calc(100% - ${props.nestedMenuOpen ? 0 : 7.5 * gridSize}px)` : 'auto',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"div[role='group']": {
			/*  the below selectors target all variations of menu items <MenuItem /> which can be
                a, button or either nested inside a span -> if the nested menu is not open, the left negative margin
                (which accounts for the expand button) needs to be removed so menu items appear correctly in nested
                menus.
            */
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& > a, & > span > a, & > button, & > span > button': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
				paddingLeft: (props) =>
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					props.submenuActive ? `${3 * gridSize}px` : `${2.125 * gridSize}px`,
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
				borderRadius: `${Math.round(borderRadius * 1.333)}px`,
			},
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) =>
		props.nestedMenuOpen && {
			overflow: 'auto',
			// eslint-disable-next-line @atlaskit/design-system/use-tokens-space
			"& > div:not([role='group'])": { '& > a': { paddingLeft: '21px' } },
		},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const NextGenIssueTypesWrapperContainer = styled.div({
	height: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > a': {
		paddingLeft: token('space.300'),
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"& > div:not([role='group'])": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			paddingLeft: `${gridSize * 2.325}px`,
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[role='group']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'[data-item-title]': {
			paddingLeft: token('space.150'),
		},
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > a, & > span > a, & > button': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-important-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			paddingLeft: `${gridSize * 2.5}px !important`,
		},
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandableProjectContainer = styled.div({
	height: '100%',
	margin: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"[role='group']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& > a': {
			paddingLeft: token('space.200'),
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'[data-ds--menu--heading-item]': {
		marginLeft: token('space.200'),
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShortCutsAndAppsContainer = styled.div({
	/* Inherit height to enable scrolling in nested menus */
	height: '100%',
	/* Inside nested menu -> back to project */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors -- Ignored via go/DSP-18766
	"& > div:not([role='group'])": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'> a': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			paddingLeft: `${gridSize * 2.325}px`,
		},
	},
	/* Forge/Connect Apps section (Note: that closure can be removed when removing the FF) */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	"& > div[role='group']": {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		'& [data-ds--menu--heading-item]': {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
			paddingLeft: `${gridSize * 2.625}px`,
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			color: token('color.text.subtle', colors.N500),
		},
	},
	/* Inside nested menu -> content */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& [data-component-selector="NavigationContentWrapperContainer"]': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
		"[role='group']": {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			'& [data-item-title]': {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
				paddingLeft: `${gridSize * 1.375}px`,
			},
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
			"& div[role='group']": {
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
				'& [data-ds--menu--heading-item]': {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
					paddingLeft: `${gridSize * 2.75}px`,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
					'& ~ a': {
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
						paddingLeft: `${gridSize * 1.375}px`,
					},
				},
			},
		},
	},
});
