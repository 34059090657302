import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { trashPlansRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-spa-routes/src/trashPlansRoute.tsx';
import { TrashedPlansDirectory, LazyTrashedPlans } from './ui/plan-trash/index.tsx';

export const trashPlansRouteEntry = createEntry(trashPlansRoute, () => ({
	group: 'software',
	component: TrashedPlansDirectory,
	layout: globalSettingsLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
	},
	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation, LazyTrashedPlans],
}));
