/**
 * @generated SignedSource<<41924ccb303b5d3b27264e56cf9a5101>>
 * @relayHash f01b0a348049b3c26499af7159f898e3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c6e9a099b5fe0bed228db70dab296511e1b1c69247c364583be4f0735cc57708

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybookDetailQuery } from './PlaybookDetailQuery.graphql';

const node: PreloadableConcreteRequest<PlaybookDetailQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c6e9a099b5fe0bed228db70dab296511e1b1c69247c364583be4f0735cc57708",
    "metadata": {},
    "name": "PlaybookDetailQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
