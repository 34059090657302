import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { systemConfigurationGeneralResource } from '@atlassian/jira-router-resources-system-configuration-settings/src/index.tsx';
import { emailNotificationsLogAccessRoute } from '@atlassian/jira-router-routes-admin-pages-email-notifications-log-routes/src/emailNotificationsLogAccessRoute.tsx';
import { notificationLogsEntryPoint } from '@atlassian/jira-spa-apps-notification-logs/entrypoint.tsx';
import type NotificationLogsType from '@atlassian/jira-spa-apps-notification-logs/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';

const emailNotificationsLogEntryPointPage = createPageEntryPoint({
	main: notificationLogsEntryPoint,
	topNavigationMenuId: MENU_ID.SETTINGS,
});

const LazyNotificationLogsType = lazyForPaint<typeof NotificationLogsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-system-email-notifications-log", jiraSpaEntry: "async-system-email-notifications-log" */ '@atlassian/jira-spa-apps-notification-logs'
		),
	),
);

const NotificationLogs = () => (
	<LazyPage Page={LazyNotificationLogsType} pageId="Notification Logs" shouldShowSpinner={false} />
);

export const emailNotificationsLogAccessRouteEntry: Route = createEntry(
	emailNotificationsLogAccessRoute,
	() => ({
		group: 'global-settings',
		component: NotificationLogs,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsSystem,
		},

		resources: [
			...getNavigationResources(),
			navigationSidebarGlobalResource,
			systemConfigurationGeneralResource,
		],

		forPaint: [LazyAtlassianNavigation, LazyNotificationLogsType],
		pageId: 'admin-settings-email-notifications-log',

		entryPoint() {
			return emailNotificationsLogEntryPointPage;
		},
	}),
);
