import parameters from '@atlassian/jira-relay/src/__generated__/JswExpandableProjectQuery.graphql';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

const NO_OF_BOARDS_TO_FETCH = 25;

export const nav4JSWProjectEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "nav4-jsw-project-entry-point" */ './JswExpandableProjectQuery'
		).then(({ JswExpandableProjectQuery }) => JswExpandableProjectQuery),
	),
	getPreloadProps: (params: EntryPointRouteParams) => {
		const cloudId = params.tenantContext?.cloudId ?? '';
		const projectKey = params.context?.match.params?.projectKey ?? '';
		return {
			queries: {
				projects: {
					parameters,
					variables: {
						cloudId,
						projectKey,
						noOfBoardsToFetch: NO_OF_BOARDS_TO_FETCH,
					},
				},
			},
		};
	},
});
