import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { dashboardBackgroundScriptsLayout } from '@atlassian/jira-background-scripts-layout/src/index.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { gadgetDirectoryResource } from '@atlassian/jira-router-resources-dashboard/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardEditRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardEditRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import { DashboardEditPage, dashboardResources, LazyDashboardEdit } from './ui/index.tsx';

// IMPORTANT - must precede the view route due to substring path match
export const dashboardEditRouteEntry: Route = createEntry(dashboardEditRoute, () => ({
	group: 'dashboard',
	component: DashboardEditPage,
	layout: composeLayouts(globalLayout, dashboardBackgroundScriptsLayout),
	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},
	resources: [...getNavigationResources(), ...dashboardResources(), gadgetDirectoryResource],
	forPaint: [LazyAtlassianNavigation, LazyDashboardEdit],
	meta: {
		capability: DASHBOARD,
	},
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
