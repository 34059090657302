import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	CUSTOM_ROUTE_SUFFIX,
	TIME_SCALE,
	ISSUE_KEY,
} from './common/constants.tsx';

export const servicedeskReportsCustomIssueRoute: InvariantRoute = {
	name: 'servicedesk-reports-custom-time-scale-issue',
	path: `${SERVICE_DESK_ROUTE_PREFIX}${CUSTOM_ROUTE_SUFFIX}${TIME_SCALE}${ISSUE_KEY}`,
	query: ['oldIssueView?'],
	exact: true,
};
