import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	label: {
		id: 'polaris-component-collection-advanced-filters.filters.label',
		defaultMessage: 'Select search mode',
		description:
			'The label which is attached to the mode switcher for selecting between basic and advanced mode. This is not visible in the UI, but is read aloud by assistive technology like screen-readers.',
	},
	basicModeDisabledTooltip: {
		id: 'polaris-component-collection-advanced-filters.filters.basic-mode-disabled-tooltip',
		defaultMessage: "You can't switch to basic as the query is too complex or is invalid",
		description:
			'Tooltip content to display when a the switch to the basic mode button is disabled.',
	},
	basicMode: {
		id: 'polaris-component-collection-advanced-filters.filters.basic-mode',
		defaultMessage: 'Basic',
		description: 'Button text to display when a user can switch to the basic search mode.',
	},
	advancedMode: {
		id: 'polaris-component-collection-advanced-filters.filters.advanced-mode',
		defaultMessage: 'Advanced',
		description: 'Button text to display when a user can switch to the advanced search mode.',
	},
	addFilterLabel: {
		id: 'polaris-component-collection-advanced-filters.add-filter-label',
		defaultMessage: 'Add a filter',
		description: 'Button text to add a new filter in basic mode.',
	},
	orderByError: {
		id: 'polaris-component-collection-advanced-filters.order-by-removed-title',
		defaultMessage: '"ORDER BY" not allowed. Use view sorting.',
		description:
			'ORDER BY is not allowed when applying an advanced filter to a roadmap. Sorting must be done individually in views.',
	},
	selectProjectsFirst: {
		id: 'polaris-component-collection-advanced-filters.select-projects-first',
		defaultMessage: 'Select projects to filter the ideas.',
		description:
			'Message to inform users they should select first some projects before filtering ideas.',
	},
});
