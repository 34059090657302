import type { RecordSourceSelectorProxy } from 'relay-runtime';
import { graphql, useMutation } from 'react-relay';
import type {
	updateNavigationCustomisationMutation,
	updateNavigationCustomisationMutation$data,
} from '@atlassian/jira-relay/src/__generated__/updateNavigationCustomisationMutation.graphql';
import type { DisplaySettingsProperties } from '../ui/display-settings-provider/types.tsx';
import type { NavigationCustomisationNodes } from './types.tsx';
import { useSidebarSettingsAri, useOwnerAri } from './ari/use-ari.tsx';
import { mapProperties } from './navigation-customisation-properties.tsx';

export const useUpdateNavigationCustomisation = (
	menuName: string,
	parentType?: string,
	parentId?: string,
) => {
	const entityAri = useSidebarSettingsAri(menuName, parentType, parentId);
	const ownerAri = useOwnerAri();

	const [commitMutation] = useMutation<updateNavigationCustomisationMutation>(graphql`
		mutation updateNavigationCustomisationMutation($input: SettingsNavigationCustomisationInput!) {
			settings_updateNavigationCustomisation(input: $input) {
				properties {
					nodes {
						key
						value
					}
				}
			}
		}
	`);

	const updateStore = (
		store: RecordSourceSelectorProxy,
		newNodes: NavigationCustomisationNodes,
	) => {
		const settings = store
			.getRoot()
			.getLinkedRecord('settings_navigationCustomisation', { entityAri, ownerAri });
		const propertiesRecord = settings?.getLinkedRecord('properties');

		if (propertiesRecord) {
			const existingNodesMap = new Map();
			const existingNodes = propertiesRecord.getLinkedRecords('nodes');

			// Match existing nodes by key for updating
			existingNodes?.forEach((node) => {
				existingNodesMap.set(node.getValue('key'), node);
			});

			const updatedNodes = newNodes?.map((newNode) => {
				if (newNode && newNode?.key && newNode?.value) {
					const key = newNode.key;
					const value = newNode.value;
					let nodeRecord = existingNodesMap.get(key);

					if (nodeRecord) {
						nodeRecord.setValue(value, 'value');
					} else {
						nodeRecord = store.create(key, 'Node');
						nodeRecord.setValue(key, 'key');
						nodeRecord.setValue(value, 'value');
					}

					return nodeRecord;
				}
				return null;
			});

			if (updatedNodes) {
				propertiesRecord.setLinkedRecords(updatedNodes?.filter(Boolean), 'nodes');
			}
		}
	};

	const updateNavigationCustomisation = (displaySettingProperties: DisplaySettingsProperties) => {
		const properties = mapProperties(displaySettingProperties);
		commitMutation({
			variables: {
				input: {
					entityAri,
					ownerAri,
					properties,
				},
			},
			optimisticUpdater: (store) => {
				updateStore(store, properties);
			},
			updater: (
				store: RecordSourceSelectorProxy,
				data: updateNavigationCustomisationMutation$data | null | undefined,
			) => {
				const newNodes = data?.settings_updateNavigationCustomisation?.properties?.nodes;
				updateStore(store, newNodes);
			},
		});
	};

	return { updateNavigationCustomisation };
};
