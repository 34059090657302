import React, { useCallback, useEffect, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { ExitingPersistence, SlideIn } from '@atlaskit/motion';
import { easeOut } from '@atlaskit/motion/curves';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { DRAWER_Z_INDEX } from '../../common/constants.tsx';
import messages from './messages.tsx';

type DrawerProps = PropsWithChildren<{
	isOpen: boolean;
	onClose: VoidFunction;
}>;

const animationTimingFunction = () => easeOut;

export const Drawer = ({ isOpen, onClose, children }: DrawerProps) => {
	const { formatMessage } = useIntl();

	const handleKeyDown = useCallback(
		(event: KeyboardEvent) => {
			if (event.key === 'Escape' && isOpen) {
				onClose();
			}
		},
		[isOpen, onClose],
	);

	useEffect(() => {
		if (isOpen) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.addEventListener('keydown', handleKeyDown);
		} else {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handleKeyDown);
		}

		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('keydown', handleKeyDown);
		};
	}, [isOpen, handleKeyDown]);

	return (
		<ExitingPersistence appear>
			{isOpen && (
				<SlideIn
					animationTimingFunction={animationTimingFunction}
					duration="medium"
					enterFrom="left"
					exitTo="left"
				>
					{(props) => (
						<Container {...props}>
							{children}
							<Box xcss={closeButtonStyles}>
								<IconButton
									shape="circle"
									icon={CrossIcon}
									label={formatMessage(messages.closeButtonLabel)}
									appearance="subtle"
									onClick={onClose}
								/>
							</Box>
						</Container>
					)}
				</SlideIn>
			)}
		</ExitingPersistence>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div({
	height: 'calc(100vh - var(--topNavigationHeight))',
	width: '100vw',
	position: 'fixed',
	top: 'var(--topNavigationHeight)',
	left: token('space.0'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	zIndex: DRAWER_Z_INDEX,
	backgroundColor: token('elevation.surface.overlay'),
	overflow: 'hidden',
});

const closeButtonStyles = xcss({
	position: 'absolute',
	top: 'space.250',
	left: 'space.250',
});
