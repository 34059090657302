import { isFedRamp } from '@atlassian/atl-context';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { productsJsmChatopsAndVideosToolsRoute } from '@atlassian/jira-router-routes-products-jsm-chatops-and-videos-tools-routes/src/productsJsmChatopsAndVideosToolsRoute.tsx';
import ProductsJsmChatopsAndVideoTools, {
	LazyProductsJsmChatopsAndVideoTools,
} from './ui/index.tsx';

export const productsJsmChatopsAndVideosToolsRouteEntry = createEntry(
	productsJsmChatopsAndVideosToolsRoute,
	() => ({
		group: 'global-settings',
		component: componentWithCondition(
			isFedRamp,
			ErrorPagesNotFound,
			ProductsJsmChatopsAndVideoTools,
		),

		layout: opsSettingsLayout,

		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
			horizontal: AsyncHorizontalOperationsConfigurationNav,
			onlyShowHorizontalOnNav4: true,
		},

		resources: [...getNavigationResources(), ...getNavigationSidebarProductsSettingResource()],

		forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyProductsJsmChatopsAndVideoTools,
		],
	}),
);
