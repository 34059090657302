export const DragState = {
	IDLE: 'idle',
	PREVIEW: 'preview',
	DRAGGING: 'dragging',
} as const;

export const DETAILS_QUERY_PARAM_VALUE = 'details';

export const STEP_QUERY_PARAM = 'step';

export const NEW_PLAYBOOK_IDENTIFIER = '_new_';
