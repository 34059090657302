import { NIN_NEW_ISSUE_NAVIGATOR } from '@atlassian/jira-capabilities/src/constants.tsx';

export const getIssueNavigatorRouteEntryCommon = () => ({
	apdexIgnoreParams: ['issueKey'],
	meta: {
		capability: NIN_NEW_ISSUE_NAVIGATOR,
		reporting: {
			id: 'issue-navigator',
			packageName: 'issueNavigator',
			teamName: 'empanada',
		},
	},
});
