import React from 'react';
import { Flex, Box, xcss, Inline, Stack } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

export const TabsSkeleton = () => (
	<Flex xcss={[tabsWrapperSkeletonStyles]}>
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
		<Box xcss={tabSkeletonStyles} />
	</Flex>
);

export const HorizontalNavSkeleton = () => (
	<Stack space="space.050" alignBlock="end" xcss={horizontalNavWrapperStyles}>
		<Box xcss={[baseSkeletonStyles, breadcrumbStyles]} />
		<Inline spread="space-between" xcss={headerWrapperStyles}>
			<Box xcss={primaryContainerStyles}>
				<Box xcss={[baseSkeletonStyles, squareStyles]} />
				<Box xcss={[baseSkeletonStyles, projectTitleStyles]} />
				<Box xcss={[baseSkeletonStyles, squareStyles]} />
			</Box>
			<Inline space="space.150" xcss={actionStyles}>
				<Box xcss={[baseSkeletonStyles, squareStyles]} />
				<Box xcss={[baseSkeletonStyles, squareStyles]} />
			</Inline>
		</Inline>
		<TabsSkeleton />
	</Stack>
);

const tabsWrapperSkeletonStyles = xcss({
	gap: 'space.150',
	height: '32px',
	paddingInlineStart: 'space.300',
	paddingInlineEnd: 'space.300',
	paddingBlockEnd: 'space.150',
	marginBlockStart: 'space.100',
	position: 'relative',
	'::after': {
		content: '""',
		position: 'absolute',
		bottom: '0',
		backgroundColor: 'color.skeleton',
		height: '2px',
		left: '0',
		right: '0',
	},
});

const tabSkeletonStyles = xcss({
	minWidth: token('space.1000'),
	backgroundColor: 'color.skeleton',
	borderRadius: token('border.radius'),
});

const horizontalNavWrapperStyles = xcss({
	paddingTop: 'space.300',
	minBlockSize: '122px',
});

const baseSkeletonStyles = xcss({
	backgroundColor: 'color.skeleton',
	borderRadius: 'border.radius',
});

const breadcrumbStyles = xcss({
	blockSize: token('space.300'),
	inlineSize: '60px',
	marginInlineStart: 'space.300',
	marginBlockEnd: 'space.025',
});

const primaryContainerStyles = xcss({
	display: 'flex',
	gap: 'space.100',
	marginInlineStart: 'space.300',
});

const squareStyles = xcss({
	blockSize: token('space.300'),
	inlineSize: token('space.300'),
});

const headerWrapperStyles = xcss({
	marginBlockStart: 'space.050',
});

const projectTitleStyles = xcss({
	blockSize: token('space.300'),
	inlineSize: '180px',
});

const actionStyles = xcss({
	marginInlineEnd: 'space.300',
});
