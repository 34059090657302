/**
 * @generated SignedSource<<b11d5f22228dc9211f8c5e00e7ff8b20>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data = {
  readonly jira?: {
    readonly jqlBuilder: {
      readonly hydrateJqlQuery?: {
        readonly __typename: "JiraJqlHydratedQuery";
        readonly jql: string | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
      readonly hydrateJqlQueryForFilter?: {
        readonly __typename: "JiraJqlHydratedQuery";
        readonly jql: string | null | undefined;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
  readonly " $fragmentSpreads": FragmentRefs<"jqlEditor_jqlBuilderBasic_JQLEditorUI" | "useHydratedValues" | "useHydratedValues_cascadeSelectPicker" | "useQuery">;
  readonly " $fragmentType": "ui_jqlBuilder_JQLBuilderUI_hydrationQueryData";
};
export type ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$key = {
  readonly " $data"?: ui_jqlBuilder_JQLBuilderUI_hydrationQueryData$data;
  readonly " $fragmentSpreads": FragmentRefs<"ui_jqlBuilder_JQLBuilderUI_hydrationQueryData">;
};

import HydrationRefetchQuery_graphql from './HydrationRefetchQuery.graphql';

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "viewContext",
  "variableName": "viewContext"
},
v1 = [
  {
    "kind": "ScalarField",
    "name": "__typename"
  },
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "kind": "ScalarField",
        "name": "jql"
      }
    ],
    "type": "JiraJqlHydratedQuery"
  }
];
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "accountId"
    },
    {
      "kind": "RootArgument",
      "name": "cloudId"
    },
    {
      "kind": "RootArgument",
      "name": "filterAri"
    },
    {
      "kind": "RootArgument",
      "name": "includeJira"
    },
    {
      "kind": "RootArgument",
      "name": "includeUser"
    },
    {
      "kind": "RootArgument",
      "name": "isFilter"
    },
    {
      "kind": "RootArgument",
      "name": "jql"
    },
    {
      "kind": "RootArgument",
      "name": "viewContext"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "fragmentPathInResult": [],
      "operation": HydrationRefetchQuery_graphql
    }
  },
  "name": "ui_jqlBuilder_JQLBuilderUI_hydrationQueryData",
  "selections": [
    {
      "kind": "FragmentSpread",
      "name": "useHydratedValues"
    },
    {
      "kind": "FragmentSpread",
      "name": "jqlEditor_jqlBuilderBasic_JQLEditorUI"
    },
    {
      "kind": "FragmentSpread",
      "name": "useQuery"
    },
    {
      "kind": "FragmentSpread",
      "name": "useHydratedValues_cascadeSelectPicker"
    },
    {
      "condition": "includeJira",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "args": [
                {
                  "kind": "Variable",
                  "name": "cloudId",
                  "variableName": "cloudId"
                }
              ],
              "concreteType": "JiraJqlBuilder",
              "kind": "LinkedField",
              "name": "jqlBuilder",
              "plural": false,
              "selections": [
                {
                  "condition": "isFilter",
                  "kind": "Condition",
                  "passingValue": false,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "query",
                          "variableName": "jql"
                        },
                        (v0/*: any*/)
                      ],
                      "kind": "LinkedField",
                      "name": "hydrateJqlQuery",
                      "plural": false,
                      "selections": (v1/*: any*/)
                    }
                  ]
                },
                {
                  "condition": "isFilter",
                  "kind": "Condition",
                  "passingValue": true,
                  "selections": [
                    {
                      "args": [
                        {
                          "kind": "Variable",
                          "name": "id",
                          "variableName": "filterAri"
                        },
                        (v0/*: any*/)
                      ],
                      "kind": "LinkedField",
                      "name": "hydrateJqlQueryForFilter",
                      "plural": false,
                      "selections": (v1/*: any*/)
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "560710f99a045f83f5c29aa4233e060f";

export default node;
