import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectFormBuilderRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormBuilderRoute.tsx';
import { formBuilderPageEntrypoint } from './ui/spa/business/form-builder/index.tsx';

export const classicBusinessProjectFormBuilderRouteEntry = createEntry(
	classicBusinessProjectFormBuilderRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.form-view',
		layout: businessProjectLayout,
		skeleton: ThemedLazySkeleton,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),

		entryPoint: () => formBuilderPageEntrypoint,
	}),
);
