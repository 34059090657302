import React from 'react';
import type PlanProgramType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-program/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanProgram = lazyForPaint<typeof PlanProgramType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-program", jiraSpaEntry: "async-plan-program" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-program/index-old.tsx'
			),
		),
	{ ssr: false },
);

export const PlanProgram = () => (
	<LazyPage Page={LazyPlanProgram} pageId="plan-program" shouldShowSpinner />
);
