import React, { useCallback, useState, type ReactNode } from 'react';
import prsLocaleMapper from '@atlaskit/prs-locale-mapper';
import { useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import type { Flag } from '@atlassian/people-teams';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import { lazy } from '@atlassian/react-loosely-lazy';
import { useRouterActions } from '@atlassian/react-resource-router';

// Relay entry point is not applicable here because this component does not live in jira frontend.
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyTeamCreateDialog = lazy(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-teams-team-create-dialog" */ '@atlassian/people-teams'
	).then(({ TeamCreateDialog }) => TeamCreateDialog),
);

type TriggerCreateTeamDialogCallback = () => void;
type CreateTeamModalReturn = [ReactNode, TriggerCreateTeamDialogCallback];

export const useCreateTeamModal = (): CreateTeamModalReturn => {
	const [isOpen, setIsOpen] = useState(false);

	const { locale } = useIntl();
	const { showFlag } = useFlagsService();
	const { push } = useRouterActions();

	const cloudId = useCloudId();
	const { data: orgId } = useOrgId();
	const {
		data: { user },
	} = useCurrentUser();
	const atlassianAccountId = useAccountId() || user.accountId;

	const pushRoute = useCallback((url: string) => push(url), [push]);
	const addFlag = useCallback(
		({ appearance, ...flagProps }: Flag) => {
			const Flag = getFlagComponent(appearance);
			showFlag((serviceProps) => (
				<Flag
					{...flagProps}
					{...serviceProps}
					messageId={`navigation-apps-sidebar-nav4-sidebars-content-teams.ui.use-create-team-modal.flag.showFlag.${appearance}.${flagProps.id}`}
					messageType="engagement"
				/>
			));
		},
		[showFlag],
	);

	const callback = useCallback<TriggerCreateTeamDialogCallback>(() => {
		setIsOpen(true);
	}, []);

	if (!atlassianAccountId) {
		throw new Error('Missing current user id');
	}

	return [
		isOpen ? (
			<Placeholder name="team-create-dialog">
				<PeopleAndTeamsConfigurationProvider product="jira" locale={prsLocaleMapper(locale)}>
					<LazyTeamCreateDialog
						pushRoute={pushRoute}
						cloudId={cloudId}
						orgId={orgId}
						product="jira"
						principalId={atlassianAccountId}
						onClose={() => setIsOpen(false)}
						enableMembershipSettingsChoice
						currentUser={
							user.accountId
								? {
										id: user.accountId,
										fullName: user.userFullname,
										// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
										email: user.emailAddress !== undefined ? user.emailAddress : '',
										// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
										avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
										nickname: user.userFullname,
									}
								: undefined
						}
						addFlag={addFlag}
					/>
				</PeopleAndTeamsConfigurationProvider>
			</Placeholder>
		) : null,
		callback,
	];
};

const getFlagComponent = (appearance: Flag['appearance']) => {
	switch (appearance) {
		case 'warning':
			return WarningFlag;
		case 'error':
			return ErrorFlag;
		case 'success':
		case 'normal':
		default:
			return SuccessFlag;
	}
};
