import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';

const SYSTEM_AND_GLOBAL_FIELDS_URL =
	'/rest/polaris/global-fields/searchable?includeSystemFields=true';

export type Field = {
	name: string;
	key: FieldKey;
	custom: boolean;
	type: string;
	clauseNames: string[];
};

export const getSystemAndGlobalFieldKeys = (): Promise<{ [jqlFieldValue: string]: Field }> =>
	fetchJson<Field[]>(SYSTEM_AND_GLOBAL_FIELDS_URL).then((res) => {
		const fields: { [keyOrName: string]: Field } = {};

		res.forEach((field) => {
			if (field.custom) {
				fields[`cf[${field.key.replace('customfield_', '')}]`] = field;
				fields[field.clauseNames.slice(-1)[0]] = field;
			} else {
				fields[field.key] = field;
			}
		});
		return fields;
	});
