import React, {
	createContext,
	type ReactNode,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import isEqual from 'lodash/isEqual';
import { fetchQuery, type RefetchFn, useRelayEnvironment } from 'react-relay';
import type { Subscription, GraphQLTaggedNode } from 'relay-runtime';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type { HydrationRefetchQuery } from '@atlassian/jira-relay/src/__generated__/HydrationRefetchQuery.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type HydrationQueryContextProps = {
	/**
	 * Whether or not the hydration query is currently fetching
	 */
	hydrationQueryIsFetching: boolean;
	/**
	 * The JQL context for the last completed hydration operation
	 */
	hydrationQueryContextJql: string | undefined;
};

type HydrationQueryProviderProps = {
	refetch: RefetchFn<HydrationRefetchQuery>;
	query: GraphQLTaggedNode;
	contextJql: string;
	hydratedJql: string | undefined;
	children: ReactNode;
};

const HydrationQueryContext = createContext<HydrationQueryContextProps>({
	hydrationQueryIsFetching: false,
	hydrationQueryContextJql: '',
});
HydrationQueryContext.displayName = 'HydrationQueryContext';

export const useHydrationQuery = () => useContext(HydrationQueryContext);
export const HydrationQueryProvider = ({
	refetch,
	query,
	contextJql,
	hydratedJql,
	children,
}: HydrationQueryProviderProps) => {
	const [hydrationQueryIsFetching, setHydrationQueryIsFetching] = useState(false);
	// contextJql for last completed prefetch (i.e. fetchQuery) operation
	const [hydrationQueryContextJqlOld, setHydrationQueryContextJql] = useState(contextJql);
	const cloudId = useCloudId();
	const atlassianAccountId = useAccountId();
	const environment = useRelayEnvironment();
	const inFlightRequest = useRef<Subscription>();
	const inFlightContextJql = useRef<string>();

	let hydrationQueryContextJql: string | undefined;
	if (fg('empanada_nin_concurrent_mode_fixes')) {
		hydrationQueryContextJql = hydratedJql;
	} else {
		hydrationQueryContextJql = hydrationQueryContextJqlOld;
	}

	useEffect(() => {
		let shouldRefetch: boolean;
		if (fg('empanada_nin_concurrent_mode_fixes')) {
			shouldRefetch =
				contextJql !== hydrationQueryContextJql && contextJql !== inFlightContextJql.current;
		} else {
			shouldRefetch =
				!isEqual(contextJql, hydrationQueryContextJql) &&
				!isEqual(contextJql, inFlightContextJql.current);
		}

		if (shouldRefetch) {
			// Prevent race condition from multiple requests in progress
			// (ex. if user clicks quickly on different filters)
			inFlightRequest.current?.unsubscribe();
			inFlightContextJql.current = contextJql;

			const variables = {
				filterAri: '',
				includeFilter: false,
				jql: contextJql,
				accountId: atlassianAccountId ?? '',
				includeJira: true,
				includeUser: true,
				cloudId,
				isFilter: false,
			};

			setHydrationQueryIsFetching(true);

			// Prefetch data, then update returned variables on complete
			inFlightRequest.current = fetchQuery<HydrationRefetchQuery>(environment, query, variables, {
				fetchPolicy: 'store-or-network',
				networkCacheConfig: {
					metadata: { META_SLOW_ENDPOINT: true },
				},
			}).subscribe({
				complete: () => {
					refetch(variables, {
						fetchPolicy: 'store-only',
						onComplete: () => {
							setHydrationQueryIsFetching(false);
							if (!fg('empanada_nin_concurrent_mode_fixes')) {
								setHydrationQueryContextJql(contextJql);
							}
							inFlightContextJql.current = undefined;
						},
					});
				},
				error: (error: Error) => {
					setHydrationQueryIsFetching(false);
					fireErrorAnalytics({
						meta: {
							id: 'hydrateJqlBuilderQuery',
							packageName: 'jiraJqlBuilder',
							teamName: 'empanada',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				},
			});
		}
	}, [
		atlassianAccountId,
		cloudId,
		contextJql,
		environment,
		hydrationQueryContextJql,
		query,
		refetch,
		setHydrationQueryIsFetching,
	]);

	const contextValue: HydrationQueryContextProps = useMemo(
		() => ({
			hydrationQueryIsFetching,
			hydrationQueryContextJql,
		}),
		[hydrationQueryIsFetching, hydrationQueryContextJql],
	);

	return (
		<HydrationQueryContext.Provider value={contextValue}>{children}</HydrationQueryContext.Provider>
	);
};
