import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { knowledgeHubBrowseViewArticleRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubBrowseViewArticleRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import ServiceDeskKnowledgeHubViewArticle, {
	LazyServicedeskKnowledgeHubViewArticle,
} from './ui/view-article/index.tsx';

export const knowledgeHubBrowseViewArticleRouteEntry: Route = createEntry(
	knowledgeHubBrowseViewArticleRoute,
	() => ({
		group: 'servicedesk',
		perfMetricKey: 'service-management.knowledge.article',
		component: ServiceDeskKnowledgeHubViewArticle,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getKnowledgeHubResources(),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubViewArticle],
		meta: {
			reporting: {
				id: APP_NAMES.KB_VIEW_ARTICLE,
				packageName: toPackageName(APP_NAMES.KB_VIEW_ARTICLE),
				teamName: 'jsm-nebula',
			},
		},
	}),
);
