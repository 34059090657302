import React, { useState, type SyntheticEvent } from 'react';
import Button from '@atlaskit/button/new';
import { FormFooter } from '@atlaskit/form';
import WarningIcon from '@atlaskit/icon/core/migration/warning';
import { Box, Inline, Pressable, Text, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { IDEAS_COUNT_LIMIT } from '../constants.tsx';
import messages from './messages.tsx';

export type FooterProps = {
	submitting?: boolean;
	ideasCount?: number;
	isIdeasCountLoading?: boolean;
};

export const Footer = ({ submitting, ideasCount, isIdeasCountLoading }: FooterProps) => {
	const { formatMessage, formatNumber } = useIntl();
	const [isLimitWarningPopupOpen, setIsLimitWarningPopupOpen] = useState(false);
	const limitWarningMessage = formatMessage(messages.ideasCountLimitMessage, {
		ideasCountLimit: formatNumber(IDEAS_COUNT_LIMIT),
	});
	const openWarningPopup = (e: SyntheticEvent) => {
		e.stopPropagation();
		setIsLimitWarningPopupOpen(true);
	};

	if (fg('jpd-aurora-roadmap-advanced-filtering')) {
		const exceedsIdeasLimit =
			!isIdeasCountLoading && !!ideasCount && ideasCount > IDEAS_COUNT_LIMIT;

		return (
			<Inline xcss={footerStyles} alignInline="end" space="space.300" alignBlock="start">
				{ideasCount !== undefined && (
					<Inline alignBlock="start" space="space.050" xcss={ideasCountWrapperStyles}>
						<Box xcss={issuesCountStyles}>
							<Text color="color.text.subtle">
								{ideasCount !== undefined && formatMessage(messages.ideasCount)}
							</Text>
							<Text color="color.text.subtle" weight="bold">
								{' '}
								{isIdeasCountLoading ? '' : formatNumber(ideasCount)}
							</Text>
						</Box>
						{exceedsIdeasLimit && (
							<Popup
								isOpen={isLimitWarningPopupOpen}
								onClose={() => setIsLimitWarningPopupOpen(false)}
								placement="bottom"
								messageId="polaris-component-collection-create.ui.form.footer.popup"
								messageType="transactional"
								content={() => <Box xcss={limitWarningPopupStyles}>{limitWarningMessage}</Box>}
								trigger={(triggerProps) => (
									<Pressable
										{...triggerProps}
										onClick={openWarningPopup}
										xcss={ideasCountWarningIconButtonStyles}
									>
										<WarningIcon
											spacing="spacious"
											label={limitWarningMessage}
											color={token('color.icon.warning')}
										/>
									</Pressable>
								)}
							/>
						)}
					</Inline>
				)}
				<Button
					testId="polaris-component-collection-create.ui.form.footer.button"
					type={exceedsIdeasLimit ? 'button' : 'submit'}
					appearance="primary"
					isLoading={submitting}
					onClick={exceedsIdeasLimit ? openWarningPopup : undefined}
				>
					{formatMessage(messages.createButtonLabel)}
				</Button>
			</Inline>
		);
	}

	const exceedsIdeasLimit = !!ideasCount && ideasCount > IDEAS_COUNT_LIMIT;

	return (
		<FormFooter>
			<Button
				testId="polaris-component-collection-create.ui.form.footer.button"
				type="submit"
				appearance="primary"
				isLoading={submitting}
				isDisabled={exceedsIdeasLimit}
			>
				{formatMessage(messages.createButtonLabel)}
			</Button>
		</FormFooter>
	);
};

const footerStyles = xcss({
	marginBlockStart: 'space.300',
});

const ideasCountWrapperStyles = xcss({
	flex: 1,
});

const ideasCountWarningIconButtonStyles = xcss({
	background: 'transparent',
	flexShrink: 0,
	marginTop: 'space.050',
});

const issuesCountStyles = xcss({
	marginTop: 'space.075',
});

const limitWarningPopupStyles = xcss({
	padding: 'space.200',
	maxWidth: '410px',
});
