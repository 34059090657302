import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import { ThemeSetter } from '@atlassian/jira-project-theme-page-container/src/ui/theme-setter/ThemeSetter.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';

export const ProjectThemeSetter = () => {
	const { queryReference } = useRelayResource(projectThemeResource);

	return (
		<JSErrorBoundary id="projectThemeSetter" packageName="jiraPageContainerV2" teamName="deliveroo">
			<Placeholder name="project-theme-setter-placeholder" fallback={null}>
				{queryReference && <ThemeSetter queryReference={queryReference} />}
			</Placeholder>
		</JSErrorBoundary>
	);
};
