import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	assetsDataManager: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.common.data-manager.assets-data-manager',
		defaultMessage: 'Data Manager',
		description:
			'Name of a link to "Data Manager" which is product name that should be capitalised',
	},
	assetsOpenDataManager: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.common.data-manager.assets-open-data-manager',
		defaultMessage: 'Open Data Manager',
		description:
			'Icon label to open "Data Manager" which is product name that should be capitalised',
	},
	assetsDataManagerBeta: {
		id: 'navigation-apps-sidebar-nav4-sidebars-content-assets.common.data-manager.assets-data-manager-beta',
		defaultMessage: 'Beta',
		description: 'Indicates that the product is in "beta" (early access)',
	},
});
