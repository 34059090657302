import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import componentWithCondition from '@atlassian/jira-common-util-component-with-condition/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsToolchainResource } from '@atlassian/jira-router-resources-project-settings-toolchain/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { projectSettingsToolchainRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsToolchainRoute.tsx';
import { isDevopsFeatureDisabledInFedRamp } from '@atlassian/jira-software-devops-fedramp-utils/src/index.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import DevOpsConnectionsSettings, {
	LazyDevOpsConnectionsSettings,
} from './ui/project-settings/connections/index.tsx';
import { getSettingsNavigationResources } from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsToolchainRouteEntry: Route = createEntry(
	projectSettingsToolchainRoute,
	() => ({
		group: 'project-settings-software',
		component: componentWithCondition(
			isDevopsFeatureDisabledInFedRamp,
			ErrorPagesNotFound,
			DevOpsConnectionsSettings,
		),
		layout: softwareProjectLayoutNoOnboarding,
		resources: [...getSettingsNavigationResources(), projectSettingsToolchainResource],
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		forPaint: [LazyAtlassianNavigation, LazyDevOpsConnectionsSettings],
		ufoName: 'project-settings.devops-connections',
	}),
);
