import type {
	AccountId,
	CloudId,
	ActivationId,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { JiraNavigationSettingsAri } from '@atlassian/ari/jira/navigation-settings';
import { IdentityUserAri } from '@atlassian/ari/identity/user';

import type { NavigationSettingScope } from './types.tsx';

export const createNavigationSettingScope = (
	menuName: string,
	parentType?: string,
	parentId?: string,
) => {
	if ((parentType === undefined) !== (parentId === undefined)) {
		throw new Error(
			'Invalid Navigation Setting Scope parentType and parentId must be defined together',
		);
	}
	if (parentType !== undefined && parentId !== undefined) {
		return `${parentType}/${parentId}/${menuName}`;
	}
	return menuName;
};

export const parseNavigationSettingsScope = (scope: string): NavigationSettingScope => {
	const [parentTypeOrMenuName, parentId, menuName] = scope.split('/');
	if (parentTypeOrMenuName && !parentId && !menuName) {
		return { menuName: parentTypeOrMenuName };
	}
	if (parentTypeOrMenuName && parentId && menuName) {
		return { parentType: parentTypeOrMenuName, parentId, menuName };
	}
	throw new Error('Invalid Navigation Setting Scope format');
};

export const createSidebarSettingsAri = (
	cloudId: CloudId,
	activationId: ActivationId,
	menuName: string,
	parentType?: string,
	parentId?: string,
) => {
	return JiraNavigationSettingsAri.create({
		siteId: cloudId,
		activationId,
		navigationSettingScope: createNavigationSettingScope(menuName, parentType, parentId),
	}).toString();
};

export const parseSidebarSettingsAri = (ariLikeString: string) => {
	const ari = JiraNavigationSettingsAri.parse(ariLikeString);
	return parseNavigationSettingsScope(ari.navigationSettingScope);
};

export const createIdentityUserAri = (userId: AccountId) => {
	return IdentityUserAri.create({ userId }).toString();
};
