import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getAssetsAppResources } from '@atlassian/jira-router-routes-servicedesk-cmdb-entries/src/common/getCmdbResources.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { LazyAssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { assetsSearchRoute } from '@atlassian/jira-router-routes-assets-routes/src/assetsSearchRoute.tsx';
import { assetsSearchEntryPoint } from '@atlassian/jira-assets-app-search/entrypoint.tsx';
import { getSharedRouteProps } from './common/constants.tsx';

const assetsSearchPageEntryPoint = createChromelessEntryPoint({
	main: assetsSearchEntryPoint,
});

export const assetsSearchRouteEntry: Route = createEntry(assetsSearchRoute, () => ({
	...getSharedRouteProps(),
	entryPoint: () => assetsSearchPageEntryPoint,
	resources: getAssetsAppResources(),
	forPaint: [LazyAssetsLayout],
	perfMetricKey: 'assets-app.interact-with-search-page',
}));
