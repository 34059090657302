import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
// eslint-disable-next-line jira/import-whitelist
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { businessHorizontalNav3Resource } from '@atlassian/jira-router-resources-business-navigation-items/src/index.tsx';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
import { overviewDetailsResource } from '@atlassian/jira-router-resources-business-overview-details/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { licenseDetailsResource_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-license-details/src/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';

export const getBusinessProjectResources = () =>
	getWillShowNav4()
		? [
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				resourceWithCondition2(() => fg('jira_theming_relay_migration'), projectThemeResource),
			]
		: [
				projectCreatePermissionsResource,
				navigationResource,
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				businessHorizontalNav3Resource,
				licenseDetailsResource_DEPRECATED_DO_NOT_USE,
				resourceWithCondition2(() => fg('jira_theming_relay_migration'), projectThemeResource),
			];

export const getBusinessProjectWithAsyncHorizontalNavResources = () =>
	getWillShowNav4()
		? [
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				horizontalNavJiraResource,
				resourceWithCondition2(() => fg('jira_theming_relay_migration'), projectThemeResource),
			]
		: [
				projectCreatePermissionsResource,
				navigationResource,
				// eslint-disable-next-line camelcase
				DEPRECATED_DO_NOT_USE_projectDetailsResource,
				businessHorizontalNav3Resource,
				licenseDetailsResource_DEPRECATED_DO_NOT_USE,
				resourceWithCondition2(() => fg('jira_theming_relay_migration'), projectThemeResource),
			];

export const getProjectViewResources = () => [
	...(fg('migrate_horizontal_nav_to_entrypoint')
		? getBusinessProjectResources()
		: getBusinessProjectWithAsyncHorizontalNavResources()),
	...getNavigationResources(),
	// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
	projectContextResource,
	forgeProjectModuleResource,
	staticSidebarShortcutsResource,
	staticProjectSidebarNavigationResource,
	staticProjectSidebarConnectAddonsNavigationResource,
	// end of resources expanded from getNavigationSidebarProjectResource
	fieldsResource,
];

/**
 * @deprecated Overviews are phased out in favor of Plans
 */
export const getBusinessOverviewResources = () => [
	projectCreatePermissionsResource,
	navigationResource,
	licenseDetailsResource_DEPRECATED_DO_NOT_USE,
	overviewDetailsResource,
];

/**
 * @deprecated Overviews are phased out in favor of Plans
 */
export const getOverviewViewResources = () => [
	...getBusinessOverviewResources(),
	...getNavigationResources(),
	fieldsResource,
];
