import React, { type ReactNode } from 'react';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { PageConfigContainer as PageConfigContainerType } from './index.tsx';

export const LazyAssetsPageConfigContainer = lazyForPaint<typeof PageConfigContainerType>(() =>
	import(/* webpackChunkName: "async-assets-page-config-container" */ './index').then(
		(module) => module.PageConfigContainer,
	),
);

export const AssetsPageConfigContainer = ({ children }: { children: ReactNode }) => (
	<Placeholder name="assets-page-config-container-async">
		<LazyAssetsPageConfigContainer>{children}</LazyAssetsPageConfigContainer>
	</Placeholder>
);
