import parameters from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentViewQuery.graphql';
import { JSResourceForUserVisible } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';

export const nav4ProjectsContentEntryPoint = createEntryPoint({
	root: JSResourceForUserVisible(() =>
		import(
			/* webpackChunkName: "async-nav4-projects-content-view-query" */ './Nav4ProjectsContentViewQuery'
		).then(({ Nav4ProjectsContentViewQuery }) => Nav4ProjectsContentViewQuery),
	),
	getPreloadProps: (params: EntryPointRouteParams) => {
		const cloudId = params.tenantContext?.cloudId || '';
		return {
			queries: {
				projects: {
					parameters,
					variables: {
						cloudId,
					},
				},
			},
		};
	},
});
