/**
 * @generated SignedSource<<4c73aaf0579d92c13469981aa5dd3a97>>
 * @relayHash 308e700b71c591d557752506dbcbe6b2
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d72560fe8d4e39b1aa0b561b3cdea71102f7c5a72118cad7da75997b3cb4a86b

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type reportCategoriesQuery$variables = {
  boardId?: string | null | undefined;
  projectKey?: string | null | undefined;
};
export type reportCategoriesQuery$data = {
  readonly jira: {
    readonly reportsPage: {
      readonly categories: ReadonlyArray<{
        readonly name: string;
        readonly " $fragmentSpreads": FragmentRefs<"reportCategory_reportsOverviewPage">;
      } | null | undefined>;
    };
  };
};
export type reportCategoriesQuery = {
  response: reportCategoriesQuery$data;
  variables: reportCategoriesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "boardId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectKey"
},
v2 = [
  {
    "kind": "Variable",
    "name": "boardId",
    "variableName": "boardId"
  },
  {
    "kind": "Variable",
    "name": "projectKey",
    "variableName": "projectKey"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "name"
},
v4 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "reportCategoriesQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "concreteType": "JiraQuery",
          "kind": "LinkedField",
          "name": "jira",
          "plural": false,
          "selections": [
            {
              "kind": "RequiredField",
              "field": {
                "args": (v2/*: any*/),
                "concreteType": "JiraReportsPage",
                "kind": "LinkedField",
                "name": "reportsPage",
                "plural": false,
                "selections": [
                  {
                    "kind": "RequiredField",
                    "field": {
                      "concreteType": "JiraReportCategory",
                      "kind": "LinkedField",
                      "name": "categories",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "RequiredField",
                          "field": (v3/*: any*/),
                          "action": "THROW",
                          "path": "jira.reportsPage.categories.name"
                        },
                        {
                          "kind": "FragmentSpread",
                          "name": "reportCategory_reportsOverviewPage"
                        }
                      ]
                    },
                    "action": "THROW",
                    "path": "jira.reportsPage.categories"
                  }
                ]
              },
              "action": "THROW",
              "path": "jira.reportsPage"
            }
          ]
        },
        "action": "THROW",
        "path": "jira"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "reportCategoriesQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "JiraReportsPage",
            "kind": "LinkedField",
            "name": "reportsPage",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraReportCategory",
                "kind": "LinkedField",
                "name": "categories",
                "plural": true,
                "selections": [
                  (v3/*: any*/),
                  {
                    "concreteType": "JiraReport",
                    "kind": "LinkedField",
                    "name": "reports",
                    "plural": true,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "key"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "imageUrl"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "description"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "url"
                      },
                      (v4/*: any*/)
                    ]
                  },
                  (v4/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "d72560fe8d4e39b1aa0b561b3cdea71102f7c5a72118cad7da75997b3cb4a86b",
    "metadata": {},
    "name": "reportCategoriesQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "c635bccc09ddfca6ad7459cb2e570d20";

export default node;
