import React from 'react';
import type { HelpCenterSettingsSupportWebsitePagesComponent as HelpCenterSettingsSupportWebsitePagesType } from '@atlassian/jira-spa-apps-help-center-settings-support-website-pages/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyHelpCenterSettingsSupportWebsitePages = lazyForPaint<
	typeof HelpCenterSettingsSupportWebsitePagesType
>(
	() =>
		import(
			/* webpackChunkName: "async-help-center-settings-support-website-pages", jiraSpaEntry: "async-help-center-settings-support-website-pages" */ '@atlassian/jira-spa-apps-help-center-settings-support-website-pages'
		),
);

export const HelpCenterSettingsSupportWebsitePages = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsSupportWebsitePages}
		pageId="help-center-settings-support-website-pages"
		shouldShowSpinner
	/>
);
