/**
 * @generated SignedSource<<4e1561aedd01b915bed6529d2151d5d0>>
 * @relayHash 51eb42330d2fdd46e0933617699ae886
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID a2b96243589d6874c35486375561e6900e7e47b4e2652364d9b1719b45d7648c

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PopupContentQuery } from './PopupContentQuery.graphql';

const node: PreloadableConcreteRequest<PopupContentQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "a2b96243589d6874c35486375561e6900e7e47b4e2652364d9b1719b45d7648c",
    "metadata": {},
    "name": "PopupContentQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
