import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { AsyncSupportCenterHorizontalNav } from '@atlassian/jira-help-center-settings-agent-studio/src/ui/horizontal-nav/index.tsx';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-help-center-settings/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';

export const navigation = {
	sidebarId: SIDEBAR_ID.HELP_CENTER_SETTINGS,
	menuId: MENU_ID.HELP_CENTER_SETTINGS,
	sidebar: AsyncHelpCenterSettingsSidebar,
};

export const agentStudioNavigation = {
	sidebarId: SIDEBAR_ID.HELP_CENTER_SETTINGS,
	menuId: MENU_ID.HELP_CENTER_SETTINGS,
	sidebar: AsyncHelpCenterSettingsSidebar,
	horizontal: componentWithFG(
		'enable_support_center_agent_studio_page',
		AsyncSupportCenterHorizontalNav,
		() => null,
	),
};
