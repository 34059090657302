import { useCallback } from 'react';
import { generatePath, type Route } from '@atlassian/react-resource-router';
import { useLocation } from '../../../utils/use-location/index.tsx';

type GetLinkPropsOptions = {
	shouldMatchExactPath: boolean;
};

type GetLinkPropsArgs = {
	currentPath: string;
	menuItemPath: string;
	options?: GetLinkPropsOptions;
};

export type GetLinkPropsResult = { href: string; isSelected: boolean };

type UseGetLinkPropsResult = {
	getLinkProps: (menuItemPath: string, options?: GetLinkPropsOptions) => GetLinkPropsResult;
	getLinkPropsFromRoute: (
		menuItemRoute: Route,
		pathParamValues?: Record<string, string | number>,
		options?: GetLinkPropsOptions,
	) => GetLinkPropsResult;
};

/**
 * Comparison is done in the getMenuLinkPropsFromUrlPath.
 * @returns some of the MenuItem props (`href`, `isSelected`), based on the current path. isSelected is true if the current page/route matches the menuItemPath
 */
export const useGetLinkProps = (): UseGetLinkPropsResult => {
	const location = useLocation();

	const getLinkProps = useCallback(
		(menuItemPath: string, options?: GetLinkPropsOptions) =>
			getMenuLinkPropsFromUrlPath({
				currentPath: location.pathname,
				menuItemPath,
				options,
			}),
		[location.pathname],
	);

	const getLinkPropsFromRoute = useCallback(
		(
			menuItemRoute: Route,
			pathParamValues: Record<string, string | number> = {},
			options?: GetLinkPropsOptions,
		) => {
			const menuItemPath = generatePath(menuItemRoute.path, pathParamValues);
			return getLinkProps(menuItemPath, options);
		},
		[getLinkProps],
	);

	return {
		getLinkPropsFromRoute,
		/**
		 * Consider using getLinkPropsFromRoute instead, to be able to reuse the page route object, rather than copying its url path around.
		 */
		getLinkProps,
	};
};

const defaultOptions = { shouldMatchExactPath: true };

const getMenuLinkPropsFromUrlPath = ({
	currentPath,
	menuItemPath,
	options,
}: GetLinkPropsArgs): GetLinkPropsResult => {
	const { shouldMatchExactPath } = {
		...defaultOptions,
		...options,
	};

	return {
		href: menuItemPath,
		isSelected: shouldMatchExactPath
			? currentPath.replace(/\/$/, '') === menuItemPath.replace(/\/$/, '')
			: // analog of old matchPartialUrlPathname()
				currentPath.includes(menuItemPath.replace(/\/$/, '')),
	};
};
