import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarPlanResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { planPageWithoutScenarioIdRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planPageWithoutScenarioIdRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { RedirectPlanPageWithoutScenarioId } from './common/ui/redirect/plan/index.tsx';
import { LazyPlanTimeline } from './ui/plan-timeline/index-old.tsx';

export const planPageWithoutScenarioIdRouteEntry: Route = createEntry(
	planPageWithoutScenarioIdRoute,
	() => ({
		group: 'software',
		ufoName: 'software-advanced-roadmaps-plan',
		component: RedirectPlanPageWithoutScenarioId,
		layout: globalLayout,

		resources: [
			...getNavigationResources(),
			...getNavigationSidebarPlanResources(),
			advancedRoadmapsPlanPageInitialChecksResource,
		],

		preloadOptions: {
			earlyChunks: ['advanced-roadmaps-early-entry'],
		},

		navigation: {
			menuId: MENU_ID.PLANS,
		},

		forPaint: [LazyAtlassianNavigation, LazyPlanTimeline],
	}),
);
