import React from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { HorizontalNavQueue } from './ui/index.tsx';

const LazyHorizontalNavQueue = lazyForPaint<typeof HorizontalNavQueue>(() =>
	import(/* webpackChunkName: "async-jsm-horizontal-nav-queue" */ './ui').then(
		({ HorizontalNavQueue }) => HorizontalNavQueue,
	),
);

export const AsyncHorizontalNavQueue = () => (
	<Placeholder name="horizontal-jsm-horizontal-nav-queue" fallback={null}>
		<LazyHorizontalNavQueue />
	</Placeholder>
);
