import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';

/**
 * Reports Overview page route for CMP projects that do not have a board.
 */
export const softwareReportsOverviewBoardlessRoute: InvariantRoute = {
	name: 'software-reports-boardless',
	path: '/jira/software/c/projects/:projectKey/reports',
	exact: true,
};
