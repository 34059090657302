/** @jsx jsx */
import React from 'react';
import { jsx } from '@compiled/react';
import { cssMap } from '@atlaskit/css';
import Heading from '@atlaskit/heading';
import { Box, Stack, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { getErrorHash } from '@atlassian/jira-errors-handling/src/utils/error-hash.tsx';
import GenericError from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/error/components/generic-error/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

const styles = cssMap({
	wrapper: {
		backgroundColor: token('color.background.neutral'),
		color: token('color.text.subtle'),
		paddingTop: token('space.200'),
		paddingRight: token('space.200'),
		paddingBottom: token('space.200'),
		paddingLeft: token('space.200'),
		textAlign: 'center',
		alignItems: 'center',
		gap: token('space.100'),
	},
	error: {
		borderRadius: token('border.radius.050'),
		backgroundColor: token('color.background.accent.gray.subtler'),
		color: token('color.text.subtlest'),
		paddingTop: token('space.025'),
		paddingBottom: token('space.025'),
		paddingLeft: token('space.050'),
		paddingRight: token('space.050'),
	},
});

type FallbackProps = {
	error: Error;
};

export const Fallback = ({ error }: FallbackProps) => {
	const { formatMessage } = useIntl();

	return (
		<Stack xcss={styles.wrapper}>
			<GenericError width="40px" alt="" />
			<Heading size="xsmall">{formatMessage(messages.errorHeading)}</Heading>
			<Text>{formatMessage(messages.errorBody)}</Text>
			<Box xcss={styles.error}>ERROR: {getErrorHash(error)}</Box>
		</Stack>
	);
};
