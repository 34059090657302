/* eslint-disable eslint-comments/no-restricted-disable */
/* eslint-disable @atlassian/react-entrypoint/no-imports-in-entrypoint-file */
import { createEntryPoint } from '@atlassian/react-entrypoint';
import { JSResourceForInteraction } from '@atlassian/react-async';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/HorizontalNavPlansQuery$parameters';
import { NAVIGATION_ITEMS_PAGE_SIZE } from '@atlassian/jira-navigation-kit-common/src/index.tsx';

function toPlanAri({
	siteId,
	activationId,
	planId,
}: {
	siteId: string;
	activationId: string;
	planId: string;
}) {
	return `ari:cloud:jira:${siteId}:plan/activation/${activationId}/${planId}`;
}

export const plansHorizontalNavigationEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-plans-horizontal-nav-entrypoint" */ './src/ui/HorizontalNavPlans'
			),
	),
	getPreloadProps: ({
		context: { match },
		tenantContext: { cloudId, activationId },
	}: EntryPointRouteParams) => {
		const planId = match.params.planId;
		if (!planId) {
			throw new Error('Plan Id is missing');
		}
		const planAri = toPlanAri({ siteId: cloudId, activationId, planId });
		return {
			queries: {
				horizontalNavPlansQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						first: NAVIGATION_ITEMS_PAGE_SIZE,
						cloudId,
						containerNavigationInput: {
							scopeId: planAri,
						},
					},
				},
			},
		};
	},
});
