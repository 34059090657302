import { resources as roleBasedNotificationsResources } from '@atlassian/eoc-role-based-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { LazyHorizontalOperationsConfigurationNav } from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { roleBasedNotificationDetailRoute } from '@atlassian/jira-router-routes-eoc-role-based-notifications-routes/src/roleBasedNotificationDetailRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	LazyRoleBasedNotifications,
	RoleBasedNotificationsComponent,
} from './ui/RoleBasedNotificationsComponent.tsx';
import { RoleBasedNotificationDetail } from './ui/RoleBasedNotificationDetail.tsx';

export const roleBasedNotificationDetailRouteEntry: Route = createEntry(
	roleBasedNotificationDetailRoute,
	() => ({
		group: 'eoc',
		component: RoleBasedNotificationsComponent,
		layout: globalSettingsLayout,
		slot: RoleBasedNotificationDetail,
		resources: [
			...getNavigationResources(),
			roleBasedNotificationsResources[0],
			roleBasedNotificationsResources[1],
			getConsolidationStateResource(),
			navigationSidebarGlobalResource,
		],
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyRoleBasedNotifications,
		],
	}),
);
