import {
  JiraNavigationSettingsAriResourceOwner,
  JiraNavigationSettingsAriResourceType
} from "./chunk-DQIMIQK4.mjs";
import {
  RegisteredAri
} from "./chunk-YG2E6ZVD.mjs";
import {
  AriParser
} from "./chunk-Q7FABYST.mjs";

// src/jira/navigation-settings/manifest.ts
var jiraNavigationSettingsAriStaticOpts = {
  qualifier: "ari",
  platformQualifier: "cloud",
  cloudId: new RegExp("^[a-zA-Z0-9_\\-.]{1,255}$"),
  // eslint-disable-line no-useless-escape
  resourceOwner: JiraNavigationSettingsAriResourceOwner,
  resourceType: JiraNavigationSettingsAriResourceType,
  resourceIdSlug: "activation/{activationId}/{navigationSettingScope}",
  resourceIdSegmentFormats: {
    activationId: /[a-zA-Z0-9\-]+/,
    // eslint-disable-line no-useless-escape
    navigationSettingScope: /[!a-zA-Z0-9\-_.~@:{}=]+(\/[!a-zA-Z0-9\-_.~@:{}=]+)*/
    // eslint-disable-line no-useless-escape
  }
};

// src/jira/navigation-settings/index.ts
var JiraNavigationSettingsAri = class _JiraNavigationSettingsAri extends RegisteredAri {
  constructor(opts) {
    super(opts);
    this._siteId = opts.cloudId || "", this._activationId = opts.resourceIdSegmentValues.activationId, this._navigationSettingScope = opts.resourceIdSegmentValues.navigationSettingScope;
  }
  get siteId() {
    return this._siteId;
  }
  get activationId() {
    return this._activationId;
  }
  get navigationSettingScope() {
    return this._navigationSettingScope;
  }
  static create(opts) {
    let derivedOpts = {
      qualifier: jiraNavigationSettingsAriStaticOpts.qualifier,
      platformQualifier: jiraNavigationSettingsAriStaticOpts.platformQualifier,
      cloudId: opts.siteId,
      resourceOwner: jiraNavigationSettingsAriStaticOpts.resourceOwner,
      resourceType: jiraNavigationSettingsAriStaticOpts.resourceType,
      resourceId: `activation/${opts.activationId}/${opts.navigationSettingScope}`,
      resourceIdSegmentValues: {
        activationId: opts.activationId,
        navigationSettingScope: opts.navigationSettingScope
      }
    }, ariOpts = AriParser.fromOpts(derivedOpts, jiraNavigationSettingsAriStaticOpts);
    return new _JiraNavigationSettingsAri(ariOpts);
  }
  static parse(maybeAri) {
    let opts = AriParser.fromString(maybeAri, jiraNavigationSettingsAriStaticOpts);
    return new _JiraNavigationSettingsAri(opts);
  }
  getVariables() {
    return {
      siteId: this.siteId,
      activationId: this.activationId,
      navigationSettingScope: this.navigationSettingScope
    };
  }
};

export {
  JiraNavigationSettingsAri
};
