import '@atlassian/jira-vendor/src/vendor-parcel.tsx';
import '@atlassian/jira-vendor/src/commons-parcel.tsx';

import { setMark } from '@atlassian/jira-common-performance/src/marks.tsx';
import { getSpaRoutes } from '@atlassian/jira-routes/src/getSpaRoutes.tsx';
import { loadEmptyBundle } from '@atlassian/jira-atlaspack-cache-efficiency/src/index.tsx';
import { startJiraSpa as oldStartJiraSpa } from './start-jira-spa.tsx';
import { startJiraSpa as altStartJiraSpa } from './start-jira-spa-alt.tsx';

setMark('JIRA_SPA_ENTRY_LOADED');
loadEmptyBundle();

// It is easier to make the check against window than it is to make typescript happy here.
// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
if (!__SERVER__ && typeof window !== 'undefined' && window.__SPA_BOOTSTRAP_ALT) {
	altStartJiraSpa(getSpaRoutes);
} else {
	oldStartJiraSpa(getSpaRoutes);
}
