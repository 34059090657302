import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { customQueueCalendarRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customQueueCalendarRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { AsyncHorizontalNavQueue } from '@atlassian/jira-horizontal-nav-queue/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { getCalendarFilterFieldsResource } from '@atlassian/jira-router-resources-servicedesk-calendar/src/services/filters/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { ClassicCalendarSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-calendar/ClassicCalendarSkeleton.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';

import { calendarPageEntryPoint } from './ui/calendar/index.tsx';

export const customQueueCalendarRouteEntry: Route = createEntry(customQueueCalendarRoute, () => ({
	group: 'servicedesk',
	component: ErrorPagesNotFound,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: fg('jsm_views_inside_queues_-_main_flag') ? AsyncHorizontalNavQueue : () => null,
	},
	entryPoint: () =>
		fg('jsm_views_inside_queues_-_main_flag') ? calendarPageEntryPoint : undefined,
	forPaint: [LazyAtlassianNavigation],
	skeleton: ClassicCalendarSkeleton,
	resources: [
		...getQueuesResources(),
		getConsolidationStateResource(),
		getCalendarFilterFieldsResource(),
		resourceWithCondition2(() => !__SERVER__, uifBoardResource),
	],
}));
