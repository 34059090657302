import React from 'react';
import { qs } from 'url-parse';
import type { Match, Query } from '@atlassian/react-resource-router';
import { RedirectToProjectType } from './project-type/index.tsx';

export const ComponentsRedirect = () => {
	const page = (_: Match, { selectedItem, ...rest }: Query) => {
		// @ts-expect-error - TS2554 - Expected 1 arguments, but got 2.
		const querystring = qs.stringify(rest, true);
		return `/components${querystring}`;
	};
	return <RedirectToProjectType page={page} />;
};
