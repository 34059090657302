import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessListRoute } from '@atlassian/jira-router-routes-business-list-routes/src/jiraBusinessListRoute.tsx';
import { listEntryPoint } from '@atlassian/jira-spa-apps-business-list/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

const listPageEntryPoint = createPageEntryPoint({
	main: listEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const jiraBusinessListRouteEntry = createEntry(jiraBusinessListRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.list-view',
	layout: businessProjectLayout,
	skeleton: ThemedLazySkeleton,

	entryPoint: () => listPageEntryPoint,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: getProjectViewResources(),
}));
