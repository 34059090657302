import React, { useCallback } from 'react';
import { graphql, useFragment } from 'react-relay';
import { forgeModulesConcurrentLoadTime } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import { Nav4AppMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-apps/src/common/ui/app-menu-item/Nav4AppMenuItem.tsx';
import type { Nav4ForgeMenuItem$key } from '@atlassian/jira-relay/src/__generated__/Nav4ForgeMenuItem.graphql';
import { fg } from '@atlassian/jira-feature-gating';

type Nav4ForgeMenuItemProps = {
	queryRef: Nav4ForgeMenuItem$key;
};

export function Nav4ForgeMenuItem({ queryRef }: Nav4ForgeMenuItemProps) {
	const data = useFragment<Nav4ForgeMenuItem$key>(
		graphql`
			fragment Nav4ForgeMenuItem on JiraAppNavigationItem {
				appId
				...Nav4AppMenuItem
			}
		`,
		queryRef,
	);

	const onClick = useCallback(() => {
		fg('arken-1562_full_page_performance_metric_addition')
			? undefined
			: forgeModulesConcurrentLoadTime(data.appId || '').start({ startTime: performance.now() });
	}, [data.appId]);

	return <Nav4AppMenuItem queryRef={data} onClick={onClick} />;
}
