import { fireCmdbErrorAnalytics } from '@atlassian/jira-servicedesk-cmdb-error-handling/src/fireCmdbErrorAnalytics.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import {
	performCmdbGetRequest,
	performCmdbPutRequest,
} from '@atlassian/jira-servicedesk-cmdb-client/src/fetch.tsx';
import {
	getCmdbPermsTokenStatusUrl,
	getCmdbPermsRoleAssignmentUrl,
} from '@atlassian/jira-servicedesk-cmdb-rest-urls/src/common/constants/config.tsx';
import type { ActionApi, UpdateRoleAssignmentResponse } from '../../common/types.tsx';

type UpdateDataManagerRoleParams = {
	principalIds: string[];
	roleId: string;
};

type TokenStatusResponse = {
	isSuccessful: boolean;
};

export const UPDATE_ROLE_TOKEN_STATUS_TIMEOUT_MS = 5000;

export const UPDATE_ROLE_TOKEN_STATUS_INTERVAL_MS = 1000;

export const updateDataManagerRole =
	({ principalIds, roleId }: UpdateDataManagerRoleParams): ActionApi =>
	async ({ setState, getState }, { workspaceId, createAnalyticsEvent }) => {
		const state = getState();

		if (state.dataManager.rolesUpdateLoading) {
			return;
		}

		setState({
			...state,
			dataManager: { ...state.dataManager, rolesUpdateLoading: true },
		});

		try {
			const requestBody = {
				principalIds,
				roleId,
				resourceType: 'workspace',
			};

			const updateRolesResponse: UpdateRoleAssignmentResponse = await performCmdbPutRequest(
				getCmdbPermsRoleAssignmentUrl(workspaceId),
				{
					body: JSON.stringify(requestBody),
				},
			);

			const pollTimeoutTime = new Date(Date.now() + UPDATE_ROLE_TOKEN_STATUS_TIMEOUT_MS).getTime();

			// poll the checking of the token status for 5 seconds as updating of
			// Identity role assignments have eventually consistency
			const tryPollTokenStatus = async (
				resolve: (value?: unknown) => void,
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				reject: (error?: any) => void,
			) => {
				if (Date.now() > pollTimeoutTime) {
					reject(new Error('Some role assignments have failed or is still in progress'));
					return;
				}
				try {
					const url = getCmdbPermsTokenStatusUrl(
						workspaceId,
						updateRolesResponse.tokens[updateRolesResponse.tokens.length - 1],
					);
					const updateRoleAssignmentToken: TokenStatusResponse = await performCmdbGetRequest(url);

					if (updateRoleAssignmentToken.isSuccessful) {
						resolve();
						return;
					}

					setTimeout(() => {
						tryPollTokenStatus(resolve, reject);
					}, UPDATE_ROLE_TOKEN_STATUS_INTERVAL_MS);
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
				} catch (e: any) {
					// Ignore unexpected network/server error and quickly retry
					setTimeout(() => {
						tryPollTokenStatus(resolve, reject);
					}, UPDATE_ROLE_TOKEN_STATUS_INTERVAL_MS);
				}
			};

			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			await new Promise((res, rej: (error?: any) => void) => tryPollTokenStatus(res, rej));

			setState({
				...state,
				dataManager: {
					...state.dataManager,
					rolesUpdateLoading: false,
				},
			});

			fireTrackAnalytics(createAnalyticsEvent({}), 'updateDataManagerRole succeeded');
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setState({
				...state,
				dataManager: {
					...state.dataManager,
					rolesUpdateLoading: false,
					rolesUpdateError: error,
				},
			});

			fireCmdbErrorAnalytics({
				meta: {
					id: 'updateDataManagerRole',
					packageName: 'jiraServicedeskInsightGlobalConfigurationStore',
					teamName: 'data-manager',
				},
				error,
			});

			throw error;
		}
	};
