import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { classicBusinessProjectFormSubmitPublicRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormSubmitPublicRoute.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { formSubmitPageEntrypoint } from './ui/spa/business/form-submit-public/index.tsx';

export const classicBusinessProjectFormSubmitPublicRouteEntry = createEntry(
	classicBusinessProjectFormSubmitPublicRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.form-submit-public-view',
		layout: chromelessLayout,
		entryPoint: () =>
			expVal('jira_forms_public_access_experiment', 'isEnabled', 'false')
				? formSubmitPageEntrypoint
				: notFoundPageEntrypoint,
	}),
);
