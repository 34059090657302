import camelCase from 'lodash/camelCase';
import { fg } from '@atlassian/jira-feature-gating';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { servicedeskPlaybooksListRoute } from '@atlassian/jira-router-routes-project-settings-playbooks-routes/src/servicedeskPlaybooksListRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { SIDEBAR_ID, MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import playbookListEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-list/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { playbooksListRequestTypesResource } from '@atlassian/jira-playbooks-list-screen-router-resources/src/services/index.tsx';

const listEntryPoint = createPageEntryPoint({
	main: playbookListEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const servicedeskPlaybooksListRouteEntry: Route = createEntry(
	servicedeskPlaybooksListRoute,
	() => ({
		group: 'project-settings-servicedesk',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		entryPoint() {
			return fg('playbooks-in-jsm') ? listEntryPoint : undefined;
		},
		resources: [
			...getServiceDeskSettingsResources(),
			getConsolidationStateResource(),
			playbooksListRequestTypesResource,
		],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-list-view',
				packageName: camelCase('project-settings-playbooks-list'),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		ufoName: 'playbook-list',
	}),
);
