import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { externalImportRoute } from '@atlassian/jira-router-routes-admin-pages-external-import-routes/src/externalImportRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ExternalImport from './ui/async.tsx';

export const externalImportRouteEntry: Route = createEntry(externalImportRoute, () => ({
	group: 'global-settings',
	component: ExternalImport,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource],
	forPaint: [LazyAtlassianNavigation],
}));
