import { metrics } from '@atlassian/browser-metrics';
import {
	TTRSLO,
	TTISLO,
	WAITING_FOR_BE_DATA_TIMING_KEY,
	REQUEST_START,
	REQUEST_END,
	AVAILABLE_FIELDS_QUERY_TIMING_KEY,
	AVAILABLE_FIELDS_QUERY_START,
	AVAILABLE_FIELDS_QUERY_END,
	UI_RENDER_TIMING_KEY,
	UI_RENDER_START,
	UI_RENDER_END,
} from '../../constants.tsx';
import type { JWM_VIEW } from '../../types.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type PageLoadMetric = any;

const slo = {
	fmp: {
		initial: { threshold: TTRSLO },
		transition: { threshold: TTRSLO },
	},
	tti: {
		initial: { threshold: TTISLO },
		transition: { threshold: TTISLO },
	},
} as const;

const timings = [
	{
		key: WAITING_FOR_BE_DATA_TIMING_KEY,
		startMark: REQUEST_START,
		endMark: REQUEST_END,
	},
	{
		key: AVAILABLE_FIELDS_QUERY_TIMING_KEY,
		startMark: AVAILABLE_FIELDS_QUERY_START,
		endMark: AVAILABLE_FIELDS_QUERY_END,
	},
	{
		key: UI_RENDER_TIMING_KEY,
		startMark: UI_RENDER_START,
		endMark: UI_RENDER_END,
	},
];

export const pageLoadMetrics: Record<JWM_VIEW, PageLoadMetric> = {
	'approvals-view': metrics.pageLoad({
		key: 'jwm.approvals-view',
		slo,
		timings,
	}),
	summary: metrics.pageLoad({
		key: 'jwm.summary-view',
		slo,
		timings,
	}),
	'summary-embed': metrics.pageLoad({
		key: 'jwm.summary-view-embed',
		slo,
		timings,
	}),
	list: metrics.pageLoad({
		key: 'jwm.list-view',
		slo,
		timings,
	}),
	'list-embed': metrics.pageLoad({
		key: 'jwm.list-embed-view',
		slo,
		timings,
	}),
	'software-form-submit-embed': metrics.pageLoad({
		key: 'jsw.form-submit-embed',
		slo,
		timings,
	}),
	'software-list': metrics.pageLoad({
		key: 'jsw.list-view',
		slo,
		timings,
	}),
	'software-list-embed': metrics.pageLoad({
		key: 'jsw.list-view-embed',
		slo,
		timings,
	}),
	timeline: metrics.pageLoad({
		key: 'jwm.timeline-view',
		slo,
		timings,
	}),
	'timeline-embed': metrics.pageLoad({
		key: 'jwm.timeline-embed-view',
		slo,
		timings,
	}),
	calendar: metrics.pageLoad({
		key: 'jwm.calendar-view',
		slo,
		timings,
	}),
	'calendar-embed': metrics.pageLoad({
		key: 'jwm.calendar-embed-view',
		slo,
		timings,
	}),
	form: metrics.pageLoad({
		key: 'jwm.form-view',
		slo,
		timings,
	}),
	'form-submit': metrics.pageLoad({
		key: 'jwm.form-submit-view',
		slo,
		timings,
	}),
	'form-submit-embed': metrics.pageLoad({
		key: 'jwm.form-submit-embed-view',
		slo,
		timings,
	}),
	'form-submit-public': metrics.pageLoad({
		key: 'jwm.form-submit-public-view',
		slo,
		timings,
	}),
	'form-directory': metrics.pageLoad({
		key: 'jwm.form-directory-view',
		slo,
		timings,
	}),
	board: metrics.pageLoad({
		key: 'jwm.board-view',
		slo,
		timings,
	}),
	'jwm-board': metrics.pageLoad({
		key: 'jwm.jwm-board',
		slo,
		timings,
	}),
	'jwm-board-embed': metrics.pageLoad({
		key: 'jwm.jwm-board-embed',
		slo,
		timings,
	}),
	'jwm-pages': metrics.pageLoad({
		key: 'jwm.jwm-pages',
		slo,
		timings,
	}),
	// TODO: will be removed with sploverview-decouple-calendar-perf-metrics
	'software-plan-calendar': metrics.pageLoad({
		key: 'jsw.plan-calendar-view',
		slo,
		timings,
	}),
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const startedMarks: Record<string, any> = {};

export const markViewMetric = (view: JWM_VIEW, mark: string) => {
	const markKey = `${view}-${mark}`;
	// prevent overriding marks when markViewMetric() is called many times with the same param
	if (startedMarks[markKey]) {
		return;
	}
	startedMarks[markKey] = true;
	const metric = pageLoadMetrics[view];
	metric?.mark(mark);
};
