import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskAutomationViewLogRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationViewLogRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import ServiceDeskAutomationViewLog, {
	LazyProjectSettingsServiceDeskAutomationViewLog,
} from './ui/service-desk-automation/view-log/index.tsx';

export const projectSettingsServicedeskAutomationViewLogRouteEntry = createEntry(
	projectSettingsServicedeskAutomationViewLogRoute,
	() => ({
		group: 'project-settings-servicedesk',
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.log',
		component: ServiceDeskAutomationViewLog,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationViewLog],
	}),
);
