import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	information: {
		id: 'polaris-component-collection-create.form.filter-explanation.icon-label',
		defaultMessage: 'Information',
		description: 'Label for the filter explanation icon',
	},
	filterImageAlt: {
		id: 'polaris-component-collection-create.form.filter-explanation.filter-image',
		defaultMessage: 'Image depicting the filter feature',
		description: 'Alt text for the filter explanation image',
	},
	explanation: {
		id: 'polaris-component-collection-create.form.filter-explanation.explanation',
		defaultMessage: 'Use global and system fields to display ideas that matter in your roadmap.',
		description: 'Explanation for the filter feature',
	},
	explore: {
		id: 'polaris-component-collection-create.form.filter-explanation.explore',
		defaultMessage: 'Explore how the filtering works',
		description: 'Label for the explore button',
	},
});
