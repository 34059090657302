import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { dashboardsDirectoryResource } from '@atlassian/jira-router-resources-directories-dashboards/src/services/dashboards-directory/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardsDirectoryRedirectRoute } from '@atlassian/jira-router-routes-directories-routes/src/dashboardsDirectoryRedirectRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import { DashboardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { DashboardsDirectoryComponent, LazyDashboardsDirectory } from './ui/dashboards/index.tsx';

const DashboardsDirectoryRedirect = () => <ScreenRedirect to="/jira/dashboards" />;

export const dashboardsDirectoryRedirectRouteEntry = createEntry(
	dashboardsDirectoryRedirectRoute,
	() => ({
		group: 'directories',
		resources: [...getNavigationResources(), dashboardsDirectoryResource],
		component: !__SERVER__ ? DashboardsDirectoryRedirect : DashboardsDirectoryComponent,
		skeleton: DashboardsDirectorySkeleton,
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.DASHBOARDS,
		},

		forPaint: [LazyAtlassianNavigation, LazyDashboardsDirectory],
		isRedirect: true,
	}),
);
