import { AssetsLayout } from '@atlassian/jira-assets-layout/src/ui/async.tsx';
import { AssetsPageConfigContainer } from '@atlassian/jira-assets-app-page-config-container/src/ui/async.tsx';
import Spinner from '@atlassian/jira-common-components-spinner/src/view.tsx';

export const getSharedRouteProps = () =>
	({
		group: 'assets',
		LayoutOverrideComponent: AssetsLayout,
		PageConfigContainerOverride: AssetsPageConfigContainer,
		skeleton: Spinner,
	}) as const;
