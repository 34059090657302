import React, { type ReactNode, type MouseEvent } from 'react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import Button from '@atlaskit/button';
import Icon from '@atlaskit/icon';
import { Box, xcss } from '@atlaskit/primitives';
import Lozenge from '@atlaskit/lozenge';
import { Link } from '@atlassian/react-resource-router';

export type DataManagerLinkProps = {
	to: string;
	testId?: string;
	children: ReactNode;
	onClick?: (analyticsEvent: UIAnalyticsEvent) => void;
	app: 'adapters' | 'data-manager';
};

export const DataManagerGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.5 6.5C7.5 7.05228 7.05228 7.5 6.5 7.5C5.94772 7.5 5.5 7.05228 5.5 6.5C5.5 5.94772 5.94772 5.5 6.5 5.5C7.05228 5.5 7.5 5.94772 7.5 6.5ZM7.5 17.5C7.5 18.0523 7.05228 18.5 6.5 18.5C5.94772 18.5 5.5 18.0523 5.5 17.5C5.5 16.9477 5.94772 16.5 6.5 16.5C7.05228 16.5 7.5 16.9477 7.5 17.5ZM18.5 12C18.5 12.5523 18.0523 13 17.5 13C16.9477 13 16.5 12.5523 16.5 12C16.5 11.4477 16.9477 11 17.5 11C18.0523 11 18.5 11.4477 18.5 12ZM8.88555 7.25C8.56698 8.26428 7.61941 9 6.5 9C5.11929 9 4 7.88071 4 6.5C4 5.11929 5.11929 4 6.5 4C7.61941 4 8.56698 4.73572 8.88555 5.75H10.5523C11.697 5.75 12.625 6.67797 12.625 7.82267V11.25H14.5H15.1145C15.433 10.2357 16.3806 9.5 17.5 9.5C18.8807 9.5 20 10.6193 20 12C20 13.3807 18.8807 14.5 17.5 14.5C16.3806 14.5 15.433 13.7643 15.1145 12.75H14.5H12.625V16.1773C12.625 17.322 11.697 18.25 10.5523 18.25H8.88555C8.56698 19.2643 7.61941 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.1193 5.11929 15 6.5 15C7.61941 15 8.56698 15.7357 8.88555 16.75H10.5523C10.8686 16.75 11.125 16.4936 11.125 16.1773V12.75V12V11.25V7.82267C11.125 7.50639 10.8686 7.25 10.5523 7.25H8.88555Z"
			fill="#44546F"
		/>
	</svg>
);

export const DataManagerAdaptersGlyph = () => (
	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_2374_102539)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.5076 15.8019C10.8212 16.0862 11 16.4898 11 16.913V18.5H13V16.913C13 16.4898 13.1788 16.0862 13.4924 15.8019L16.5 13.0745V9.5H7.5V13.0745L10.5076 15.8019ZM6 13.7391V9C6 8.44771 6.44772 8 7 8H17C17.5523 8 18 8.44772 18 9V13.7391L14.5 16.913V19C14.5 19.5523 14.0523 20 13.5 20H10.5C9.94772 20 9.5 19.5523 9.5 19V16.913L6 13.7391Z"
				fill="currentColor"
			/>
			<path fillRule="evenodd" clipRule="evenodd" d="M9 9L9 4L10.5 4V9H9Z" fill="currentColor" />
			<path fillRule="evenodd" clipRule="evenodd" d="M13.5 9V4L15 4V9H13.5Z" fill="currentColor" />
		</g>
		<defs>
			<clipPath id="clip0_2374_102539">
				<rect width="24" height="24" fill="currentColor" />
			</clipPath>
		</defs>
	</svg>
);

export const DataManagerLink = ({ children, onClick, testId, to, app }: DataManagerLinkProps) => {
	const getIcon = () => {
		if (app === 'data-manager') {
			return <Icon glyph={DataManagerGlyph} label="Data manager icon" size="medium" />;
		}
		return <Icon glyph={DataManagerAdaptersGlyph} label="Adapters icon" size="medium" />;
	};
	return (
		<Box xcss={wrapperStyles}>
			<Button
				href={to}
				component={Link}
				appearance="subtle"
				iconBefore={getIcon()}
				testId={testId}
				onClick={(event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
					onClick?.(analyticsEvent);
				}}
				target="_blank"
			>
				{children}

				<Box xcss={lozengeStyles}>
					<Lozenge appearance="new">BETA</Lozenge>
				</Box>
			</Button>
		</Box>
	);
};

const lozengeStyles = xcss({
	display: 'inline-block',
	marginLeft: 'space.050',
});

const wrapperStyles = xcss({});
