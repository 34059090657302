export const PACKAGE_NAME = 'jiraNavigationAppsSidebarNav4Roadmaps';
export const TEAM_NAME = 'jpd-aurora';

export const VIEW_ALL_ROADMAPS = 'viewAllRoadmaps';

export const CREATE_ROADMAP = 'createRoadmaps';
export const MEATBALL_DROPDOWN = 'meatballDropdown';

export type FixButtonTypeId = typeof CREATE_ROADMAP | typeof MEATBALL_DROPDOWN;

export const PERMISSIONS_CACHE_TTL = 10 * 60 * 1000; // 10 minutes
