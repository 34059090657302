/**
 * @generated SignedSource<<119d46c8e9de90b8b66841f37084e158>>
 * @relayHash 9a58486151638f4e45660f746394b248
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 187971dfb7440b89eb5a98f67e766ec91b921dc8dd450612125a7211123e347d

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type DashboardsContentViewQuery$variables = {
  cloudId: string;
};
export type DashboardsContentViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsContent">;
};
export type DashboardsContentViewQuery = {
  response: DashboardsContentViewQuery$data;
  variables: DashboardsContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "__typename"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "name": "dashboardId"
          },
          {
            "kind": "ScalarField",
            "name": "title"
          },
          {
            "concreteType": "JiraFavouriteValue",
            "kind": "LinkedField",
            "name": "favouriteValue",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              (v2/*: any*/)
            ]
          }
        ],
        "type": "JiraDashboard"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v2/*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
      }
    ]
  }
],
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "DashboardsContentViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "DashboardsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DashboardsContentViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "dashboardFavourites",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "DASHBOARD"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              (v4/*: any*/)
            ]
          },
          {
            "alias": "dashboardRecents",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "DASHBOARD"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "187971dfb7440b89eb5a98f67e766ec91b921dc8dd450612125a7211123e347d",
    "metadata": {},
    "name": "DashboardsContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "7ed95226ae03585ac6cc616adbd83544";

export default node;
