import { createResource, type RouteResourceResponse } from 'react-resource-router';

import { fg } from '@atlaskit/platform-feature-flags';
import { assert, AssertionError } from '@atlassian/eoc-common';

import {
	type MemberRolesPageAccessResponse,
	type MembersQueryResponseV2,
} from '../../common/types';
import {
	getCustomTeamRolesEndpoint,
	getJSMInvitationStatusInfoEndpoint,
	getMemberRolesPageAccess,
	getMembers,
	getRoleAndJSMLicenseInfoEndpoint,
	getTeam,
} from '../endpoints';

const mapStatus = (status: string | undefined, userAccessLevel: string | undefined) => {
	if (userAccessLevel === 'INTERNAL_INACTIVE') {
		return 'suspended';
	}
	if (
		status === 'REQUEST_ACCESS' ||
		status === 'DENIED_REQUEST_EXISTS' ||
		status === 'FORBIDDEN' ||
		status === 'DIRECT_ACCESS'
	) {
		return 'invite';
	}
	if (status === 'ACCESS_EXISTS') {
		return 'member';
	}
	if (status === 'PENDING_REQUEST_EXISTS') {
		return 'pending';
	}
	if (status === '' || status === undefined) {
		return 'member';
	}
	return 'invite';
};

// Redefined here since it is not exported from RRR.
type ResourceDependencies = {
	[type: string]: RouteResourceResponse;
};

const checkPageAccessRightsOnRouteDependency = async (dependencies: ResourceDependencies) => {
	let isAuthorizedToSeeMemberRolesPage = false;

	if (dependencies[PAGE_ACCESS_RESOURCE_TYPE]) {
		const data = await dependencies[PAGE_ACCESS_RESOURCE_TYPE].promise;
		isAuthorizedToSeeMemberRolesPage = (data as MemberRolesPageAccessResponse)?.productOrTeamAdmin;
	}

	return isAuthorizedToSeeMemberRolesPage;
};

const PAGE_ACCESS_RESOURCE_TYPE = 'pageAccessResource';

export const pageAccessResource = createResource({
	type: PAGE_ACCESS_RESOURCE_TYPE,
	getKey: () => 'pageAccessResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		assert(
			!!teamId,
			new AssertionError('pageAccessResource', 'teamId should be set to fetch page access'),
		);
		return getMemberRolesPageAccess(teamId);
	},
	isBrowserOnly: true,
});

export const memberRolesResource = createResource({
	type: 'memberRolesResource',
	getKey: () => 'memberRolesResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		const dependencies = routerContext.dependencies;

		assert(
			!!teamId,
			new AssertionError('memberRolesResource', 'teamId should be set to fetch member-roles'),
		);

		const isAuthorizedToSeeMemberRolesPage = await checkPageAccessRightsOnRouteDependency(
			dependencies as ResourceDependencies,
		);

		if (!isAuthorizedToSeeMemberRolesPage) {
			return null;
		}

		const gqlResponse = await getMembers(teamId);

		const members =
			(gqlResponse as MembersQueryResponseV2)?.data.team?.teamV2.members.edges.map(
				(edge) => edge.node.member,
			) || [];

		if (fg('member-roles-resource-null-check')) {
			const memberRoleResponse = await getRoleAndJSMLicenseInfoEndpoint({
				platformTeamId: teamId,
				accountIds: members.map((member) => member.accountId),
			});

			const teamMembers = members.map((user) => {
				const userWithRole = memberRoleResponse.teamMembers.find(
					(userWithRole) => userWithRole.accountId === user.accountId,
				);

				return {
					...user,
					role: userWithRole?.roleId || 'user',
					jsmLicense: userWithRole?.jsmLicense || false,
					jiraAdmin: userWithRole?.jiraAdmin || false,
					invitationStatus: 'member',
				};
			});

			const membersWithoutJSMLicense = teamMembers.filter((teamMembers) => !teamMembers.jsmLicense);

			if (!membersWithoutJSMLicense.length) {
				return teamMembers;
			}

			const accountIds = membersWithoutJSMLicense.map((member) => member.accountId).join(',');

			try {
				const { embeddedValue } = await getJSMInvitationStatusInfoEndpoint({
					accountIds,
				});

				return teamMembers.map((user) => {
					const userWithInvitationStatus = embeddedValue.find(
						(userWithInvitationStatus) => userWithInvitationStatus.accountId === user.accountId,
					);

					return {
						...user,
						invitationStatus: mapStatus(
							userWithInvitationStatus?.accessMode,
							userWithInvitationStatus?.userAccessLevel,
						),
					};
				});
			} catch {
				return teamMembers;
			}
		}

		const teamMembers = await getRoleAndJSMLicenseInfoEndpoint({
			platformTeamId: teamId,
			accountIds: members.map((member) => member.accountId),
		}).then((res) => {
			const users = members.map((user) => {
				const userWithRole = res.teamMembers.find(
					(userWithRole) => userWithRole.accountId === user.accountId,
				);

				return {
					...user,
					role: userWithRole?.roleId || 'user',
					jsmLicense: userWithRole?.jsmLicense || false,
					jiraAdmin: userWithRole?.jiraAdmin || false,
					invitationStatus: 'member',
				};
			});
			return users;
		});

		const membersWithoutJSMLicense = teamMembers.filter((teamMembers) => !teamMembers.jsmLicense);
		if (membersWithoutJSMLicense.length > 0) {
			const accountIds = membersWithoutJSMLicense.map((member) => member.accountId).join(',');
			const membersWithFullInfo = await getJSMInvitationStatusInfoEndpoint({
				accountIds,
			})
				.then((res) => {
					const users = teamMembers.map((user) => {
						const userWithInvitationStatus = res.embeddedValue.find(
							(userWithInvitationStatus) => userWithInvitationStatus.accountId === user.accountId,
						);
						return {
							...user,
							invitationStatus: mapStatus(
								userWithInvitationStatus?.accessMode,
								userWithInvitationStatus?.userAccessLevel,
							),
						};
					});
					return users;
				})
				.catch((err) => {
					return teamMembers;
				});
			return membersWithFullInfo;
		}
		return teamMembers;
	},
	isBrowserOnly: true,
	depends: [PAGE_ACCESS_RESOURCE_TYPE],
});

export const getTeamResource = createResource({
	type: 'getTeamResource',
	getKey: () => 'getTeamResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;

		assert(
			!!teamId,
			new AssertionError(
				'getTeamResource',
				'teamId should be set to fetch team info on member roles',
			),
		);

		return getTeam(teamId);
	},
	isBrowserOnly: true,
});

export const customTeamRolesResource = createResource({
	type: 'customTeamRolesResource',
	getKey: () => 'customRolesResource',
	getData: async (routerContext) => {
		const teamId = routerContext.match.params?.teamId;
		const dependencies = routerContext.dependencies;

		assert(
			!!teamId,
			new AssertionError(
				'customTeamRolesResource',
				'teamId should be set to fetch custom-team-roles',
			),
		);

		const isAuthorizedToSeeMemberRolesPage = await checkPageAccessRightsOnRouteDependency(
			dependencies as ResourceDependencies,
		);

		if (!isAuthorizedToSeeMemberRolesPage) {
			return null;
		}
		return getCustomTeamRolesEndpoint(teamId);
	},
	isBrowserOnly: true,
	depends: [PAGE_ACCESS_RESOURCE_TYPE],
});

// PageAccessResource is used seperately to show the user a permission error page if they are not authorized to see the page and also to avoid unnecessary requests.
export const resources = () => [memberRolesResource, getTeamResource, customTeamRolesResource];
