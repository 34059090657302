import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsAnalyticsRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsAnalyticsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { helpCenterSettingsAnalyticsRoutePageEntryPoint } from './ui/analytics/index.tsx';

export const helpCenterSettingsAnalyticsRouteEntry: Route = createEntry(
	helpCenterSettingsAnalyticsRoute,
	() => ({
		group: 'customer-service',
		perfMetricKey: 'customer-service.analytics',
		layout: globalLayout,
		navigation: {
			menuId: MENU_ID.HELP_CENTER_SETTINGS,
		},
		component: ErrorPagesNotFound,
		entryPoint() {
			return fg('jcs-analytics-page') ? helpCenterSettingsAnalyticsRoutePageEntryPoint : undefined;
		},
		resources: [...getNavigationResources()],
	}),
);
