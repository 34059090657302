import { useMemo } from 'react';
import {
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_POLARIS_IDEAS,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import type { RouterState } from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';
import type { Navigation } from '@atlassian/jira-route-types/src/index.tsx';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useGlobalAppsSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-apps/src/controllers/global-settings/index.tsx';
import { useGlobalSystemSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-system/src/controllers/global-settings/index.tsx';
import { useIsConnectAppActive as useIsProjectSettingsConnectAppActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/controllers/is-connect-app-active/index.tsx';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { getRouterState } from '@atlassian/react-resource-router';
import { useMaybeConfigParam } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-config-param/index.tsx';

export type Params = {
	configParam: string;
	isGlobalAppsSettingsConnectItemActive: boolean;
	isGlobalSystemSettingsConnectItemActive: boolean;
	isProjectSettingsConnectAppActive: boolean;
	routerState: RouterState;
	shouldUseJSM: boolean;
};

// getNav4SidebarId() contains all of the logic to determine what sidebar to show.
// It returns a sidebarId rather than a component, as some callers just want to compare
// the current sidebarId to the next sidebarId, rather than show a component.
export function getNav4SidebarId(params: Params): NonNullable<Navigation['sidebarId']> {
	const {
		configParam,
		isProjectSettingsConnectAppActive,
		isGlobalAppsSettingsConnectItemActive,
		isGlobalSystemSettingsConnectItemActive,
		routerState,
		shouldUseJSM,
	} = params;
	const sidebarId = routerState.route.navigation?.sidebarId;
	const pathname = routerState.location.pathname;

	// Deal with the exceptions...
	if (sidebarId === SIDEBAR_ID.GLOBAL_APPS_SETTINGS && !pathname.includes('/discover')) {
		return sidebarId;
	}

	if (sidebarId === SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS) {
		if (
			pathname.includes('/jira-service-management-chatops-and-video-tools') &&
			shouldUseJSM &&
			fg('fix_chatops_new_nav_bug')
		) {
			return SIDEBAR_ID.CONTENT;
		}
		return sidebarId;
	}

	// Project settings sidebars
	if (
		(routerState.route.name === ROUTE_NAMES_POLARIS_IDEAS &&
			routerState.match.params.section === 'settings') ||
		isProjectSettingsConnectAppActive
	) {
		return SIDEBAR_ID.PROJECT_SETTINGS;
	}

	// Board settings sidebar
	if (
		routerState.route.group === ROUTE_GROUPS_CLASSIC_SOFTWARE &&
		BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(configParam) &&
		fg('simplified-boards-milestone-2_07hbk')
	) {
		return SIDEBAR_ID.BOARD_SETTINGS;
	}

	if (isGlobalAppsSettingsConnectItemActive) {
		return SIDEBAR_ID.GLOBAL_APPS_SETTINGS;
	}
	if (isGlobalSystemSettingsConnectItemActive) {
		return SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS;
	}

	return sidebarId || SIDEBAR_ID.CONTENT;
}

type UseGetParamsForGetNav4SidebarIdParams = {
	routerState?: RouterState;
};

export function useGetParamsForGetNav4SidebarId(
	params: UseGetParamsForGetNav4SidebarIdParams = {},
) {
	const { routerState = getRouterState() } = params;
	const { isActive: isGlobalAppsSettingsConnectItemActive } =
		useGlobalAppsSettingsConnectItemActive();
	const { isActive: isGlobalSystemSettingsConnectItemActive } =
		useGlobalSystemSettingsConnectItemActive();
	const { isActive: isProjectSettingsConnectAppActive } = useIsProjectSettingsConnectAppActive();
	const { shouldUseJSM } = useOgTenantInfo();
	const configParam = useMaybeConfigParam() || '';

	const data = useMemo(
		() => ({
			configParam,
			isGlobalAppsSettingsConnectItemActive,
			isGlobalSystemSettingsConnectItemActive,
			isProjectSettingsConnectAppActive,
			routerState,
			shouldUseJSM,
		}),
		[
			configParam,
			isGlobalAppsSettingsConnectItemActive,
			isGlobalSystemSettingsConnectItemActive,
			isProjectSettingsConnectAppActive,
			routerState,
			shouldUseJSM,
		],
	);
	return data;
}
