import { fg } from '@atlassian/jira-feature-gating';
import { getIssueKey } from '@atlassian/jira-router-resources-utils/src/utils/get-issue-key/index.tsx';
import { createResource } from '@atlassian/react-resource-router';
import { IS_SSR_BIG_BUNDLE } from '@atlassian/jira-conditional-ssr-render/src/constants.tsx';
import { browserOnlyResourceWithCondition } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import getData from './get-data/index.tsx';

/**
 * Delete with cleanup of move_forge_data_fetch_to_main_issue_agg
 * forge data is now fetched in the main issue agg query
 * @deprecated
 */
export const issueForgeDataResource = browserOnlyResourceWithCondition(
	() => {
		if (IS_SSR_BIG_BUNDLE) {
			// not "browser only" if FF is on
			return !fg('issue-view-ssr-perf-m1');
		}
		return true;
	},
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	createResource<any>({
		type: 'ISSUE_FORGE_DATA',
		getKey: ({ match }) => {
			const issueKey = getIssueKey(match);
			return issueKey || '';
		},
		getData: (...args) => getData(...args),
		isBrowserOnly: true,
	}),
);
