import isEmpty from 'lodash/isEmpty';
import { JiraProjectAri } from '@atlassian/ari/jira/project';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';
import { createResource, useResource } from '@atlassian/react-resource-router';
import { PROJECT_KEY_QUERY, PROJECT_ID_QUERY, OVERVIEW_QUERY } from './services/navigation/gql.tsx';
import { fetchNavigation } from './services/navigation/index.tsx';
import type { NavigationResourceType } from './types.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export { PROJECT_PAGE_SIZE, NOT_APPLICABLE } from './constants';
// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { NavigationProject, NavigationOverview, BaseProject } from './types';

/**
 * @deprecated Do not use this resource. It has been deprecated and will eventually be removed when cleaning up jira_nav4.
 */
export const navigationResource = createResource<NavigationResourceType>({
	type: 'BUSINESS_NAVIGATION',
	getKey: () => 'BUSINESS_NAVIGATION',
	getData: async ({ match, query }, { tenantContext: { cloudId, isAnonymous } }) => {
		// do not fetch the resource for anonymous access
		if (isAnonymous) {
			return null;
		}

		const { projectKey, projectId } = getProjectKeyId(match, query);

		if (!isEmpty(projectKey)) {
			return fetchNavigation(PROJECT_KEY_QUERY, {
				cloudId,
				projectKey,
			});
		}

		if (!isEmpty(projectId)) {
			return fetchNavigation(PROJECT_ID_QUERY, {
				cloudId,
				projectId: JiraProjectAri.create({
					siteId: cloudId,
					projectId,
				}).toString(),
			});
		}

		return fetchNavigation(OVERVIEW_QUERY, {
			cloudId,
		});
	},
	maxAge: Infinity,
});

/**
 * @deprecated Do not use this resource's hook. It has been deprecated and will eventually be removed when cleaning up jira_nav4.
 */
export const useNavigationResource = () => useResource(navigationResource);
