import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { issueNavigatorProjectBusinessRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorProjectBusinessRoute.tsx';
import { ProjectIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/project-app.tsx';
import { getCommonProjectResources } from './common/getCommonProjectResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import { issueNavigatorPageEntryPoint } from './ui/index.tsx';

/**
 * Business Project Issue Navigator Route Entry
 * Handles Business projects, also known as JWM or Core projects.
 */
export const issueNavigatorProjectBusinessRouteEntry = createEntry(
	issueNavigatorProjectBusinessRoute,
	() => ({
		group: 'issue-navigator',
		...getIssueNavigatorRouteEntryCommon(),
		skeleton: ProjectIssueNavigatorSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},
		resources: [...getCommonProjectResources()],
		layout: createLayout(genericProjectLayout),
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
		entryPoint: () => issueNavigatorPageEntryPoint,
	}),
);
