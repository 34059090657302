/** @jsx jsx */
import { jsx } from '@compiled/react';
import Avatar from '@atlaskit/avatar';
import ProductRoadMapProjectIconUrl from '../assets/product-roadmap-project-icon.svg';

const ProductRoadmapProjectAvatar = () => {
	return (
		<Avatar
			appearance="square"
			borderColor="transparent"
			size="small"
			src={ProductRoadMapProjectIconUrl}
		/>
	);
};
export default ProductRoadmapProjectAvatar;
