import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsContactAdministratorRoute } from '@atlassian/jira-router-routes-admin-pages-contact-administrator-routes/src/globalSettingsContactAdministratorRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ContactAdministratorPage, { LazyContactAdministratorPage } from './ui/index.tsx';

export const globalSettingsContactAdministratorRouteEntry: Route = createEntry(
	globalSettingsContactAdministratorRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'admin.contact-administrator',
		component: ContactAdministratorPage,
		layout: globalLayout,
		resources: [...getNavigationResources()],
		forPaint: [LazyAtlassianNavigation, LazyContactAdministratorPage],
	}),
);
