/**
 * @generated SignedSource<<e999f38de92db02cb900ff3e39b1c9b5>>
 * @relayHash e1db5ab6de3c01b21716a8934a36cc9b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 0b047866626fc08071e5717fa8caf4a6c7867aefae410e0168cbc01019589055

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSummaryBusinessQuery } from './src_jiraSummaryBusinessQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraSummaryBusinessQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "0b047866626fc08071e5717fa8caf4a6c7867aefae410e0168cbc01019589055",
    "metadata": {},
    "name": "src_jiraSummaryBusinessQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
