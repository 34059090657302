import React, { useCallback } from 'react';
import { IconButton } from '@atlaskit/button/new';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import type { ContentProps } from '@atlaskit/popup';
import ShowMoreHorizontalIcon from '@atlaskit/icon/utility/show-more-horizontal';
import { useIntl } from '@atlassian/jira-intl';
import { fg } from '@atlassian/jira-feature-gating';
import { JiraPopup as Popup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import messages from './messages.tsx';
import { MoreNavMenuButtonSubmenu } from './submenu/index.tsx';
import type { MoreNavMenuButtonProps } from './types.tsx';

export const MoreNavMenuButton = ({
	MenuItems,
	onCloseMenu,
	isOpen,
	onClick,
	isUsedForSidebarNav,
}: MoreNavMenuButtonProps) => {
	const { formatMessage } = useIntl();
	const content = (contentProps: ContentProps) => (
		<MoreNavMenuButtonSubmenu {...contentProps} MenuItems={MenuItems} />
	);

	const onClose = useCallback(() => {
		onCloseMenu();
	}, [onCloseMenu]);

	return (
		<Popup
			content={content}
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			messageId="jet-plan-action-menu.ui.more-nav-menu-button.popup"
			messageType="transactional"
			trigger={({ ref, ...props }) =>
				isUsedForSidebarNav ? (
					<IconButton
						{...props}
						onClick={onClick}
						label={formatMessage(messages.triggerLabel)}
						ref={ref}
						icon={
							fg('update_plan_more_menu_icon')
								? ShowMoreHorizontalIcon
								: () => <MoreIcon LEGACY_size="small" label="" />
						}
						appearance={fg('update_plan_more_menu_icon') ? 'subtle' : 'default'}
						spacing="compact"
						isTooltipDisabled
						testId="jet-plan-action-menu.ui.more-nav-menu-button.sidebar"
					/>
				) : (
					<IconButton
						{...props}
						isSelected={isOpen}
						onClick={onClick}
						label={formatMessage(messages.triggerLabel)}
						ref={ref}
						appearance="subtle"
						icon={MoreIcon}
						isTooltipDisabled={false}
						testId="jet-plan-action-menu.ui.more-nav-menu-button.horizontal"
					/>
				)
			}
			shouldRenderToParent
		/>
	);
};
