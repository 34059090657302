/**
 * @generated SignedSource<<38b934d4b5d93776c09bdfb8c487999e>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type ThemeSetterBackground$data = {
  readonly __typename: "JiraColorBackground";
  readonly colorValue: string | null | undefined;
  readonly " $fragmentType": "ThemeSetterBackground";
} | {
  readonly __typename: "JiraGradientBackground";
  readonly gradientValue: string | null | undefined;
  readonly " $fragmentType": "ThemeSetterBackground";
} | {
  readonly __typename: "JiraMediaBackground";
  readonly customBackground: {
    readonly altText: string | null | undefined;
    readonly id: string | null | undefined;
    readonly mediaApiFileId: string | null | undefined;
    readonly mediaReadToken: string | null | undefined;
    readonly sourceIdentifier: string | null | undefined;
    readonly sourceType: string | null | undefined;
  } | null | undefined;
  readonly " $fragmentType": "ThemeSetterBackground";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "ThemeSetterBackground";
};
export type ThemeSetterBackground$key = {
  readonly " $data"?: ThemeSetterBackground$data;
  readonly " $fragmentSpreads": FragmentRefs<"ThemeSetterBackground">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "ThemeSetterBackground",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__typename"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "colorValue"
        }
      ],
      "type": "JiraColorBackground"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "kind": "ScalarField",
          "name": "gradientValue"
        }
      ],
      "type": "JiraGradientBackground"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "concreteType": "JiraCustomBackground",
          "kind": "LinkedField",
          "name": "customBackground",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "id"
            },
            {
              "kind": "ScalarField",
              "name": "mediaApiFileId"
            },
            {
              "args": [
                {
                  "kind": "Literal",
                  "name": "durationInSeconds",
                  "value": 900
                }
              ],
              "kind": "ScalarField",
              "name": "mediaReadToken",
              "storageKey": "mediaReadToken(durationInSeconds:900)"
            },
            {
              "kind": "ScalarField",
              "name": "sourceType"
            },
            {
              "kind": "ScalarField",
              "name": "sourceIdentifier"
            },
            {
              "kind": "ScalarField",
              "name": "altText"
            }
          ]
        }
      ],
      "type": "JiraMediaBackground"
    }
  ],
  "type": "JiraActiveBackgroundDetailsResult",
  "abstractKey": "__isJiraActiveBackgroundDetailsResult"
};

(node as any).hash = "a2889b0cfa3689dd4a7f6c279739caba";

export default node;
