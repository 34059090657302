import React, { type ReactNode } from 'react';
import isEqual from 'lodash/isEqual';
import { useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { createContainer, createHook, createStore } from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import type { Store, Props } from './types.tsx';
import { getASTAndClausesFromJQL } from './utils.tsx';

type Actions = {};
const actions = Object.freeze<Actions>({});

const ASTContainerInternal = createContainer<Props>();

const store = createStore<Store, Actions, Props>({
	containedBy: ASTContainerInternal,
	initialState: {
		ast: {
			query: undefined,
			represents: '',
			errors: [],
		},
		clauseMap: {},
		textSearchInputClause: undefined,
	},
	actions,
	name: 'JQLBasic-ASTService',
	handlers: {
		onInit:
			() =>
			({ setState }, props) => {
				setState(getASTAndClausesFromJQL(props));
			},

		onContainerUpdate:
			(nextProps, prevProps) =>
			({ setState }, props) => {
				if (fg('empanada_nin_concurrent_mode_fixes')) {
					if (!isEqual(nextProps, prevProps)) {
						setState(getASTAndClausesFromJQL(nextProps));
					}
				} else {
					setState(getASTAndClausesFromJQL(props));
				}
			},
	},
});

export type WrapperProps = {
	jql: string;
	onSwitchToJql?: () => void;
	children: ReactNode;
};

export const ASTContainer = ({
	jql,
	children,
	onSwitchToJql,
}: WrapperProps & {
	children: ReactNode;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<ASTContainerInternal
			jql={jql}
			createAnalyticsEvent={createAnalyticsEvent}
			onSwitchToJql={onSwitchToJql}
		>
			{children}
		</ASTContainerInternal>
	);
};

export const useAST = createHook(store, {
	selector: ({ ast }) => ast,
});

export const useClauseMap = createHook(store, {
	selector: ({ clauseMap }) => clauseMap,
});

export const useTextSearchInputClause = createHook(store, {
	selector: ({ textSearchInputClause }) => textSearchInputClause,
});
