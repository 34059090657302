import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import {
	AsyncJSMOperationsHorizontalNav,
	LazyJSMOperationsHorizontalNav,
} from '@atlassian/jira-horizontal-nav-jsm-operations/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskServicesRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/servicedeskServicesRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ServiceDeskServicesWithProjectContext, {
	LazyServicedeskServicesWithProjectContext,
} from './ui/services-with-project-context/index.tsx';

export const servicedeskServicesRouteEntry: Route = createEntry(servicedeskServicesRoute, () => ({
	group: 'servicedesk',
	ufoName: 'service-management.services',
	component: ServiceDeskServicesWithProjectContext,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncJSMOperationsHorizontalNav,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		getConsolidationStateResource(),
	],
	forPaint: [
		LazyAtlassianNavigation,
		LazyJSMOperationsHorizontalNav,
		LazyServicedeskServicesWithProjectContext,
	],
}));
