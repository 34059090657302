import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State, NavigationCollection } from '../../types.tsx';
import { updateCollectionsState } from '../utils.tsx';

export const updateCollection =
	(collectionUUID: string, patch: Partial<NavigationCollection>): Action<State, Props> =>
	async ({ setState, getState }, props) => {
		const { navigationCollectionsRemote, cloudId } = props;
		const state = getState();

		if (
			!cloudId ||
			!navigationCollectionsRemote ||
			state.collections.isLoading ||
			!state.collections.initialized
		) {
			return;
		}

		if (!collectionUUID) {
			return;
		}

		const nextState = updateCollectionsState(state, collectionUUID, (prevNavigationCollection) => ({
			...prevNavigationCollection,
			...patch,
		}));

		if (nextState) {
			setState(nextState);
		}
	};
