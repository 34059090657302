import React, { type MouseEvent, type PropsWithChildren } from 'react';
import { styled } from '@compiled/react';
import EditorWarningIcon from '@atlaskit/icon/core/warning';
import EditorWarningIconOld from '@atlaskit/icon/glyph/editor/warning';
import { Box, xcss, Flex } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import messages from './messages.tsx';

export const ContentBox = ({ children }: PropsWithChildren<{}>) => {
	/**
	 * Because the field description button is nested within the picker button,
	 * any click events in the field description popup would bubble up and trigger
	 * the picker. This is an intrim solution to prevent that from happening. Wrap
	 * the entire field description popup in a div that listens for clicks and traps
	 * them so that they don't bubble further up to the picker button.
	 *
	 * TODO remove this when completing the followup ticket:
	 * https://jplat.jira.atlassian.cloud/browse/EM-6510
	 */
	const propagationTrap = (e: MouseEvent) => e.stopPropagation();

	return (
		<Box padding="space.150" xcss={contentBoxStyles} onClick={propagationTrap} tabIndex={0}>
			{children}
		</Box>
	);
};

export const ContentError = () => {
	const { formatMessage } = useIntl();

	return (
		<Flex direction="column" justifyContent="center" alignItems="center">
			{!isVisualRefreshEnabled() && (
				<FlexRow>
					<EditorWarningIcon
						label=""
						LEGACY_fallbackIcon={EditorWarningIconOld}
						LEGACY_size="xlarge"
						LEGACY_primaryColor="color.text"
						color={token('color.icon.warning')}
						testId="jql-builder-basic.common.ui.field-description-popup-common.editor-warning-icon"
					/>
				</FlexRow>
			)}
			<FlexRow>
				{isVisualRefreshEnabled() && (
					<Box xcss={warningIconStyle}>
						<EditorWarningIcon
							label=""
							LEGACY_fallbackIcon={EditorWarningIconOld}
							LEGACY_size="xlarge"
							LEGACY_primaryColor="color.text"
							color={token('color.icon.warning')}
							testId="jql-builder-basic.common.ui.field-description-popup-common.editor-warning-icon"
						/>
					</Box>
				)}
				{formatMessage(messages.error)}
			</FlexRow>
		</Flex>
	);
};

export const ContentLoading = () => (
	<Spinner
		testId="jql-builder-basic.common.ui.field-description-popup-common.spinner"
		size="small"
	/>
);

const contentBoxStyles = xcss({
	maxWidth: '300px',
	maxHeight: '300px',
	overflow: 'auto',
	outline: 'none',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FlexRow = styled.span({
	display: 'block',
	textAlign: 'center',
	color: token('color.text'),
});

const warningIconStyle = xcss({
	marginRight: 'space.100',
	display: 'inline',
});
