import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskServiceDetailsRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/servicedeskServiceDetailsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ServiceDetailsWithProjectContext, {
	LazyServicedeskServiceDetailsWithProjectContext,
} from './ui/service-details-with-project-context/index.tsx';

export const servicedeskServiceDetailsRouteEntry: Route = createEntry(
	servicedeskServiceDetailsRoute,
	() => ({
		group: 'servicedesk',
		ufoName: 'service-management.service-details',
		component: ServiceDetailsWithProjectContext,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},
		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyServicedeskServiceDetailsWithProjectContext],
	}),
);
