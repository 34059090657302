import { createRouterSelector } from '@atlassian/react-resource-router/src/controllers/router-store';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { SOFTWARE_THEME_ROUTES, SOFTWARE_FULL_THEME_ROUTES } from './constants.tsx';

export const useCurrentRoute = createRouterSelector((state) => state.route.name);

export const useSoftwareProjectTheming = () => {
	const { data: projectData } = useProjectContext();
	const currentRoute = useCurrentRoute();

	const isSoftwareProject = projectData?.projectType === SOFTWARE_PROJECT;

	// temporarily disable theming for calendar
	// the logic matches with the one in src/packages/platform/project-theme/page-container/src/controllers/project-theme/index.tsx
	const isSoftwareCalendarRoute =
		currentRoute === 'software-calendar' || currentRoute === 'software-calendar-classic';

	const hasSoftwareTheming =
		SOFTWARE_THEME_ROUTES.includes(currentRoute) && !isSoftwareCalendarRoute;

	const isSoftwareFullTheming = SOFTWARE_FULL_THEME_ROUTES.includes(currentRoute);

	return {
		hasSoftwareTheming,
		isSoftwareFullTheming,
		isSoftwareProject,
	};
};
