import { AsyncHorizontalOverviewNav } from '@atlassian/jira-business-horizontal-nav/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { AsyncHorizontalNavPlans } from '@atlassian/jira-horizontal-nav-plans/src/async.tsx';
import { useHorizontalNavComponent } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/controllers/nav-component-controller/index.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useSoftwareProjectTheming } from '@atlassian/jira-project-theme-providers/src/controllers/software-theme/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { fg } from '@atlassian/jira-feature-gating';

export const useProjectTheme = () => {
	const route = useCurrentRoute();
	const HorizontalNavComponent = useHorizontalNavComponent();
	const { hasSoftwareTheming, isSoftwareFullTheming, isSoftwareProject } =
		getIsSoftwareThemingEnabled()
			? // eslint-disable-next-line react-hooks/rules-of-hooks
				useSoftwareProjectTheming()
			: { hasSoftwareTheming: false, isSoftwareFullTheming: false, isSoftwareProject: false };

	const isBusinessRouteGroup = route.group === 'classic-business';

	// eslint-disable-next-line no-nested-ternary
	const hasProjectViewNavigation = getWillShowNav4()
		? HorizontalNavComponent != null
		: fg('migrate_plans_navigation_to_relay')
			? HorizontalNavComponent === AsyncHorizontalNavJira ||
				HorizontalNavComponent === AsyncHorizontalOverviewNav ||
				HorizontalNavComponent === AsyncHorizontalNavPlans
			: HorizontalNavComponent === AsyncHorizontalNavJira ||
				HorizontalNavComponent === AsyncHorizontalOverviewNav;

	let hasTheming: boolean;
	if (getWillShowNav4()) {
		const isSoftwareCalendarRoute =
			route.name === 'software-calendar' || route.name === 'software-calendar-classic';

		if (isSoftwareCalendarRoute) {
			hasTheming = false;
		} else if (isSoftwareProject) {
			hasTheming = hasSoftwareTheming;
		} else {
			hasTheming =
				HorizontalNavComponent === AsyncHorizontalNavJira ||
				HorizontalNavComponent === AsyncHorizontalOverviewNav ||
				route.name === 'issue';
		}
	} else {
		hasTheming = hasProjectViewNavigation;
	}

	// Routes showing a themed inset layout are:
	// - all routes with the business project view navigation
	// - select software routes with contoured theming
	// - except for the routes with business group (as they already cater for the theme)
	const isInset = hasTheming && !isBusinessRouteGroup && !isSoftwareFullTheming;

	return {
		isInset,
		hasTheming,
		hasProjectViewNavigation,
	};
};
