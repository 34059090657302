import React, { useEffect } from 'react';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import type IssueCreateEmbed from '@atlassian/jira-issue-create-embed/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { issueCreateEmbedRoute } from '@atlassian/jira-router-routes-issue-create-embed-routes/src/issueCreateEmbedRoute.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';

const handleEmbedLinkClick = (e: Event) => {
	const eventTarget: EventTarget | null = e.target;
	// this is used for handling all the button clicks inside the iframe to open the new link in a new tab
	// as this view is mainly used an embed view inside an iframe.
	if (eventTarget && eventTarget instanceof HTMLAnchorElement && eventTarget.target !== '_blank') {
		// link
		eventTarget.target = '_blank';
	}
};

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyIssueCreateEmbed = lazyForPaint<typeof IssueCreateEmbed>(
	() =>
		import(
			/* webpackChunkName: "async-issue-create-embed", jiraSpaEntry: "async-issue-create-embed" */ '@atlassian/jira-issue-create-embed'
		),
	{ ssr: false },
);
const IssueCreateEmbedLazy = () => {
	useEffect(() => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.addEventListener('click', handleEmbedLinkClick, true);
		return () => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.removeEventListener('click', handleEmbedLinkClick);
		};
	}, []);
	return (
		<AuthenticationCheck>
			<LazyPage
				Page={LazyIssueCreateEmbed}
				pageId="spa-apps/issue-create-embed"
				shouldShowSpinner
			/>
		</AuthenticationCheck>
	);
};
export const issueCreateEmbedRouteEntry: Route = createEntry(issueCreateEmbedRoute, () => ({
	group: 'issue',
	component: IssueCreateEmbedLazy,
	resources: [themePreferenceResource],
	layout: chromelessLayout,
	forPaint: [LazyIssueCreateEmbed],
}));
