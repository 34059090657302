import React from 'react';
import type PlanIncrementType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanIncrement = lazyForPaint<typeof PlanIncrementType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-increment", jiraSpaEntry: "async-plan-increment" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment/index-old.tsx'
			),
		),
	{ ssr: false },
);

export const PlanIncrement = () => (
	<LazyPage Page={LazyPlanIncrement} pageId="plan-increment" shouldShowSpinner />
);
