import {
	COMPOUND_OPERATOR_AND,
	creators,
	OPERATOR_IN,
	print,
	JastBuilder,
	CLAUSE_TYPE_COMPOUND,
	CLAUSE_TYPE_TERMINAL,
	type Clause,
	CLAUSE_TYPE_NOT,
} from '@atlaskit/jql-ast';
import { fg } from '@atlassian/jira-feature-gating';
import type { FieldKey } from '@atlassian/jira-polaris-domain-field/src/field/types.tsx';
import type { ProjectKey } from '@atlassian/jira-shared-types/src/general.tsx';

const quoteProjectKey = (projectKey: ProjectKey): string => `"${projectKey}"`;

const PROJECT_FIELD_KEY = 'project';

export const getJQLForCollection = (projectsKeys: ProjectKey[] = [], filtersJql = ''): string => {
	if (fg('jpd-aurora-roadmap-advanced-filtering')) {
		const jast = new JastBuilder().build(filtersJql);
		projectsKeys.length &&
			jast.query?.appendClause(
				creators.terminalClause(
					creators.field(PROJECT_FIELD_KEY),
					creators.operator(OPERATOR_IN),
					creators.listOperand(projectsKeys.map(quoteProjectKey).map(creators.byText.valueOperand)),
				),
				COMPOUND_OPERATOR_AND,
			);

		if (jast.query?.orderBy) {
			jast.query.removeOrderBy();
		}

		return print(jast);
	}

	if (!projectsKeys.length) {
		return '';
	}

	const jast = creators.jast(
		creators.query(
			creators.terminalClause(
				creators.field(PROJECT_FIELD_KEY),
				creators.operator(OPERATOR_IN),
				creators.listOperand(projectsKeys.map(quoteProjectKey).map(creators.byText.valueOperand)),
			),
		),
	);

	return print(jast);
};

const projectInRegex = /project\s+in\s+\((.*?)\)/;
const projectKeyRegex = /"(.*?)"/g;

export const extractProjectKeysFromJql = (jql: string) => {
	// Extract the part of the JQL related to `project in (...)`
	const projectInMatch = projectInRegex.exec(jql);

	const projectKeys: string[] = [];

	if (projectInMatch) {
		const projectInClause = projectInMatch[1];
		let match;

		// Extract all project keys inside the quotes within the `project in (...)` clause
		// eslint-disable-next-line no-cond-assign
		while ((match = projectKeyRegex.exec(projectInClause)) !== null) {
			projectKeys.push(match[1]);
		}
	}

	return projectKeys;
};

// extract the filters jql part of collection jql by removing the project keys jql clause
export const extractFiltersJqlFromJql = (jql: string): string => {
	if (!fg('jpd-aurora-roadmap-advanced-filtering')) return '';
	const jast = new JastBuilder().build(jql);
	const { where } = jast.query ?? {};
	if (!where) return '';

	const clauses = where.clauseType === CLAUSE_TYPE_COMPOUND ? where.clauses : [where];
	const projectKeysClause = clauses.find(
		(c) => c.clauseType === CLAUSE_TYPE_TERMINAL && c.field.value === PROJECT_FIELD_KEY,
	);
	if (projectKeysClause) {
		projectKeysClause.remove();
	}

	return print(jast);
};

const getFieldsFromClauses = ([clause, ...rest]: Clause[]): FieldKey[] => {
	if (!clause) return [];

	if (clause.clauseType === CLAUSE_TYPE_TERMINAL) {
		return [clause.field.value, ...getFieldsFromClauses(rest)];
	}
	if (clause.clauseType === CLAUSE_TYPE_NOT) {
		return getFieldsFromClauses([clause.clause, ...rest]);
	}
	return getFieldsFromClauses([...clause.clauses, ...rest]);
};

type FieldKeysMap = { [name: string]: FieldKey };

const getFieldKeyFromFieldName = (fieldKeys: FieldKeysMap) => (fieldName: string) =>
	fieldKeys[fieldName.replace(/\[.+\]$/, '').toLowerCase()];

export const getFieldsFromJql = (jql: string, fieldKeys: FieldKeysMap): FieldKey[] => {
	if (!fg('jpd-aurora-roadmap-advanced-filtering')) return [];
	const jast = new JastBuilder().build(jql);
	const { where } = jast.query ?? {};
	if (!where) return [];

	const clauses = where.clauseType === CLAUSE_TYPE_COMPOUND ? where.clauses : [where];
	return getFieldsFromClauses(clauses)
		.map(getFieldKeyFromFieldName(fieldKeys))
		.filter((field) => !!field);
};
