import React, { useEffect } from 'react';
import { fg } from '@atlassian/jira-feature-gating';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { pageContext } from './pageContext.tsx';

type AtlaspackEvent = Event & {
	detail?: {
		action: string;
		attributes?: Record<string, string | number | boolean>;
	};
};

export type DisposeFunc = () => void;

export function atlaspackMetricsConnector(): DisposeFunc {
	if (!fg('atlaspack_runtime_metrics'))
		return () => {
			/**/
		};

	const fn = async (event: AtlaspackEvent) => {
		try {
			if (event.detail === undefined || !event.detail.action) return;
			const { action, attributes = {} } = event.detail;

			// Get client or wait for it to initialize
			const analyticsWebClient = await getAnalyticsWebClientPromise();
			const analyticsClient = analyticsWebClient.getInstance();

			// Fire event
			analyticsClient.sendOperationalEvent({
				source: 'devinfra-app-framework',
				actionSubject: 'atlaspack',
				action,
				attributes: {
					...attributes,
					...pageContext(),
				},
			});
		} catch (error) {
			// Suppress
		}
	};

	globalThis.addEventListener('atlaspack:analytics', fn);
	return () => globalThis.removeEventListener('atlaspack:analytics', fn);
}

function AtlaspackMetricsConnectorInner() {
	useEffect(atlaspackMetricsConnector, [globalThis]);
	return null;
}

export function AtlaspackMetricsConnector() {
	if (!fg('atlaspack_runtime_metrics')) return null;
	return (
		<JSErrorBoundary
			id="atlaspack_runtime_metrics"
			packageName="@atlassian/jira-atlaspack-global-metrics"
			fallback="unmount"
			teamName="devinfra-app-framework"
		>
			<AtlaspackMetricsConnectorInner />
		</JSErrorBoundary>
	);
}
