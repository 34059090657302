import { useMemo } from 'react';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useActivationId } from '@atlassian/jira-tenant-context-controller/src/components/activation-id/index.tsx';
import { createSidebarSettingsAri, createIdentityUserAri } from './index.tsx';

export const useSidebarSettingsAri = (menuName: string, parentType?: string, parentId?: string) => {
	const cloudId = useCloudId();
	const activationId = useActivationId();
	return useMemo(
		() => createSidebarSettingsAri(cloudId, activationId, menuName, parentType, parentId),
		[cloudId, activationId, menuName, parentType, parentId],
	);
};

export const useOwnerAri = () => {
	const accountId = useAccountId() || 'unidentified';
	return useMemo(() => createIdentityUserAri(accountId), [accountId]);
};
