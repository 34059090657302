/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useMemo, useEffect } from 'react';
import { css, jsx } from '@compiled/react';
import ComponentWithAnalytics from '@atlassian/jira-analytics-web-react/src/utils/component-with-analytics.tsx';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';
import BreadcrumbsApp from '@atlassian/jira-navigation-apps-breadcrumbs/src/index.tsx';
import type { TenantContextSubscriber as TenantContextSubscriberType } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { useBreadcrumbs } from '../controller/index.tsx';
import type { BreadcrumbStateData } from '../types.tsx';

type Props = {
	TenantContextSubscriber: typeof TenantContextSubscriberType;
	onMount: () => void;
};

// Adapt to prop type from breadcrumbs app
const mapTrailToBreadcrumbItems = (trail: BreadcrumbStateData[]) =>
	trail.map((crumb) => ({
		...crumb,
		target: crumb.target || '_self',
	}));

const BreadcrumbRenderer = ({ onMount }: Props) => {
	const [{ trail }] = useBreadcrumbs();

	const items = useMemo(() => mapTrailToBreadcrumbItems(trail), [trail]);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(onMount, []);

	return (
		<div css={breadcrumbsWrapperStyles}>
			<BreadcrumbsApp isVisible items={items} />
		</div>
	);
};

export default ComponentWithAnalytics('breadcrumbsRenderer', { onMount: 'mounted' })(
	BreadcrumbRenderer,
);

const breadcrumbsWrapperStyles = css({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	minHeight: `${gridSize * 3}px`,
});
