import React from 'react';
import { listResources } from '@atlassian/eoc-incoming-call/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalIncomingCallRoute } from '@atlassian/jira-router-routes-eoc-incoming-call-routes/src/globalIncomingCallRoute.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyIncomingCall } from './ui/async.tsx';

const IncomingCall = () => (
	<LazyPage
		Page={LazyIncomingCall}
		pageId="async-jira-eoc-incoming-call"
		shouldShowSpinner={false}
	/>
);

// Global routes
export const globalIncomingCallRouteEntry: Route = createEntry(globalIncomingCallRoute, () => ({
	group: 'eoc',
	component: IncomingCall,
	layout: opsSettingsLayout,
	resources: [
		...getNavigationResources(),
		...getNavigationSidebarProductsSettingResource(),
		...listResources(),
	],
	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [LazyHorizontalOperationsConfigurationNav, LazyAtlassianNavigation, LazyIncomingCall],
}));
