import React from 'react';
import type { HelpCenterSettingsSupportWebsiteOverview as HelpCenterSettingsSupportWebsiteOverviewType } from '@atlassian/jira-spa-apps-help-center-settings-support-website-overview/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyHelpCenterSettingsSupportWebsiteOverview = lazyForPaint<
	typeof HelpCenterSettingsSupportWebsiteOverviewType
>(
	() =>
		import(
			/* webpackChunkName: "async-help-center-settings-support-website-overview", jiraSpaEntry: "async-help-center-settings-support-website-overview" */ '@atlassian/jira-spa-apps-help-center-settings-support-website-overview'
		),
);

export const HelpCenterSettingsSupportWebsiteOverview = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsSupportWebsiteOverview}
		pageId="help-center-settings-support-website-overview"
		shouldShowSpinner
	/>
);
