import camelCase from 'lodash/camelCase';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { projectSettingsServicedeskJourneysRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-journeys-routes/src/projectSettingsServicedeskJourneysRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { journeysPageEntryPoint } from './ui/journeys/index.tsx';

export const projectSettingsServicedeskJourneysRouteEntry: Route = createEntry(
	projectSettingsServicedeskJourneysRoute,
	() => ({
		group: 'project-settings-servicedesk',
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		meta: {
			reporting: {
				id: 'jsm.journey-builder.journeys-list',
				packageName: camelCase('journeys-list'),
				teamName: 'JSM Getting Started',
			},
		},
		layout: serviceProjectLayout,
		resources: [...getServiceDeskSettingsResources()],
		entryPoint: () => (fg('jsm-journey-builder') ? journeysPageEntryPoint : undefined),
	}),
);
