import React, { memo } from 'react';
import { SidebarBoundary } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/sidebar-boundary/index.tsx';
import { Skeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Nav4RoadmapsProps } from './ui/roadmaps/index.next.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

const LazySidebarContent = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-navigation-apps-sidebar-nav4-sidebar-roadmaps" */ './ui/roadmaps/index.next.tsx'
	).then(({ Nav4RoadmapsContainer }) => Nav4RoadmapsContainer),
);

export const Nav4RoadmapsNext = memo(({ isExpanded }: Nav4RoadmapsProps) => (
	<SidebarBoundary id="nav4.sidebar.roadmaps" packageName={PACKAGE_NAME} teamName={TEAM_NAME}>
		<UFOSegment name="nav4-sidebar-roadmaps">
			<Placeholder name="nav4-sidebar-roadmaps" fallback={<Skeleton />}>
				<LazySidebarContent isExpanded={isExpanded} />
			</Placeholder>
		</UFOSegment>
	</SidebarBoundary>
));
