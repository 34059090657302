import memoizeOne from 'memoize-one';
import getMeta from '@atlassian/jira-get-meta';

const FIRST_RELEASE_ROUTES = [
	'issue',
	'issue-embed',
	'rapidboard-backlog',
	'rapidboard-board',
	'rapidboard-report',
	'your-work',
	'projects-directory',
	'projects-directory-legacy-path-spa',
	'boards-directory',
	'filters-directory',
	'filters-directory-secure',
	'dashboards-directory',
	'dashboards-directory-secure',
	'project-components',
	'project-pages',
	'project-versions',
	'software-boards',
	'software-backlog',
	'software-timeline',
	'software-roadmap',
	'software-roadmap-classic',
	'software-timeline-classic',
	'software-reports',
	'software-reports-burnup',
	'software-reports-burndown',
	'software-reports-cumulative',
	'software-reports-velocity',
	'issue-navigator',
	'global-issue-navigator',
	'issue-navigator-classic',
	'issue-navigator-core',
	'software-issue-navigator',
	'system-dashboard-edit',
];

export const LOADER_STYLES_CSS_SELECTOR = 'body::before';
export const LOADER_STYLESHEET_ID = 'loaderStylesheet';
export const DEFAULT_LOADING_BAR_COLOR = 'rgb(76, 154, 255)';

export const getAllowedRoutes = memoizeOne(() => {
	const ALLOWED_ROUTES: string[] = FIRST_RELEASE_ROUTES;
	return ALLOWED_ROUTES;
});

export const getLoadingBarColor = memoizeOne(
	() => getMeta('ajs-lf-navigation-highlightcolour') || DEFAULT_LOADING_BAR_COLOR,
);
