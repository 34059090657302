import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { layers } from '@atlassian/jira-common-styles/src/main.tsx';
import type { AkSelectStyles } from '../../common/types.tsx';

export const defaultSelectStyles: AkSelectStyles = {
	menuPortal: (base) => ({ ...base, zIndex: layers.modal }),
	menu: (base) => ({
		...base,
		zIndex: layers.modal,
	}),
};

const inputIconPaddingLeft = token('space.025');
const inputIconPaddingRight = token('space.050');

export const indicatorFixSelectStyles: AkSelectStyles = {
	// need to apply same padding to both loadingIndicator and clearIndicator - not to have input query jumping between lines e.g. in multi-select
	loadingIndicator: (base) => ({
		...base,
		paddingLeft: inputIconPaddingLeft,
		paddingRight: inputIconPaddingRight,
	}),
	clearIndicator: (base) => ({
		...base,
		paddingLeft: inputIconPaddingLeft,
		paddingRight: inputIconPaddingRight,
	}),
};

export const inValidStyle: AkSelectStyles = {
	container: (base) => ({
		...base,
		border: `${token('space.025')} solid ${token('color.border.danger', colors.R400)}`,
		borderRadius: token('space.050'),
	}),
};
