import parameters from '@atlassian/jira-relay/src/__generated__/JswExpandableProjectOldQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string; projectId: string; noOfBoardsToFetch: number };

export const asyncJswExpandableProjectQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-jsw-expandable-project-old-query" */ './JswExpandableProjectOldQuery'
		).then(({ JswExpandableProjectOldQuery }) => JswExpandableProjectOldQuery),
	),
	getPreloadProps: ({ cloudId, projectId, noOfBoardsToFetch }: EntryPointParams) => ({
		queries: {
			projects: {
				parameters,
				variables: {
					cloudId,
					projectId,
					noOfBoardsToFetch,
				},
			},
		},
	}),
});
