import React, { memo, useCallback, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import uniq from 'lodash/uniq';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import { isLoaderErrorAttributes } from '@atlassian/jira-errors-handling/src/utils/is-loader-error-attributes.tsx';
import ReportErrors from '@atlassian/jira-errors-handling/src/utils/reporting-error-boundary.tsx';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import {
	DEFAULT_IRREMOVABLE_FIELDS,
	useIrremovableFields,
} from '@atlassian/jira-jql-builder-basic/src/utils/irremovable-fields/index.tsx';
import { HYDRATION_QUERY } from '@atlassian/jira-jql-builder-common/src/ui/hydrate-jql-builder/index.tsx';
import { PerformanceContext } from '@atlassian/jira-jql-builder-common/src/ui/performance-context/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { ContextualAnalyticsData, SCREEN } from '@atlassian/jira-product-analytics-bridge';
import type { hydrateJqlBuilderQuery } from '@atlassian/jira-relay/src/__generated__/hydrateJqlBuilderQuery.graphql';
import type { main_jqlBuilder_JQLBuilder$key } from '@atlassian/jira-relay/src/__generated__/main_jqlBuilder_JQLBuilder.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	SEARCH_MODE_ADVANCED,
	SEARCH_MODE_BASIC,
	SEARCH_MODE_SWITCHER_LEFT_LOCATION,
} from './common/constants.tsx';
import type { JQLBuilderProps, SearchMode } from './common/types.tsx';
import { toSearchModeWithBasicModeFallback } from './common/utils.tsx';
import { useContextJql } from './controllers/use-context-jql/index.tsx';
import JQLBuilderUI, { JQLBuilderUIOld } from './ui/index.tsx';
import { JQLBuilderFallback } from './ui/jql-builder-fallback/index.tsx';

/* eslint-disable @atlassian/relay/graphql-naming */
const JQLBUILDER_FRAGMENT = graphql`
	fragment main_jqlBuilder_JQLBuilder on JiraUserPreferences {
		...ui_jqlBuilder_JQLBuilderUI
		jqlBuilderSearchMode
	}
`;

const JQLBuilderOld = ({ onPerformanceMark, ...props }: JQLBuilderProps) => {
	const userPreferencesRef = useFragment<main_jqlBuilder_JQLBuilder$key>(
		JQLBUILDER_FRAGMENT,
		props.userPreferencesData,
	);
	return (
		<ReportErrors
			id="unhandled"
			packageName="jiraJqlBuilder"
			teamName="empanada"
			sendToPrivacyUnsafeSplunk
			attributes={isLoaderErrorAttributes}
		>
			<ContextualAnalyticsData sourceName="jqlBuilder" sourceType={SCREEN}>
				<PerformanceContext.Provider value={onPerformanceMark || noop}>
					<JQLBuilderUIOld {...props} userPreferences={userPreferencesRef} />
				</PerformanceContext.Provider>
			</ContextualAnalyticsData>
		</ReportErrors>
	);
};

const JQLBuilder = ({
	query,
	viewContext = null,
	projectKeys,
	searchModes = [SEARCH_MODE_ADVANCED, SEARCH_MODE_BASIC],
	searchModeSwitcherLocation = SEARCH_MODE_SWITCHER_LEFT_LOCATION,
	showOrderByPicker,
	hideTextSearchInput = false,
	onPerformanceMark,
	maybeHydrationQueryReference,
	...props
}: JQLBuilderProps) => {
	const { onLoad } = props;

	// Persist mode used in loading state to send in analytics
	const loadingModeRef = useRef<SearchMode | undefined>();
	const onLoadingMode = useCallback(
		(mode: SearchMode) => {
			loadingModeRef.current = mode;
			onLoad?.(mode);
		},
		[onLoad],
	);

	const userPreferencesRef = useFragment<main_jqlBuilder_JQLBuilder$key>(
		JQLBUILDER_FRAGMENT,
		props.userPreferencesData,
	);

	const searchModeResponse = userPreferencesRef?.jqlBuilderSearchMode;
	const selectedSearchMode = toSearchModeWithBasicModeFallback(searchModeResponse);
	if (searchModes.length === 0) {
		throw new Error('Search mode should be non empty');
	}
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const uniqueSearchModes = uniq(searchModes) as [SearchMode, ...SearchMode[]];

	const isSearchModeToggleEnabled = uniqueSearchModes ? uniqueSearchModes.length > 1 : true;
	const irremovableFields = useIrremovableFields(
		props.basicModeVisibleFields ?? DEFAULT_IRREMOVABLE_FIELDS(),
	);
	const accountId = useAccountId();
	const cloudId = useCloudId();
	const initialJql = useRef(query);
	const initialContextJql = useContextJql({
		jql: initialJql.current,
		projectKeys,
	});

	const fallback = (
		<JQLBuilderFallback
			query={query}
			searchModes={searchModes}
			onLoad={onLoadingMode}
			selectedSearchMode={selectedSearchMode}
			isSearchModeToggleEnabled={isSearchModeToggleEnabled}
			beforeControls={props.beforeControls}
			renderExtraControls={props.renderExtraControls}
			excludedFields={props.excludedFields}
			components={props.components}
			irremovableFields={irremovableFields}
			hideTextSearchInput={hideTextSearchInput}
			searchPlaceholder={props.searchPlaceholder}
			searchModeSwitcherLocation={searchModeSwitcherLocation}
			showOrderByPicker={showOrderByPicker}
		/>
	);

	const [initialQueryRef, loadQuery] = useQueryLoader<hydrateJqlBuilderQuery>(
		HYDRATION_QUERY,
		fg('nin_request_hydrate_query_earlier') ? maybeHydrationQueryReference : undefined,
	);

	useEffect(() => {
		if (!initialQueryRef) {
			loadQuery(
				{
					accountId: accountId ?? '',
					cloudId,
					includeUser: initialJql.current.length > 0 && !!accountId,
					includeJira: initialJql.current.length > 0,
					jql: initialContextJql,
					isFilter: false,
					filterAri: '',
					viewContext,
				},
				{
					fetchPolicy: 'store-or-network',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			);
		}
		/* Here we only need to initialise the query reference so we fire for the client on mount.
		   We allow useRefetchableFragment in JQLBuilderUI to handle the refetching. */
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<ReportErrors
			id="unhandled"
			packageName="jiraJqlBuilder"
			teamName="empanada"
			sendToPrivacyUnsafeSplunk
			attributes={isLoaderErrorAttributes}
		>
			<ContextualAnalyticsData sourceName="jqlBuilder" sourceType={SCREEN}>
				<PerformanceContext.Provider value={onPerformanceMark || noop}>
					<Placeholder name="jql-builder-main" fallback={fallback}>
						{initialQueryRef ? (
							<JQLBuilderUI
								query={query}
								searchModes={searchModes}
								selectedSearchMode={selectedSearchMode}
								isSearchModeToggleEnabled={isSearchModeToggleEnabled}
								beforeControls={props.beforeControls}
								renderExtraControls={props.renderExtraControls}
								excludedFields={props.excludedFields}
								components={props.components}
								irremovableFields={irremovableFields}
								uniqueSearchModes={uniqueSearchModes}
								hideTextSearchInput={hideTextSearchInput}
								searchModeSwitcherLocation={searchModeSwitcherLocation}
								showOrderByPicker={showOrderByPicker}
								viewContext={viewContext}
								projectKeys={projectKeys}
								userPreferences={userPreferencesRef}
								initialQueryRef={initialQueryRef}
								onLoadingMode={onLoadingMode}
								loadingModeRef={loadingModeRef}
								{...props}
							/>
						) : (
							fallback
						)}
					</Placeholder>
				</PerformanceContext.Provider>
			</ContextualAnalyticsData>
		</ReportErrors>
	);
};

export default componentWithCondition(
	() => fg('nin_migrate_jql_builder_to_refetchable_query'),
	memo<JQLBuilderProps>(JQLBuilder),
	memo<JQLBuilderProps>(JQLBuilderOld),
);
