import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { fieldSettingsRelayResource } from '@atlassian/jira-router-resources-project-settings-fields/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { projectSettingsServicedeskFieldsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskFieldsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import FieldSettingsPage, { LazyFieldsSettings } from './ui/fields/index.tsx';

export const projectSettingsServicedeskFieldsRouteEntry: Route = createEntry(
	projectSettingsServicedeskFieldsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		ufoName: 'project-settings.fields.jsm-tmp',
		component: componentWithFG('efa_and_pfp_targeting_gate', FieldSettingsPage, ErrorPagesNotFound),
		layout: serviceProjectLayout,
		navigation: {
			horizontal: GENERIC_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			getConsolidationStateResource(),
			fieldSettingsRelayResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyFieldsSettings],
	}),
);
