import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { rovoDemandTestsRoute } from '@atlassian/jira-router-routes-rovo-demand-tests-routes/src/rovoDemandTestsRoute.tsx';
import type RovoDemandTestsPageType from '@atlassian/jira-spa-apps-rovo-demand-tests/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';

const LazyRovoDemandTestsPage = lazyForPaint<typeof RovoDemandTestsPageType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-rovo-demand-tests", jiraSpaEntry: "async-rovo-demand-tests" */ '@atlassian/jira-spa-apps-rovo-demand-tests'
		),
	),
);

const RovoDemandTestsPage = () => (
	<LazyPage Page={LazyRovoDemandTestsPage} pageId="rovo-demand-tests" shouldShowSpinner={false} />
);

export const rovoDemandTestsRouteEntry: Route = createEntry(rovoDemandTestsRoute, () => ({
	group: 'components',
	resources: [...getNavigationResources()],

	component: componentWithFG('jsw_rovo_demand_tests_gate', RovoDemandTestsPage, ErrorPagesNotFound),

	layout: globalLayout,

	forPaint: [LazyAtlassianNavigation, LazyRovoDemandTestsPage],
}));
