import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { updateCollection } from '../update-collection/index.tsx';

export const expandSectionForSelectedCollection =
	(): Action<State, Props> =>
	async ({ dispatch }, { activeCollectionUUID }) => {
		if (activeCollectionUUID) {
			dispatch(updateCollection(activeCollectionUUID, { isExpanded: true }));
		}
	};
