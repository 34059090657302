import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { TEAM_MANAGED_BOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	forgeProjectModuleResourceOnNav3Only,
	forgeBoardModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { boardFilterRefinementResource } from '@atlassian/jira-router-resources-board-resource/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3Only } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { softwareBoardRoute } from '@atlassian/jira-router-routes-software-board-routes/src/softwareBoardRoute.tsx';
import { NextGenBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-board/NextGenBoardSkeleton.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { softwareProjectLayout } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';
import { conditionalBoardNBTOptInResource } from './common/conditionalBoardNBTOptInResource.tsx';
import { conditionalBoardRelayResource } from './common/conditionalBoardRelayResource.tsx';
import { conditionalBoardResource } from './common/conditionalBoardResource.tsx';
import { LazyBoardPage, SoftwareBoardComponent } from './ui/SoftwareBoardComponent.tsx';
import { conditionalCustomFiltersResource } from './common/conditionalCustomFiltersResource.tsx';

export const softwareBoardRouteEntry = createEntry(softwareBoardRoute, () => ({
	isStrictModeEnabled: false,
	group: 'software',
	component: SoftwareBoardComponent,
	skeleton: NextGenBoardSkeleton,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-board'),
	layout: composeLayouts(
		softwareProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{
				query: ['selectedIssue'],
			},
		]),
	),
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarNextGen,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3Only,
		projectContextResource,
		forgeProjectModuleResourceOnNav3Only,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResourceOnNav3Only,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		conditionalBoardResource,
		conditionalCustomFiltersResource,
		boardFilterRefinementResource,
		conditionalBoardRelayResource,
		conditionalBoardNBTOptInResource,
		forgeBoardModuleResource,
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],
	preloadOptions: {
		earlyChunks: ['software-board-early-entry'],
	},
	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyBoardPage],
	ufoName: 'next-gen-board',
	meta: {
		capability: TEAM_MANAGED_BOARD,
	},
}));
