import React, { useEffect } from 'react';
import type { ModalDialogProps } from '@atlaskit/modal-dialog';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { JiraModal as AKModalDialog } from '@atlassian/jira-modal/src/ui/jira-modal.tsx';
import { useModalDialogActions, useReturnFocusTo } from '../../controllers/index.tsx';

export const ModalDialog = ({
	shouldReturnFocus,
	...rest
}: ModalDialogProps & MaybeChoreographedComponentProps) => {
	const returnFocusTarget = useReturnFocusTo();
	const { setReturnFocusTo } = useModalDialogActions();

	useEffect(
		() => () => {
			if (returnFocusTarget?.current) {
				!shouldReturnFocus && returnFocusTarget.current.focus();
				setReturnFocusTo(null);
			}
		},
		[returnFocusTarget, setReturnFocusTo, shouldReturnFocus],
	);

	/**
	 *  when shouldReturnFocus is defined,
	 * 	we won't handle passing focus back to this element
	 *	we will ignore the returnFocusTarget
	 */
	const returnFocus = shouldReturnFocus ?? !returnFocusTarget;

	return <AKModalDialog shouldReturnFocus={returnFocus} {...rest} />;
};
