import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { customerPermissionsPageDataResource } from '@atlassian/jira-router-resources-service-desk-customer-permissions/src/services/customer-permissions-page/index.tsx';
import { projectSettingsServicedeskCustomerPermissionsRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-service-desk-routes/src/projectSettingsServicedeskCustomerPermissionsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import ServiceDeskCustomerPermissions, {
	LazyProjectSettingsServiceDeskCustomerPermissions,
} from './ui/service-desk-customer-permissions/index.tsx';

export const projectSettingsServicedeskCustomerPermissionsRouteEntry: Route = createEntry(
	projectSettingsServicedeskCustomerPermissionsRoute,
	() => ({
		group: 'project-settings-servicedesk',
		component: ServiceDeskCustomerPermissions,
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [
			...getServiceDeskSettingsResources(),
			projectContextResource,
			customerPermissionsPageDataResource,
			getConsolidationStateResource(),
		],
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskCustomerPermissions],
		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_PERMISSIONS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_PERMISSIONS),
				teamName: 'jsd-flux-capacitors',
			},
		},
		perfMetricKey: 'jsm-customer-permissions',
	}),
);
