import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICE_MODAL_ROUTE_SUFFIX,
	SERVICE_HASHED_ARI,
} from './common/constants.tsx';

export const servicedeskServiceModalEditRoute: InvariantRoute = {
	name: 'servicedesk-service-modal-edit',
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICE_MODAL_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};
