import React, { useState } from 'react';
import Popup from '@atlaskit/popup';
import { Stack, Text, xcss } from '@atlaskit/primitives';
import InformationIcon from '@atlaskit/icon/core/information';
import Image from '@atlaskit/image';
import Link from '@atlaskit/link';
import { IconButton } from '@atlaskit/button/new';
import { useIntl } from '@atlassian/jira-intl';
import FilterImg from './assets/filter.svg';
import FilterImgDark from './assets/filter-dark.svg';
import messages from './messages.tsx';

const HELP_ARTICLE_URL =
	'https://support.atlassian.com/jira-product-discovery/docs/create-and-configure-roadmaps/#Add-details-and-modify-your-roadmap';

export const FilterExplanation = () => {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			placement="right-start"
			content={() => (
				<Stack xcss={popupStyles} space="space.300">
					<Image
						src={FilterImg}
						srcDark={FilterImgDark}
						alt={formatMessage(messages.information)}
					/>
					<Stack space="space.100" xcss={explanationStyles}>
						<Text>{formatMessage(messages.explanation)}</Text>
						<Link href={HELP_ARTICLE_URL} target="_blank">
							{formatMessage(messages.explore)}
						</Link>
					</Stack>
				</Stack>
			)}
			trigger={(triggerProps) => (
				<IconButton
					{...triggerProps}
					appearance="subtle"
					spacing="compact"
					icon={InformationIcon}
					color="color.icon"
					onClick={() => setIsOpen(!isOpen)}
					label={formatMessage(messages.filterImageAlt)}
				/>
			)}
		/>
	);
};

const popupStyles = xcss({
	width: '320px',
});

const explanationStyles = xcss({
	paddingInline: 'space.250',
	paddingBlockEnd: 'space.250',
});
