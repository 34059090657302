/**
 * @generated SignedSource<<27cf8d4c8476b55ad0214615c4321597>>
 * @relayHash c6e9983b2d202d37d436585f73068bb1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 9c2e6d7f1e46fae7f20940e5f2d25270db884f9c4ffb727bc5ca1be7cef02546

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessAttachmentsQuery } from './src_jiraBusinessAttachmentsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessAttachmentsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "9c2e6d7f1e46fae7f20940e5f2d25270db884f9c4ffb727bc5ca1be7cef02546",
    "metadata": {},
    "name": "src_jiraBusinessAttachmentsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
