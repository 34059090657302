import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsCustomerAccessOldRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsCustomerAccessOldRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import {
	HelpCenterSettingsCustomerAccess,
	LazyHelpCenterSettingsCustomerAccess,
} from './ui/customer-access/index.tsx';

// Delete this as part of berry-4386-add-helpcenterid-to-routes FG clean up
export const helpCenterSettingsCustomerAccessOldRouteEntry: Route = createEntry(
	helpCenterSettingsCustomerAccessOldRoute,
	() => ({
		perfMetricKey: 'help-center-settings.customer-access',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFG(
			'berry-4386-add-helpcenterid-to-routes',
			ErrorPagesNotFound,
			componentWithFG('jcs_master_flag', HelpCenterSettingsCustomerAccess, ErrorPagesNotFound),
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsCustomerAccess],
		resources: [...getServiceDeskSettingsResources()],
	}),
);
