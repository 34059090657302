/**
 * @generated SignedSource<<16204e63c274f6c188558fbff170b22d>>
 * @relayHash ddda93522f041393f21fe421d6db1f15
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID b7da1133f1fcf8be4ecf808c18cd19ad7a7d140a58b25d3b90edd34a46fcb897

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4PlansListViewQuery$variables = {
  cloudId: string;
};
export type Nav4PlansListViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PlansList" | "PlansListOld">;
};
export type Nav4PlansListViewQuery = {
  response: Nav4PlansListViewQuery$data;
  variables: Nav4PlansListViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "ScalarField",
  "name": "id"
},
v3 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "__typename"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v2/*: any*/),
          {
            "kind": "ScalarField",
            "name": "planId"
          },
          {
            "kind": "ScalarField",
            "name": "title"
          },
          {
            "kind": "ScalarField",
            "name": "isReadOnly"
          },
          {
            "kind": "ScalarField",
            "name": "planStatus"
          },
          {
            "concreteType": "JiraFavouriteValue",
            "kind": "LinkedField",
            "name": "favouriteValue",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              (v2/*: any*/)
            ]
          },
          {
            "concreteType": "JiraScenario",
            "kind": "LinkedField",
            "name": "scenario",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "scenarioId"
              },
              (v2/*: any*/)
            ]
          }
        ],
        "type": "JiraPlan"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v2/*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
      }
    ]
  }
],
v4 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4PlansListViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "PlansList"
      },
      {
        "kind": "FragmentSpread",
        "name": "PlansListOld"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4PlansListViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouritePlans",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "PLAN"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              (v4/*: any*/)
            ]
          },
          {
            "alias": "recentPlans",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "PLAN"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v3/*: any*/)
              },
              (v4/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "b7da1133f1fcf8be4ecf808c18cd19ad7a7d140a58b25d3b90edd34a46fcb897",
    "metadata": {},
    "name": "Nav4PlansListViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "32cab3facf0612ab0b04e3ca121fbe0f";

export default node;
