import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	getBusinessProjectResources,
	getBusinessProjectWithAsyncHorizontalNavResources,
} from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { archivedIssuesJwmRoute } from '@atlassian/jira-router-routes-archives-routes/src/archivedIssuesJwmRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { archivedIssuesPageEntryPoint, getCommonProjectLayout } from './ui/index.tsx';

const getBusinessResources = () =>
	fg('migrate_horizontal_nav_to_entrypoint')
		? getBusinessProjectResources()
		: getBusinessProjectWithAsyncHorizontalNavResources();

export const archivedIssuesJwmRouteEntry: Route = createEntry(archivedIssuesJwmRoute, () => ({
	group: 'archive-issues',
	// Refer: issueNavigatorProjectBusinessRoute from issue-navigator
	layout: getCommonProjectLayout(),
	entryPoint: () => archivedIssuesPageEntryPoint,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResourceOnNav3OnlyNonCriticalRoutes,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getBusinessResources(),
	],
}));
