import React, { useCallback, useState } from 'react';
import TrashIcon from '@atlaskit/icon/core/migration/delete--trash';
import { Section, ButtonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { OpsgenieSavedSearchAri } from '@atlassian/ari/opsgenie/saved-search';
import { DeleteModal } from '@atlassian/eoc-alert-common';
import { useSavedSearchRelayUtils, deleteSearchEndpoint } from '@atlassian/eoc-alert-list';
import { toFlagId, useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import {
	BUTTON,
	DROPDOWN_ITEM,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/action-subject-type.tsx';
import {
	CUSTOM_ITEM_DROPDOWN_ACTION,
	FIXED_ITEM_BUTTON,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import { ALERT_SETTINGS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import {
	LEVEL_FOUR,
	LEVEL_THREE,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/level.tsx';
import { MENU_ID_ANALYTIC_EVENT_IDS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/side-nav.tsx';
import { MENU_ID_OPERATIONS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import type { OnCloseProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { useStopPropagation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-stop-propagation/index.tsx';
import { MoreNavMenuButton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/main.tsx';
import type { MoreNavMenuButtonMenuItemsProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/ui/more-nav-menu-button/submenu/index.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { FETCH_ERROR_FLAG_ID } from '../../../../common/constants.tsx';
import messages from './messages.tsx';
import type { AlertActionProps, ModalType } from './types.tsx';

export const SavedSearchActionButtons = ({
	onClose,
	savedSearch,
}: AlertActionProps & OnCloseProps) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<MoreNavMenuButton
			onOpen={() => {
				fireUIAnalytics(createAnalyticsEvent({}), {
					action: 'clicked',
					actionSubject: BUTTON,
					actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
					attributes: {
						componentType: FIXED_ITEM_BUTTON,
						level: LEVEL_THREE,
						itemId: ALERT_SETTINGS,
						parentItemType: 'savedSearch',
						parentItemId: savedSearch.id,
					},
				});
			}}
			MenuItems={(props) => <MoreMenuItems {...props} savedSearch={savedSearch} />}
			onClose={onClose}
		/>
	);
};

const MoreMenuItems = ({
	onClose,
	savedSearch,
}: AlertActionProps & MoreNavMenuButtonMenuItemsProps) => {
	const { formatMessage } = useIntl();
	const [modalType, setModalType] = useState<ModalType | undefined>(undefined);
	const onCloseWithStopPropagation = useStopPropagation(onClose);
	const { removeSavedSearchFromCreatedByMe } = useSavedSearchRelayUtils();
	const { showFlag } = useFlagsService();
	const [isLoading, setIsLoading] = useState(false);

	const deleteSavedSearch = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			setIsLoading(true);
			const savedSearchId = OpsgenieSavedSearchAri.parse(savedSearch.id).savedSearchId;

			deleteSearchEndpoint(savedSearchId)
				.then(() => {
					removeSavedSearchFromCreatedByMe(savedSearch.id);
				})
				.catch(() => {
					showFlag({
						key: toFlagId(FETCH_ERROR_FLAG_ID),
						type: 'error',
						title: messages.errorTitle,
						description: messages.errorMessage,
						messageId:
							'navigation-apps-sidebar-nav4-sidebars-content-operations.ui.alerts-content.alerts-menu-item.action-button.showFlag',
						messageType: 'transactional',
					});
				})
				.finally(() => {
					setIsLoading(false);
					if (onClose) {
						onClose(e);
					}
				});
		},
		[savedSearch, onClose, removeSavedSearchFromCreatedByMe, showFlag],
	);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	return (
		<>
			<Section>
				<ButtonItem
					iconBefore={<TrashIcon LEGACY_size="small" color={token('color.icon.danger')} label="" />}
					onClick={(e) => {
						e.preventDefault();
						setModalType('delete');

						fireUIAnalytics(createAnalyticsEvent({}), {
							action: 'clicked',
							actionSubject: DROPDOWN_ITEM,
							actionSubjectId: MENU_ID_ANALYTIC_EVENT_IDS[MENU_ID_OPERATIONS],
							attributes: {
								componentType: CUSTOM_ITEM_DROPDOWN_ACTION,
								level: LEVEL_FOUR,
								itemId: 'deleteSavedSearch',
								parentItemType: 'savedSearch',
								parentItemId: savedSearch.id,
							},
						});
					}}
				>
					{formatMessage(messages.deleteSavedSearch)}
				</ButtonItem>
			</Section>

			<DeleteModal
				messageDescriptors={{
					title: messages.confirmTrashHeading,
					description: messages.confirmTrashMessage,
				}}
				isDeleteDialogOpen={modalType === 'delete'}
				onDeleteAttemptCancel={onCloseWithStopPropagation}
				isLoading={isLoading}
				onDelete={(e) => deleteSavedSearch(e)}
			/>
		</>
	);
};
