import { createRelayResource } from '@atlassian/jira-relay-utils/src/services/resources/index.tsx';
import QUERY, {
	type ThemeSetterQuery,
} from '@atlassian/jira-relay/src/__generated__/ThemeSetterQuery.graphql';
import { getProjectKeyId } from '@atlassian/jira-router-resources-utils/src/utils/get-project-key-id/index.tsx';

export const projectThemeResource = createRelayResource<ThemeSetterQuery>({
	type: 'JIRA_PROJECT_THEME',
	getQuery: ({ match, query }, { tenantContext }) => {
		const { cloudId } = tenantContext;
		const { projectKey, projectId } = getProjectKeyId(match, query);

		return {
			parameters: QUERY,
			variables: {
				cloudId,
				projectIdOrKey: projectKey ?? projectId ?? '',
			},
			options: { fetchPolicy: 'store-or-network' },
		};
	},
});
