import React, { type PropsWithChildren } from 'react';
import { EmojiContainer } from '@atlassian/jira-polaris-lib-emoji-picker/src/controllers/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { AnalyticContextProvider } from '../../common/controllers/analytic-context/index.tsx';
import { ActionButtons } from '../../common/ui/action-buttons/index.tsx';
import { RoadmapsDefault } from './default/index.legacy.tsx';

export type Nav4RoadmapsProps = {
	isExpanded: boolean;
};

export const Nav4RoadmapsContainerLegacy = (props: Nav4RoadmapsProps) => {
	return (
		<AnalyticContextProvider>
			<Nav4Roadmaps {...props} />
		</AnalyticContextProvider>
	);
};

export const Nav4Roadmaps = ({ isExpanded }: Nav4RoadmapsProps) => {
	return (
		<EmojiDataContainer>
			<RoadmapsDefault actionsOnHover={<ActionButtons />} isExpanded={isExpanded} />
		</EmojiDataContainer>
	);
};

const EmojiDataContainer = ({ children }: PropsWithChildren) => {
	const { cloudId, atlassianAccountId } = useTenantContext();
	return (
		<EmojiContainer cloudId={cloudId} currentUser={atlassianAccountId || undefined}>
			{children}
		</EmojiContainer>
	);
};
