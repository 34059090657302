import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsServicedeskAutomationEditRuleRoute } from '@atlassian/jira-router-routes-project-settings-service-desk-automation-routes/src/projectSettingsServicedeskAutomationEditRuleRoute.tsx';
import { getLegacyAutomationRoutesCommon } from './common/getLegacyAutomationRoutesCommon.tsx';
import ServiceDeskAutomationEditRule, {
	LazyProjectSettingsServiceDeskAutomationEditRule,
} from './ui/service-desk-automation/edit-rule/index.tsx';

export const projectSettingsServicedeskAutomationEditRuleRouteEntry = createEntry(
	projectSettingsServicedeskAutomationEditRuleRoute,
	() => ({
		group: 'project-settings-servicedesk',
		...getLegacyAutomationRoutesCommon(),
		ufoName: 'service-management.automation.edit-rule',
		component: ServiceDeskAutomationEditRule,
		forPaint: [LazyAtlassianNavigation, LazyProjectSettingsServiceDeskAutomationEditRule],
	}),
);
