/**
 * @generated SignedSource<<fc6e2d903d7a5160ec3c02b7156848e4>>
 * @relayHash 2cc1b2be473185de0346970d79002539
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 2a7b24f841e6d55f0b788183900c0f5208b596d9f5efe804ede9c194b19f5e8d

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessApprovalsQuery } from './src_jiraBusinessApprovalsQuery.graphql';

const node: PreloadableConcreteRequest<src_jiraBusinessApprovalsQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "2a7b24f841e6d55f0b788183900c0f5208b596d9f5efe804ede9c194b19f5e8d",
    "metadata": {},
    "name": "src_jiraBusinessApprovalsQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
