import React, { memo } from 'react';
import { EntryPointContainer } from 'react-relay';
import { EntryPointPlaceholder } from '@atlassian/jira-entry-point-placeholder/src/old/index.tsx';
import {
	JiraEntryPointContainer,
	type Props,
} from '@atlassian/jira-entry-point-container/src/index.tsx';
import type { AnyEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import type { EntryPointReferenceSubject } from '@atlassian/jira-entry-point/src/controllers/utils/use-entry-point-load-manager/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';

type EntryPointAdapterProps<TEntryPoint extends AnyEntryPoint> = {
	epContainerProps: Omit<Props<TEntryPoint>, 'entryPointReferenceSubject'>;
	jiraEPRef: EntryPointReferenceSubject<TEntryPoint>;
	nestedEPRef?: never;
	placeholderEntryPoint: TEntryPoint;
};

export const RouteEntryPointAdapter = memo(_RouteEntryPointAdapter);

/**
 * This component is a Route-EntryPoint adapter. It is designed to support the situation
 * where a route is using entryPoints, which means that the route *might* be referencing
 * a left-sidebar entryPoint, which may subsequently be pre-loading a menu-component entryPoint.
 */
export function _RouteEntryPointAdapter<TEntryPoint extends AnyEntryPoint>(
	params: EntryPointAdapterProps<TEntryPoint>,
) {
	const { epContainerProps, jiraEPRef, nestedEPRef, placeholderEntryPoint } = params;

	// If the route is an entryPoint, and the initialRoute was an entryPoint, use EntryPointContainer.
	// Otherwise, use the JiraEntryPointContainer.
	return nestedEPRef ? (
		// eslint-disable-next-line jira/ufo/valid-labels
		<UFOSegment name={`${epContainerProps.id}`}>
			<JSErrorBoundary
				attributes={epContainerProps.errorAttributes}
				fallback={epContainerProps.errorFallback}
				id={epContainerProps.id}
				packageName={epContainerProps.packageName}
				teamName={epContainerProps.teamName}
			>
				<EntryPointPlaceholder
					name={`${epContainerProps.id || 'route-entrypoint-adapter'}-entrypoint-placeholder`}
					fallback={epContainerProps.fallback}
					/* @ts-expect-error - TS2322 - We're using JSResourceReference from @atlassian/react-async. */
					entryPoint={placeholderEntryPoint}
				>
					<EntryPointContainer
						entryPointReference={nestedEPRef}
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						props={{} as never}
					/>
				</EntryPointPlaceholder>
			</JSErrorBoundary>
		</UFOSegment>
	) : (
		<JiraEntryPointContainer {...epContainerProps} entryPointReferenceSubject={jiraEPRef} />
	);
}
