import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const connectProjectsEntrypoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-connect-projects-entrypoint" */ './src'),
	),
	getPreloadProps: ({ context }: EntryPointRouteParams) => ({
		extraProps: {
			query: context.query,
		},
	}),
});
