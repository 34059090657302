import React from 'react';
import type { HelpCenterSettingsSupportWebsite as HelpCenterSettingsSupportWebsiteType } from '@atlassian/jira-spa-apps-help-center-settings-support-website/src/ui/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

export const LazyHelpCenterSettingsSupportWebsite = lazyForPaint<
	typeof HelpCenterSettingsSupportWebsiteType
>(
	() =>
		import(
			/* webpackChunkName: "async-help-center-settings-support-website", jiraSpaEntry: "async-help-center-settings-support-website" */ '@atlassian/jira-spa-apps-help-center-settings-support-website'
		),
);

export const HelpCenterSettingsSupportWebsite = () => (
	<LazyPage
		Page={LazyHelpCenterSettingsSupportWebsite}
		pageId="help-center-settings-support-website"
		shouldShowSpinner
	/>
);
