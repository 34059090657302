import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { layoutWithCondition } from '@atlassian/jira-route-layout/src/utils/layout-with-condition/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { peopleProfilesRoute } from '@atlassian/jira-router-routes-people-profiles-routes/src/peopleProfilesRoute.tsx';
import { rightSidebarsLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type PeopleProfilesType from '@atlassian/jira-spa-apps-people-profiles/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPeopleProfiles = lazyForPaint<typeof PeopleProfilesType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-people-profiles", jiraSpaEntry: "async-people-profiles" */ '@atlassian/jira-spa-apps-people-profiles'
			),
		),
	{
		// becasue `@atlassian/ptc-embeddable-directory` package is not ready for SSR
		ssr: false,
	},
);

const PeopleProfilesComponent = () => (
	<LazyPage Page={LazyPeopleProfiles} pageId="people-profiles" shouldShowSpinner />
);

export const peopleProfilesRouteEntry = createEntry(peopleProfilesRoute, () => ({
	group: 'people-profiles',
	component: PeopleProfilesComponent,

	layout: layoutWithCondition(
		() => expValEquals('jsm_getting_started_page_for_nple_exp', 'cohort', 'variation'),
		rightSidebarsLayout,
		globalLayout,
	),

	navigation: {
		menuId: MENU_ID.PEOPLE,
	},

	resources: [...getNavigationResources(), getConsolidationStateResource()],
	forPaint: [LazyAtlassianNavigation, LazyPeopleProfiles],
	ufoName: 'people-profiles',
}));
