import {
	CUSTOM_FIELD_MODULE,
	CUSTOM_FIELD_TYPE_MODULE,
	ISSUE_ACTION_MODULE,
	ISSUE_ACTIVITY_MODULE,
	ISSUE_CONTEXT_MODULE,
	ISSUE_GLANCE_MODULE,
	ISSUE_PANEL_MODULE,
	ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
	SOURCE_ISSUE_VIEW,
	UI_MODIFICATIONS_MODULE,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { Extension } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import type { ExtensionPointModule } from '@atlassian/jira-forge-ui-types/src/common/types/module.tsx';
import { isExtensionHiddenByAppAccessRules } from '@atlassian/jira-forge-ui-utils/src/utils/extension/index.tsx';
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/index.tsx';
import type { CloudId, IssueKey } from '@atlassian/jira-shared-types/src/general.tsx';
import type { ForgeResponse } from '../../types.tsx';
import { dedupeContextAndGlance } from './utils.tsx';

/**
 * Takes an array of arrays and uses the type to find the appropriate array to return. This is so we can ensure
 * that when constructing the forge response only the correct type is put into the forge response.
 *
 * It is not expected that Extension[] will contain mixed types, however Extension[][] will.
 *
 * @param result The array of arrays that contain extensions returned from the fetch forge API
 * @param type The type of the ExtensionPointModule
 */
export const getExtensions = <ExtensionModuleType extends ExtensionPointModule>(
	result: Extension[][],
	type: ExtensionModuleType,
) =>
	result.find((extensions): extensions is Extract<Extension, { type: ExtensionModuleType }>[] =>
		extensions.some((extension) => extension.type === type),
	) || [];

export const fetchIssueForgeData = async (
	cloudId: CloudId,
	issueKey: IssueKey,
	isAnonymous: boolean,
): Promise<ForgeResponse> => {
	const extensions = await fetchModules({
		cloudId,
		isAnonymous,
		types: [
			ISSUE_ACTION_MODULE,
			ISSUE_GLANCE_MODULE,
			ISSUE_CONTEXT_MODULE,
			ISSUE_PANEL_MODULE,
			ISSUE_ACTIVITY_MODULE,
			CUSTOM_FIELD_MODULE,
			CUSTOM_FIELD_TYPE_MODULE,
			ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE,
			UI_MODIFICATIONS_MODULE,
		],
		context: { issueKey },
		includeHidden: true,
		includeScopes: true,
		source: SOURCE_ISSUE_VIEW,
	});

	const getFilteredExtensions = (filterFn: (extension: Extension) => boolean) => ({
		issueAction: extensions[ISSUE_ACTION_MODULE].filter(filterFn),
		issueGlance: extensions[ISSUE_GLANCE_MODULE].filter(filterFn),
		issueContext: extensions[ISSUE_CONTEXT_MODULE].filter(filterFn),
		issuePanel: extensions[ISSUE_PANEL_MODULE].filter(filterFn),
		issueActivity: extensions[ISSUE_ACTIVITY_MODULE].filter(filterFn),
		customField: extensions[CUSTOM_FIELD_MODULE].filter(filterFn),
		customFieldType: extensions[CUSTOM_FIELD_TYPE_MODULE].filter(filterFn),
		issueViewBackgroundScript: extensions[ISSUE_VIEW_BACKGROUND_SCRIPT_MODULE].filter(filterFn),
		uiModifications: extensions[UI_MODIFICATIONS_MODULE].filter(filterFn),
	});

	const mappedResult = {
		...getFilteredExtensions((extension) => !isExtensionHiddenByAppAccessRules(extension)),
		blockedExtensions: {
			...getFilteredExtensions((extension) => isExtensionHiddenByAppAccessRules(extension)),
		},
	};

	return dedupeContextAndGlance(mappedResult);
};
