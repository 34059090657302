{
	"name": "@atlassian/post-office-placement-shared",
	"version": "1.2.0",
	"description": "Post Office placement data",
	"author": "Atlassian Pty Ltd",
	"publishConfig": {
		"registry": "https://packages.atlassian.com/api/npm/npm-remote"
	},
	"atlassian": {
		"team": "Post Office",
		"runReact18": true
	},
	"repository": "https://stash.atlassian.com/projects/ATLASSIAN/repos/atlassian-frontend-monorepo",
	"main": "dist/cjs/index.js",
	"module": "dist/esm/index.js",
	"module:es2019": "dist/es2019/index.js",
	"types": "dist/types/index.d.ts",
	"typesVersions": {
		">=4.5 <5.4": {
			"*": [
				"dist/types-ts4.5/*",
				"dist/types-ts4.5/index.d.ts"
			]
		}
	},
	"sideEffects": false,
	"atlaskit:src": "src/index.ts",
	"af:exports": {
		".": "./src/index.ts",
		"./request-initial-data": "./src/initialDataClient.ts"
	},
	"exports": {
		".": "./src/index.ts",
		"./request-initial-data": "./src/initialDataClient.ts"
	},
	"peerDependencies": {
		"react": "^16.8.0 || ^17.0.0 || ^18.2.0",
		"react-dom": "^16.8.0 || ^17.0.0 || ^18.2.0",
		"react-intl-next": "npm:react-intl@^5.18.1"
	},
	"dependencies": {
		"@atlaskit/analytics-namespaced-context": "^6.13.0",
		"@atlaskit/feature-gate-js-client": "^4.23.0",
		"@atlassian/post-office-context": "1.1.0",
		"@atlassian/post-office-frontend-performance-tracking": "^1.2.0",
		"@atlassian/post-office-placement-data": "^1.2.0",
		"@babel/runtime": "^7.0.0",
		"@compiled/react": "^0.18.1"
	},
	"devDependencies": {
		"@testing-library/jest-dom": "^6.4.5",
		"@testing-library/react": "^13.4.0",
		"@testing-library/react-hooks": "^8.0.1",
		"@types/node": "~20.16.5",
		"@types/react": "^18.2.0",
		"@types/react-dom": "^18.2.0",
		"eslint": "^8.57.0",
		"jest": "^29.7.0",
		"jest-environment-jsdom": "^29.7.0",
		"npm-run-all2": "^5.0.0",
		"parcel": "^2.6.0",
		"react": "^18.2.0",
		"typescript": "~5.4.2"
	},
	"targets": {
		"main": {
			"optimize": true
		},
		"module": {
			"optimize": true
		}
	}
}
