import { useEffect, useMemo } from 'react';
import {
	shouldRenderBannerPrefetched,
	type PrefetchedConsentPreferenceData,
} from '@atlassian/browser-storage-controls';
import { useConsentBannerUserPreferencesResource } from '@atlassian/jira-router-resources-cookie-consent/src/index.tsx';
import { BANNER_NAME } from '../constants.tsx';
import { isInIframe, useCookieConsentBannerSettings } from '../services/preferences/index.tsx';

export const shouldRenderBanner = (data: PrefetchedConsentPreferenceData | null) => {
	try {
		return data && shouldRenderBannerPrefetched(data);
	} catch (e) {
		return true;
	}
};

export const useBannerCookieConsent = () => {
	const [{ isDismissed, height }, { onShow }] = useCookieConsentBannerSettings();

	const { data } = useConsentBannerUserPreferencesResource();

	useEffect(() => {
		const shouldRender = shouldRenderBanner(data);
		// only show banner if banner should be shown and not in iframe
		if (shouldRender && !isInIframe()) {
			// the initial state of banner is "Dismissed" so we need to show it if we "think" it should be shown
			// in case we are making a mistake and CookieBanner will not display
			// it will inform us via `onConsentBannerBailOut` and we will dismiss the banner again.
			onShow();
		}
	}, [data, onShow]);

	return useMemo(
		() => ({
			isEnabled: !isDismissed,
			name: BANNER_NAME,
			height,
		}),
		[isDismissed, height],
	);
};
