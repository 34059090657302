import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { homeOnboardingRedirectRoute } from '@atlassian/jira-router-routes-home-onboarding-redirect-routes/src/homeOnboardingRedirectRoute.tsx';
import JiraHomeOnboardingRedirect from '@atlassian/jira-spa-apps-common/src/redirect/jira-home-onboarding/index.tsx';

export const homeOnboardingRedirectRouteEntry = createEntry(homeOnboardingRedirectRoute, () => ({
	group: 'home',
	component: JiraHomeOnboardingRedirect,
	layout: globalLayout,
	resources: [...getNavigationResources()],
	isRedirect: true,
	forPaint: [LazyAtlassianNavigation],
}));
