/**
 * @generated SignedSource<<be6f5993018a343b4acafe0061e2e04e>>
 * @relayHash abf151795028f3770a9300523a04af00
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ae80ab59ee2189cebc2881d3c421bf61d0f5f6840817cb3dddfaff62c6677a31

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4DashboardsContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
};
export type Nav4DashboardsContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"DashboardsContent">;
};
export type Nav4DashboardsContentQuery = {
  response: Nav4DashboardsContentQuery$data;
  variables: Nav4DashboardsContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentURL"
  }
],
v1 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "id"
},
v4 = [
  {
    "kind": "LinkedField",
    "name": "node",
    "plural": false,
    "selections": [
      {
        "kind": "ScalarField",
        "name": "__typename"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v3/*: any*/),
          {
            "kind": "ScalarField",
            "name": "dashboardId"
          },
          {
            "kind": "ScalarField",
            "name": "title"
          },
          {
            "concreteType": "JiraFavouriteValue",
            "kind": "LinkedField",
            "name": "favouriteValue",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "isFavourite"
              },
              (v3/*: any*/)
            ]
          }
        ],
        "type": "JiraDashboard"
      },
      {
        "kind": "InlineFragment",
        "selections": [
          (v3/*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
      }
    ]
  }
],
v5 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4DashboardsContentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "DashboardsContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4DashboardsContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "dashboardFavourites",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "DASHBOARD"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v4/*: any*/)
              },
              (v5/*: any*/)
            ]
          },
          {
            "alias": "dashboardRecents",
            "args": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "DASHBOARD"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": (v4/*: any*/)
              },
              (v5/*: any*/)
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ae80ab59ee2189cebc2881d3c421bf61d0f5f6840817cb3dddfaff62c6677a31",
    "metadata": {},
    "name": "Nav4DashboardsContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2efc6b73165a1bbb92f1953043700da4";

export default node;
