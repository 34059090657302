import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { issueNavigatorGlobalJiraRoute } from '@atlassian/jira-router-routes-issue-navigator-routes/src/issueNavigatorGlobalJiraRoute.tsx';
import { GlobalIssueNavigatorSkeleton } from '@atlassian/jira-skeletons/src/ui/issue-navigator/components/global-app.tsx';
import { getCommonGlobalNavigation } from './common/getCommonGlobalNavigation.tsx';
import { getCommonGlobalResources } from './common/getCommonGlobalResources.tsx';
import { getIssueNavigatorRouteEntryCommon } from './common/getIssueNavigatorRouteEntryCommon.tsx';
import { LazyGlobalIssueNavigator, GinRedirect } from './ui/index.tsx';

/**
 * Global Issue Navigator Route Entry
 * Handles the new `/jira/issues` route scheme
 */
export const issueNavigatorGlobalJiraRouteEntry = createEntry(
	issueNavigatorGlobalJiraRoute,
	() => ({
		group: 'issue-navigator',
		...getIssueNavigatorRouteEntryCommon(),
		component: GinRedirect,
		skeleton: GlobalIssueNavigatorSkeleton,
		forPaint: [LazyAtlassianNavigation, LazyGlobalIssueNavigator],
		ufoName: 'global-issue-navigator-jfe',
		navigation: getCommonGlobalNavigation(),
		resources: [...getCommonGlobalResources()],
		layout: globalLayout,
		// We want to opt out of concurrent mode for all NIN routes https://hello.atlassian.net/wiki/x/wTRfDAE
		// But allow for users with empanada-concurrent-incremental gate enabled to continue testing it
		shouldOptOutConcurrentMode:
			fg('jira-concurrent-incremental') && !fg('empanada-concurrent-incremental'),
	}),
);
