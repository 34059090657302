import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { arjIncrementEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/arjIncrementEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { planIncrementEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-increment/entrypoint.tsx';
import { PlanIncrementEmbedPage, LazyPlanIncrementEmbed } from './ui/index.tsx';

const planProgramEmbedRouteEntry = createChromelessEntryPoint({
	main: planIncrementEntryPoint,
});

export const arjIncrementEmbedRouteEntry: Route = createEntry(arjIncrementEmbedRoute, () => ({
	group: 'software',
	component: componentWithFG('smart_links_for_plans', PlanIncrementEmbedPage, ErrorPagesNotFound),
	entryPoint: () => (fg('migrate_plans_to_entrypoints') ? planProgramEmbedRouteEntry : undefined),
	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		themePreferenceResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanIncrementEmbed],
}));
