import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskServiceModalEditRoute } from '@atlassian/jira-router-routes-servicedesk-services-routes/src/servicedeskServiceModalEditRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ServiceUpdateModalWithoutProjectContext, {
	LazyServiceUpdateModalWithProjectContext,
} from './ui/service-update-modal-without-project-context/index.tsx';

export const servicedeskServiceModalEditRouteEntry: Route = createEntry(
	servicedeskServiceModalEditRoute,
	() => ({
		group: 'servicedesk',
		component: ServiceUpdateModalWithoutProjectContext,
		layout: serviceProjectLayout,
		resources: [...getServiceDeskNavigationResources(), ...getServicedeskPageContextResources()],
		forPaint: [LazyAtlassianNavigation, LazyServiceUpdateModalWithProjectContext],
	}),
);
