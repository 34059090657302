import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';
import PlaybookDetailQuery$parameters from '@atlassian/jira-relay/src/__generated__/PlaybookDetailQuery$parameters';

const playbookEditViewEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-spa-apps-playbooks-edit" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext }: EntryPointRouteParams) => {
		const { projectKey, playbookId = '' } = context.match.params;
		if (!projectKey) {
			throw new Error('projectKey is required');
		}
		const { cloudId, activationId } = tenantContext;

		const isNewPlaybook = !!playbookId?.includes(NEW_PLAYBOOK_IDENTIFIER);

		const playbookAri = toPlaybookAri({
			siteId: cloudId,
			activationId,
			playbookId: playbookId || '',
		});
		if (isNewPlaybook) {
			return {
				extraProps: {
					playbookAri,
					isNew: true,
				},
			};
		}
		return {
			queries: {
				PlaybookDetailQuery: {
					parameters: PlaybookDetailQuery$parameters,
					variables: {
						playbookAri,
					},
				},
			},
			extraProps: {
				playbookAri,
				isNew: false,
			},
		};
	},
});

export default playbookEditViewEntryPoint;

function toPlaybookAri({
	siteId,
	activationId,
	playbookId,
}: {
	siteId: string;
	activationId: string;
	playbookId: string;
}) {
	return `ari:cloud:jira:${siteId}:playbook/activation/${activationId}/${playbookId}`;
}

const NEW_PLAYBOOK_IDENTIFIER = '_new_';
