import React, {
	type ComponentPropsWithoutRef,
	createContext,
	useContext,
	useCallback,
	useEffect,
	useLayoutEffect,
	useRef,
	useState,
} from 'react';
import type { PreloadedEntryPoint } from 'react-relay';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { JSResourceReference } from '@atlassian/react-async';

export type EntryPoint = {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	root: JSResourceReference<any>;
};

type Placeholders = Map<string, string>;

export function getPlaceholdersFromHTML() {
	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const placeholders = document.querySelectorAll<HTMLElement>('[data-ep-placeholder-id]');

	// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
	return new Map(Array.from(placeholders, (el) => [el.dataset.epPlaceholderId!, el.innerHTML]));
}

export const EntryPointPlaceholderContext = createContext<Placeholders>(new Map());

export type EntryPointReferencePlaceholderProps = Omit<
	ComponentPropsWithoutRef<typeof Placeholder>,
	'name'
> & {
	name: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	entryPointReference: PreloadedEntryPoint<any>;
};

export function EntryPointReferencePlaceholder({
	children,
	entryPointReference,
	fallback: originalFallback,
	name,
	...placeholderProps
}: EntryPointReferencePlaceholderProps) {
	const hasMounted = useRef(false);
	const placeholders = useContext(EntryPointPlaceholderContext);
	const ssrFallback = placeholders.get(name);
	const [fallback, setFallback] = useState(
		!ssrFallback ? (
			originalFallback
		) : (
			// eslint-disable-next-line react/no-danger
			<span dangerouslySetInnerHTML={{ __html: ssrFallback }} data-ep-placeholder-id={name} />
		),
	);

	const lastOriginalFallback = useRef(originalFallback);
	lastOriginalFallback.current = originalFallback;

	const resetFallback = useCallback(() => {
		if (placeholders.has(name)) {
			placeholders.delete(name);
			setFallback(lastOriginalFallback.current);
		}
	}, [name, placeholders, setFallback]);

	useLayoutEffect(() => {
		// reset the fallback when the entrypoint reference changes
		if (hasMounted.current) {
			resetFallback();
		}
	}, [entryPointReference, resetFallback]);

	// using useState to ensure the entryPointReference is loaded synchronously
	// for the initial render (enabling SSR)
	useState(() => {
		if (entryPointReference) {
			try {
				entryPointReference.getComponent();
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} catch (thrown: any) {
				if ('then' in thrown && typeof thrown.then === 'function') {
					thrown.then(resetFallback);
				}
			}
		}
	});

	useEffect(() => {
		hasMounted.current = true;

		// reset fallback on unmount
		return resetFallback;
	}, [resetFallback]);

	return (
		<Placeholder fallback={fallback} name={name} {...placeholderProps}>
			<span data-ep-placeholder-id={name}>{children}</span>
		</Placeholder>
	);
}
