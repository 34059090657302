/**
 * @generated SignedSource<<ef856f6c30c8ee952cf90af4eb875eb3>>
 * @relayHash b0df8d97c2454a75437176869922eae7
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6f7c7e777a7531e2f5fb3eadbbbad4fc9f5e476ee75726989f80b342a26e0615

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessTimelineEmbedQuery } from './src_jiraBusinessTimelineEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessTimelineEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6f7c7e777a7531e2f5fb3eadbbbad4fc9f5e476ee75726989f80b342a26e0615",
    "metadata": {},
    "name": "src_jiraBusinessTimelineEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
