import {
	DEFAULT_BACKGROUND_CONFIG,
	BACKGROUND_CONFIGS_OLD,
	OLD_TO_NEW_BACKGROUND_CONFIG_MAP,
	IMAGE_BACKGROUND_CONFIG,
	GRADIENT_BACKGROUND_CONFIGS,
	FIXED_COLOR_BACKGROUND_CONFIGS,
	ADAPTIVE_COLOR_BACKGROUND_CONFIGS,
	ADAPTIVE_GRADIENT_BACKGROUND_CONFIGS,
	NO_BACKGROUND_CONFIG,
} from './constants.tsx';
import type {
	ThemeSetting,
	GradientThemeName,
	OldThemeName,
	GradientBackgroundConfig,
	ColorBackgroundConfig,
	ColorThemeName,
} from './types.tsx';

const ALL_COLOR_BACKGROUND_CONFIGS = [
	...FIXED_COLOR_BACKGROUND_CONFIGS,
	...ADAPTIVE_COLOR_BACKGROUND_CONFIGS,
	NO_BACKGROUND_CONFIG,
];

/**
 * This is a temporary class that will be used to transition between the old
 * and new theme systems. Once the old theme system is removed, this class
 * will be removed as well.
 */
class ThemeManager {
	gradients: Map<GradientThemeName, GradientBackgroundConfig> = new Map();

	gradientNames: Set<GradientThemeName> = new Set();

	colors: Map<ColorThemeName, ColorBackgroundConfig> = new Map();

	colorNames: Set<ColorThemeName> = new Set();

	oldColorNames: Set<OldThemeName> = new Set();

	init() {
		GRADIENT_BACKGROUND_CONFIGS.forEach((theme) => {
			this.gradients.set(theme.name, theme);
			this.gradientNames.add(theme.name);
		});

		ALL_COLOR_BACKGROUND_CONFIGS.forEach((theme) => {
			this.colors.set(theme.name, theme);
			this.colorNames.add(theme.name);
		});

		BACKGROUND_CONFIGS_OLD.forEach((theme) => {
			this.oldColorNames.add(theme.name);
		});
	}

	isColorTheme(themeName: string | null | undefined): themeName is ColorThemeName {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return this.colorNames.has(themeName as ColorThemeName);
	}

	isOldColorTheme(themeName: string | null | undefined): themeName is OldThemeName {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return this.oldColorNames.has(themeName as OldThemeName);
	}

	isGradientTheme(themeName: string | null | undefined): themeName is GradientThemeName {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		return this.gradientNames.has(themeName as GradientThemeName);
	}

	isImageTheme(themeSetting: ThemeSetting) {
		return themeSetting.type === 'image';
	}

	isValidTheme = (themeSetting: ThemeSetting) =>
		this.isColorTheme(themeSetting.value) ||
		this.isOldColorTheme(themeSetting.value) ||
		this.isGradientTheme(themeSetting.value) ||
		this.isImageTheme(themeSetting);

	getGradient(themeName: GradientThemeName) {
		return this.gradients.get(themeName);
	}

	getColor(themeName: ColorThemeName) {
		return this.colors.get(themeName);
	}

	/**
	 * Gets the theme meta for the given theme setting and version.
	 * This is used because we want to handle cases where users get flag
	 * variations during rollout that change what background is allowed.
	 * For example, if you have a new background color, but the flag is off,
	 * we want to revert back to the default background color instead.
	 * @param themeSetting the name setting with its type and value.
	 * @param enableImages determines if image backgrounds are enabled.
	 * @returns the meta information for the theme.
	 */

	get({
		themeSetting,
		enableImages,
	}: {
		themeSetting: ThemeSetting | null | undefined; // remove undefined when cleaning up jira_theming_relay_migration
		enableImages: boolean;
	}) {
		if (!themeSetting) {
			return DEFAULT_BACKGROUND_CONFIG;
		}

		if (enableImages && themeSetting.type === 'image') {
			return IMAGE_BACKGROUND_CONFIG;
		}

		if (this.isGradientTheme(themeSetting.value)) {
			return this.getGradient(themeSetting.value) ?? DEFAULT_BACKGROUND_CONFIG;
		}

		if (this.isColorTheme(themeSetting.value)) {
			return this.getColor(themeSetting.value) ?? DEFAULT_BACKGROUND_CONFIG;
		}

		// we have an old color on the image version
		return (
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			OLD_TO_NEW_BACKGROUND_CONFIG_MAP[themeSetting.value as OldThemeName] ??
			DEFAULT_BACKGROUND_CONFIG
		);
	}
}

export const themeManager = new ThemeManager();
themeManager.init();

interface GenerateMediaImageUrlProps {
	mediaApiFileId: string;
	mediaClientId: string;
	mediaExternalEndpointUrl: string;
	mediaEndpoint: 'binary' | 'image';
	token?: string;
	width?: number;
	height?: number;
	'max-age'?: number;
	mode?: string;
}

/*
 * Generate background image url for a file uploaded to media
 */
export function generateMediaImageUrl({
	mediaApiFileId,
	mediaClientId,
	mediaExternalEndpointUrl,
	mediaEndpoint,
	...queryParams
}: GenerateMediaImageUrlProps) {
	const url = new URL(`${mediaExternalEndpointUrl}/file/${mediaApiFileId}/${mediaEndpoint}`);

	url.searchParams.append('client', mediaClientId);
	Object.entries(queryParams).forEach(([param, value]) =>
		url.searchParams.append(param, String(value)),
	);

	return url.toString();
}

type BackgroundConfigs = {
	backgroundColorConfigs:
		| typeof ADAPTIVE_COLOR_BACKGROUND_CONFIGS
		| typeof FIXED_COLOR_BACKGROUND_CONFIGS;
	gradientBackgroundConfigs:
		| typeof ADAPTIVE_GRADIENT_BACKGROUND_CONFIGS
		| typeof GRADIENT_BACKGROUND_CONFIGS;
};

export function getBackgroundConfigs(withAdaptiveColorTheme: boolean): BackgroundConfigs {
	return withAdaptiveColorTheme
		? {
				backgroundColorConfigs: ADAPTIVE_COLOR_BACKGROUND_CONFIGS,
				gradientBackgroundConfigs: ADAPTIVE_GRADIENT_BACKGROUND_CONFIGS,
			}
		: {
				backgroundColorConfigs: FIXED_COLOR_BACKGROUND_CONFIGS,
				gradientBackgroundConfigs: GRADIENT_BACKGROUND_CONFIGS,
			};
}
