import { coreProjectLayout } from '@atlassian/jira-core-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { classicBusinessProformaIssueFormsDirectRoute } from '@atlassian/jira-router-routes-proforma-routes/src/classicBusinessProformaIssueFormsDirectRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/issue-forms-direct/index.tsx';

export const classicBusinessProformaIssueFormsDirectRouteEntry: Route = createEntry(
	classicBusinessProformaIssueFormsDirectRoute,
	() => ({
		group: 'classic-business',
		layout: coreProjectLayout,
		resources: [projectContextResource],
		component: ProformaIssueFormsDirect,
		navigation: {
			menuId: MENU_ID.PROJECTS,
		},
		forPaint: [LazyProformaIssueFormsDirect],
		perfMetricKey: 'issue-forms-direct',
	}),
);
