import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { expValEquals } from '@atlassian/jira-feature-experiments';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessCalendarRoute } from '@atlassian/jira-router-routes-business-calendar-routes/src/jiraBusinessCalendarRoute.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { calendarEntryPoint } from '@atlassian/jira-spa-apps-business-calendar-new/entrypoint.tsx';
import { calendarEntrypoint as legacyCalendarEntrypoint } from '@atlassian/jira-spa-apps-business-calendar/entrypoint.tsx';
import { jiraHorizontalNavEntrypoint } from '@atlassian/jira-horizontal-nav/entrypoint.tsx';

const calendarPageEntrypoint = createPageEntryPoint({
	main: calendarEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

const legacyCalendarPageEntrypoint = createPageEntryPoint({
	main: legacyCalendarEntrypoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	horizontalNavigation: jiraHorizontalNavEntrypoint,
});

export const jiraBusinessCalendarRouteEntry = createEntry(jiraBusinessCalendarRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.calendar-view',

	entryPoint() {
		return expValEquals('business_projects_shared_calendar', 'cohort', 'variation')
			? calendarPageEntrypoint
			: legacyCalendarPageEntrypoint;
	},

	layout: businessProjectLayout,
	skeleton: ThemedLazySkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		// delete when cleaning up getWillShowNav4
		sidebar: AsyncSidebarCore,
		horizontal: AsyncHorizontalNavJira,
	},

	resources: getProjectViewResources(),
}));
