import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { DisplaySettingsScope } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/constants.tsx';
import { DisplaySettingsProvider } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/index.tsx';
import {
	Nav4Projects as Nav4ProjectsWithoutErrorBoundary,
	type Nav4ProjectsProps,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/ui/projects/Nav4Projects.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

export const Nav4Projects = (props: Nav4ProjectsProps) => (
	<UFOSegment name="nav4-sidebar-projects">
		<JSErrorBoundary
			fallback="flag"
			id="projects-section"
			packageName={PACKAGE_NAME}
			teamName={TEAM_NAME}
		>
			{expVal('blu-6315-display-settings-a-part-1', 'isEnabled', false) ? (
				<DisplaySettingsProvider settingScope={DisplaySettingsScope.PROJECTS}>
					<Nav4ProjectsWithoutErrorBoundary {...props} />
				</DisplaySettingsProvider>
			) : (
				<Nav4ProjectsWithoutErrorBoundary {...props} />
			)}
		</JSErrorBoundary>
	</UFOSegment>
);
