import React, { useCallback } from 'react';
import Button, { IconButton } from '@atlaskit/button/new';
import MegaphoneIcon from '@atlaskit/icon/core/megaphone';
import Link from '@atlaskit/link';
import Tooltip from '@atlaskit/tooltip';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { ENTRYPOINT_ID_NAVIGATION_REFRESH } from '@atlassian/jira-feedback-collector/src/constants.tsx';
import { useFeedbackCollectorActions } from '@atlassian/jira-feedback-collector/src/controllers/index.tsx';
import { useIntl, FormattedMessage } from '@atlassian/jira-intl';
import { getWillShowNav4UserOptIn } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4-user-opt-in.tsx';
import { getWillShowNav4UserOptOut } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4-user-opt-out/index.tsx';
import { NAV4_GENERAL_FEEDBACK_GROUP_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/constants/index.tsx';
import normaliseUrl from '@atlassian/jira-platform-url-normalisation/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useEnvironment } from '@atlassian/jira-tenant-context-controller/src/components/environment/index.tsx';
import { useLocale } from '@atlassian/jira-tenant-context-controller/src/components/locale/index.tsx';
import messages from './messages.tsx';

export function SidebarFeedbackButton() {
	const environment = useEnvironment();
	const cloudId = useCloudId();
	const { formatMessage } = useIntl();

	const { openFeedbackCollector, closeFeedbackCollector } = useFeedbackCollectorActions();

	const onClickHandler = useCallback(() => {
		const showFeedbackTitleDetailsWithLink =
			getWillShowNav4UserOptIn() || getWillShowNav4UserOptOut();

		const formattedMessageNode = showFeedbackTitleDetailsWithLink ? (
			<FormattedMessage
				{...messages.feedbackTitleDetailsWithLink}
				values={{
					a: (chunks) => (
						<Link href="/jira/settings/personal/general" onClick={closeFeedbackCollector}>
							{chunks}
						</Link>
					),
				}}
			/>
		) : (
			<FormattedMessage {...messages.feedbackTitleDetailsNoLink} />
		);

		openFeedbackCollector({
			entrypointId: ENTRYPOINT_ID_NAVIGATION_REFRESH,
			feedbackContext: [
				{
					id: 'environment',
					value: environment,
				},
				{
					id: 'cloud-id',
					value: cloudId,
				},
				{
					id: 'location',

					// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
					value: normaliseUrl(window?.location?.href ?? ''),
				},
			],
			feedbackGroupId: NAV4_GENERAL_FEEDBACK_GROUP_ID,
			feedbackTitle: formatMessage(messages.feedbackTitle),
			feedbackTitleDetails: formattedMessageNode,
		});
	}, [closeFeedbackCollector, cloudId, environment, formatMessage, openFeedbackCollector]);

	const locale = useLocale();
	const showPersistentInvite =
		// Ensure that we can turn off the locale check with a feature gate if needed
		// eslint-disable-next-line jira/ff/no-preconditioning
		fg('jira_global_invite_nav4') &&
		// Do not trigger an exposure on the experiment for non-English locales
		(locale?.toLowerCase().startsWith('en') ?? false) &&
		expVal('jira_global_invite_nav4_experiment', 'global_invite_enabled', false);

	return (
		<Tooltip content={formatMessage(messages.sidebarFeedbackButtonLabel)} position="top">
			{showPersistentInvite ? (
				<IconButton
					icon={MegaphoneIcon}
					onClick={onClickHandler}
					label={formatMessage(messages.sidebarFeedbackButtonLabel)}
				/>
			) : (
				<Button iconBefore={MegaphoneIcon} appearance="subtle" onClick={onClickHandler}>
					{formatMessage(messages.sidebarFeedbackButtonLabel)}
				</Button>
			)}
		</Tooltip>
	);
}
