import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { DASHBOARD } from '@atlassian/jira-capabilities/src/constants.tsx';
import { NORMAL_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { homepageLayout } from '@atlassian/jira-homepage-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { dashboardRoute } from '@atlassian/jira-router-routes-dashboard-routes/src/dashboardRoute.tsx';
import type { Route } from '@atlassian/react-resource-router/src/common/types.tsx';
import { DashboardRedirect } from './ui/dashboard-redirect/index.tsx';
import { dashboardResources, LazyDashboardView } from './ui/index.tsx';

export const dashboardRouteEntry: Route = createEntry(dashboardRoute, () => ({
	group: 'dashboard',
	component: DashboardRedirect,
	layout: homepageLayout,
	resources: [...getNavigationResources(), ...dashboardResources()],
	forPaint: [LazyAtlassianNavigation, LazyDashboardView],
	meta: {
		capability: DASHBOARD,
	},
	isRedirect: true,
	earlyAssets: NORMAL_EARLY_ASSET_STRATEGY,
}));
