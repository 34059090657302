/** @jsx jsx */
import React from 'react';
import { css, jsx } from '@compiled/react';
import type { FieldProps } from '@atlaskit/form';
import { Box, Inline } from '@atlaskit/primitives';
import { AsyncSelect, CheckboxOption, components } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import type { ProjectOption } from './types.tsx';
import { loadProjectsOptions } from './utils.tsx';

export const ProjectSelect = (props: FieldProps<string[]>) => {
	const { formatMessage } = useIntl();
	const { onChange, value, id, isRequired, ...restProps } = props;

	return (
		<AsyncSelect<ProjectOption, true>
			{...restProps}
			cacheOptions
			defaultOptions
			loadOptions={loadProjectsOptions}
			placeholder={formatMessage(messages.projectsSelectFieldPlaceholder)}
			onChange={(values) => {
				onChange(values.map((v) => v.value));
			}}
			components={{
				Option: ({ children, ...rest }) => (
					<CheckboxOption {...rest}>
						<Box paddingInlineStart="space.100">
							<Inline space="space.100" alignBlock="center">
								<img css={projectAvatarStyles} alt={rest.data.label} src={rest.data.imageUrl} />
								{children}
							</Inline>
						</Box>
					</CheckboxOption>
				),
				MultiValueLabel: ({ children, ...rest }) => (
					<components.MultiValueLabel {...rest}>
						<Inline space="space.050" alignBlock="center">
							<img css={projectAvatarStyles} alt={rest.data.label} src={rest.data.imageUrl} />
							{children}
						</Inline>
					</components.MultiValueLabel>
				),
			}}
			closeMenuOnSelect={false}
			hideSelectedOptions={false}
			isMulti
		/>
	);
};

const projectAvatarStyles = css({
	width: token('space.200'),
	height: token('space.200'),
	borderRadius: token('border.radius.050'),
});
