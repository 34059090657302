import type {
	DashboardConfigAndWRMResourceData,
	DashboardAMDModulesResourceData,
	AmdResourceSharedValue,
	DashboardForgeResourceData,
	GadgetDirectoryResourceData,
	DashboardConfigAndWRMResourceDependency,
	GadgetData,
	WebResourceName,
} from '@atlassian/jira-dashboard-common/src/types.tsx';
// eslint-disable-next-line jira/import-whitelist
import {
	DASHBOARD_BACKGROUND_SCRIPT_MODULE,
	DASHBOARD_GADGET_MODULE,
	SOURCE_ROUTER,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
// eslint-disable-next-line jira/import-whitelist
import { fetchModules } from '@atlassian/jira-forge-ui-utils/src/utils/fetch-modules/index.tsx';
import type {
	DashboardReactGadgetsResourceData,
	ReactGadgetsResourceSharedValue,
} from '@atlassian/jira-react-gadgets-definition/src/main.tsx';
import {
	createResource,
	useResource,
	type RouteResourceUpdater,
	type UseResourceHookResponse,
} from '@atlassian/react-resource-router';
import {
	createDependentResource,
	withEitherDataOrError,
	type DataOrErrorOrNull,
} from './common/dependent-resources.tsx';
import { getKey } from './common/get-key.tsx';
import {
	RESOURCE_TYPE_DASHBOARD_CONFIG_AND_WRM,
	RESOURCE_TYPE_DASHBOARD_AMD_MODULES,
	RESOURCE_TYPE_DASHBOARD_FORGE,
	RESOURCE_TYPE_DASHBOARD_GADGET_DIRECTORY,
	RESOURCE_TYPE_DASHBOARD_REACT_GADGETS,
} from './constants.tsx';
import getAMDModulesData from './get-amd-modules-data/index.tsx';
import getConfigAndWRMData from './get-config-and-wrm-data/index.tsx';
import getGadgetDirectoryData from './get-gadget-directory-data/index.tsx';
import getReactGadgetsData from './get-react-gadgets-data/index.tsx';
import { gadgetsToSortedReactGadgets, gadgetsToUniqueSortedAmdModules } from './utils.tsx';

export const dashboardConfigAndWRMResource = createResource<DashboardConfigAndWRMResourceData[]>({
	type: RESOURCE_TYPE_DASHBOARD_CONFIG_AND_WRM,
	getKey,
	getData: getConfigAndWRMData,
	maxAge: Infinity,
});

// the type signature of promise and update is different on the useDashboardResource and useMultiDashboardsResource hook
const extractFirstPromise = (
	promise: Promise<DashboardConfigAndWRMResourceData[]> | null,
): Promise<DashboardConfigAndWRMResourceData> | null =>
	promise
		? promise.then((configs: DashboardConfigAndWRMResourceData[]) => configs && configs[0])
		: null;

/**
 * This gets the first dashboard config and wrm resources out of an array of dashboards.
 */
export const useDashboardResource =
	(): UseResourceHookResponse<DashboardConfigAndWRMResourceData> => {
		// the response will have different signature on update, promise and clear etc
		const resourceResponse = useMultiDashboardsResource();
		const { loading, error, data, promise: promiseOfMultiConfigs, ...rest } = resourceResponse;
		const extractFirstUpdate = (
			getNewData: RouteResourceUpdater<DashboardConfigAndWRMResourceData>,
		) => resourceResponse.update(([a]) => [getNewData(a)]);

		// RouteResourceResponseLoading type
		if (loading) {
			return {
				...rest,
				error: null,
				data: null,
				promise: extractFirstPromise(promiseOfMultiConfigs),
				loading: true,
				update: extractFirstUpdate,
			};
		}
		// RouteResourceResponseError type
		if (error) {
			return {
				...rest,
				error,
				data: null,
				promise: extractFirstPromise(promiseOfMultiConfigs),
				loading: false,
				update: extractFirstUpdate,
			};
		}
		// RouteResourceResponseLoaded type
		if (data && !error) {
			return {
				...rest,
				loading: false,
				update: extractFirstUpdate,
				data: data[0],
				error: null,
				promise: extractFirstPromise(promiseOfMultiConfigs),
			};
		}
		// RouteResourceResponseInitial type
		return {
			...rest,
			loading: false,
			error,
			data: null,
			promise: null,
			update: extractFirstUpdate,
		};
	};

export const useMultiDashboardsResource = () =>
	useResource<DashboardConfigAndWRMResourceData[]>(dashboardConfigAndWRMResource);
export const dashboardAMDModulesResource = createDependentResource<
	DashboardAMDModulesResourceData,
	[DashboardConfigAndWRMResourceDependency],
	AmdResourceSharedValue
>({
	type: RESOURCE_TYPE_DASHBOARD_AMD_MODULES,
	getKey,
	depends: [RESOURCE_TYPE_DASHBOARD_CONFIG_AND_WRM],
	mapToSharedValue: withEitherDataOrError<
		[DashboardConfigAndWRMResourceData[]],
		DataOrErrorOrNull<AmdResourceSharedValue>
	>(
		(
			data: Array<{ gadgets: GadgetData[]; preloadedModuleNames: WebResourceName[] }>,
		): AmdResourceSharedValue => {
			const {
				gadgets,
				preloadedModuleNames,
			}: { gadgets: GadgetData[]; preloadedModuleNames: WebResourceName[] } = data.reduce(
				(prev, curr) => ({
					gadgets: prev.gadgets.concat(curr.gadgets),
					preloadedModuleNames: prev.preloadedModuleNames.concat(curr.preloadedModuleNames),
				}),
				{ gadgets: [], preloadedModuleNames: [] },
			);
			const moduleNames = gadgetsToUniqueSortedAmdModules(gadgets);
			const isPreloaded = moduleNames.every((name) => preloadedModuleNames.includes(name));
			return { moduleNames, isPreloaded };
		},
		(x) => x,
	),
	shouldUpdate: ({ sharedValue: a, amdModules }, b) =>
		withEitherDataOrError<[AmdResourceSharedValue, AmdResourceSharedValue], boolean>(
			(prev, next) =>
				next.moduleNames.length > prev.moduleNames.length ||
				next.moduleNames.some((name) => amdModules?.[name] == null),
			() => true,
		)(a, b),
	getData: getAMDModulesData,
	maxAge: Infinity,
	isBrowserOnly: true,
});

export const useDashboardAMDModulesResource = () =>
	useResource<DashboardAMDModulesResourceData>(dashboardAMDModulesResource);

export const dashboardReactGadgetsResource = createDependentResource<
	DashboardReactGadgetsResourceData,
	[DashboardConfigAndWRMResourceDependency],
	ReactGadgetsResourceSharedValue
>({
	type: RESOURCE_TYPE_DASHBOARD_REACT_GADGETS,
	getKey,
	depends: [RESOURCE_TYPE_DASHBOARD_CONFIG_AND_WRM],
	mapToSharedValue: withEitherDataOrError<
		[DashboardConfigAndWRMResourceData[]],
		DataOrErrorOrNull<ReactGadgetsResourceSharedValue>
	>(
		(data): ReactGadgetsResourceSharedValue => {
			const gadgets = data.flatMap((dashboard) => dashboard.gadgets);
			return { reactGadgets: gadgetsToSortedReactGadgets(gadgets) };
		},
		(x) => x,
	),
	shouldUpdate: ({ sharedValue: a, reactGadgetsState }, b) =>
		withEitherDataOrError<
			[ReactGadgetsResourceSharedValue, ReactGadgetsResourceSharedValue],
			boolean
		>(
			(prev, next): boolean =>
				JSON.stringify(next.reactGadgets) !== JSON.stringify(prev.reactGadgets) ||
				(reactGadgetsState != null && Object.values(reactGadgetsState).some((v) => !v)),
			() => true,
		)(a, b),
	getData: getReactGadgetsData,
	maxAge: Infinity,
	isBrowserOnly: true,
});

export const useDashboardReactGadgetsResource = () =>
	useResource<DashboardReactGadgetsResourceData>(dashboardReactGadgetsResource);

export const dashboardForgeResource = createResource<DashboardForgeResourceData>({
	type: RESOURCE_TYPE_DASHBOARD_FORGE,
	getKey,
	getData: async (
		_,
		{ tenantContext: { cloudId, isAnonymous } },
	): Promise<DashboardForgeResourceData> => {
		const modules = await fetchModules({
			cloudId,
			isAnonymous,
			types: [DASHBOARD_GADGET_MODULE, DASHBOARD_BACKGROUND_SCRIPT_MODULE],
			context: {},
			includeHidden: false,
			source: SOURCE_ROUTER,
		});
		return {
			gadgets: modules[DASHBOARD_GADGET_MODULE],
			backgroundScripts: modules[DASHBOARD_BACKGROUND_SCRIPT_MODULE],
		};
	},
	maxAge: Infinity,
});

export const gadgetDirectoryResource = createResource<GadgetDirectoryResourceData | null>({
	type: RESOURCE_TYPE_DASHBOARD_GADGET_DIRECTORY,
	getKey: () => 'gadgetDirectoryItems',
	getData: getGadgetDirectoryData,
	maxAge: Infinity,
	isBrowserOnly: true,
});

export const useGadgetDirectoryResource = () =>
	useResource<GadgetDirectoryResourceData | null>(gadgetDirectoryResource);

export const useDashboardForgeResource = () =>
	useResource<DashboardForgeResourceData>(dashboardForgeResource);
