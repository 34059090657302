import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssueFieldScreensRoute } from '@atlassian/jira-router-routes-admin-pages-screens-routes/src/globalSettingsIssueFieldScreensRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import ScreensPage, { LazyScreensPage } from './ui/spa/admin-pages/screens/screens-page/index.tsx';

export const globalSettingsIssueFieldScreensRouteEntry: Route = createEntry(
	globalSettingsIssueFieldScreensRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'global-admin.issues.screens',
		component: ScreensPage,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyScreensPage],
	}),
);
