/**
 * @generated SignedSource<<bf0da0012aa49b8e49a66d5f57303532>>
 * @relayHash 6c0d088a2d16696755e68a5c62390c1a
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID aaaebd97ac7d32c0bc1f614ef162fa4752704ea53d80fe06e413482483f48be2

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarQuery } from './src_jiraBusinessCalendarQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "aaaebd97ac7d32c0bc1f614ef162fa4752704ea53d80fe06e413482483f48be2",
    "metadata": {},
    "name": "src_jiraBusinessCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
