import React, { type ReactElement, useEffect, useMemo, useState } from 'react';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { SECTION_ITEM_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/section-type.tsx';
import { MENU_ID_PROJECTS } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useGetCurrentUrl } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-get-current-url/index.tsx';
import {
	L1_MENU_ID,
	type LeftSidebarEntryPointConfig,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useSectionItemAnalytics } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-section-item-analytics/index.tsx';
import { useConfigurableMessageAndIconMap } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-configurable-message-and-icon-map/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { ExpandableMenuItemContent, ExpandableMenuItemTrigger } from '@atlassian/navigation-system';
import { fg } from '@atlassian/jira-feature-gating';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout/src/index.tsx';
import { LEFT_SIDEBAR } from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import { RouteEntryPointAdapter } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/route-entry-point-adapter/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../../../common/constants.tsx';
import { Footer } from '../../../common/ui/footer/index.tsx';
import { nav4ProjectsContentEntryPoint } from './async-content/entrypoint.tsx';
import { asyncNav4ProjectsContentViewQuery } from './async-content/old/entrypoint.tsx';

type Nav4ProjectsDefaultProps = {
	actionsOnHover: ReactElement;
	isExpanded: boolean;
	simulateLoading?: boolean; // For testing only
};

export function Nav4ProjectsDefault(props: Nav4ProjectsDefaultProps) {
	const { actionsOnHover, isExpanded, simulateLoading = false } = props;
	const cloudId = useCloudId();
	const currentURL = useGetCurrentUrl();
	const entryPointParamsOld = useMemo(() => ({ cloudId, currentURL }), [cloudId, currentURL]);
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const entryPointParams = useMemo(
		() => ({ tenantContext: { cloudId } }),
		[cloudId],
	) as EntryPointRouteParams;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const leftSidebarEPRef = usePageLayoutEntryPoint(LEFT_SIDEBAR) as LeftSidebarEntryPointConfig;
	const projectsEPRef = leftSidebarEPRef?.entryPoints?.projects;

	const {
		entryPointActions: entryPointActionsOld,
		entryPointReferenceSubject: entryPointReferenceSubjectOld,
	} = useEntryPoint(asyncNav4ProjectsContentViewQuery, entryPointParamsOld);
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		nav4ProjectsContentEntryPoint,
		entryPointParams,
	);

	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	const { formattedMessage, icon } = useConfigurableMessageAndIconMap(L1_MENU_ID.PROJECTS);
	const sendSectionItemAnalyticsEvent = useSectionItemAnalytics();

	// If starting life expanded, make sure to load.
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (fg('blu-5799-jira-software-board-entrypoint-route')) {
			// We intentionally won't re-evaluate this if the route changes.
			if (!projectsEPRef && isExpanded) {
				entryPointActions.load();
			}
		} else if (isExpanded) {
			entryPointActionsOld.load();
		}
	}, [entryPointActions, entryPointActionsOld, projectsEPRef, isExpanded]);

	const menuId = MENU_ID_PROJECTS;

	// Create this component once, then keep using it FOREVER.
	// We don't want to keep switching the implementation as the user does SPA transitions.
	// We set the state this to cater for components that may be an entrypoint so that we don't keep switching between the entryPoint version and the non-entryPoint version
	const [entryPointConfig] = useState(() => {
		const epContainerProps = {
			id: 'nav4-sidebar-projects-default',
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
			errorFallback: 'flag' as const,
			fallback: <SkeletonNavMenuItems />,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			runtimeProps: entryPointParamsOld as never,
		};

		return {
			Component: () => (
				<RouteEntryPointAdapter
					epContainerProps={epContainerProps}
					jiraEPRef={entryPointReferenceSubject}
					nestedEPRef={projectsEPRef}
					placeholderEntryPoint={nav4ProjectsContentEntryPoint}
				/>
			),
		};
	});

	const content = () => {
		if (fg('blu-5799-jira-software-board-entrypoint-route')) {
			return <entryPointConfig.Component />;
		}

		return (
			<JiraEntryPointContainer
				// Data stream, will subscribe to load updates.
				entryPointReferenceSubject={entryPointReferenceSubjectOld}
				// Required props for error JSErrorBoundary.
				id="nav4-sidebar-projects-default"
				packageName={PACKAGE_NAME}
				// Optional props for error JSErrorBoundary.
				errorFallback="flag"
				teamName={TEAM_NAME}
				// Fallback for `Suspense`.
				fallback={<SkeletonNavMenuItems />}
				// Props for the root component.
				runtimeProps={entryPointParamsOld}
			/>
		);
	};

	return (
		<Nav4ExpandableMenuItem
			isExpanded={isExpanded}
			menuId={menuId}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					if (fg('blu-5799-jira-software-board-entrypoint-route')) {
						entryPointActions.load();
					}
					entryPointActionsOld.load();
				}
			}}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					if (fg('blu-5799-jira-software-board-entrypoint-route')) {
						entryPointActions.load();
					}
					entryPointActionsOld.load();

					sendSectionItemAnalyticsEvent({
						menuId: L1_MENU_ID.PROJECTS,
						sectionItem: SECTION_ITEM_PROJECTS,
					});
				}
			}}
		>
			<ExpandableMenuItemTrigger
				interactionName="nav4-sidebar-projects-default"
				actionsOnHover={actionsOnHover}
				// Prevent double-up of accessible name that occurs in icon+text situation.
				aria-label={formattedMessage}
				elemBefore={icon}
				ref={triggerRef}
				testId={getTestId(menuId)}
			>
				{formattedMessage}
			</ExpandableMenuItemTrigger>
			<ExpandableMenuItemContent>
				{simulateLoading ? <SkeletonNavMenuItems /> : content()}
				<Footer />
			</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
}
