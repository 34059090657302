/**
 * @generated SignedSource<<cf6a712202c2ce9b6d303392f6ccbca2>>
 * @relayHash 6f940a27fc0f8c5ed2047a5fc1786947
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c64647109a0254e9abd057d72ab0e0c778a2a6c8658f2ad7820f8d754ae4473e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { HorizontalNavPlansQuery } from './HorizontalNavPlansQuery.graphql';

const node: PreloadableConcreteRequest<HorizontalNavPlansQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c64647109a0254e9abd057d72ab0e0c778a2a6c8658f2ad7820f8d754ae4473e",
    "metadata": {},
    "name": "HorizontalNavPlansQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
