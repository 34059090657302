import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	heading: {
		id: 'polaris-component-collection-create.form.heading',
		defaultMessage: 'Create new roadmap',
		description: 'Heading of create roadmap form',
	},
	description: {
		id: 'polaris-component-collection-create.form.description',
		defaultMessage: 'Curate your discovery projects ideas in a roadmap.',
		description: 'Description of create roadmap form',
	},
	roadmapNameFieldLabel: {
		id: 'polaris-component-collection-create.form.roadmap-name-field-label',
		defaultMessage: 'Name your roadmap',
		description: 'Label for roadmap name field',
	},
	roadmapNameFieldPlaceholder: {
		id: 'polaris-component-collection-create.form.roadmap-name-field-placeholder',
		defaultMessage: 'Roadmap name',
		description: 'Placeholder for roadmap name field',
	},
	projectsSelectFieldLabel: {
		id: 'polaris-component-collection-create.form.projects-select-field-label',
		defaultMessage: 'Select the discovery projects you want to pull ideas from',
		description: 'Label for projects select field',
	},
	filtersLabel: {
		id: 'polaris-component-collection-create.form.filters-label',
		defaultMessage: 'Filter your roadmap ideas',
		description: 'Label for filters',
	},
	filtersDescription: {
		id: 'polaris-component-collection-create.form.filters-description',
		defaultMessage: 'Use global fields to display only certain ideas in your roadmap.',
		description: 'Description for filters, rendered right below the label',
	},
	limitExceededError: {
		id: 'polaris-component-collection-create.form.limit-exceeded-error',
		defaultMessage:
			'You have <b>{ideasCount, plural, one {# idea} other {# ideas}}</b> in your roadmap.',
		description: 'Error message when there are more than maximum amount of ideas in roadmap',
	},
	limitExceededErrorAction: {
		id: 'polaris-component-collection-create.form.limit-exceeded-error-action',
		defaultMessage:
			'Apply filters to have {maxIdeas, plural, one {# idea} other {# ideas}} maximum.',
		description: 'Error message when there are more than maximum amount of ideas in roadmap',
	},
	errorTitle: {
		id: 'polaris-component-collection-create.form.error-title',
		defaultMessage: 'We couldn’t create the roadmap',
		description: 'Flag title to communicate an error.',
	},
	errorDescription: {
		id: 'polaris-component-collection-create.form.error-description',
		defaultMessage: 'Please try again or refresh the page.',
		description: 'Flag description for an error occurring while creating a roadmap.',
	},
	listViewName: {
		id: 'polaris-component-collection-create.form.list-view-name',
		defaultMessage: 'List view',
		description: 'Name of new list view',
	},
	timelineViewName: {
		id: 'polaris-component-collection-create.form.timeline-view-name',
		defaultMessage: 'Timeline view',
		description: 'Name of new timeline view',
	},
	boardViewName: {
		id: 'polaris-component-collection-create.form.board-view-name',
		defaultMessage: 'Board view',
		description: 'Name of new board view',
	},
	matrixViewName: {
		id: 'polaris-component-collection-create.form.matrix-view-name',
		defaultMessage: 'Matrix view',
		description: 'Name of new matrix view',
	},
	emptyNameError: {
		id: 'polaris-component-collection-create.form.empty-name-error',
		defaultMessage: 'Provide a name for your roadmap.',
		description: 'Error message when roadmap name is empty',
	},
	emptyProjectsError: {
		id: 'polaris-component-collection-create.form.empty-projects-error',
		defaultMessage: 'Select at least one project.',
		description: 'Error message when no projects are selected',
	},
	nameTooLongError: {
		id: 'polaris-component-collection-create.form.name-too-long-error',
		defaultMessage: 'Roadmap name is too long.',
		description: 'Error message when roadmap name is too long',
	},
});
