/**
 * @generated SignedSource<<cef87c6c0607ab9dda0a50b56b066430>>
 * @relayHash 6f93355b00354fa9f1cc1d197efd2a21
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID c7b7e9a2930f2e17fda6fda373a6e9eae980d0e6baae0887ad6bfaa02f887efa

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraSoftwareListQuery } from './src_jiraSoftwareListQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraSoftwareListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "c7b7e9a2930f2e17fda6fda373a6e9eae980d0e6baae0887ad6bfaa02f887efa",
    "metadata": {},
    "name": "src_jiraSoftwareListQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
