import React, { useState } from 'react';
import ListBulletedIcon from '@atlaskit/icon/core/list-bulleted';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { ButtonItem } from '@atlaskit/menu';
import Popup from '@atlaskit/popup';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { ViewOptionsList } from './view-options/index.tsx';
import type { ShowMenuItemProps } from './types.tsx';

export function ShowMenuItem({ onChange }: ShowMenuItemProps) {
	const { formatMessage } = useIntl();
	const [isOpen, setIsOpen] = useState(false);

	const onShowMenuItemClick = () => setIsOpen(!isOpen);

	const onShowMenuItemValueChange = (event: Event): void => {
		setIsOpen(false);
		onChange?.(event);
	};

	return (
		<Popup
			isOpen={isOpen}
			onClose={() => setIsOpen(false)}
			content={() => <ViewOptionsList onChange={onShowMenuItemValueChange} />}
			placement="right-start"
			shouldRenderToParent
			trigger={(triggerProps) => (
				<ButtonItem
					{...triggerProps}
					isSelected={isOpen}
					iconBefore={
						<ListBulletedIcon
							label={formatMessage(messages.listBulletedIcon)}
							spacing="spacious"
							color={token('color.icon')}
						/>
					}
					iconAfter={<ChevronRightIcon label={formatMessage(messages.chevronRightIcon)} />}
					onClick={onShowMenuItemClick}
					aria-label={formatMessage(messages.showMenuItemLabel)}
					aria-expanded={isOpen}
					aria-haspopup="true"
				>
					{formatMessage(messages.showMenuItemLabel)}
				</ButtonItem>
			)}
		/>
	);
}
