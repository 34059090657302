/**
 * @generated SignedSource<<dae539cde5e3d05d04cacafb611cdbf4>>
 * @relayHash 469b2539751e203a1f8a7fde1590c429
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 6521f2de2e7a032dcd2a121fbb2a636aae5a4a54be929080a5196484ed4b1f20

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessCalendarEmbedQuery } from './src_jiraBusinessCalendarEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessCalendarEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "6521f2de2e7a032dcd2a121fbb2a636aae5a4a54be929080a5196484ed4b1f20",
    "metadata": {},
    "name": "src_jiraBusinessCalendarEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
