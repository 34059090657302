/**
 * @generated SignedSource<<2f3b9d4fc64843518d2f413fac6a1764>>
 * @relayHash 20a01c51ed7608425451c01dcf8515d1
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 26644e86f3fb036ecee17a24d42c303d425557dcb3803521e5bfae3b4f0162bd

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { ui_jiraCalendarQuery } from './ui_jiraCalendarQuery.graphql';

import isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider from '@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider';

const node: PreloadableConcreteRequest<ui_jiraCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "26644e86f3fb036ecee17a24d42c303d425557dcb3803521e5bfae3b4f0162bd",
    "metadata": {},
    "name": "ui_jiraCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcisjiracalendarreleaseflyoutlazyloadingenabledrelayprovider": isJiraCalendarReleaseFlyoutLazyLoadingEnabled_provider
    }
  }
};

export default node;
