import React from 'react';
import Link from '@atlaskit/link';
import { useIntl } from '@atlassian/jira-intl';
import type { FormatMessage } from '@atlassian/jira-shared-types/src/general.tsx';
import type { Boards, Users, Groups } from '../../model/index.tsx';
import messages from './messages.tsx';

type Props = {
	boards: Boards;
	users: Users;
	groups: Groups;
};

const ContentWithoutBoardsAndSubscriptions = ({
	formatMessage,
}: {
	formatMessage: FormatMessage;
	// eslint-disable-next-line @atlaskit/design-system/use-primitives-text
}) => <p>{formatMessage(messages.noSubscriptionsAndBoards)}</p>;

const ContentWithAssociatedSubscriptions = ({
	formatMessage,
	groups,
	users,
}: {
	formatMessage: FormatMessage;
	groups: Groups;
	users: Users;
}) =>
	(users.length === 1 && groups.length === 0) || (users.length === 0 && groups.length === 1) ? (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>
				{formatMessage(messages.singleSubscription)}
				{users.length === 1 ? <Link href={users[0].href}>{`${users[0].displayName}.`}</Link> : null}
				{groups.length === 1 ? `${groups[0].name}.` : null}
			</p>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.multipleBoardsWarning)}</p>
		</>
	) : (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>
				{formatMessage(messages.multipleSubscriptions, {
					number: users.length + groups.length,
				})}
			</p>
			<ul>
				{users.map(({ displayName, href }, index) => (
					<li key={index}>
						<Link href={href}>{displayName}</Link>
					</li>
				))}
				{groups.map(({ name }, index) => (
					<li key={index}>{name}</li>
				))}
			</ul>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.multipleSubscriptionsWarning)}</p>
		</>
	);

const ContentWithAssociatedBoards = ({
	boards,
	formatMessage,
}: {
	boards: Boards;
	formatMessage: FormatMessage;
}) =>
	boards.length === 1 ? (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>
				{formatMessage(messages.singleBoard)}
				<Link href={boards[0].href}>{`${boards[0].name}.`}</Link>
			</p>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.multipleBoardsWarning)}</p>
		</>
	) : (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>
				{formatMessage(messages.multipleBoards, {
					number: boards.length,
				})}
			</p>
			<ul>
				{boards.map(({ name, href }, index) => (
					<li key={index}>
						<Link href={href}>{name}</Link>
					</li>
				))}
			</ul>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.multipleBoardsWarning)}</p>
		</>
	);

export const Content = ({ boards, groups, users }: Props) => {
	const { formatMessage } = useIntl();
	if (users.length === 0 && groups.length === 0 && boards.length === 0) {
		return <ContentWithoutBoardsAndSubscriptions formatMessage={formatMessage} />;
	}

	return (
		<>
			{/* eslint-disable-next-line @atlaskit/design-system/use-primitives-text */}
			<p>{formatMessage(messages.associatedItems)}</p>
			{boards.length > 0 ? (
				<ContentWithAssociatedBoards boards={boards} formatMessage={formatMessage} />
			) : null}

			{users.length > 0 || groups.length > 0 ? (
				<ContentWithAssociatedSubscriptions
					users={users}
					groups={groups}
					formatMessage={formatMessage}
				/>
			) : null}
		</>
	);
};

export default Content;
