{
  "got-it": [
    {
      "type": 0,
      "value": "Got it"
    }
  ],
  "mercury.email.footer.sent-by": [
    {
      "type": 0,
      "value": "This message was sent to you by Atlassian Focus"
    }
  ],
  "mercury.email.footer.unsubscribe": [
    {
      "type": 0,
      "value": "Unsubscribe"
    }
  ],
  "mercury.focus-areas.arrow-icon.alt": [
    {
      "type": 0,
      "value": "arrow-icon"
    }
  ],
  "mercury.focus-areas.arrow-right-icon.alt": [
    {
      "type": 0,
      "value": "arrow-right-icon"
    }
  ],
  "mercury.focus-areas.calendar-icon.alt": [
    {
      "type": 0,
      "value": "calendar-icon"
    }
  ],
  "mercury.focus-areas.date-changed": [
    {
      "type": 0,
      "value": "Date Changed"
    }
  ],
  "mercury.focus-areas.focus-area.alt": [
    {
      "type": 0,
      "value": "focus-area-icon"
    }
  ],
  "mercury.focus-areas.focus-footer.alt": [
    {
      "type": 0,
      "value": "Atlassian footer"
    }
  ],
  "mercury.focus-areas.focus-icon.alt": [
    {
      "type": 0,
      "value": "Focus area icon"
    }
  ],
  "mercury.focus-areas.for": [
    {
      "type": 0,
      "value": "for"
    }
  ],
  "mercury.focus-areas.heading": [
    {
      "type": 0,
      "value": "You're following "
    },
    {
      "type": 1,
      "value": "total"
    },
    {
      "type": 0,
      "value": " active focus areas, here's the breakdown."
    }
  ],
  "mercury.focus-areas.no-target-date.alt": [
    {
      "type": 0,
      "value": "No target date set"
    }
  ],
  "mercury.focus-areas.status-changed": [
    {
      "type": 0,
      "value": "Status changed"
    }
  ],
  "mercury.focus-areas.view-area": [
    {
      "type": 0,
      "value": "View Focus Area"
    }
  ],
  "mercury.focus.catch.up.link": [
    {
      "type": 0,
      "value": "Jump into Atlassian Focus"
    }
  ],
  "mercury.focus.catch.up.text": [
    {
      "type": 0,
      "value": "Want to catch up on previous updates? "
    },
    {
      "type": 1,
      "value": "link"
    }
  ],
  "mercury.mercury-monthly-digest.message-template.email.subject.no-translate": [
    {
      "type": 1,
      "value": "name"
    },
    {
      "type": 0,
      "value": ", here are your monthly focus area updates"
    }
  ],
  "mercury.message-templates.edit-focus-area-heirarchy-spotlight-body": [
    {
      "type": 0,
      "value": "Whenever you need to make changes to your hierarchy you can do so from settings."
    }
  ],
  "mercury.message-templates.edit-focus-area-heirarchy-spotlight-heading": [
    {
      "type": 0,
      "value": "Edit your focus area hierarchy"
    }
  ]
}