import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { notFoundPageEntrypoint } from '@atlassian/jira-router-routes-not-found-entrypoint/src/ui/error-pages/not-found/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { softwareFormSubmitClassicPublicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareFormSubmitClassicPublicRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { formSubmitPageEntrypoint } from '@atlassian/jira-router-routes-business-entries/src/ui/spa/business/form-submit-public/index.tsx';

export const softwareFormSubmitClassicPublicRouteEntry: Route = createEntry(
	softwareFormSubmitClassicPublicRoute,
	() => ({
		group: 'classic-software',
		ufoName: 'classic-form-submit-public',
		layout: chromelessLayout,
		entryPoint: () => {
			return expVal('jira_forms_public_access_experiment', 'isEnabled', 'false')
				? formSubmitPageEntrypoint
				: notFoundPageEntrypoint;
		},
		// This public route should not include
		// DEPRECATED_DO_NOT_USE_projectDetailsResource as a resource, unless we
		// remove all usages of projectKey.
	}),
);
