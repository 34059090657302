import React, { type ComponentType, useEffect, useState } from 'react';
import CloseIcon from '@atlaskit/icon/core/migration/close--cross';
import { SpotlightTransition, Modal, ModalTransition } from '@atlaskit/onboarding';
import { token } from '@atlaskit/tokens';
import { useHelpPanelLink } from '@atlassian/jira-help-panel-button/src/index.tsx';
import OnboardingIllustration from '@atlassian/jira-illustrations/src/ui/adg4/jira/spots/software/components/plans-review-changes/index.tsx';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { usePageLayoutResize } from '@atlassian/jira-navigation-system/src/index.tsx';
import { IconButton } from '@atlassian/jira-portfolio-3-common/src/icon/index.tsx';
import { FireUiAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import {
	Spotlights,
	useCurrentSpotlight as useCurrentSpotlightNew,
	useCurrentSpotlightOld,
} from '../../controllers/spotlights/index.tsx';
import { FinishFlag } from './flag-finish/index.tsx';
import { messages } from './messages.tsx';
import { FindYourLookSpotlight } from './spotlight-find-your-look/index.tsx';
import { HeartOfPlanSpotlight } from './spotlight-heart-of-plan/index.tsx';
import { ListModeSpotlight } from './spotlight-list-mode/index.tsx';
import { SaveYourChangesSpotlight } from './spotlight-save-your-changes/index.tsx';
import { ShowAndTellSpotlight } from './spotlight-show-and-tell/index.tsx';
import { ViewsSpotlight } from './spotlight-views/index.tsx';
import type { Props } from './types.tsx';
import { isCrossTeamPlanningTemplateExperimentEnabled, useController, useStep } from './utils.tsx';

const IconButtonStyles = {
	width: token('space.300'),
	height: token('space.300'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
};

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;
type SpotlightAction = Exclude<SpotlightProps['actions'], undefined>[number];

export const Onboarding = (props: Props) => {
	const useCurrentSpotlight = getWillShowNav4() ? useCurrentSpotlightNew : useCurrentSpotlightOld;
	const [{ visible }, { init, close }] = useController(props);
	const { formatMessage } = useIntl();
	const [step, { next, start, finish, skip }] = useStep();
	const [
		{ spotlight, currentStepCount, totalStepCount },
		{ reset: resetSpotlight, start: startSpotlight, next: nextSpotlight },
	] = useCurrentSpotlight();
	const { expandLeftSidebar: expandLeftSidebarChangingEveryRender } = usePageLayoutResize();
	const [expandLeftSidebar] = useState(() => expandLeftSidebarChangingEveryRender);
	const openInProductHelp = useHelpPanelLink();

	useEffect(() => {
		init();
	}, [init]);

	// Auto-start
	useEffect(() => {
		if (visible) {
			start();
		}
	}, [start, visible]);

	// Start the spotlight
	useEffect(() => {
		if (step === 'spotlight') {
			startSpotlight();
		}
	}, [step, startSpotlight]);

	// When the spotlight finishes
	useEffect(() => {
		if (spotlight === undefined) {
			next();
		}
	}, [next, spotlight]);

	useEffect(() => {
		if (step === 'spotlight' && spotlight === Spotlights.Views) {
			expandLeftSidebar();
		}
		if (step === 'flag') {
			resetSpotlight();
			close({ silent: true });
		}
		if (step === 'finished') {
			if (isCrossTeamPlanningTemplateExperimentEnabled()) {
				// We must ensure the 'CLOSED' state of the plans onboarding is set on the state
				// when the plans onboarding finishes to allow display of onboardings that follow
				close({ silent: false });
			} else {
				close();
			}
		}
	}, [step, spotlight, resetSpotlight, expandLeftSidebar, close]);

	const spotlightActions = ({ learnMoreURL }: { learnMoreURL: string }): SpotlightAction[] => {
		const nextAction: SpotlightAction = {
			onClick: nextSpotlight,
			text: formatMessage(messages.spotlightNext),
		};

		return learnMoreURL
			? [
					nextAction,
					{
						text: formatMessage(messages.spotlightLearnMore),
						appearance: 'subtle-link',
						href: learnMoreURL,
						target: '_blank',
					},
				]
			: [nextAction];
	};

	const spotlightHeadingAfterElement = (
		<IconButton
			onClick={skip}
			ariaLabel={formatMessage(messages.spotlightCloseLabel)}
			// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
			style={IconButtonStyles}
		>
			<CloseIcon LEGACY_size="small" label="" color="currentColor" />
		</IconButton>
	);

	return (
		<>
			<ModalTransition>
				{step === 'modal' && (
					<Modal
						heading={formatMessage(messages.modalHeadingPlansSpork)}
						header={() => <OnboardingIllustration alt="" />}
						actions={[
							{
								onClick: finish,
								appearance: 'subtle',
								text: formatMessage(messages.modalSkip),
							},
							{
								onClick: next,
								appearance: 'primary',
								text: formatMessage(messages.modalTakeTour),
							},
						]}
					>
						<FireUiAnalytics
							actionSubject="modal"
							action="viewed"
							actionSubjectId="advancedRoadmapsOnboarding"
						/>
						<FormattedMessage {...messages.modalContentParagraphPlansSpork} />
					</Modal>
				)}
			</ModalTransition>
			<SpotlightTransition>
				{step === 'spotlight' && spotlight === Spotlights.HeartOfPlan && (
					<HeartOfPlanSpotlight
						target={Spotlights.HeartOfPlan}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
				{step === 'spotlight' && spotlight === Spotlights.ListMode && (
					<ListModeSpotlight
						target={Spotlights.ListMode}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
				{step === 'spotlight' && spotlight === Spotlights.Views && (
					<ViewsSpotlight
						target={Spotlights.Views}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
				{step === 'spotlight' && spotlight === Spotlights.ShowAndTell && (
					<ShowAndTellSpotlight
						target={Spotlights.ShowAndTell}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
				{step === 'spotlight' && spotlight === Spotlights.SaveYourChanges && (
					<SaveYourChangesSpotlight
						target={Spotlights.SaveYourChanges}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
				{step === 'spotlight' && spotlight === Spotlights.FindYourLook && (
					<FindYourLookSpotlight
						target={Spotlights.FindYourLook}
						actionsBeforeElement={`${currentStepCount}/${totalStepCount}`}
						actions={spotlightActions}
						headingAfterElement={spotlightHeadingAfterElement}
					/>
				)}
			</SpotlightTransition>
			{step === 'flag' && <FinishFlag onDismiss={next} openInProductHelp={openInProductHelp} />}
		</>
	);
};
