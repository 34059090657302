import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { workflowIssuesResource } from '@atlassian/jira-router-resources-business-board-workflow-issues/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessBoardEmbedRoute } from '@atlassian/jira-router-routes-business-board-routes/src/jiraBusinessBoardEmbedRoute.tsx';
import { boardEmbedPageEntryPoint } from './ui/board-embed/index.tsx';

export const jiraBusinessBoardEmbedRouteEntry = createEntry(jiraBusinessBoardEmbedRoute, () => ({
	group: 'classic-business',
	ufoName: 'jwm.jwm-board-embed',
	layout: chromelessLayout,
	skeleton: ThemedLazySkeleton,
	resources: [...getProjectViewResources(), workflowIssuesResource],
	entryPoint: () => boardEmbedPageEntryPoint,
}));
