import React from 'react';
import type PlanTimelineType from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

/**
 * Plan Timeline page
 */
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const LazyPlanTimeline = lazyForPaint<typeof PlanTimelineType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-timeline", jiraSpaEntry: "async-plan-timeline" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline/index-old.tsx'
			),
		),
	{ ssr: false },
);

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default () => <LazyPage Page={LazyPlanTimeline} pageId="plan-timeline" shouldShowSpinner />;
