import React, { useEffect, useMemo, useState } from 'react';
import {
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	CUSTOMER_SERVICE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ROUTE_NAMES_POLARIS_IDEAS,
	ROUTE_GROUPS_CLASSIC_SOFTWARE,
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_SERVICEDESK_REDIRECT,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { V4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/nav-state.tsx';
import { SIDEBAR } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-container.tsx';
import {
	GLOBAL,
	CONTAINER,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/navigation-layer.tsx';
import { getNav4Rollout } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-nav4-rollout/index.tsx';
import {
	NavigateBackProvider,
	useNavigateBack,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-back-button/src/controllers/navigate-back-context/index.tsx';
import AsyncSidebarContent from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/src/async.tsx';
import { SidebarContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/src/index.tsx';
import AsyncBoardSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-board/src/ui/index.tsx';
import { Skeleton as BoardSettingsSkeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-board/src/ui/skeleton/index.tsx';
import { useGlobalAppsSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-apps/src/controllers/global-settings/index.tsx';
import AsyncGlobalAppsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-apps/src/ui/index.tsx';
import AsyncGlobalIssuesSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-issues/src/ui/index.tsx';
import AsyncGlobalProductsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-products/src/ui/index.tsx';
import AsyncGlobalProjectsSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-projects/src/ui/index.tsx';
import { useGlobalSystemSettingsConnectItemActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-system/src/controllers/global-settings/index.tsx';
import AsyncGlobalSystemSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-system/src/ui/index.tsx';
import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-help-center/src/ui/index.tsx';
import AsyncPersonalSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-personal/src/ui/index.tsx';
import AsyncPlanSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-plan/src/ui/index.tsx';
import AsyncBusinessCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-business-company-managed/src/ui/index.tsx';
import AsyncBusinessTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-business-team-managed/src/ui/index.tsx';
import { Skeleton as ProjectSettingsSkeleton } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/common/ui/skeleton/index.tsx';
import { useIsConnectAppActive as useIsProjectSettingsConnectAppActive } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-common/src/controllers/is-connect-app-active/index.tsx';
import AsyncJpdProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-product-discovery/src/ui/index.tsx';
import { AsyncJsmCmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-service-desk-company-managed/src/index.tsx';
import { AsyncJsmTmpProjectSettingsSidebar } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-service-desk-team-managed/src/index.tsx';
import AsyncSoftwareCompanyManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-software-company-managed/src/ui/index.tsx';
import AsyncSoftwareTeamManagedProjectSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-project-software-team-managed/src/ui/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/common/constants.tsx';
import { getRouterState } from '@atlassian/react-resource-router';
import {
	addRouteListener,
	createRouterSelector,
} from '@atlassian/react-resource-router/src/controllers/router-store';
import type { RouterState } from '@atlassian/react-resource-router/src/controllers/router-store/types';
import { useMaybeConfigParam } from '@atlassian/jira-software-board-settings-rapidboard-config-common/src/services/use-config-param/index.tsx';

// Export this for unit testing
export const SidebarContentComponent = componentWithCondition(
	() =>
		// eslint-disable-next-line jira/ff/inline-expVal-params -- ESLint rule doesn't understand the array type
		expVal<string[]>('jira_nav4_hot_113350_changes', 'keys', []).includes(
			'blu-5991-sync-load-nav4-sidebar',
		),
	SidebarContent,
	AsyncSidebarContent,
);

const entitySettingsSidebarAnalyticsAttributes = {
	navigationLayer: CONTAINER,
};

const ProjectSettingsSidebarSelector = () => {
	const { data: project, loading, error } = useProjectContext();

	let sidebar = null;
	if (loading) {
		sidebar = <ProjectSettingsSkeleton />;
	}
	if (error) {
		throw error;
	}

	if (project?.projectType === CORE_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncBusinessTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncBusinessCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === SOFTWARE_PROJECT) {
		sidebar = project.simplified ? (
			<AsyncSoftwareTeamManagedProjectSettingsSidebar project={project} />
		) : (
			<AsyncSoftwareCompanyManagedProjectSettingsSidebar project={project} />
		);
	}

	if (project?.projectType === PRODUCT_DISCOVERY_PROJECT) {
		sidebar = <AsyncJpdProjectSettingsSidebar project={project} />;
	}

	if (
		project?.projectType === SERVICE_DESK_PROJECT ||
		project?.projectType === CUSTOMER_SERVICE_PROJECT
	) {
		sidebar = project.simplified ? (
			<AsyncJsmTmpProjectSettingsSidebar project={project} />
		) : (
			<AsyncJsmCmpProjectSettingsSidebar project={project} />
		);
	}

	if (!sidebar) {
		throw new Error(
			`No project settings sidebar found for the project type: ${project?.projectType}`,
		);
	}

	return (
		<ContextualAnalyticsData
			containerId={project ? String(project.projectId) : undefined}
			containerType="project"
			attributes={entitySettingsSidebarAnalyticsAttributes}
		>
			{sidebar}
		</ContextualAnalyticsData>
	);
};

const BoardSettingsSidebar = () => {
	const { data: project, loading, error } = useProjectContext();

	if (loading) {
		return <BoardSettingsSkeleton />;
	}

	if (error) {
		throw error;
	}

	return (
		<ContextualAnalyticsData
			containerId={project ? String(project.projectId) : undefined}
			containerType="project"
			attributes={entitySettingsSidebarAnalyticsAttributes}
		>
			<AsyncBoardSettingsSidebar />
		</ContextualAnalyticsData>
	);
};
const REDIRECT_ROUTES_NAMES = new Set([
	ROUTE_NAMES_BROWSE_PROJECT_REDIRECT,
	ROUTE_NAMES_SERVICEDESK_REDIRECT,
]);

const getSidebarForRoute = (
	state: RouterState,
	{
		configParam,
		shouldUseJSM,
		isGlobalAppsSettingsConnectItemActive,
		isGlobalSystemSettingsConnectItemActive,
		isProjectSettingsConnectAppActive,
	}: {
		configParam: string;
		shouldUseJSM: boolean;
		isGlobalAppsSettingsConnectItemActive: boolean;
		isGlobalSystemSettingsConnectItemActive: boolean;
		isProjectSettingsConnectAppActive: boolean;
	},
) => {
	const sidebarId = state.route.navigation?.sidebarId;
	const pathname = state.location.pathname;

	switch (sidebarId) {
		case SIDEBAR_ID.PLAN_SETTINGS:
			return <AsyncPlanSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_APPS_SETTINGS:
			if (!pathname.includes('/discover')) {
				return <AsyncGlobalAppsSettingsSidebar />;
			}
			break;
		case SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS:
			return <AsyncGlobalProjectsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS:
			return <AsyncGlobalIssuesSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS:
			if (
				pathname.includes('/jira-service-management-chatops-and-video-tools') &&
				shouldUseJSM &&
				fg('fix_chatops_new_nav_bug')
			) {
				break;
			}
			return <AsyncGlobalProductsSettingsSidebar />;
		case SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS:
			return <AsyncGlobalSystemSettingsSidebar />;
		case SIDEBAR_ID.PERSONAL_SETTINGS:
			return <AsyncPersonalSettingsSidebar />;
		case SIDEBAR_ID.HELP_CENTER_SETTINGS:
			return <AsyncHelpCenterSettingsSidebar />;
		case SIDEBAR_ID.PROJECT_SETTINGS:
			return <ProjectSettingsSidebarSelector />;
		default:
			break;
	}

	if (
		(state.route.name === ROUTE_NAMES_POLARIS_IDEAS && state.match.params.section === 'settings') ||
		isProjectSettingsConnectAppActive
	) {
		return <ProjectSettingsSidebarSelector />;
	}

	if (
		state.route.group === ROUTE_GROUPS_CLASSIC_SOFTWARE &&
		BOARD_SETTINGS_CONFIG_QUERY_PARAM_VALUES.has(configParam) &&
		fg('simplified-boards-milestone-2_07hbk')
	) {
		return <BoardSettingsSidebar />;
	}

	if (isGlobalAppsSettingsConnectItemActive) {
		return <AsyncGlobalAppsSettingsSidebar />;
	}
	if (isGlobalSystemSettingsConnectItemActive) {
		return <AsyncGlobalSystemSettingsSidebar />;
	}
};

export const isNonRedirectRouteTest = (state: RouterState) => {
	return !fg('jira_nav4_eap_drop_2') || !REDIRECT_ROUTES_NAMES.has(state.route.name);
};

export const useIsNonRedirectRoute = createRouterSelector(isNonRedirectRouteTest);

export const InternalSidebarSelector = () => {
	const { saveCurrentRoute } = useNavigateBack();
	const { isActive: isGlobalAppsSettingsConnectItemActive } =
		useGlobalAppsSettingsConnectItemActive();
	const { isActive: isGlobalSystemSettingsConnectItemActive } =
		useGlobalSystemSettingsConnectItemActive();
	const { isActive: isProjectSettingsConnectAppActive } = useIsProjectSettingsConnectAppActive();
	const { shouldUseJSM } = useOgTenantInfo();
	const isNonRedirectRoute = useIsNonRedirectRoute();
	const configParam = useMaybeConfigParam() || '';
	const [sidebar, setSidebar] = useState<React.ReactNode | null>(
		getSidebarForRoute(getRouterState(), {
			configParam,
			shouldUseJSM,
			isGlobalAppsSettingsConnectItemActive,
			isGlobalSystemSettingsConnectItemActive,
			isProjectSettingsConnectAppActive,
		}),
	);

	useEffect(() => {
		return addRouteListener((state) => {
			setSidebar(
				getSidebarForRoute(state, {
					configParam: configParam || state.query.config || state.match.params.config || '',
					isGlobalAppsSettingsConnectItemActive,
					isGlobalSystemSettingsConnectItemActive,
					isProjectSettingsConnectAppActive,
					shouldUseJSM,
				}),
			);
		});
	}, [
		configParam,
		isGlobalAppsSettingsConnectItemActive,
		isGlobalSystemSettingsConnectItemActive,
		isProjectSettingsConnectAppActive,
		shouldUseJSM,
	]);

	if (sidebar) {
		return sidebar;
	}

	if (isNonRedirectRoute) {
		saveCurrentRoute();
	}

	// Main nav (with "Your work", Starred, Recent items etc.)
	return <SidebarContentComponent />;
};

export const SidebarSelector = () => {
	const attributes = useMemo(
		() => ({
			navigationLayer: GLOBAL,
			navigationContainer: SIDEBAR,
			navState: V4,
			stage: getNav4Rollout().stage,
		}),
		[],
	);

	return (
		<NavigateBackProvider>
			<ContextualAnalyticsData attributes={attributes}>
				<InternalSidebarSelector />
			</ContextualAnalyticsData>
		</NavigateBackProvider>
	);
};
