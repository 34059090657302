import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { rapidboardUserReportsOverviewRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/rapidboardUserReportsOverviewRoute.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { getRapidboardResources } from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { reportsOverviewPageEntryPoint as reportsOverviewPageEntry } from '@atlassian/jira-reports-overview-page/entrypoint.tsx';

const reportsOverviewPageEntryPoint = createPageEntryPoint({
	main: reportsOverviewPageEntry,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const rapidboardUserReportsOverviewRouteEntry = createEntry(
	rapidboardUserReportsOverviewRoute,
	() => ({
		group: 'classic-software',
		entryPoint() {
			return fg('reports-overview-page-migration') ? reportsOverviewPageEntryPoint : undefined;
		},
		layout: genericProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: classicBoardSidebars,
			horizontal: AsyncHorizontalNavJira,
			onlyShowHorizontalOnNav4: true,
		},
		resources: [
			...getNavigationResources(),
			incidentExperimentTraitResource,
			horizontalNavJiraResource,
			...getUserBoardNavigationResources(),
			...getRapidboardResources(),
		],
		ufoName: 'reports-overview',
	}),
);
