/**
 * @generated SignedSource<<284832646cc319bb3d1facb547725add>>
 * @relayHash a91cd5c2a931539de9b545dcf90944ae
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1e71c7868588f386662ed1dcddbc480fa973cab59671560c9a0ae205bcdb4059

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewCalendarQuery } from './src_jiraBusinessOverviewCalendarQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewCalendarQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1e71c7868588f386662ed1dcddbc480fa973cab59671560c9a0ae205bcdb4059",
    "metadata": {},
    "name": "src_jiraBusinessOverviewCalendarQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
