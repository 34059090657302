import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/projectsDirectoryRoute.tsx';
import { ProjectsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { ProjectsDirectoryPageEntryPoint } from './common/ProjectsDirectoryPageEntryPoint.tsx';

export const projectsDirectoryRouteEntry: Route = createEntry(projectsDirectoryRoute, () => ({
	group: 'directories',
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-projects'),
	entryPoint() {
		return ProjectsDirectoryPageEntryPoint;
	},
	skeleton: ProjectsDirectorySkeleton,
	navigation: {
		menuId: MENU_ID.PROJECTS,
	},
	resources: [...getNavigationResources()],
	ufoName: 'projects-directory-v3',
}));
