import React from 'react';
import { useFlagsService } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import PlanTrashAsync from '@atlassian/jira-portfolio-3-plan-trash/src/async.tsx';
import { useRouterActions } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

type Props = {
	planId: number;
	setClosed: () => void;
	planTitle: string;
};

export const TrashPlanModal = ({ planId, setClosed, planTitle }: Props) => {
	const { showFlag } = useFlagsService();
	const { push } = useRouterActions();
	const { formatMessage } = useIntl();

	const onComplete = () => {
		push('/jira/plans');
		setClosed();
	};

	const onError = () => {
		setClosed();
		showFlag({
			id: 'trashPlanError',
			type: 'error',
			title: messages.errorTitle,
			description: formatMessage(messages.errorDescription, { planTitle }),
			messageId: 'jet-plan-action-menu.ui.trash-modal.plan-trash-async',
			messageType: 'transactional',
		});
	};

	return (
		<PlanTrashAsync
			planId={planId}
			onCancel={setClosed}
			onComplete={onComplete}
			onError={onError}
			fallback={null}
		/>
	);
};
