import {
	resources as opsReportsResources,
	opsReportsPermissionResource,
} from '@atlassian/eoc-ops-reports-common/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { productsJsmGlobalOpsReportsRoute } from '@atlassian/jira-router-routes-products-jsm-global-ops-reports-routes/src/productsJsmGlobalOpsReportsRoute.tsx';
import { LazyOpsReportsRedirect, GlobalOpsReports } from './ui/eoc/ops-reports/index.tsx';

export const productsJsmGlobalOpsReportsRouteEntry = createEntry(
	productsJsmGlobalOpsReportsRoute,
	() => ({
		group: 'global-settings',
		component: GlobalOpsReports,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...opsReportsResources(),
			opsReportsPermissionResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyOpsReportsRedirect],
	}),
);
