import { useCallback, useMemo } from 'react';
import { CoordinationClient } from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';

export const useCoordinationClient = () => {
	const epMessageId = 'jira_rovo_video_modal_ga';

	const { cloudId } = useTenantContext();
	const { data: orgId } = useOrgId();

	const coordinationClient = useMemo(
		() => new CoordinationClient(cloudId, '/gateway/api', false, orgId, 'engagement'),
		[cloudId, orgId],
	);

	const start = useCallback(async () => {
		if (!epMessageId) return false;

		return coordinationClient.start(epMessageId);
	}, [epMessageId, coordinationClient]);

	const stop = useCallback(() => {
		if (!epMessageId) return false;

		return coordinationClient.stop(epMessageId);
	}, [epMessageId, coordinationClient]);

	return {
		coordinationClient,
		start,
		stop,
	};
};
