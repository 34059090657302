import { resources as integrationAutomationResources } from '@atlassian/eoc-integration-automation/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalIntegrationCreateRoute } from '@atlassian/jira-router-routes-eoc-integration-automation-routes/src/globalIntegrationCreateRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { IntegrationAutomation } from './ui/IntegrationAutomation.tsx';
import { IntegrationCreate } from './ui/IntegrationCreate.tsx';
import { LazyIntegrationAutomation } from './ui/async.tsx';

export const globalIntegrationCreateRouteEntry: Route = createEntry(
	globalIntegrationCreateRoute,
	() => ({
		group: 'eoc',
		basePath: '/jira/settings/products/ops',
		component: IntegrationAutomation,
		slot: IntegrationCreate,
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			integrationAutomationResources.configuredIntegrationsResource,
			integrationAutomationResources.integrationListResource,
			integrationAutomationResources.userAccessRightsResource,
		],
		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
			horizontal: AsyncHorizontalOperationsConfigurationNav,
			onlyShowHorizontalOnNav4: true,
		},
		forPaint: [
			LazyHorizontalOperationsConfigurationNav,
			LazyAtlassianNavigation,
			LazyIntegrationAutomation,
		],
	}),
);
