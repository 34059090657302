import omit from 'lodash/omit';
import type { Action } from '@atlassian/react-sweet-state';
import type { Props, State } from '../../types.tsx';
import { expandSectionForSelectedCollection } from '../expand-section-for-selected-collection/index.tsx';
import { transformRemoteNavigationCollection, normalizeError } from '../utils.tsx';

// This is a temporary workaround for fetching & displaying
// a single collection in the new navigation
// This action will be removed and replaced with already implemented
// loadCollections when collections start supporting "Recents"
export const loadCollection =
	(collectionUUID: string): Action<State, Props> =>
	async (
		{ setState, getState, dispatch },
		{ navigationCollectionsRemote, cloudId, onFailure, onSuccess },
	) => {
		if (
			!cloudId ||
			!navigationCollectionsRemote ||
			!navigationCollectionsRemote.fetchCollection ||
			getState().collections.isLoading
		) {
			return;
		}

		const cachedCollection = getState().collectionsCache[collectionUUID];
		if (cachedCollection) {
			setState({
				collections: {
					isLoading: false,
					initialized: true,
					error: null,
					data: [{ ...cachedCollection }],
				},
			});
			return;
		}

		setState({
			collections: {
				isLoading: true,
				initialized: true,
				error: null,
				data: [],
			},
		});

		try {
			const data = await navigationCollectionsRemote.fetchCollection(collectionUUID);
			const navigationCollection = transformRemoteNavigationCollection(data);

			setState({
				collectionsCache: {
					...getState().collectionsCache,
					[collectionUUID]: { ...navigationCollection },
				},
				collections: {
					isLoading: false,
					initialized: true,
					error: null,
					data: [{ ...navigationCollection }],
				},
			});

			dispatch(expandSectionForSelectedCollection());
			onSuccess?.('loadCollections');
		} catch (maybeError: unknown) {
			const error = normalizeError(maybeError);

			setState({
				collectionsCache: omit(getState().collectionsCache, collectionUUID),
				collections: {
					isLoading: false,
					initialized: true,
					error,
					data: [],
				},
			});

			onFailure?.(error, 'loadCollections');
		}
	};
