import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { performPostRequest } from '@atlassian/jira-fetch/src/utils/requests.tsx';
import { QUERY } from './gql.tsx';
import type { RovoEntitlementGraphQLResponse } from './types.tsx';

type RovoEntitlementData = {
	isRovoEnabled: boolean | null;
};

export const fetchRovoEnablement = async (cloudId: string): Promise<RovoEntitlementData> => {
	try {
		const response = await performPostRequest<RovoEntitlementGraphQLResponse>(
			'/gateway/api/graphql',
			{
				body: JSON.stringify({
					query: QUERY,
					variables: {
						cloudId,
					},
				}),
				headers: {
					'content-type': 'application/json',
				},
			},
		);

		// handle GraphQL errors
		if (response.errors) {
			fireErrorAnalytics({
				meta: {
					id: 'navigationResource',
					packageName: 'jiraRovoVideoModal',
					teamName: 'luna-growth',
				},
				error: new Error(
					`RovoEntitlement Error. ${response.errors.map((error) => error.message).join('; ')}; IsDataEmpty=${response.data?.jira === null || response.data?.jira === undefined}`,
				),
				sendToPrivacyUnsafeSplunk: true,
			});
		}

		return {
			isRovoEnabled: !!response.data?.jira?.isRovoEnabled,
		};
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'navigationResource',
				packageName: 'jiraRovoVideoModal',
				teamName: 'luna-growth',
			},
			error,
			sendToPrivacyUnsafeSplunk: true,
		});
	}

	return {
		isRovoEnabled: null,
	};
};
