/**
 * @generated SignedSource<<9a7126d53bd0b629a7a67762e0d3958e>>
 * @relayHash 31fac692ef72ff593338806bf0a10ff3
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 61ddc71504e4e979ef23a3e8e70b5dce57e319fde040502585aae0a62d0855f7

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type JiraProjectType = "BUSINESS" | "CUSTOMER_SERVICE" | "PRODUCT_DISCOVERY" | "SERVICE_DESK" | "SOFTWARE" | "%future added value";
export type ThemeSetterQuery$variables = {
  cloudId: string;
  projectIdOrKey: string;
};
export type ThemeSetterQuery$data = {
  readonly jira: {
    readonly mediaClientId: string | null | undefined;
    readonly mediaExternalEndpointUrl: string | null | undefined;
  };
  readonly jira_projectByIdOrKey: {
    readonly background: {
      readonly " $fragmentSpreads": FragmentRefs<"ThemeSetterBackground">;
    } | null | undefined;
    readonly projectId: string | null | undefined;
    readonly projectType: JiraProjectType | null | undefined;
  } | null | undefined;
};
export type ThemeSetterQuery = {
  response: ThemeSetterQuery$data;
  variables: ThemeSetterQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectIdOrKey"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "concreteType": "JiraQuery",
  "kind": "LinkedField",
  "name": "jira",
  "plural": false,
  "selections": [
    {
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "mediaClientId"
    },
    {
      "args": (v2/*: any*/),
      "kind": "ScalarField",
      "name": "mediaExternalEndpointUrl"
    }
  ]
},
v4 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "idOrKey",
    "variableName": "projectIdOrKey"
  }
],
v5 = {
  "kind": "ScalarField",
  "name": "projectId"
},
v6 = {
  "kind": "ScalarField",
  "name": "projectType"
},
v7 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "ThemeSetterQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v3/*: any*/),
        "action": "THROW",
        "path": "jira"
      },
      {
        "args": (v4/*: any*/),
        "concreteType": "JiraProject",
        "kind": "LinkedField",
        "name": "jira_projectByIdOrKey",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "name": "background",
            "plural": false,
            "selections": [
              {
                "kind": "FragmentSpread",
                "name": "ThemeSetterBackground"
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ThemeSetterQuery",
    "selections": [
      (v3/*: any*/),
      {
        "args": (v4/*: any*/),
        "concreteType": "JiraProject",
        "kind": "LinkedField",
        "name": "jira_projectByIdOrKey",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "LinkedField",
            "name": "background",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isJiraActiveBackgroundDetailsResult"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "colorValue"
                  }
                ],
                "type": "JiraColorBackground"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "gradientValue"
                  }
                ],
                "type": "JiraGradientBackground"
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "concreteType": "JiraCustomBackground",
                    "kind": "LinkedField",
                    "name": "customBackground",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "kind": "ScalarField",
                        "name": "mediaApiFileId"
                      },
                      {
                        "args": [
                          {
                            "kind": "Literal",
                            "name": "durationInSeconds",
                            "value": 900
                          }
                        ],
                        "kind": "ScalarField",
                        "name": "mediaReadToken",
                        "storageKey": "mediaReadToken(durationInSeconds:900)"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "sourceType"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "sourceIdentifier"
                      },
                      {
                        "kind": "ScalarField",
                        "name": "altText"
                      }
                    ]
                  }
                ],
                "type": "JiraMediaBackground"
              }
            ]
          },
          (v7/*: any*/)
        ]
      }
    ]
  },
  "params": {
    "id": "61ddc71504e4e979ef23a3e8e70b5dce57e319fde040502585aae0a62d0855f7",
    "metadata": {},
    "name": "ThemeSetterQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9aecad4f77f18db864eecfea006e7963";

import { PreloadableQueryRegistry } from 'relay-runtime';
PreloadableQueryRegistry.set(node.params.id, node);

export default node;
