import { useMemo } from 'react';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/types.tsx';
import { findItemAndStack } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/find-item-stack/index.tsx';
import { getLinks } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/get-links/index.tsx';
import { transformLink } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-connect/src/common/utils/transform-link/index.tsx';
import { useNavigationGlobalResource } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-global-common/src/controllers/navigation-global-resource/index.tsx';
import type { SidebarConfigData } from '@atlassian/jira-navigation-types/src/types.tsx';
import { useConnectGeneralResource } from '@atlassian/jira-router-resources-connect-general/src/index.tsx';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import { useRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-route/index.tsx';

const getItems = (d: SidebarConfigData) =>
	getLinks(d, ['admin_plugins_menu'], { skipFilter: true })
		.filter((v) => !['upm_section', 'jpo-ws-admin'].includes(v.id)) // remove sections also statically defined
		.map(transformLink) // Apply transformation to each link
		.filter(Boolean); // Remove falsy values

export const useGlobalSettingsConnectItems = () => {
	const { data, loading, error } = useNavigationGlobalResource();

	const items: ConnectDataItem[] | null = useMemo(() => data && getItems(data), [data]);

	return {
		data: items,
		loading,
		error,
	};
};

export const APPS_SETTINGS_CONNECT_APP_MODULE_TYPES = new Set<string>([
	'configurePage',
	'postInstallPage',
]);

export const useIsAppsSettingsConnectAppModule = () => {
	const { data, loading, error } = useConnectGeneralResource();

	return {
		isActive: APPS_SETTINGS_CONNECT_APP_MODULE_TYPES.has(data?.moduleType),
		loading,
		error,
	};
};

export const useGlobalAppsSettingsConnectItemActive = () => {
	const location = useLocation();
	const route = useRoute();

	const { data, loading, error } = useGlobalSettingsConnectItems();
	const {
		isActive: isAppsSettingsConnectAppModuleActive,
		loading: isAppsSettingsConnectAppModuleLoading,
		error: isAppsSettingsConnectAppModuleError,
	} = useIsAppsSettingsConnectAppModule();

	const isActive = useMemo(
		() => Boolean(route && data && findItemAndStack(data, route.name, location)?.selected),
		[data, route, location],
	);

	return {
		isActive: isActive || isAppsSettingsConnectAppModuleActive,
		loading: loading || isAppsSettingsConnectAppModuleLoading,
		error: error || isAppsSettingsConnectAppModuleError,
	};
};
