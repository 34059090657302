import React, { createContext, useCallback, useContext, useMemo } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import type { displaySettingsProviderQuery } from '@atlassian/jira-relay/src/__generated__/displaySettingsProviderQuery.graphql.ts';
import type {
	ViewOptionsType,
	ViewOptionsListType,
} from '../show-menu-item/view-options/types.tsx';
import { ViewOptions } from '../show-menu-item/view-options/constants.tsx';
import messages from '../show-menu-item/messages.tsx';
import { useUpdateNavigationCustomisation } from '../../services/update-navigation-customisation.tsx';
import { useOwnerAri, useSidebarSettingsAri } from '../../services/ari/use-ari.tsx';
import type {
	DisplaySettingsProperties,
	DisplaySettingsContextType,
	DisplaySettingsProviderProps,
} from './types.tsx';

import { DisplaySettingsPropertyKeys, DisplaySettingsScope } from './constants.tsx';

// Function to retrieve the list of view options based on the contextName.
// Currently, supports only 'projects' context
const useViewOptionsList = (contextName: string): ViewOptionsListType => {
	const { formatMessage } = useIntl();

	if (contextName === DisplaySettingsScope.PROJECTS) {
		return [
			{
				label: formatMessage(messages.starredAndRecentProjectsLabel),
				value: ViewOptions.STARRED_AND_RECENT,
			},
			{
				label: formatMessage(messages.starredProjectsLabel),
				value: ViewOptions.STARRED,
			},
		];
	}
	return [];
};

const DisplaySettingsContext = createContext<DisplaySettingsContextType | undefined>(undefined);

// Provider component to wrap parts of the sidebar that need access to display settings
// https://developer.atlassian.com/platform/atlassian-resource-identifier/resource-owners/registry/#ati:cloud:jira:navigation-settings
// settingScope is used as {navigationSettingScope}
export const DisplaySettingsProvider = ({
	settingScope,
	children,
}: DisplaySettingsProviderProps) => {
	const entityAri = useSidebarSettingsAri(settingScope);
	const ownerAri = useOwnerAri();
	const { updateNavigationCustomisation } = useUpdateNavigationCustomisation(settingScope);

	const navigationCustomisationData = useLazyLoadQuery<displaySettingsProviderQuery>(
		graphql`
			query displaySettingsProviderQuery($entityAri: ID!, $ownerAri: ID!) {
				settings_navigationCustomisation(entityAri: $entityAri, ownerAri: $ownerAri) {
					properties {
						nodes {
							key
							value
						}
					}
				}
			}
		`,
		useMemo(() => ({ entityAri, ownerAri }), [entityAri, ownerAri]),
	);

	const fetchedViewOptionValue =
		navigationCustomisationData?.settings_navigationCustomisation?.properties?.nodes?.find(
			(node) => node?.key === DisplaySettingsPropertyKeys.VIEW_OPTION,
		)?.value;

	const selectedViewOption: ViewOptionsType =
		fetchedViewOptionValue === ViewOptions.STARRED_AND_RECENT ||
		fetchedViewOptionValue === ViewOptions.STARRED
			? fetchedViewOptionValue
			: ViewOptions.STARRED_AND_RECENT;

	// Complete navigation customisation object to allow for sparse updates
	const navigationCustomisation = useMemo<DisplaySettingsProperties>(
		() => ({ VIEW_OPTION: selectedViewOption }),
		[selectedViewOption],
	);

	const setSelectedViewOption = useCallback(
		(viewOption: ViewOptionsType) => {
			updateNavigationCustomisation({ ...navigationCustomisation, VIEW_OPTION: viewOption });
		},
		[updateNavigationCustomisation, navigationCustomisation],
	);

	const viewOptionsList: ViewOptionsListType = useViewOptionsList(settingScope);

	return (
		<DisplaySettingsContext.Provider
			value={{
				viewOptionsList,
				selectedViewOption,
				setSelectedViewOption,
			}}
		>
			{children}
		</DisplaySettingsContext.Provider>
	);
};

export const useDisplaySettings = () => {
	const context = useContext(DisplaySettingsContext);
	if (!context) {
		throw new Error('useDisplaySettings must be used within a DisplaySettingsProvider');
	}
	return context;
};
