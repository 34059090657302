import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsFormDataConnectionsRoute } from '@atlassian/jira-router-routes-admin-pages-proforma-routes/src/globalSettingsFormDataConnectionsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import JiraAdminProformaDataConnectionsEntryPoint from '@atlassian/jira-admin-proforma-data-connections/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import ProformaJiraDataConnectionsPage, {
	LazyProformaJiraDataConnectionsPage,
} from './ui/spa/admin-pages/proforma/data-connections/index.tsx';

const jiraAdminProformaDataConnectionsPageEntryPoint = createPageEntryPoint({
	main: JiraAdminProformaDataConnectionsEntryPoint,
	topNavigationMenuId: MENU_ID.SETTINGS,
});

export const globalSettingsFormDataConnectionsRouteEntry: Route = createEntry(
	globalSettingsFormDataConnectionsRoute,
	() => ({
		group: 'global-settings',
		component: ProformaJiraDataConnectionsPage,
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyProformaJiraDataConnectionsPage],

		entryPoint() {
			return fg('jira-data-connections-without-mobx')
				? jiraAdminProformaDataConnectionsPageEntryPoint
				: undefined;
		},
	}),
);
