/**
 * @generated SignedSource<<4e2516a7bb61c9979ea7d4dc7fbc5768>>
 * @relayHash 1d7671334bfb8572b5a1f0994c9c9954
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 02cb2e7e0eab975ddec7f23f7d60ac4547b721af6f92f06778b7adc3305c044f

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardQuery } from './src_jiraBusinessBoardQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';
import isSavedViewsColumnOrderSettingsEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-column-order-setting.relayprovider';
import withFilters_provider from '@atlassian/jira-relay-provider/src/saved-views-board-filter-setting.relayprovider';
import isSavedViewsGroupBySettingEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-group-by-setting.relayprovider';
import isSaveResetViewEnabled_provider from '@atlassian/jira-relay-provider/src/sv-230-save-revert-board-view-settings.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "02cb2e7e0eab975ddec7f23f7d60ac4547b721af6f92f06778b7adc3305c044f",
    "metadata": {},
    "name": "src_jiraBusinessBoardQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardcolumnordersettingrelayprovider": isSavedViewsColumnOrderSettingsEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardfiltersettingrelayprovider": withFilters_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsv230saverevertboardviewsettingsrelayprovider": isSaveResetViewEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardgroupbysettingrelayprovider": isSavedViewsGroupBySettingEnabled_provider
    }
  }
};

export default node;
