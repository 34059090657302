import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { planTeamsEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-teams/entrypoint.tsx';
import { plansHorizontalNavigationEntryPoint } from '@atlassian/jira-horizontal-nav-plans/entrypoint.tsx';

export const planTeamsPageEntryPoint = createPageEntryPoint({
	main: planTeamsEntryPoint,
	topNavigationMenuId: MENU_ID.PLANS,
	horizontalNavigation: plansHorizontalNavigationEntryPoint,
});

export const planTeamsPageEntryPointWithoutHorizontalNavigation = createPageEntryPoint({
	main: planTeamsEntryPoint,
	topNavigationMenuId: MENU_ID.PLANS,
});
