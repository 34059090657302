import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { settingsCmdbFieldConfigRoute } from '@atlassian/jira-router-routes-settings-cmdb-field-config-routes/src/settingsCmdbFieldConfigRoute.tsx';
import { workspaceResource } from '@atlassian/jira-servicedesk-insight-common-workspace-context/src/services/resources/index.tsx';
import {
	AsyncCmdbFieldConfigPage,
	LazyCMDBFieldConfigAdmin,
} from './ui/admin-pages/cmdb-field-config/index.tsx';

export const settingsCmdbFieldConfigRouteEntry = createEntry(settingsCmdbFieldConfigRoute, () => ({
	group: 'global-settings',
	ufoName: 'admin.cmdb-field-config',
	component: AsyncCmdbFieldConfigPage,
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsIssues,
	},

	resources: [...getNavigationResources(), navigationSidebarGlobalResource, workspaceResource],
	forPaint: [LazyAtlassianNavigation, LazyCMDBFieldConfigAdmin],
}));
