import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const boardEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-servicedesk-board", jiraSpaEntry: "async-servicedesk-board" */ './src'
			),
	),
	getPreloadProps: () => ({}),
});
