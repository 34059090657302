import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { planDependenciesReportEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-dependencies-report/entrypoint.tsx';
import { plansHorizontalNavigationEntryPoint } from '@atlassian/jira-horizontal-nav-plans/entrypoint.tsx';

export const planDependenciesReportPageEntryPoint = createPageEntryPoint({
	main: planDependenciesReportEntryPoint,
	topNavigationMenuId: MENU_ID.PLANS,
	horizontalNavigation: plansHorizontalNavigationEntryPoint,
});

export const planDependenciesReportPageEntryPointWithoutHorizontalNavigation = createPageEntryPoint(
	{
		main: planDependenciesReportEntryPoint,
		topNavigationMenuId: MENU_ID.PLANS,
	},
);
