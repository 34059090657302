import { resources as assignCustomRolesResources } from '@atlassian/eoc-assign-custom-roles/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { opsSettingsLayout } from '@atlassian/jira-ops-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import {
	AsyncHorizontalOperationsConfigurationNav,
	LazyHorizontalOperationsConfigurationNav,
} from '@atlassian/jira-operations-configurations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocAssignCustomRolesRoute } from '@atlassian/jira-router-routes-eoc-assign-custom-roles-routes/src/eocAssignCustomRolesRoute.tsx';
import AssignCustomRoles, {
	LazyAssignCustomRoles,
} from './ui/spa/eoc/assign-custom-roles/index.tsx';

export const eocAssignCustomRolesRouteEntry = createEntry(eocAssignCustomRolesRoute, () => ({
	group: 'eoc',
	component: AssignCustomRoles,
	layout: opsSettingsLayout,

	navigation: {
		menuId: MENU_ID.SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsProducts,
		horizontal: AsyncHorizontalOperationsConfigurationNav,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		...assignCustomRolesResources(),
		getConsolidationStateResource(),
		navigationSidebarGlobalResource,
	],

	forPaint: [
		LazyHorizontalOperationsConfigurationNav,
		LazyAtlassianNavigation,
		LazyAssignCustomRoles,
	],
}));
