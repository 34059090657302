import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { softwareListEmbedClassicRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareListEmbedClassicRoute.tsx';
import { ListEmbedSkeleton } from '@atlassian/jira-skeletons/src/ui/list/ListSkeleton.tsx';
import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { fieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { softwareListEmbedRouteEntry } from './ui/software/list/index.tsx';

export const softwareListEmbedClassicRouteEntry = createEntry(
	softwareListEmbedClassicRoute,
	() => ({
		group: 'classic-software',
		skeleton: ListEmbedSkeleton,

		entryPoint() {
			return softwareListEmbedRouteEntry;
		},

		layout: chromelessLayout,
		// TODO: This is a copy of getProjectViewResources, but it has been copied in order to expand getNavigationSidebarProjectResource
		resources: [
			// the below 5 resources are copied from getBusinessProjectResources
			// they should be feature flagged and removed when it is safe to do so or
			// moved out of business packages
			// start getBusinessProjectResources
			projectCreatePermissionsResource,
			navigationResource,
			// eslint-disable-next-line camelcase
			DEPRECATED_DO_NOT_USE_projectDetailsResource,
			// end getBusinessProjectResources
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			fieldsResource,
		],
		afterPaint: [LazyIssueApp],
		ufoName: 'classic-list-embed',
	}),
);
