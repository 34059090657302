import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT,
	SERVICE_MODAL_ROUTE_SUFFIX,
} from './common/constants.tsx';

export const servicedeskServiceModalNewRoute: InvariantRoute = {
	name: 'servicedesk-service-modal-new',
	path: `${SERVICE_DESK_ROUTE_PREFIX_WITHOUT_PROJECT}${SERVICE_MODAL_ROUTE_SUFFIX}`,
	exact: true,
};
