import { useCallback } from 'react';
import type {
	GetAutocompleteInitialData,
	JQLAutocompleteResponse,
} from '@atlaskit/jql-editor-autocomplete-rest';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { fetchWithRetry } from '../../common/utils.tsx';

export const useAutocompleteInitialData = (
	excludedAutocompleteFields: string[] = [],
	fieldsFilter?: (fieldValue: string) => boolean,
): GetAutocompleteInitialData =>
	useCallback(
		(url: string) => {
			const options = {
				method: 'POST',
				body: JSON.stringify({
					includeCollapsedFields: true,
				}),
			};
			return fetchWithRetry<JQLAutocompleteResponse>(() => fetchJson(url, options), 1)
				.then((res) => ({
					jqlFields:
						excludedAutocompleteFields.length === 0 && !fieldsFilter
							? res.visibleFieldNames
							: res.visibleFieldNames.filter((field) =>
									fg('jpd-aurora-roadmap-advanced-filtering')
										? fieldsFilter?.(field.cfid || field.value) !== false &&
											!excludedAutocompleteFields.includes(field.value)
										: !excludedAutocompleteFields.includes(field.value),
								),
					jqlFunctions: res.visibleFunctionNames,
				}))
				.catch((error) => {
					fireErrorAnalytics({
						error,
						meta: {
							id: 'getAutocompleteInitialData',
							packageName: PACKAGE_NAME,
							teamName: TEAM_NAME,
						},
					});
					throw error;
				});
		},
		[excludedAutocompleteFields, fieldsFilter],
	);
