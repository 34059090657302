import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsIssuesPrioritySchemesRoute } from '@atlassian/jira-router-routes-admin-pages-priorities-routes/src/globalSettingsIssuesPrioritySchemesRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import PrioritySchemes, {
	LazyPrioritySchemes,
} from './ui/spa/admin-pages/priorities/priority-schemes/index.tsx';

export const globalSettingsIssuesPrioritySchemesRouteEntry: Route = createEntry(
	globalSettingsIssuesPrioritySchemesRoute,
	() => ({
		group: 'global-settings',
		component: PrioritySchemes,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyPrioritySchemes],
	}),
);
