import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import { useIntl } from '@atlassian/jira-intl';
import {
	MenuList,
	MenuListItem,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import { MENU_ID_PROJECTS_VIEW_ALL_STARRED } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useDisplaySettings } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/display-settings-provider/index.tsx';
import { ViewOptions } from '@atlassian/jira-navigation-apps-sidebar-nav4-display-settings/src/ui/show-menu-item/view-options/constants.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import messages from './messages.tsx';
import type { FavoriteItemsType, RecentItemsType } from './types.tsx';

const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

const RenderSectionsWithDisplaySettings = ({
	favoriteItems,
	recentItems,
}: {
	favoriteItems: FavoriteItemsType;
	recentItems: RecentItemsType;
}) => {
	const { selectedViewOption } = useDisplaySettings();

	const shouldShowStarredItems =
		selectedViewOption === ViewOptions.STARRED_AND_RECENT ||
		selectedViewOption === ViewOptions.STARRED;
	const shouldShowRecentItems = selectedViewOption === ViewOptions.STARRED_AND_RECENT;

	return (
		<>
			{shouldShowStarredItems && <RenderStarredSection favoriteItems={favoriteItems} />}
			{shouldShowRecentItems && <RenderRecentSection recentItems={recentItems} />}
		</>
	);
};

const RenderStarredSection = ({ favoriteItems }: { favoriteItems: FavoriteItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		favoriteItems.hasItems && (
			<>
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{formatMessage(messages.starred)}</MenuSectionHeading>
						<MenuList>{favoriteItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
				{favoriteItems.hasNextPage && (
					<Nav4MenuLinkItem href={VIEW_ALL_STARRED_HREF} menuId={MENU_ID_PROJECTS_VIEW_ALL_STARRED}>
						{formatMessage(messages.viewAllStarred)}
					</Nav4MenuLinkItem>
				)}
			</>
		)
	);
};

const RenderRecentSection = ({ recentItems }: { recentItems: RecentItemsType }) => {
	const { formatMessage } = useIntl();

	return (
		recentItems.hasItems && (
			<MenuListItem>
				<MenuSection>
					<MenuSectionHeading>{formatMessage(messages.recent)}</MenuSectionHeading>
					<MenuList>{recentItems.list}</MenuList>
				</MenuSection>
			</MenuListItem>
		)
	);
};

// Adding this component to use the useDisplaySettings hook only when the blu-6315-display-settings-a-part-1 experiment is ON
export const Nav4ProjectsSections = ({
	favoriteItems,
	recentItems,
}: {
	favoriteItems: FavoriteItemsType;
	recentItems: RecentItemsType;
}) =>
	expVal('blu-6315-display-settings-a-part-1', 'isEnabled', false) ? (
		<RenderSectionsWithDisplaySettings favoriteItems={favoriteItems} recentItems={recentItems} />
	) : (
		<>
			<RenderStarredSection favoriteItems={favoriteItems} />
			<RenderRecentSection recentItems={recentItems} />
		</>
	);
