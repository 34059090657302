/**
 * @generated SignedSource<<23a6e9e3efd53029f1bd2f15dafcc83f>>
 * @relayHash 9748961340a0877dad06569907936559
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID da04654402c040cfb1a5731e6742c8a699abdddac45bfc2bc9c768547f13bcf3

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { pageLayout_projectsDirectoryLayout_RootQuery } from './pageLayout_projectsDirectoryLayout_RootQuery.graphql';

const node: PreloadableConcreteRequest<pageLayout_projectsDirectoryLayout_RootQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "da04654402c040cfb1a5731e6742c8a699abdddac45bfc2bc9c768547f13bcf3",
    "metadata": {},
    "name": "pageLayout_projectsDirectoryLayout_RootQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
