/**
 * @generated SignedSource<<39b4c2382e9edb79ca18ee82cc3dcb5e>>
 * @relayHash c015acb85644d3bee5c8b2cb145b1d66
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 082161233dbd381be9ed014242444b47bd21443811aada3c5cc8c3c2e46e7c51

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { BoardViewNotFoundQuery } from './BoardViewNotFoundQuery.graphql';

const node: PreloadableConcreteRequest<BoardViewNotFoundQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "082161233dbd381be9ed014242444b47bd21443811aada3c5cc8c3c2e46e7c51",
    "metadata": {},
    "name": "BoardViewNotFoundQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
