import { createRouterSelector } from '@atlassian/react-resource-router';
import { useMatch } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-match/index.tsx';
import { useQuery } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-query/index.tsx';

export function useGetCurrentUrl() {
	const match = useMatch();
	const query = useQuery();

	const searchParams = new URLSearchParams(query ?? {});
	const url = new URL(`http://example.com${match.url}/?${searchParams.toString()}`);
	return url.toString();
}

export const useGetCurrentUrlPath = createRouterSelector((routerContext) => {
	const url = new URL(`http://example.com${routerContext.match.url}`);
	return url.toString();
});
