import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { projectSettingsSoftwareRedirectRoute } from '@atlassian/jira-router-routes-project-settings-product-routes-software-routes/src/projectSettingsSoftwareRedirectRoute.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import {
	RedirectForSettings,
	getSettingsNavigationResources,
} from './ui/project-settings/product-routes/common/index.tsx';

export const projectSettingsSoftwareRedirectRouteEntry: Route = createEntry(
	projectSettingsSoftwareRedirectRoute,
	() => ({
		group: 'project-settings-software',
		component: RedirectForSettings,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarNextGen,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		resources: [...getSettingsNavigationResources()],
		forPaint: [LazyAtlassianNavigation],
	}),
);
