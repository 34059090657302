import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { customPracticeQueueBoardRoute } from '@atlassian/jira-router-routes-servicedesk-queue-routes/src/customPracticeQueueBoardRoute.tsx';
import { getQueuesResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { fg } from '@atlassian/jira-feature-gating';
import { AsyncHorizontalNavQueue } from '@atlassian/jira-horizontal-nav-queue/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getRapidBoardDataResource,
	getRapidboardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';

import { boardPageEntryPoint } from './ui/board/index.tsx';

export const customPracticeQueueBoardRouteEntry: Route = createEntry(
	customPracticeQueueBoardRoute,
	() => ({
		group: 'servicedesk',
		component: ErrorPagesNotFound,
		entryPoint: () => (fg('jsm_views_inside_queues_-_main_flag') ? boardPageEntryPoint : undefined),
		layout: serviceProjectLayout,
		skeleton: ClassicBoardSkeleton,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			horizontal: fg('jsm_views_inside_queues_-_main_flag') ? AsyncHorizontalNavQueue : () => null,
		},
		resources: [
			...getQueuesResources(),
			getConsolidationStateResource(),
			...getRapidboardResources(),
			getRapidBoardDataResource(),
			resourceWithCondition2(() => !__SERVER__, uifBoardResource),
		],
		forPaint: [LazyAtlassianNavigation],
	}),
);
