import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsSupportWebsiteOverviewRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsSupportWebsiteOverviewRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';

import AsyncHelpCenterSettingsSidebar from '@atlassian/jira-navigation-apps-sidebar-help-center-settings/src/async.tsx';

import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { AsyncSupportWebsiteNav } from '@atlassian/jira-horizontal-nav-csm-support-website/src/index.tsx';
import {
	HelpCenterSettingsSupportWebsiteOverview,
	LazyHelpCenterSettingsSupportWebsiteOverview,
} from './ui/support-website-overview/index.tsx';

export const helpCenterSettingsSupportWebsiteOverviewRouteEntry: Route = createEntry(
	helpCenterSettingsSupportWebsiteOverviewRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'customer-service.support-website.overview',
		layout: serviceProjectLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.HELP_CENTER_SETTINGS,
			menuId: MENU_ID.HELP_CENTER_SETTINGS,
			sidebar: AsyncHelpCenterSettingsSidebar,
			horizontal: AsyncSupportWebsiteNav,
		},
		component: componentWithFG(
			'csm_support_site_m3',
			HelpCenterSettingsSupportWebsiteOverview,
			ErrorPagesNotFound,
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsSupportWebsiteOverview],
		resources: [...getServiceDeskNavigationResources()],
	}),
);
