import { L1_MENU_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { dashboardsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/dashboardsDirectoryRoute.tsx';
import type { JiraUserNavigationConfiguration } from '../types.tsx';

export const PACKAGE_NAME = 'jiraNavigationAppsSidebarNav4SidebarContent';
export const TEAM_NAME = 'jira-cosmos';

// This is part of an intermediate state workaround where links are accounted for;
// we don't expect links to be in the More menu at the end state, at which point this can be removed.
export const USER_NAVIGATION_ITEM_LINKS: Record<string, string> = {
	[L1_MENU_ID.APPS]: '/jira/marketplace/discover?source=apps-menu',
	[L1_MENU_ID.GOALS]: '/jira/your-work?goals',
	[L1_MENU_ID.DASHBOARDS]: dashboardsDirectoryRoute.path,
	[L1_MENU_ID.TEAMS]: '/jira/people/search',
	[L1_MENU_ID.ROADMAPS]: '/jira/polaris/roadmaps',
};

export const USER_NAVIGATION_CONFIGURATION_DEFAULT: JiraUserNavigationConfiguration = {
	navKey: 'JIRA_SIDEBAR',
	navItems: [
		{
			menuId: L1_MENU_ID.RECENT,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.STARRED,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.APPS,
			isVisible: true,
		},
		// See https://jplat.jira.atlassian.cloud/browse/BLU-3307
		// For the Dogfooding phase of Nav4 we are going to intentionally hide the Goals menu item.
		// Keeping code in comments so it can be easily reintroduced during EAP
		// {
		// 	menuId: L1_MENU_ID.GOALS,
		// 	isVisible: true,
		// },
		{
			menuId: L1_MENU_ID.ROADMAPS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.OVERVIEWS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.PLANS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.PROJECTS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.FILTERS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.DASHBOARDS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.OPERATIONS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.ASSETS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.TEAMS,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.CUSTOMER_SERVICE,
			isVisible: true,
		},
		{
			menuId: L1_MENU_ID.CUSTOMER_SERVICE_ANALYTICS,
			isVisible: true,
		},
	],
};

export const USER_NAVIGATION_CONFIGURATION_DEFAULT_MENU_IDS =
	USER_NAVIGATION_CONFIGURATION_DEFAULT.navItems.map(({ menuId }) => menuId);
