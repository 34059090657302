/**
 * @generated SignedSource<<5aefa2221c328a3305bac155bad05d3f>>
 * @relayHash 8a3ce9d687866ad821c864ea8fc9c9c0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 684fa365596e55c3f1e33b3d0430bd9adba4780d4144f0021a4a6f96047e6950

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessBoardEmbedQuery } from './src_jiraBusinessBoardEmbedQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';
import isSavedViewsColumnOrderSettingsEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-column-order-setting.relayprovider';
import withFilters_provider from '@atlassian/jira-relay-provider/src/saved-views-board-filter-setting.relayprovider';
import isSavedViewsGroupBySettingEnabled_provider from '@atlassian/jira-relay-provider/src/saved-views-board-group-by-setting.relayprovider';
import isSaveResetViewEnabled_provider from '@atlassian/jira-relay-provider/src/sv-230-save-revert-board-view-settings.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessBoardEmbedQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "684fa365596e55c3f1e33b3d0430bd9adba4780d4144f0021a4a6f96047e6950",
    "metadata": {},
    "name": "src_jiraBusinessBoardEmbedQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardcolumnordersettingrelayprovider": isSavedViewsColumnOrderSettingsEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardfiltersettingrelayprovider": withFilters_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsv230saverevertboardviewsettingsrelayprovider": isSaveResetViewEnabled_provider,
      "__relay_internal__pv__atlassianjirarelayprovidersrcsavedviewsboardgroupbysettingrelayprovider": isSavedViewsGroupBySettingEnabled_provider
    }
  }
};

export default node;
