import camelCase from 'lodash/camelCase';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskSettingsResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { servicedeskPlaybooksEditRoute } from '@atlassian/jira-router-routes-project-settings-playbooks-routes/src/servicedeskPlaybooksEditRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { SIDEBAR_ID, MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import playbookEditEntryPoint from '@atlassian/jira-spa-apps-project-settings-playbooks-edit/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { PlaybookEditSkeleton } from '@atlassian/jira-playbooks-edit-screen/src/ui/skeleton/PlaybookEditSkeleton.tsx';

const editEntryPoint = createPageEntryPoint({
	main: playbookEditEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const servicedeskPlaybooksEditRouteEntry: Route = createEntry(
	servicedeskPlaybooksEditRoute,
	() => ({
		group: 'project-settings-servicedesk',
		layout: serviceProjectLayout,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		entryPoint() {
			return fg('playbooks-in-jsm') ? editEntryPoint : undefined;
		},
		resources: [...getServiceDeskSettingsResources(), getConsolidationStateResource()],
		meta: {
			reporting: {
				id: 'project-settings.playbooks.admin-edit-view',
				packageName: camelCase('project-settings-playbooks-edit'),
				teamName: 'ITOps Phantom',
			},
		},
		component: ErrorPagesNotFound,
		ufoName: 'playbook-edit',
		skeleton: PlaybookEditSkeleton,
	}),
);
