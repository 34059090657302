import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { classicBoardSidebars } from '@atlassian/jira-navigation-apps-sidebar-generic-sidebars/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	shouldLoadUIFBoardResources,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import {
	getNavigationResources,
	getUserBoardNavigationResources,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { uifBoardResource } from '@atlassian/jira-router-resources-uif-board/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { userBoardRoute } from '@atlassian/jira-router-routes-user-board-routes/src/userBoardRoute.tsx';
import { ClassicBoardSkeleton } from '@atlassian/jira-skeletons/src/ui/classic-board/ClassicBoardSkeleton.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import {
	RapidBoardSpaPage,
	LazyRapidBoard,
	LazyRapidBoardApps,
	LazyRapidBoardDataApiResource,
	LazyRapidBoardConfigApiResource,
	LazyRapidBoardWrmBundleResource,
	LazyRapidBoardResource,
} from './ui/async.tsx';

/**
 * Jira Software user-located boards SPA route
 */
export const userBoardRouteEntry = createEntry(userBoardRoute, () => ({
	group: 'classic-software',
	component: RapidBoardSpaPage,
	skeleton: ClassicBoardSkeleton,

	layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([
			{ query: ['selectedIssue', 'view=detail'] },
			{ query: ['selectedIssue', 'modal=detail'] },
		]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: classicBoardSidebars,
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		...getUserBoardNavigationResources(),
		resourceWithCondition2(shouldLoadUIFBoardResources, uifBoardResource),
		getEditModelResource(),
		getBoardDetailsResource(),
	],

	afterPaint: [LazyIssueApp],

	forPaint: [
		LazyAtlassianNavigation,
		LazyRapidBoard,
		LazyRapidBoardApps,
		LazyRapidBoardDataApiResource,
		LazyRapidBoardConfigApiResource,
		LazyRapidBoardWrmBundleResource,
		LazyRapidBoardResource,
	],
	preloadOptions: {
		earlyChunks: ['software-cmp-board-early-entry'],
	},
	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,
}));
