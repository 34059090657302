import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { rapidboardBacklogRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/rapidboardBacklogRoute.tsx';
import { RapidBoardSpaPage } from '@atlassian/jira-router-routes-user-board-entries/src/ui/async.tsx';
import { NextGenBacklogSkeleton } from '@atlassian/jira-skeletons/src/ui/next-gen-backlog/NextGenBacklogSkeleton.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { composeLayouts } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import { genericProjectLayout } from '@atlassian/jira-generic-project-layout/src/index.tsx';
import { embeddedIssuePreloadLayoutBuilder } from '@atlassian/jira-issue-preload-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import {
	forgeProjectModuleResource,
	forgeBacklogModuleResource,
	forgeSprintModuleResource,
} from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { horizontalNavJiraResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/horizontal-navigation/horizontal-nav-jira/index.tsx';
import {
	getRapidboardResources,
	getRapidBoardDataResource,
	getUIFBacklogDataResource,
} from '@atlassian/jira-router-resources-classic-projects/src/services/main.tsx';
import { getEditModelResource } from '@atlassian/jira-router-resources-classic-projects/src/services/edit-model/index.tsx';
import { getBoardDetailsResource } from '@atlassian/jira-router-resources-classic-projects/src/services/board-details/index.tsx';
import { LazyIssueApp } from '@atlassian/jira-lazy-issue-app/src/LazyIssueApp.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AGGRESSIVE_EARLY_ASSET_STRATEGY } from '@atlassian/jira-common-constants/src/ssr-early-assets.tsx';
import AsyncSidebarSoftwareClassic, {
	ForPaintClassicSoftwareProjectSidebar as AsyncForPaintSidebarSoftwareClassic,
} from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { resourceWithCondition2 } from '@atlassian/jira-router-resources-utils/src/utils/resource-with-condition/index.tsx';
import { projectThemeResource } from '@atlassian/jira-router-resources-project-theme/src/index.tsx';
import { getIsSoftwareThemingEnabled } from '@atlassian/jira-project-theme-providers/src/utils/getIsSoftwareThemingEnabled.tsx';

import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';

import { nextGenBacklogEntryPoint } from '@atlassian/jira-spa-apps-next-gen-backlog/entrypoint.tsx';
import { nav4JSWProjectEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-jsw-project-menu/src/ui/jsw-expandable-project/async-content/entrypoint.tsx';
import { nav4SidebarContentCoreEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/entrypoint.tsx';
import { nav4ProjectsContentEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-core/src/ui/projects/default/async-content/entrypoint.tsx';
import { classicBacklogCapability } from './common/constants.tsx';
import { LazyBacklogPage } from './ui/LazyBacklogPage.tsx';

const classicRapidBoardBacklogEntryPoint = createPageEntryPoint({
	main: nextGenBacklogEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
	leftSidebar: {
		sidebar: nav4SidebarContentCoreEntryPoint,
		entryPoints: {
			projects: {
				entryPoint: nav4ProjectsContentEntryPoint,
			},
			boards: {
				entryPoint: nav4JSWProjectEntryPoint,
			},
		},
		isProjectsExpanded: true,
	},
});

export const rapidboardBacklogRouteEntry = createEntry(rapidboardBacklogRoute, () => ({
	group: 'classic-software',
	ufoName: 'classic-backlog',
	component: RapidBoardSpaPage,
	skeleton: NextGenBacklogSkeleton,
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-backlog'),

	layout: composeLayouts(
		genericProjectLayout,
		embeddedIssuePreloadLayoutBuilder([{ query: ['selectedIssue', 'view=planning'] }]),
	),

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: componentWithFG(
			'make_nav_sidebar_lazyforpaint_in_board_and_backlog',
			AsyncForPaintSidebarSoftwareClassic,
			AsyncSidebarSoftwareClassic,
		),
		horizontal: AsyncHorizontalNavJira,
		onlyShowHorizontalOnNav4: true,
	},

	resources: [
		...getNavigationResources(),
		// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
		incidentExperimentTraitResource,
		projectContextResource,
		forgeProjectModuleResource,
		staticSidebarShortcutsResource,
		staticProjectSidebarNavigationResource,
		staticProjectSidebarConnectAddonsNavigationResource,
		horizontalNavJiraResource,
		// end of resources expanded from getNavigationSidebarProjectResource
		...getUIFBacklogDataResource(),
		...getRapidboardResources(),
		getRapidBoardDataResource(),
		getEditModelResource(),
		getBoardDetailsResource(),
		forgeBacklogModuleResource,
		forgeSprintModuleResource,
		resourceWithCondition2(() => getIsSoftwareThemingEnabled(), projectThemeResource),
	],

	afterPaint: [LazyIssueApp],
	forPaint: [LazyAtlassianNavigation, LazyBacklogPage],

	preloadOptions: {
		earlyChunks: ['software-cmp-backlog-early-entry'],
	},

	meta: {
		capability: classicBacklogCapability,
	},

	earlyAssets: AGGRESSIVE_EARLY_ASSET_STRATEGY,

	entryPoint: () =>
		fg('blu-5799-jira-software-board-entrypoint-route')
			? classicRapidBoardBacklogEntryPoint
			: undefined,
}));
