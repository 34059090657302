import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	REQUESTS_ROUTE_SUFFIX,
	TIME_SCALE,
	ISSUE_KEY,
} from './common/constants.tsx';

export const servicedeskKbRequestsReportsIssueRoute: InvariantRoute = {
	name: 'servicedesk-kb-requests-reports-time-scale-issue',
	path: `${SERVICE_DESK_ROUTE_PREFIX}${REQUESTS_ROUTE_SUFFIX}${TIME_SCALE}${ISSUE_KEY}`,
	query: ['oldIssueView?'],
	exact: true,
};
