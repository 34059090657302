import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { softwareClassicProformaIssueFormsDirectRoute } from '@atlassian/jira-router-routes-classic-projects-routes/src/softwareClassicProformaIssueFormsDirectRoute.tsx';
import { classicSoftwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import ProformaIssueFormsDirect, {
	LazyProformaIssueFormsDirect,
} from './ui/proforma/issue-forms-direct/index.tsx';

export const softwareClassicProformaIssueFormsDirectRouteEntry = createEntry(
	softwareClassicProformaIssueFormsDirectRoute,
	() => ({
		group: 'classic-software',
		component: ProformaIssueFormsDirect,
		layout: classicSoftwareProjectLayoutNoOnboarding,

		navigation: {
			menuId: MENU_ID.PROJECTS,
		},

		resources: [projectContextResource],
		forPaint: [LazyAtlassianNavigation, LazyProformaIssueFormsDirect],
		perfMetricKey: 'issue-forms-direct',
	}),
);
