import { nav4SidebarContentCoreEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-core/entrypoint.tsx';
import { nav4SidebarSettingsBoardEntryPoint } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-settings-board/entrypoint.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import type { Navigation } from '@atlassian/jira-route-types/src/index.tsx';

// This function cannot be used within createPageEntryPoint().
// Use the actual entryPoint reference directly, rather than calling this function.
// This function CAN be used in SPA transitions.
export function getSidebarEntryPoint(sidebarId: NonNullable<Navigation['sidebarId']>) {
	switch (sidebarId) {
		case SIDEBAR_ID.BOARD_SETTINGS:
			return nav4SidebarSettingsBoardEntryPoint;

		default:
			return nav4SidebarContentCoreEntryPoint;
	}
}
