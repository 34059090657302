import parameters from '@atlassian/jira-relay/src/__generated__/Nav4ProjectsContentViewOldQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = { cloudId: string };

// To be cleaned up with blu-5799-jira-software-board-entrypoint-route
export const asyncNav4ProjectsContentViewQuery = createEntryPoint({
	root: JSResourceForInteraction(() =>
		import(
			/* webpackChunkName: "async-nav4-projects-content-view-old-query" */ './Nav4ProjectsContentViewOldQuery'
		).then(({ Nav4ProjectsContentViewOldQuery }) => Nav4ProjectsContentViewOldQuery),
	),
	getPreloadProps: ({ cloudId }: EntryPointParams) => ({
		queries: {
			projects: {
				parameters,
				variables: {
					cloudId,
				},
			},
		},
	}),
});
