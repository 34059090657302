import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getGlobalPermissionPageResources } from '@atlassian/jira-router-resources-advanced-roadmaps-settings/src/services/permissions/index.tsx';
import {
	getNavigationResources,
	getNavigationSidebarProductsSettingResource,
} from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { arjSettingsPermissionsRouteLegacyRedirectRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-settings-routes/src/arjSettingsPermissionsRouteLegacyRedirectRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyArjSettingsPermissions } from './ui/index.tsx';
import { redirectTo } from './ui/redirect.tsx';

export const arjSettingsPermissionsRouteLegacyRedirectRouteEntry: Route = createEntry(
	arjSettingsPermissionsRouteLegacyRedirectRoute,
	() => ({
		group: 'software',
		component: redirectTo('/jira/plans/settings/permissions'),
		isRedirect: true,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},
		resources: [
			...getNavigationResources(),
			...getNavigationSidebarProductsSettingResource(),
			...getGlobalPermissionPageResources(),
		],
		forPaint: [LazyAtlassianNavigation, LazyArjSettingsPermissions],
	}),
);
