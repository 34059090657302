/**
 * @generated SignedSource<<5b09b73015f6d4eda410be086a0ec06e>>
 * @relayHash c3bc46f986acdf13f968e5919fcd2d52
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 3c97505ceda772c1ff0a6ccf4c08e12237f9f7d57f5f99db7c9b61da8af86b7e

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FiltersContentViewQuery$variables = {
  cloudId: string;
};
export type Nav4FiltersContentViewQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersContent">;
};
export type Nav4FiltersContentViewQuery = {
  response: Nav4FiltersContentViewQuery$data;
  variables: Nav4FiltersContentViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v2 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v3 = {
  "kind": "ScalarField",
  "name": "filterId"
},
v4 = {
  "kind": "ScalarField",
  "name": "name"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
},
v6 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v7 = {
  "kind": "ScalarField",
  "name": "jql"
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    },
    {
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        (v5/*: any*/)
      ]
    }
  ],
  "type": "JiraCustomFilter"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v10 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4FiltersContentViewQuery",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4FiltersContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4FiltersContentViewQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouriteFilters",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "FILTER"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "type": "JiraFilter",
                        "abstractKey": "__isJiraFilter"
                      },
                      (v9/*: any*/)
                    ]
                  }
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "alias": "recentFilters",
            "args": [
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "FILTER"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/)
                            ],
                            "type": "JiraFilter",
                            "abstractKey": "__isJiraFilter"
                          }
                        ],
                        "type": "JiraCustomFilter"
                      },
                      (v9/*: any*/)
                    ]
                  }
                ]
              },
              (v10/*: any*/)
            ]
          },
          {
            "args": [
              (v1/*: any*/)
            ],
            "concreteType": "JiraSystemFilterConnection",
            "kind": "LinkedField",
            "name": "systemFilters",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSystemFilterEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraSystemFilter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v5/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v2/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/)
                        ],
                        "type": "JiraFilter",
                        "abstractKey": "__isJiraFilter"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "3c97505ceda772c1ff0a6ccf4c08e12237f9f7d57f5f99db7c9b61da8af86b7e",
    "metadata": {},
    "name": "Nav4FiltersContentViewQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "9218fd54b0172bf14bf1082dd5032107";

export default node;
