import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { trashProjectsDirectoryRedirectRoute } from '@atlassian/jira-router-routes-trash-projects-directory-routes/src/trashProjectsDirectoryRedirectRoute.tsx';
import RedirectToPrettyUrlTrashDirectory from './common/ui/redirect/jira-projects-trash-directory-redirect/index.tsx';
import { LazyTrashedProjectsAdmin } from './ui/admin-pages/trashed-projects/index.tsx';

export const trashProjectsDirectoryRedirectRouteEntry = createEntry(
	trashProjectsDirectoryRedirectRoute,
	() => ({
		group: 'global-settings',
		ufoName: 'admin.trashed-projects',
		component: RedirectToPrettyUrlTrashDirectory,
		layout: globalSettingsLayout,
		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},
		resources: [...getNavigationResources(), navigationSidebarGlobalResource],
		forPaint: [LazyAtlassianNavigation, LazyTrashedProjectsAdmin],
		isRedirect: true,
	}),
);
