import {
	getUserLocation,
	getRouteName,
} from '@atlassian/jira-platform-router-utils/src/common/utils/index.tsx';

export type PageContext = {
	routeName?: string;
	userLocation?: string;
	bundlerVersion?: string;
	buildVariant?: string;
};

/** @description safely obtain page context */
export function pageContext(): PageContext {
	return {
		routeName: getSafe(() => getUserLocation()),
		userLocation: getSafe(() => getRouteName()),
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		bundlerVersion: getSafe(() => globalThis.BUNDLER_VERSION),
		buildVariant: getSafe(() => globalThis.BUILD_VARIANT),
	};
}

function getSafe(fn: () => string | undefined): string | undefined {
	try {
		return fn();
	} catch (error) {
		return 'unknown';
	}
}
