import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { SidebarGlobalSettingsSystem as AsyncSidebarGlobalSystemSettings } from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/ui/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsSystemBulkMailRoute } from '@atlassian/jira-router-routes-admin-pages-outgoing-mail-routes/src/globalSettingsSystemBulkMailRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import BulkEmail, { LazyBulkEmail } from './ui/spa/admin-pages/outgoing-mail/bulk-mail/index.tsx';

export const globalSettingsSystemBulkMailRouteEntry: Route = createEntry(
	globalSettingsSystemBulkMailRoute,
	() => ({
		group: 'global-settings',
		component: BulkEmail,

		navigation: {
			menuId: MENU_ID.SETTINGS,
			sidebar: AsyncSidebarGlobalSystemSettings,
		},

		layout: globalSettingsLayout,

		resources: [...getNavigationResources(), navigationSidebarGlobalResource],

		forPaint: [LazyAtlassianNavigation, LazyBulkEmail],
	}),
);
