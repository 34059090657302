import type { Action } from '@atlassian/react-sweet-state';
import type { NavigationCollection, Props, State } from '../../types.tsx';
import { sortedInsert } from '../utils.tsx';

const collectionComparator = (a: NavigationCollection, b: NavigationCollection) => {
	return a.name.localeCompare(b.name, undefined, { numeric: true }) === 1;
};

export const addCollection =
	(collection: NavigationCollection): Action<State, Props> =>
	async ({ setState, getState }, { cloudId }) => {
		const state = getState();

		if (!cloudId || getState().collections?.isLoading || !getState().collections?.initialized) {
			return;
		}

		setState({
			collections: {
				...state.collections,
				data: sortedInsert(state.collections.data, collection, collectionComparator),
			},
			collectionsCache: {
				...state.collectionsCache,
				[collection.uuid]: { ...collection },
			},
		});
	};
