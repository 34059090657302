import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { coreProjectLayout } from '@atlassian/jira-core-project-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV } from '@atlassian/jira-navigation-apps-horizontal-nav-selector/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getBusinessProjectResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { projectSettingsCoreNotificationsRoute } from '@atlassian/jira-router-routes-project-settings-core-routes/src/projectSettingsCoreNotificationsRoute.tsx';
import type SoftwareNotificationsSettingsType from '@atlassian/jira-spa-apps-project-settings-software-notifications/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { licenseDetailsResource_DEPRECATED_DO_NOT_USE } from '@atlassian/jira-router-resources-business-license-details/src/index.tsx';
import { getNavigationSidebarProjectSettingsResource } from './common/getNavigationSidebarProjectSettingsResource.tsx';

const LazyNotificationsSettings = lazyForPaint<typeof SoftwareNotificationsSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-project-settings-core-notifications", jiraSpaEntry: "async-project-settings-software-notifications" */ '@atlassian/jira-spa-apps-project-settings-software-notifications'
		),
	),
);

const Notifications = () => (
	<LazyPage
		Page={LazyNotificationsSettings}
		pageId="project-settings-software-notifications"
		shouldShowSpinner={false}
	/>
);

export const projectSettingsCoreNotificationsRouteEntry = createEntry(
	projectSettingsCoreNotificationsRoute,
	() => ({
		group: 'project-settings-core',
		navigation: {
			horizontal: GENERIC_BUSINESS_TRANSITIONAL_PROJECT_HORIZONTAL_NAV,
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			sidebarId: SIDEBAR_ID.PROJECT_SETTINGS,
		},
		layout: coreProjectLayout,
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			...getNavigationSidebarProjectSettingsResource(),
			...getBusinessProjectResources(),
			licenseDetailsResource_DEPRECATED_DO_NOT_USE,
		],
		component: Notifications,
		forPaint: [LazyAtlassianNavigation, LazyNotificationsSettings],
	}),
);
