import React, { type ComponentType, type ReactNode, useCallback, useState } from 'react';
import prsLocaleMapper from '@atlaskit/prs-locale-mapper';
import { useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { Team, TeamCreateDialogProps, Flag } from '@atlassian/people-teams';
import { PeopleAndTeamsConfigurationProvider } from '@atlassian/people-teams-configuration-client';
import { lazy } from '@atlassian/react-loosely-lazy';
import type { TeamValue, TriggerCreateTeamDialogCallback } from '../../common/types.tsx';

// Relay entry point is not applicable here because this component does not live in jira frontend.
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
export const TeamCreateDialog = lazy<ComponentType<TeamCreateDialogProps>>(() =>
	import(
		/* webpackChunkName: "async-lazy-jira-issue-field-team-create-dialog" */ '@atlassian/people-teams'
	).then((module) => module.TeamCreateDialog),
);

export interface CreateTeamDialogProps {
	onCreate?: (team: TeamValue) => void;
	onCancel?: () => void;
	analyticsCtx?: object;
}

export type CreateTeamDialogReturn = [ReactNode, TriggerCreateTeamDialogCallback];

export const useCreateTeamDialog = (props: CreateTeamDialogProps = {}): CreateTeamDialogReturn => {
	const { locale } = useIntl();
	const [isOpen, setIsOpen] = useState(false);
	const [inputTeamName, setInputTeamName] = useState('');
	const { showFlag } = useFlagsService();

	const tenantContext = useTenantContext();
	const { cloudId, atlassianAccountId } = tenantContext;
	const { data: orgId } = useOrgId();
	const {
		data: { user },
	} = useCurrentUser();

	if (!atlassianAccountId && !user.accountId) {
		throw new Error('Missing current user id');
	}

	const addFlag = useCallback(
		({ appearance, ...flagProps }: Flag) => {
			if (appearance === 'success' || appearance === 'normal') {
				showFlag((serviceProps) => (
					<SuccessFlag
						messageId="issue-field-create-team-dialog.ui.use-create-team-dialog.success-flag"
						messageType="transactional"
						{...flagProps}
						{...serviceProps}
					/>
				));
			}

			if (appearance === 'warning') {
				showFlag((serviceProps) => (
					<WarningFlag
						messageId="issue-field-create-team-dialog.ui.use-create-team-dialog.warning-flag"
						messageType="transactional"
						{...flagProps}
						{...serviceProps}
					/>
				));
			}

			if (appearance === 'error') {
				showFlag((serviceProps) => (
					<ErrorFlag
						messageId="issue-field-create-team-dialog.ui.use-create-team-dialog.error-flag"
						messageType="transactional"
						{...flagProps}
						{...serviceProps}
					/>
				));
			}
		},
		[showFlag],
	);

	const { onCancel } = {
		onCancel: () => null,
		...props,
	};

	const callback: TriggerCreateTeamDialogCallback = useCallback((teamName: string) => {
		setInputTeamName(teamName);
		setIsOpen(true);
	}, []);

	const handleOnClose = () => {
		setIsOpen(false);
		onCancel();
	};

	const handleOnCreate = (team: Team) => {
		const formattedTeam: TeamValue = {
			...team,
			name: team.displayName,
		};

		props.onCreate?.(formattedTeam);
	};

	return [
		isOpen ? (
			<Placeholder name="team-create-dialog">
				<PeopleAndTeamsConfigurationProvider product="jira" locale={prsLocaleMapper(locale)}>
					<TeamCreateDialog
						cloudId={cloudId}
						orgId={orgId}
						product="jira"
						principalId={atlassianAccountId || user.accountId || ''}
						onClose={handleOnClose}
						onCreateTeamSuccess={handleOnCreate}
						extraAnalyticsAttrs={props.analyticsCtx}
						enableMembershipSettingsChoice
						currentUser={
							user.accountId
								? {
										id: user.accountId,
										fullName: user.userFullname,
										// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
										avatarUrl: user.avatarUrls && user.avatarUrls['48x48'],
										...user,
									}
								: undefined
						}
						prepopulatedFields={{
							teamName: inputTeamName,
						}}
						addFlag={addFlag}
					/>
				</PeopleAndTeamsConfigurationProvider>
			</Placeholder>
		) : null,
		callback,
	];
};
