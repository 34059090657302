import React, { memo, forwardRef } from 'react';
import ChevronRightIcon from '@atlaskit/icon/utility/migration/chevron-right';
import EditorBackgroundColorIcon from '@atlaskit/icon/core/migration/paint-bucket--editor-background-color';
import { ButtonItem, type ButtonItemProps } from '@atlaskit/menu';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

export type ThemePickerButtonItemProps = Pick<ButtonItemProps, 'onClick' | 'isSelected' | 'testId'>;

export const ThemePickerButtonItem = memo(
	forwardRef<HTMLElement, ThemePickerButtonItemProps>(
		({ onClick, isSelected, testId, ...props }, ref) => {
			const { formatMessage } = useIntl();

			return (
				<ButtonItem
					iconBefore={
						<EditorBackgroundColorIcon label="" LEGACY_size="medium" spacing="spacious" />
					}
					iconAfter={<ChevronRightIcon label="" LEGACY_size="medium" spacing="spacious" />}
					onClick={onClick}
					testId={testId}
					isSelected={isSelected}
					ref={ref}
					{...props}
				>
					{formatMessage(messages.setProjectBackgroundMenuItem)}
				</ButtonItem>
			);
		},
	),
);
