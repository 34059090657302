import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import {
	LazyHorizontalOperationsOverviewNav,
	AsyncHorizontalOperationsOverviewNav,
} from '@atlassian/jira-operations-horizontal-nav/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getTeamsResource } from '@atlassian/jira-router-resources-eoc-overview/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { eocOverviewAllTeamsRoute } from '@atlassian/jira-router-routes-eoc-overview-routes/src/eocOverviewAllTeamsRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { EocOverviewAllTeamsComponent, LazyOverviewAllTeamsPage } from './ui/index.tsx';

export const eocOverviewAllTeamsRouteEntry: Route = createEntry(eocOverviewAllTeamsRoute, () => ({
	group: 'eoc',
	component: EocOverviewAllTeamsComponent,
	resources: [...getNavigationResources(), getConsolidationStateResource(), getTeamsResource],
	navigation: {
		menuId: MENU_ID.HOME,
		horizontal: AsyncHorizontalOperationsOverviewNav,
		onlyShowHorizontalOnNav4: true,
	},
	forPaint: [
		LazyAtlassianNavigation,
		LazyHorizontalOperationsOverviewNav,
		LazyOverviewAllTeamsPage,
	],
}));
