import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskSettingsResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { helpCenterSettingsKbOldRoute } from '@atlassian/jira-router-routes-help-center-settings-routes/src/helpCenterSettingsKbOldRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { navigation } from './common/constants.tsx';
import {
	HelpCenterSettingsKnowledgeBase,
	LazyHelpCenterSettingsKnowledgeBase,
} from './ui/knowledge-base/index.tsx';

// Delete this as part of berry-4386-add-helpcenterid-to-routes FG clean up
export const helpCenterSettingsKbOldRouteEntry: Route = createEntry(
	helpCenterSettingsKbOldRoute,
	() => ({
		group: 'helpcenter-settings',
		perfMetricKey: 'help-center-settings.kb',
		layout: serviceProjectLayout,
		navigation,
		component: componentWithFG(
			'berry-4386-add-helpcenterid-to-routes',
			ErrorPagesNotFound,
			componentWithFG('jcs_master_flag', HelpCenterSettingsKnowledgeBase, ErrorPagesNotFound),
		),
		forPaint: [LazyAtlassianNavigation, LazyHelpCenterSettingsKnowledgeBase],
		resources: [
			...getServiceDeskSettingsResources(),
			// TODO: https://jplat.jira.atlassian.cloud/browse/BERRY-2884
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
		],
	}),
);
