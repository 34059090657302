import React from 'react';
import {
	createContactsResource,
	createNotificationPreferencesResource,
	eocUserResource,
	createMuteResource,
	createQuietHoursResource,
	createForwardingRulesResource,
} from '@atlassian/eoc-notifications/resources';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationForgePersonalSettingsPageResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { GENERAL_PERSONAL_SETTINGS_PATH } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { CONSOLIDATION_STATE_RESOURCE_TYPE } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/constants.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { personalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/personalSettingsRoute.tsx';
import { ScreenRedirect } from '@atlassian/jira-screen-redirect/src/ui/ScreenRedirect.tsx';
import type PersonalSettingsType from '@atlassian/jira-spa-apps-personal-settings-legacy/src/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';
import { checkConsolidationOnRouteDependency } from './common/checkConsolidationOnRouteDependency.tsx';
import { getLabsResources } from './common/getLabsResources.tsx';

const LazyLegacyPersonalSettings = lazyForPaint<typeof PersonalSettingsType>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-personal-settings", jiraSpaEntry: "async-personal-settings" */ '@atlassian/jira-spa-apps-personal-settings-legacy'
		),
	),
);

export const personalSettingsRouteEntry: Route = createEntry(personalSettingsRoute, () => ({
	group: 'personal-settings',
	component: () => <ScreenRedirect to={GENERAL_PERSONAL_SETTINGS_PATH} />,
	layout: globalLayout,
	navigation: {
		sidebarId: SIDEBAR_ID.PERSONAL_SETTINGS,
		menuId: MENU_ID.PROFILE,
	},
	resources: [
		...getNavigationResources(),
		getConsolidationStateResource(),
		createContactsResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		createNotificationPreferencesResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		eocUserResource,
		createMuteResource(checkConsolidationOnRouteDependency, [CONSOLIDATION_STATE_RESOURCE_TYPE]),
		createQuietHoursResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		createForwardingRulesResource(checkConsolidationOnRouteDependency, [
			CONSOLIDATION_STATE_RESOURCE_TYPE,
		]),
		...getNavigationForgePersonalSettingsPageResources(),
		...getLabsResources(),
	],
	forPaint: [LazyAtlassianNavigation, LazyLegacyPersonalSettings],
}));
