// Issue Type Templates

import { defaultLocale } from '@atlassian/jira-common-constants/src/supported-locales.tsx';
import {
	TEXT_AREA_CF_TYPE as TEXT_AREA_CF_TYPE_ORIGINAL,
	DESCRIPTION_TYPE,
	ENVIRONMENT_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { populateApplicationEditions } from '@atlassian/jira-shared-types/src/edition.tsx';
import {
	toBaseUrl,
	toCloudId,
	toActivationId,
	toCloudName,
	toDevopsProjectSignupSource,
} from '@atlassian/jira-shared-types/src/general.tsx';
import { populateApplicationLicenseStates } from '@atlassian/jira-shared-types/src/license-state.tsx';
import {
	type TenantContext,
	populateApplicationPermissions,
} from '@atlassian/jira-shared-types/src/tenant-context.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	NUMBER_CF_TYPE,
	STORY_POINT_ESTIMATE_CF_TYPE,
	URL_CF_TYPE,
	TEXT_CF_TYPE,
	LABELS_CF_TYPE,
	DATE_CF_TYPE,
	DATETIME_CF_TYPE,
	RADIO_BUTTONS_CF_TYPE,
	MULTI_CHECKBOXES_CF_TYPE,
	MULTI_SELECT_CF_TYPE,
	SELECT_CF_TYPE,
	MULTI_VERSION_CF_TYPE,
	VERSION_CF_TYPE,
	GROUP_CF_TYPE,
	MULTI_GROUP_CF_TYPE,
	USER_CF_TYPE,
	MULTI_USER_CF_TYPE,
	PEOPLE_CF_TYPE,
	FLAGGED_CF_TYPE,
	TEAM_CF_TYPE,
	PARENT_CF_TYPE,
	PARENT_TYPE as PARENT_FIELD_TYPE,
	BASELINE_START_CF_TYPE,
	BASELINE_END_CF_TYPE,
	SPRINT_TYPE as SPRINT_CF_GLOBAL_TYPE,
	ASSETS_CF_TYPE,
	SERVICE_ENTITY_CF_TYPE,
	RANK_CF_TYPE,
	APPROVERS_LIST_CF_TYPE,
	APPROVALS_CF_TYPE,
	APPROVALS_CF_TYPE as APPROVAL_DATA_FIELD_TYPE,
	REQUEST_PARTICIPANTS_CF_TYPE,
	ORGANIZATIONS_CF_TYPE,
	PRIORITY_TYPE as PRIORITY_FIELD_TYPE,
	PROJECT_PICKER_CF_TYPE,
	LABELS_TYPE as LABELS_FIELD_TYPE,
	SUMMARY_TYPE as SUMMARY_FIELD_TYPE,
	ATTACHMENT_TYPE as ATTACHMENT_FIELD_TYPE,
	TIME_TRACKING_TYPE as TIME_TRACKING_FIELD_TYPE,
	REPORTER_TYPE as REPORTER_FIELD_TYPE,
	ISSUE_LINKS_TYPE as ISSUE_LINKS_FIELD_TYPE,
	ASSIGNEE_TYPE as ASSIGNEE_FIELD_TYPE,
	DUE_DATE_TYPE as DUE_DATE_FIELD_TYPE,
	TIME_ESTIMATE_CONFIG_TYPE as TIME_ESTIMATE_FIELD_TYPE,
	TIME_ESTIMATE_TYPE as ORIGINAL_TIME_ESTIMATE_FIELD_TYPE,
	STATUS_TYPE as STATUS_FIELD_TYPE,
	FIX_VERSIONS_TYPE as FIX_VERSIONS_FIELD_TYPE,
	COMMENT_TYPE as COMMENT_FIELD_TYPE,
	WATCHES_TYPE as WATCHES_FIELD_TYPE,
	EPIC_TYPE as EPIC_FIELD_TYPE,
	EPIC_NAME_TYPE,
	CASCADING_SELECT_CF_TYPE,
	MESSAGE_EDIT_CF_TYPE,
	MESSAGE_VIEW_CF_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
	COMPONENTS_TYPE as COMPONENTS_FIELD_TYPE,
	RESPONDERS_CF_TYPE as RESPONDERS_FIELD_TYPE,
	BASELINE_START_CF_TYPE as BASELINE_START_FIELD_TYPE,
	BASELINE_END_CF_TYPE as BASELINE_END_FIELD_TYPE,
	WORK_LOG_TYPE as WORK_LOG_FIELD_TYPE,
	SECURITY_LEVEL_TYPE as SECURITY_LEVEL_FIELD_TYPE,
	TIME_SPENT_TYPE as TIME_SPENT_FIELD_TYPE,
	RESOLUTION_TYPE as RESOLUTION_FIELD_TYPE,
	AFFECTS_VERSIONS_TYPE as AFFECTS_VERSIONS_FIELD_TYPE,
	CATEGORY_TYPE,
	ENTITLEMENT_CF_TYPE,
	SENTIMENT_CF_TYPE,
	GOALS_CF_TYPE,
	ORGANIZATION_CF_TYPE,
} from '@atlassian/jira-platform-field-config';

export const TASK = 'task' as const;
export const BUG = 'bug' as const;
export const STORY = 'story' as const;
export const EPIC = 'epic' as const;
export const SUB_TASK = 'subtask' as const;
export const CUSTOM = 'custom' as const;

export type TemplateId = typeof TASK | typeof BUG | typeof STORY | typeof EPIC | typeof SUB_TASK;

export const TEMPLATES_WHITELIST = [TASK, BUG, STORY, EPIC] as const;
export const TEXT_AREA_CF_TYPE = TEXT_AREA_CF_TYPE_ORIGINAL;
export const DESCRIPTION_FIELD_TYPE = DESCRIPTION_TYPE;
export const ENVIRONMENT_FIELD_TYPE = ENVIRONMENT_TYPE;

// whitelisted custom field name
export const STORY_POINT_ESTIMATE_CF_NAME = 'Story point estimate' as const;
export const STORY_POINT_ESTIMATE_CF_ANALYTIC_TYPE = 'storyPointEstimate' as const;

// just a magic number at the moment (I got it checking actual height of breadcrumbs in devtools)
// it should work fine for one-line breadcrumbs, but will not work for multiline case
// we will get rid of this temporary solution soon - https://donut-world-dogfood.atlassian.net/browse/MC-1848
export const breadcrumbsHeight = 44;

export const MULTILINE_FIELDS: ReadonlyArray<string> = [
	DESCRIPTION_FIELD_TYPE,
	TEXT_AREA_CF_TYPE,
	ENVIRONMENT_FIELD_TYPE,
];

export const JIRA_SOFTWARE_APP_NAME = 'Jira Software';
export const JIRA_SERVICE_MANAGEMENT_APP_NAME = 'Jira Service Management';
export const JIRA_CORE_APP_NAME = 'Jira Work Management';
export const JIRA_OPS_APP_NAME = 'Jira Ops';
export const JIRA_PRODUCT_DISCOVERY_APP_NAME = 'Jira Product Discovery';
export const JIRA_CUSTOMER_SERVICE_APP_NAME = 'Jira Customer Service';
export const JIRA_APP_NAME = 'Jira';

export const toPackageName = (packageName: string) => packageName;

export const PACKAGE_NAME = toPackageName('jiraProjectSettingsAppsCommon');

// Prefix to be used when generating an optimisticId for a new field option. This is to avoid collision with existing options.
export const NEW_FIELD_OPTION_PREFIX = 'NEW_';

// UI interactions (prefix is needed because all Jira uses the same analytics event for interactions)
export const OPEN_ADD_ISSUE_TYPE_MODAL_INTERACTION = 'swift_openAddIssueTypeModal';

// TODO: MCD-2089 revise these field types. These are not proper field types.
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const RADIO_FIELD_TYPE = 'radio' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const USERPICKER_FIELD_TYPE = 'userpicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const MULTIUSERPICKER_FIELD_TYPE = 'multiuserpicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const SELECT_FIELD_TYPE = 'select' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const CASCADINGSELECT_FIELD_TYPE = 'cascadingselect' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const MULTISELECT_FIELD_TYPE = 'multiselect' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const CHECKBOX_FIELD_TYPE = 'checkbox' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const TEXT_FIELD_TYPE = 'text' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const NUMBER_FIELD_TYPE = 'number' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const DATETIME_FIELD_TYPE = 'datetime' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const PROJECTPICKER_FIELD_TYPE = 'projectpicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const DATE_FIELD_TYPE = 'date' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const ASSETSPICKER_FIELD_TYPE = 'assetspicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const LABELPICKER_FIELD_TYPE = 'labelpicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const GROUPPICKER_FIELD_TYPE = 'grouppicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const MULTIGROUPPICKER_FIELD_TYPE = 'multigrouppicker' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const VERSION_FIELD_TYPE = 'version' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const MULTIVERSION_FIELD_TYPE = 'multiversion' as const;
/**
 * @deprecated instead import from '@atlassian/jira-platform-field-config'
 * This is not a valid custom field type string - it is some kind of modification or simplification.
 * I suspect for JSM portal or similar. It doesn't appear anywhere else in Jira.
 */
export const TEXTAREA_FIELD_TYPE = 'textarea' as const;

export const emptyTenantContext = (): TenantContext => ({
	appEditions: populateApplicationEditions(),
	appPermissions: populateApplicationPermissions(),
	applicationKey: null,
	areProductSuggestionsEnabled: false,
	appUpdatesAvailable: null,
	atlassianAccountId: null,
	baseUrl: toBaseUrl(''),
	cloudId: toCloudId(''),
	activationId: toActivationId(''),
	cloudName: toCloudName(''),
	devopsProjectSignupSource: toDevopsProjectSignupSource(''),
	environment: null,
	fabricActivityUrl: null,
	fabricNotificationLogUrl: null,
	fabricPfDirUrl: null,
	firstActivationDateMs: null,
	isAdmin: false,
	isAnonymous: false,
	isDevMode: false,
	isDevopsProjectCreationScenario: false,
	isMarketingOptOut: false,
	isSiteAdmin: false,
	languageTag: null,
	licenseStates: populateApplicationLicenseStates(),
	licensedProducts: {},
	locale: defaultLocale,
	loggedInUser: null,
	productCrossSellConfig: 'unknown',
	productEntitlementDetails: null,
	productPermissionGated: {
		core: false,
		software: false,
		serviceDesk: false,
		productDiscovery: false,
		customerService: false,
	},
	siteAdminStatus: 'unknown',
	userFullname: null,
	xsrfToken: null,
	hasDlpEnabled: false,
	avpWorkspaceId: null,
});
