import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

const JiraAdminProformaDataConnectionsEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jira-admin-proforma-data-connections" */ './src/ui'),
	),
	getPreloadProps: () => ({}),
});

export default JiraAdminProformaDataConnectionsEntryPoint;
