import React, { useEffect, type ComponentType } from 'react';
import { lazyAfterPaint, type LazyComponent } from '@atlassian/react-loosely-lazy';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { upflowIframeComponentEntrypoint } from './entrypoint.tsx';
import { useUpflowModal } from './controllers/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AsyncUpflowIframeComponentOld: LazyComponent<ComponentType<Record<any, any>>> =
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyAfterPaint(
		() =>
			import(/* webpackChunkName: "async-upflow-iframe-plugin" */ './main').then(
				(exportedModule) => exportedModule.UpflowIframeComponent,
			),
		{
			ssr: false,
		},
	);

const entryPointParams = {};
const props = {};
const AsyncUpflowIframeComponentNew = () => {
	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		upflowIframeComponentEntrypoint,
		entryPointParams,
	);

	const [{ renderUpflowModal }] = useUpflowModal();

	// Violating react-entrypoint/no-load-in-hooks so we can experiment faster: https://hello.atlassian.net/wiki/spaces/JIE/pages/4772823699/LDR+Entrypoint+Load+Via+Hooks+For+Faster+Experimentation
	// eslint-disable-next-line @atlassian/react-entrypoint/no-load-in-hooks
	useEffect(() => {
		if (renderUpflowModal) {
			entryPointActions.load();
		}
	}, [entryPointActions, renderUpflowModal]);

	return (
		<JiraEntryPointContainer
			id="issue-onboarding-modal-lazy"
			packageName="AsyncUpflowIframeComponent"
			placeholderName="async-upflow-iframe-modal"
			entryPointReferenceSubject={entryPointReferenceSubject}
			fallback={null}
			runtimeProps={props}
		/>
	);
};

export const AsyncUpflowIframeComponent = componentWithFG(
	'upflow_iframe_iv_llc_unnecessary_bundles',
	AsyncUpflowIframeComponentNew,
	AsyncUpflowIframeComponentOld,
);
