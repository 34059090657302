/**
 * @generated SignedSource<<b8d57635f3df30756ea3efbd20e0376f>>
 * @relayHash 1009b9493e0ecbdf137f707ec53a77f0
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 1867833d622fe0c6fe617bab04cc3bcc2aba00d6d8ebe81790a4c017c685763e

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { src_jiraBusinessOverviewTimelineQuery } from './src_jiraBusinessOverviewTimelineQuery.graphql';

import withNewRankField_provider from '@atlassian/jira-relay-provider/src/fun-1500-swap-business-inline-create-rank-fetcher.relayprovider';

const node: PreloadableConcreteRequest<src_jiraBusinessOverviewTimelineQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "1867833d622fe0c6fe617bab04cc3bcc2aba00d6d8ebe81790a4c017c685763e",
    "metadata": {},
    "name": "src_jiraBusinessOverviewTimelineQuery",
    "operationKind": "query",
    "text": null,
    "providedVariables": {
      "__relay_internal__pv__atlassianjirarelayprovidersrcfun1500swapbusinessinlinecreaterankfetcherrelayprovider": withNewRankField_provider
    }
  }
};

export default node;
