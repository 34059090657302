import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { componentFilterResource } from '@atlassian/jira-router-resources-service-desk-customer-service-escalations/src/services/component-filter-resource/index.tsx';
import { priorityFilterResource } from '@atlassian/jira-router-resources-service-desk-customer-service-escalations/src/services/prority-filter-resource/index.tsx';
import { customerServiceEscalationsRoute } from '@atlassian/jira-router-routes-servicedesk-customer-service-escalations-routes/src/customerServiceEscalationsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { EscalationsAppLayout as ServicedeskCustomerServiceEscalationsType } from '@atlassian/jira-spa-apps-servicedesk-customer-service-escalations/src/ui/index.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';

const LazyServicedeskCustomerServiceEscalations = lazyForPaint<
	typeof ServicedeskCustomerServiceEscalationsType
>(() =>
	markAsProductBundleReady(
		import(
			/* webpackChunkName: "async-servicedesk-customer-service-escalations", jiraSpaEntry: "async-servicedesk-customer-service-escalations" */ '@atlassian/jira-spa-apps-servicedesk-customer-service-escalations'
		),
	),
);

const ServicedeskCustomerServiceEscalations = () => (
	<LazyPage
		Page={LazyServicedeskCustomerServiceEscalations}
		pageId="spa-apps/servicedesk/customer-service-escalations"
		shouldShowSpinner
	/>
);

export const customerServiceEscalationsRouteEntry = createEntry(
	customerServiceEscalationsRoute,
	() => ({
		group: 'servicedesk',
		layout: serviceProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: AsyncSidebarServicedesk,
		},

		resources: [
			...getServiceDeskNavigationResources(),
			...getServicedeskPageContextResources(),
			getConsolidationStateResource(),
			componentFilterResource,
			priorityFilterResource,
		],

		component: ServicedeskCustomerServiceEscalations,
		perfMetricKey: 'jsm-customer-service-escalations-component-load',
		forPaint: [LazyAtlassianNavigation, LazyServicedeskCustomerServiceEscalations],

		meta: {
			reporting: {
				id: APP_NAMES.CUSTOMER_SERVICE_ESCALATIONS,
				packageName: toPackageName(APP_NAMES.CUSTOMER_SERVICE_ESCALATIONS),
				teamName: 'boysenberry',
			},
		},
	}),
);
