import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import {
	getServiceDeskNavigationResources,
	getServicedeskPageContextResources,
} from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { servicedeskIssueTypesResource } from '@atlassian/jira-router-resources-service-desk-issue-types/src/controllers/index.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import { jsmGSPPageRoute } from '@atlassian/jira-router-routes-servicedesk-getting-started-page-routes/src/jsmGSPPageRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import { GettingStartedPageSkeleton } from '@atlassian/jira-skeletons/src/ui/getting-started-page/GettingStartedPageSkeleton.tsx';
import { gettingStartedEntryPoint } from '@atlassian/jira-spa-apps-servicedesk-getting-started-page/entrypoint.tsx';
import type { Route } from '@atlassian/react-resource-router';

const gettingStartedPageEntryPoint = createPageEntryPoint({
	main: gettingStartedEntryPoint,
	topNavigationMenuId: MENU_ID.PROJECTS,
});

export const jsmGSPPageRouteEntry: Route = createEntry(jsmGSPPageRoute, () => ({
	group: 'servicedesk',
	layout: serviceProjectLayout,
	perfMetricKey: 'servicedesk-getting-started-page-load',
	skeleton: GettingStartedPageSkeleton,

	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
	},

	resources: [
		...getServiceDeskNavigationResources(),
		...getServicedeskPageContextResources(),
		staticProjectSidebarNavigationResource,
		servicedeskIssueTypesResource,
	],

	entryPoint() {
		return fg('gsp_production_rollout') ? gettingStartedPageEntryPoint : undefined;
	},

	component: ErrorPagesNotFound,
}));
