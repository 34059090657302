import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { softwareListEmbedRoute } from '@atlassian/jira-router-routes-software-list-routes/src/softwareListEmbedRoute.tsx';
import { ListEmbedSkeleton } from '@atlassian/jira-skeletons/src/ui/list/ListSkeleton.tsx';
import { listEmbedEntryPoint } from '@atlassian/jira-spa-apps-jsw-list-embed/entrypoint.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getListProjectResources } from './common/getListProjectResources.tsx';

const listEmbedPageEntrypoint = createChromelessEntryPoint({
	main: listEmbedEntryPoint,
});

export const softwareListEmbedRouteEntry: Route = createEntry(softwareListEmbedRoute, () => ({
	group: 'software',
	skeleton: ListEmbedSkeleton,
	entryPoint() {
		return listEmbedPageEntrypoint;
	},
	layout: chromelessLayout,
	resources: [...getNavigationResources(), ...getListProjectResources()],
	ufoName: 'next-gen-list-embed',
}));
