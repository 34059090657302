import React, { type ReactNode, type ComponentType } from 'react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { messages } from './messages.tsx';

type SpotlightProps = typeof JiraSpotlight extends ComponentType<infer TProps> ? TProps : unknown;

interface Props {
	target: string;
	actionsBeforeElement: ReactNode;
	headingAfterElement: ReactNode;
	actions: (arg0: { learnMoreURL: string }) => SpotlightProps['actions'];
}

export const FindYourLookSpotlight = ({
	target,
	actionsBeforeElement,
	headingAfterElement,
	actions,
}: Props) => {
	const { formatMessage } = useIntl();
	return (
		<JiraSpotlight
			target={target}
			targetRadius={0}
			targetBgColor={token('elevation.surface')}
			heading={formatMessage(messages.title)}
			actionsBeforeElement={actionsBeforeElement}
			actions={actions({
				learnMoreURL:
					'https://support.atlassian.com/jira-software-cloud/docs/change-how-advanced-roadmaps-displays-your-timeline-data/',
			})}
			headingAfterElement={headingAfterElement}
			messageId="portfolio-3-onboarding.ui.onboarding.spotlight-find-your-look.jira-spotlight"
			messageType="transactional"
		>
			<FormattedMessage
				{...(fg('jira-issue-terminology-refresh-m3')
					? messages.contentIssueTermRefresh
					: messages.content)}
			/>
		</JiraSpotlight>
	);
};
