import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { advancedRoadmapsPlanPageInitialChecksResource } from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { planSetupPageRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-routes/src/planSetupPageRoute.tsx';
import type { Route } from '@atlassian/react-resource-router';
import SetupPage, { LazyAdvancedRoadmapsSetup } from './ui/setup/index.tsx';

export const planSetupPageRouteEntry: Route = createEntry(planSetupPageRoute, () => ({
	group: 'software',
	component: SetupPage,
	layout: globalLayout,
	resources: [...getNavigationResources(), advancedRoadmapsPlanPageInitialChecksResource],
	forPaint: [LazyAtlassianNavigation, LazyAdvancedRoadmapsSetup],
}));
