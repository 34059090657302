/**
 * @generated SignedSource<<46d6a14a65cafb396551e8049ebd15f3>>
 * @relayHash a45b5f6c08f3a6ea3d47dede57b1c29b
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 600eeb3604fb7597ab894f00224896ef6e213410aa7d0aff381533abe958cc8f

import type { ConcreteRequest, Query } from 'relay-runtime';
export type displaySettingsProviderQuery$variables = {
  entityAri: string;
  ownerAri: string;
};
export type displaySettingsProviderQuery$data = {
  readonly settings_navigationCustomisation: {
    readonly properties: {
      readonly nodes: ReadonlyArray<{
        readonly key: string | null | undefined;
        readonly value: string | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type displaySettingsProviderQuery = {
  response: displaySettingsProviderQuery$data;
  variables: displaySettingsProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "entityAri"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ownerAri"
  }
],
v1 = [
  {
    "args": [
      {
        "kind": "Variable",
        "name": "entityAri",
        "variableName": "entityAri"
      },
      {
        "kind": "Variable",
        "name": "ownerAri",
        "variableName": "ownerAri"
      }
    ],
    "concreteType": "SettingsNavigationCustomisation",
    "kind": "LinkedField",
    "name": "settings_navigationCustomisation",
    "plural": false,
    "selections": [
      {
        "concreteType": "SettingsDisplayPropertyConnection",
        "kind": "LinkedField",
        "name": "properties",
        "plural": false,
        "selections": [
          {
            "concreteType": "SettingsDisplayProperty",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "key"
              },
              {
                "kind": "ScalarField",
                "name": "value"
              }
            ]
          }
        ]
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "displaySettingsProviderQuery",
    "selections": (v1/*: any*/),
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "displaySettingsProviderQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "600eeb3604fb7597ab894f00224896ef6e213410aa7d0aff381533abe958cc8f",
    "metadata": {},
    "name": "displaySettingsProviderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "2793892e6d73cccb3048f4b7aa91df9a";

export default node;
