import { useEffect, useMemo, useRef, useState } from 'react';
import isEqual from 'lodash/isEqual';
import {
	type FetchPolicy,
	type PreloadedQuery,
	fetchQuery,
	graphql,
	useQueryLoader,
	useRelayEnvironment,
} from 'react-relay';
import type { Subscription } from 'relay-runtime';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import type {
	hydrateJqlBuilderQuery,
	hydrateJqlBuilderQuery$variables,
	JiraJqlViewContext,
} from '@atlassian/jira-relay/src/__generated__/hydrateJqlBuilderQuery.graphql';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';

export type PreloadedHydrateJqlBuilderQuery = PreloadedQuery<hydrateJqlBuilderQuery>;

/* eslint-disable @atlassian/relay/must-colocate-fragment-spreads, @atlassian/relay/unused-fields */
export const HYDRATION_QUERY = graphql`
	query hydrateJqlBuilderQuery(
		$accountId: ID!
		$cloudId: ID!
		$includeUser: Boolean!
		$includeJira: Boolean!
		$jql: String!
		$viewContext: JiraJqlViewContext
		$isFilter: Boolean!
		$filterAri: ID!
	) {
		...ui_jqlBuilder_JQLBuilderUI_hydrationQueryData
		...useHydratedValues
		...jqlEditor_jqlBuilderBasic_JQLEditorUI
		...useQuery
		...useHydratedValues_cascadeSelectPicker
		# Force suspension of consumer while hydration data is not present
		jira @include(if: $includeJira) {
			jqlBuilder(cloudId: $cloudId) {
				hydrateJqlQuery(query: $jql, viewContext: $viewContext) @skip(if: $isFilter) {
					__typename
					... on JiraJqlHydratedQuery {
						jql
					}
				}
				hydrateJqlQueryForFilter(id: $filterAri, viewContext: $viewContext)
					@include(if: $isFilter) {
					__typename
					... on JiraJqlHydratedQuery {
						jql
					}
				}
			}
		}
	}
`;
/* eslint-enable @atlassian/relay/must-colocate-fragment-spreads, @atlassian/relay/unused-fields */

type UsePrefetchedQuery = {
	variables: hydrateJqlBuilderQuery$variables;
	fetchPolicy: FetchPolicy;
	isFetching: boolean;
};

// Prefetch query to avoid hitting Suspense boundary on re-hydration
export const usePrefetchedQuery = (
	variables: hydrateJqlBuilderQuery$variables,
): UsePrefetchedQuery => {
	const environment = useRelayEnvironment();
	const [isFetching, setIsFetching] = useState(false);
	// Variables for last completed prefetch (i.e. fetchQuery) operation
	const [prefetchedVariables, setPrefetchedVariables] = useState(variables);
	const [fetchPolicy, setFetchPolicy] = useState<FetchPolicy>('store-or-network');
	// Variables used by in-flight request, used to deduplicate requests
	const inFlightRequest = useRef<Subscription>();
	const inFlightVariables = useRef<hydrateJqlBuilderQuery$variables>();

	useEffect(() => {
		if (
			!isEqual(variables, prefetchedVariables) &&
			!isEqual(variables, inFlightVariables.current)
		) {
			// Cancel previous in-flight request to stop further processing (and prevent race conditions) if a new one comes in
			inFlightRequest.current?.unsubscribe();
			inFlightVariables.current = variables;
			setIsFetching(true);
			// Prefetch data, then update returned variables on complete
			inFlightRequest.current = fetchQuery<hydrateJqlBuilderQuery>(
				environment,
				HYDRATION_QUERY,
				variables,
				{
					fetchPolicy: 'store-or-network',
					networkCacheConfig: {
						metadata: { META_SLOW_ENDPOINT: true },
					},
				},
			).subscribe({
				complete: () => {
					setPrefetchedVariables(variables);
					setFetchPolicy('store-only');
					setIsFetching(false);
					inFlightVariables.current = undefined;
				},
				error: (error: Error) => {
					setIsFetching(false);
					fireErrorAnalytics({
						meta: {
							id: 'hydrateJqlBuilderQuery',
							packageName: 'jiraJqlBuilderCommon',
							teamName: 'empanada',
						},
						error,
						sendToPrivacyUnsafeSplunk: true,
					});
				},
			});
		}
	}, [environment, prefetchedVariables, variables]);

	return useMemo(
		() => ({
			variables: prefetchedVariables,
			fetchPolicy,
			isFetching,
		}),
		[prefetchedVariables, fetchPolicy, isFetching],
	);
};

/**
 * Hydrates the provided JQL query, skipping network request if the query is empty.
 *
 * Returns an array containing:
 * – [0] reference to the hydration query that can be used in `usePreloadedQuery`
 * – [1] boolean that indicates fetching status (true if it's currently fetching data)
 *
 * Note: Hydrating through this hook will not suspend the consumer, as it does an internal data prefetch with `fetchQuery`.
 *
 * @param query – JQL query to be hydrated
 */
export const useHydrationQueryLoader = (
	query: string,
	viewContext: JiraJqlViewContext | null,
): [PreloadedHydrateJqlBuilderQuery | null | undefined, boolean] => {
	const accountId = useAccountId();
	const cloudId = useCloudId();

	const { variables, fetchPolicy, isFetching } = usePrefetchedQuery({
		accountId: accountId ?? '',
		cloudId,
		includeUser: query.length > 0 && !!accountId,
		includeJira: query.length > 0,
		jql: query,
		isFilter: false,
		filterAri: '',
		viewContext,
	});

	const [queryReference, loadQuery] = useQueryLoader<hydrateJqlBuilderQuery>(HYDRATION_QUERY);

	// @see https://hello.atlassian.net/wiki/spaces/~104598762/pages/2206935637/Why+do+we+need+to+re-hydrate+the+refinement+bar+on+every+change
	// Tl;DR – We refetch hydration data on every query change (instead of just fetching it on mount) for two reasons:
	// 1. There are external JQL changes that don't unmount the basic mode component and require hydration
	//    (e.g. changing filters in the sidebar, or clicking on the Reset button)
	// 2. We also rely on hydration API to render the invalid state when a particular field value is out of scope for
	//    the current query (e.g. if a selected status doesn't belong to one of the selected projects) – we could
	//    technically avoid rehydrating in this case if there isn't a change to scoping fields (like project or issue
	//    type), but it's probably not worth the extra complexity
	useEffect(() => {
		loadQuery(variables, {
			fetchPolicy,
			networkCacheConfig: {
				metadata: { META_SLOW_ENDPOINT: true },
			},
		});
	}, [fetchPolicy, loadQuery, variables]);

	return [queryReference, isFetching];
};
