import {
	createContainer,
	createHook,
	createStateHook,
	createStore,
} from '@atlassian/react-sweet-state';
import { fg } from '@atlassian/jira-feature-gating';
import { actions, type Actions } from '../actions/index.tsx';
import type { ActionApi, ContainerProps, IconType, State } from '../common/types.tsx';
import { sortReferenceTypes, sortStatusTypes } from '../common/utils/index.tsx';

export const initialState: State = {
	loading: false,
	error: undefined,
	config: undefined,
	referenceTypes: {
		data: undefined,
		createLoading: false,
		createError: undefined,
		deleteLoading: false,
		deleteError: undefined,
		updateLoading: false,
		updateError: undefined,
	},
	statusTypes: {
		data: undefined,
		createLoading: false,
		createError: undefined,
		deleteLoading: false,
		deleteError: undefined,
		updateLoading: false,
		updateError: undefined,
	},
	icons: {
		data: undefined,
		deleteLoading: false,
		deleteError: undefined,
		addLoading: false,
		addError: undefined,
		updateLoading: false,
		updateError: undefined,
	},
	dataManager: {
		rolesData: undefined,
		rolesError: undefined,
		rolesLoading: false,
		rolesUpdateLoading: false,
		rolesUpdateError: undefined,
		tokensLoading: false,
		tokensData: undefined,
		tokensError: undefined,
		tokenRevokeLoading: false,
		tokenRevokeError: undefined,
		isProvisioned: undefined,
		provisioningLoading: false,
		provisioningError: undefined,
		provisioningUpdateLoading: false,
		provisioningUpdateError: undefined,
		myRoles: undefined,
		myRolesError: undefined,
		myRolesLoading: false,
	},
};

const Store = createStore({
	initialState,
	actions,
});

export const useInsightGlobalConfig = createHook(Store, {
	selector: (state) => state,
});

export const useInsightReferenceTypes = createHook(Store, {
	selector: ({ referenceTypes }) => ({
		...referenceTypes,
		data: referenceTypes.data ? sortReferenceTypes(referenceTypes.data) : undefined,
	}),
});

export const useInsightStatusTypes = createHook(Store, {
	selector: ({ statusTypes }) => ({
		...statusTypes,
		data: statusTypes.data ? sortStatusTypes(statusTypes.data) : undefined,
	}),
});

export const useInsightIcons = createHook(Store, {
	selector: ({ icons }) => icons,
});

export const useDataManager = createHook(Store, {
	selector: ({ dataManager }) => dataManager,
});

export const useNamedInsightIcons = createStateHook(Store, {
	selector: ({ icons }) => {
		const map: Record<string, IconType> = {};
		(icons.data ?? []).forEach((icon) => {
			map[icon.name] = icon;
		});
		return map;
	},
});

export const isRestrictedSchema = (state: State, schemaId: string): boolean => {
	const restrictedObjectSchemaIds = state.config?.restrictedObjectSchemaIds ?? [];
	return restrictedObjectSchemaIds.includes(parseInt(schemaId, 10));
};

export const useIsRestrictedSchema = createHook(Store, {
	selector: isRestrictedSchema,
});

const autoFetchGlobalConfig =
	(): ActionApi =>
	({ dispatch, getState }, { disableOnInit }) => {
		const { loading, error, config } = getState();

		if (!disableOnInit && !loading && !error && !config) {
			dispatch(actions.fetchGlobalConfig());
			if (fg('assets_as_an_app_v2')) {
				dispatch(actions.fetchProvisioningStatus());
				dispatch(actions.fetchMyDataManagerRoles());
			}
		}
	};

export const InsightGlobalConfigContainer = createContainer<State, Actions, ContainerProps>(Store, {
	onInit: autoFetchGlobalConfig,
});
