import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { servicedeskCreateProjectRoute } from '@atlassian/jira-router-routes-servicedesk-create-project-routes/src/servicedeskCreateProjectRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type ServiceDeskCreateProjectType from '@atlassian/jira-spa-apps-servicedesk-create-project/src/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';

const LazyServiceDeskCreateProject = lazyForPaint<typeof ServiceDeskCreateProjectType>(
	() =>
		import(
			/* webpackChunkName: "async-servicedesk-create-project", jiraSpaEntry: "async-servicedesk-create-project" */ '@atlassian/jira-spa-apps-servicedesk-create-project'
		),
);

const ServiceDeskCreateProject = () => (
	<LazyPage
		Page={LazyServiceDeskCreateProject}
		pageId="spa-apps/servicedesk/create-project"
		shouldShowSpinner
	/>
);

export const servicedeskCreateProjectRouteEntry: Route = createEntry(
	servicedeskCreateProjectRoute,
	() => ({
		group: 'servicedesk',
		isRedirect: true,
		component: ServiceDeskCreateProject,
		layout: serviceProjectLayout,
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
		],
		forPaint: [LazyAtlassianNavigation, LazyServiceDeskCreateProject],

		meta: {
			reporting: {
				id: APP_NAMES.CREATE_PROJECT,
				packageName: toPackageName(APP_NAMES.CREATE_PROJECT),
				teamName: 'itops-mithril',
			},
		},
	}),
);
