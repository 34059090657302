import React, { useCallback, useMemo } from 'react';
import { graphql, useRefetchableFragment } from 'react-relay';
import { useIntl } from '@atlassian/jira-intl';
import {
	MENU_ID_DASHBOARDS,
	MENU_ID_DASHBOARDS_VIEW_ALL_STARRED,
} from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { useSidebarNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/controllers/sidebar-context/index.tsx';
import { useRefetchWhenIsSelected } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/controller/use-refetch-when-is-selected/index.tsx';
import { Nav4MenuLinkItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-menu-link-item/index.tsx';
import { useSubscribeAndUpdateFavorites } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/services/set-favorite/index.tsx';
import { useStableItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-stable-items/index.tsx';
import { DashboardMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/ui/dashboard-menu-item/DashboardMenuItem.tsx';
import { DashboardsEmptyContent } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-dashboards/src/common/ui/empty-content/DashboardsEmptyContent.tsx';
import type { DashboardsContent$key } from '@atlassian/jira-relay/src/__generated__/DashboardsContent.graphql';
import QUERY, {
	type Nav4DashboardsContentQuery,
} from '@atlassian/jira-relay/src/__generated__/Nav4DashboardsContentQuery.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	MenuListItem,
	MenuList,
	MenuSection,
	MenuSectionHeading,
} from '@atlassian/navigation-system';
import messages from './messages.tsx';

const MAX_RECENT_PROJECTS = 5;
const MAX_FAVORITE_PROJECTS = 15;
const VIEW_ALL_STARRED_HREF = '/jira/your-work?starred';

type DashboardsContentProps = {
	queryRef: DashboardsContent$key;
};

export function DashboardsContent({ queryRef }: DashboardsContentProps) {
	const cloudId = useCloudId();
	const { isSelectedPath } = useSidebarNav4();

	const { getStableItems } = useStableItems();

	// https://jplat.jira.atlassian.cloud/browse/BLU-2719
	// We intentionally request 15 starred and 20 recent dashboards.
	// Then, if all 10 starred dashboards were recently visited, we still show 5 recent dashboards as well.
	const [
		{
			jira: { dashboardFavourites, dashboardRecents },
		},
		refetch,
	] = useRefetchableFragment<Nav4DashboardsContentQuery, DashboardsContent$key>(
		graphql`
			fragment DashboardsContent on Query
			@refetchable(queryName: "Nav4DashboardsContentQuery")
			@argumentDefinitions(currentURL: { type: URL }) {
				jira @required(action: THROW) {
					dashboardFavourites: favourites(
						cloudId: $cloudId
						filter: { type: DASHBOARD, sort: { order: DESC } }
						first: 15
					) @required(action: THROW) {
						__id
						pageInfo {
							hasNextPage
						}
						edges {
							node @required(action: THROW) {
								... on JiraDashboard {
									id @required(action: THROW)
									dashboardId
									...DashboardMenuItem
								}
							}
						}
					}
					dashboardRecents: recentItems(
						cloudId: $cloudId
						currentURL: $currentURL
						types: [DASHBOARD]
						first: 20
					) @required(action: THROW) {
						__id
						edges {
							node @required(action: THROW) {
								... on JiraDashboard {
									id @required(action: THROW)
									dashboardId
									...DashboardMenuItem
								}
							}
						}
					}
				}
			}
		`,
		queryRef,
	);

	useRefetchWhenIsSelected({
		cloudId,
		isSelectedPath,
		QUERY,
		refetch,
		menuId: MENU_ID_DASHBOARDS,
	});

	const refetchOnChange = useCallback(
		({ onComplete }: { onComplete: () => void }) => {
			refetch({ cloudId }, { fetchPolicy: 'store-and-network', onComplete });
		},
		[cloudId, refetch],
	);
	useSubscribeAndUpdateFavorites({
		entityTypename: 'JiraDashboard',
		favouriteConnectionId: dashboardFavourites.__id,
		recentConnectionId: dashboardRecents.__id,
		refetch: refetchOnChange,
	});

	const { formatMessage } = useIntl();
	const starred = formatMessage(messages.starred);
	const recent = formatMessage(messages.recent);

	const favorites = useMemo(() => {
		const ids = dashboardFavourites.edges?.map((edge) => edge?.node.id).filter(Boolean) ?? [];

		const list =
			dashboardFavourites.edges
				?.filter(Boolean)
				.map((edge) => <DashboardMenuItem key={edge.node.dashboardId} queryRef={edge.node} />) ??
			[];

		return {
			idSet: new Set(ids),
			list: list.slice(0, MAX_FAVORITE_PROJECTS),
			hasItems: Boolean(list.length),
			hasNextPage:
				dashboardFavourites?.pageInfo?.hasNextPage || list.length > MAX_FAVORITE_PROJECTS,
		};
	}, [dashboardFavourites]);

	const recentItems = useMemo(() => {
		const nodes =
			dashboardRecents.edges
				?.filter(Boolean)
				.filter((edge) => edge.node.id && !favorites.idSet.has(edge.node.id))
				.map((edge) => edge.node)
				.slice(0, MAX_RECENT_PROJECTS) || [];

		const stableOrderedNodes = getStableItems({ nodes, idKey: 'id' });

		const list = stableOrderedNodes.map((node) => (
			<DashboardMenuItem key={node.dashboardId} queryRef={node} />
		));

		return { list, hasItems: Boolean(list.length) };
	}, [dashboardRecents.edges, favorites.idSet, getStableItems]);

	if (!favorites.hasItems && !recentItems.hasItems) {
		return <DashboardsEmptyContent />;
	}

	return (
		<>
			{favorites.hasItems && (
				<>
					<MenuListItem>
						<MenuSection>
							<MenuSectionHeading>{starred}</MenuSectionHeading>
							<MenuList>{favorites.list}</MenuList>
						</MenuSection>
					</MenuListItem>
					{favorites.hasNextPage && (
						<Nav4MenuLinkItem
							href={VIEW_ALL_STARRED_HREF}
							menuId={MENU_ID_DASHBOARDS_VIEW_ALL_STARRED}
						>
							{formatMessage(messages.viewAllStarred)}
						</Nav4MenuLinkItem>
					)}
				</>
			)}

			{recentItems.hasItems && (
				<MenuListItem>
					<MenuSection>
						<MenuSectionHeading>{recent}</MenuSectionHeading>
						<MenuList>{recentItems.list}</MenuList>
					</MenuSection>
				</MenuListItem>
			)}
		</>
	);
}
