import React from 'react';
import { ThemeContainer } from '@atlassian/jira-controllers-global-theme/src/controllers/index.tsx';
import getMeta from '@atlassian/jira-get-meta';
import { createRouterSelector } from '@atlassian/react-resource-router';
import { DocumentTitleContainer } from '@atlassian/jira-global-document-title/src/DocumentTitleContainer.tsx';
import { ripTitleTemplateFromMeta } from '@atlassian/jira-global-document-title/src/utils.tsx';
import type { PageConfigContainersProps } from './types.tsx';

const useRouterRoute = createRouterSelector((state) => state.route);

export const PageConfigContainers = ({ children }: PageConfigContainersProps) => {
	const { PageConfigContainerOverride } = useRouterRoute();
	if (PageConfigContainerOverride !== undefined) {
		return <PageConfigContainerOverride>{children}</PageConfigContainerOverride>;
	}
	return (
		<ThemeContainer isGlobal getMeta={getMeta}>
			<DocumentTitleContainer titleSuffix={ripTitleTemplateFromMeta(getMeta)}>
				{children}
			</DocumentTitleContainer>
		</ThemeContainer>
	);
};
