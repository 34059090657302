import React from 'react';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { SupportWebsiteNav } from './ui/index.tsx';

const LazySupportWebsiteNav = lazyForPaint<typeof SupportWebsiteNav>(() =>
	import(/* webpackChunkName: "async-support-site-nav" */ './ui').then(
		({ SupportWebsiteNav }) => SupportWebsiteNav,
	),
);

export const AsyncSupportWebsiteNav = () => (
	<Placeholder name="horizontal-csm-support-site-nav" fallback={null}>
		<LazySupportWebsiteNav />
	</Placeholder>
);
