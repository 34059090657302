import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const helpCenterSettingsAnalyticsAppEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-help-center-settings-analytics-app" */ './src/ui'),
	),
	getPreloadProps: () => {
		return {};
	},
});
