import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	REQUESTS_ROUTE_SUFFIX,
	TIME_SCALE,
} from './common/constants.tsx';

export const servicedeskKbRequestsReportsTimeScaleRoute: InvariantRoute = {
	name: 'servicedesk-kb-requests-reports-time-scale',
	path: `${SERVICE_DESK_ROUTE_PREFIX}${REQUESTS_ROUTE_SUFFIX}${TIME_SCALE}`,
};
