import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncHorizontalNavJira } from '@atlassian/jira-horizontal-nav/src/async.tsx';
import { businessProjectLayout } from '@atlassian/jira-business-project-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarCore from '@atlassian/jira-navigation-apps-sidebar-core/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { jiraBusinessProjectRedirectRoute } from '@atlassian/jira-router-routes-business-redirect-routes/src/jiraBusinessProjectRedirectRoute.tsx';
import { RedirectToLastVisitedProjectPage } from '@atlassian/jira-router-routes-common/src/ui/last-visited-project-page/index.tsx';

export const jiraBusinessProjectRedirectRouteEntry = createEntry(
	jiraBusinessProjectRedirectRoute,
	() => ({
		group: 'classic-business',
		isRedirect: true,
		component: RedirectToLastVisitedProjectPage,
		layout: businessProjectLayout,

		navigation: {
			menuId: MENU_ID.PROJECTS,
			// delete when cleaning up getWillShowNav4
			sidebar: AsyncSidebarCore,
			horizontal: AsyncHorizontalNavJira,
		},

		resources: getProjectViewResources(),
		forPaint: [LazyAtlassianNavigation],
	}),
);
