import { chromelessLayout } from '@atlassian/jira-chromeless-layout/src/index.tsx';
import { ThemedLazySkeleton } from '@atlassian/jira-project-theme-components/src/ui/ThemedLazySkeleton.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getProjectViewResources } from '@atlassian/jira-router-resources-business-common/src/index.tsx';
import { classicBusinessProjectFormSubmitEmbedRoute } from '@atlassian/jira-router-routes-business-routes/src/classicBusinessProjectFormSubmitEmbedRoute.tsx';
import { formSubmitEmbedPageEntrypoint } from './ui/spa/business/form-submit-embed/index.tsx';

export const classicBusinessProjectFormSubmitEmbedRouteEntry = createEntry(
	classicBusinessProjectFormSubmitEmbedRoute,
	() => ({
		group: 'classic-business',
		ufoName: 'jwm.form-submit-embed-view',
		layout: chromelessLayout,
		skeleton: ThemedLazySkeleton,
		resources: getProjectViewResources(),

		entryPoint: () => formSubmitEmbedPageEntrypoint,
	}),
);
