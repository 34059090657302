import React from 'react';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { boardsDirectoryResource } from '@atlassian/jira-router-resources-directories-boards/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { boardsDirectoryRoute } from '@atlassian/jira-router-routes-directories-routes/src/boardsDirectoryRoute.tsx';
import { BoardsDirectorySkeleton } from '@atlassian/jira-skeletons/src/ui/directories/FiltersDirectorySkeleton.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { LazyBoardsDirectory } from './ui/boards/index.tsx';

const BoardsDirectoryComponent = () => (
	<LazyPage Page={LazyBoardsDirectory} pageId="boards-directory" shouldShowSpinner={false} />
);

export const boardsDirectoryRouteEntry = createEntry(boardsDirectoryRoute, () => ({
	group: 'directories',
	component: BoardsDirectoryComponent,
	skeleton: BoardsDirectorySkeleton,
	layout: globalLayout,
	resources: [...getNavigationResources(), boardsDirectoryResource],

	navigation: {
		menuId: MENU_ID.PROJECTS,
	},

	forPaint: [LazyAtlassianNavigation, LazyBoardsDirectory],
}));
