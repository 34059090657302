/**
 * @generated SignedSource<<e4100b4266ad9f2bb1c31ed34ee8522f>>
 * @relayHash 99bea4c9d007a836d2f2aee3f9d2e63e
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID 7050d00d8eefcfbd4847d47076260938acf8d0a28ab345db32aaed09d76e8fc5

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4FiltersContentQuery$variables = {
  cloudId: string;
  currentURL?: AGG$URL | null | undefined;
};
export type Nav4FiltersContentQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4FiltersContent">;
};
export type Nav4FiltersContentQuery = {
  response: Nav4FiltersContentQuery$data;
  variables: Nav4FiltersContentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cloudId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentURL"
  }
],
v1 = {
  "kind": "Variable",
  "name": "currentURL",
  "variableName": "currentURL"
},
v2 = {
  "kind": "Variable",
  "name": "cloudId",
  "variableName": "cloudId"
},
v3 = {
  "kind": "ScalarField",
  "name": "__typename"
},
v4 = {
  "kind": "ScalarField",
  "name": "filterId"
},
v5 = {
  "kind": "ScalarField",
  "name": "name"
},
v6 = {
  "kind": "ScalarField",
  "name": "id"
},
v7 = {
  "kind": "ScalarField",
  "name": "isFavourite"
},
v8 = {
  "kind": "ScalarField",
  "name": "jql"
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "isEditable"
    },
    {
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": [
        (v3/*: any*/),
        {
          "kind": "ScalarField",
          "name": "accountId"
        },
        (v6/*: any*/)
      ]
    }
  ],
  "type": "JiraCustomFilter"
},
v10 = {
  "kind": "InlineFragment",
  "selections": [
    (v6/*: any*/)
  ],
  "type": "Node",
  "abstractKey": "__isNode"
},
v11 = {
  "kind": "ClientExtension",
  "selections": [
    {
      "kind": "ScalarField",
      "name": "__id"
    }
  ]
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "name": "Nav4FiltersContentQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "Nav4FiltersContent"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Nav4FiltersContentQuery",
    "selections": [
      {
        "concreteType": "JiraQuery",
        "kind": "LinkedField",
        "name": "jira",
        "plural": false,
        "selections": [
          {
            "alias": "favouriteFilters",
            "args": [
              (v2/*: any*/),
              {
                "kind": "Literal",
                "name": "filter",
                "value": {
                  "sort": {
                    "order": "DESC"
                  },
                  "type": "FILTER"
                }
              },
              {
                "kind": "Literal",
                "name": "first",
                "value": 15
              }
            ],
            "concreteType": "JiraFavouriteConnection",
            "kind": "LinkedField",
            "name": "favourites",
            "plural": false,
            "selections": [
              {
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "kind": "ScalarField",
                    "name": "hasNextPage"
                  }
                ]
              },
              {
                "concreteType": "JiraFavouriteEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "type": "JiraFilter",
                        "abstractKey": "__isJiraFilter"
                      },
                      (v10/*: any*/)
                    ]
                  }
                ]
              },
              (v11/*: any*/)
            ]
          },
          {
            "alias": "recentFilters",
            "args": [
              (v2/*: any*/),
              (v1/*: any*/),
              {
                "kind": "Literal",
                "name": "first",
                "value": 20
              },
              {
                "kind": "Literal",
                "name": "types",
                "value": [
                  "FILTER"
                ]
              }
            ],
            "concreteType": "JiraSearchableEntityConnection",
            "kind": "LinkedField",
            "name": "recentItems",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSearchableEntityEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              (v9/*: any*/)
                            ],
                            "type": "JiraFilter",
                            "abstractKey": "__isJiraFilter"
                          }
                        ],
                        "type": "JiraCustomFilter"
                      },
                      (v10/*: any*/)
                    ]
                  }
                ]
              },
              (v11/*: any*/)
            ]
          },
          {
            "args": [
              (v2/*: any*/)
            ],
            "concreteType": "JiraSystemFilterConnection",
            "kind": "LinkedField",
            "name": "systemFilters",
            "plural": false,
            "selections": [
              {
                "concreteType": "JiraSystemFilterEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "concreteType": "JiraSystemFilter",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v5/*: any*/),
                          (v3/*: any*/),
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/)
                        ],
                        "type": "JiraFilter",
                        "abstractKey": "__isJiraFilter"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "7050d00d8eefcfbd4847d47076260938acf8d0a28ab345db32aaed09d76e8fc5",
    "metadata": {},
    "name": "Nav4FiltersContentQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3f2ab01091a0154bb13d2ed1b15e13ef";

export default node;
