import type { FlagConfiguration } from '@atlassian/jira-flags';
import { messages } from './messages.tsx';

export const errorFlag = (): FlagConfiguration => ({
	type: 'error',
	title: messages.title,
	description: messages.description,
	messageId: 'spa-apps-common.redirect.legacy-routing.flags.errorFlag',
	messageType: 'transactional',
	actions: [
		{
			content: messages.goHome,

			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			onClick: () => window.location.assign('/'),
		},
	],
});
