import { createContext, type ComponentType } from 'react';

export type ModalComponentProps = {
	onClose: () => void;
	shouldCloseOnOverlayClick?: boolean;
	shouldCloseOnEscapePress?: boolean;
	setShouldCloseOnOverlayClick: (shouldCloseOnOverlayClick: boolean) => void;
	setShouldCloseOnEscapePress: (shouldCloseOnEscapePress: boolean) => void;
};

export type ModalComponent = ComponentType<ModalComponentProps>;

export const ModalContext = createContext<{
	openModal: (component: ModalComponent) => void;
}>({
	// Replace with lodash/noop
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	openModal: () => {},
});
