/**
 * @generated SignedSource<<88ca73ae55daccedd91d86ba2fee8d43>>
 * @relayHash 78fb356e6ea43583551f163d6ced2e44
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID fe53adcfcad2d0e19177edc15ca83f107e876141ec50ea7dc332b4e3a4b1b4ec

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { PlaybookListQuery } from './PlaybookListQuery.graphql';

const node: PreloadableConcreteRequest<PlaybookListQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "fe53adcfcad2d0e19177edc15ca83f107e876141ec50ea7dc332b4e3a4b1b4ec",
    "metadata": {},
    "name": "PlaybookListQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
