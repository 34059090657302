import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import parameters from '@atlassian/jira-relay/src/__generated__/src_jiraBusinessBoardEmbedQuery$parameters';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const boardEmbedEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-business-board-embed-entrypoint" */ './src'),
	),
	getPreloadProps: ({ context, tenantContext: { cloudId } }: EntryPointRouteParams) => {
		const projectKey = context.match.params.projectKey;
		const itemId = context.match.params.itemId ?? '';
		const filterJql = context.query.filter;
		const groupBy = context.query.groupBy;

		if (projectKey == null) {
			throw new Error('Project key missing');
		}

		return {
			queries: {
				boardEmbedQuery: {
					options: {
						fetchPolicy: 'store-and-network' as const,
					},
					parameters,
					variables: {
						cloudId,
						projectKey,
						viewInput: {
							jiraBoardViewQueryInput: {
								projectKeyAndItemIdQuery: {
									cloudId,
									projectKey,
									itemId,
								},
							},
							settings: {
								filterJql,
								groupBy,
							},
						},
					},
				},
			},
		};
	},
});
