import React, { useMemo, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import { OnboardingSpotlightMaybe } from '@atlassian/jira-atlassian-onboarding-nav4/src/ui/onboarding-spotlight-maybe/index.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { isNormalClick } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/is-normal-click.tsx';
import { useProjectsAnalyticsL1 } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/controllers/use-projects-analytics-l1/index.tsx';
import { ENTITY_ID } from '@atlassian/jira-navigation-apps-sidebar-nav4-context/src/common/constants.tsx';
import { Nav4ExpandableMenuItem } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/nav4-expandable-menu-item/index.tsx';
import { SkeletonNavMenuItems } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/skeleton-nav-menu-items/main.tsx';
import { useGetLinkProps } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-link-props/index.tsx';
import { getTestId } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/get-test-id/index.tsx';
import { useExpandableEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-expandable-entry-point-trigger/index.tsx';
import { removeBaseUrl } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/build-project-url/index.tsx';
import type { JswExpandableProject$key } from '@atlassian/jira-relay/src/__generated__/JswExpandableProject.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import {
	ExpandableMenuItemContent,
	ExpandableMenuItemTrigger,
	ContainerAvatar as Avatar,
} from '@atlassian/navigation-system';
import { ProjectActions } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-content-projects-common/src/ui/project-actions/ProjectActions.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useHoverEntryPointTrigger } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/controllers/use-hover-entry-point-trigger/index.tsx';
import type { EntryPointRouteParams } from '@atlassian/jira-entry-points-plugin/src/common/types.tsx';
import { RouteEntryPointAdapter } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/route-entry-point-adapter/index.tsx';
import { LEFT_SIDEBAR } from '@atlassian/jira-entry-point-page-layout-fields/src/index.tsx';
import { usePageLayoutEntryPoint } from '@atlassian/jira-entry-point-page-layout/src/index.tsx';
import type { LeftSidebarEntryPointConfig } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/types.tsx';
import { NO_OF_BOARDS_TO_FETCH, PACKAGE_NAME, TEAM_NAME } from '../../common/constants.tsx';
import { nav4JSWProjectEntryPoint } from './async-content/entrypoint.tsx';
import { asyncJswExpandableProjectQuery } from './async-content/old/entrypoint.tsx';
import { asyncActionsOnHover } from './async-actions-on-hover/entrypoint.tsx';

type JswExpandableProjectProps = {
	projectData: JswExpandableProject$key;
	spotlight?: string;
};

export const JswExpandableProject = ({ projectData, spotlight }: JswExpandableProjectProps) => {
	const { sendProjectMenuItemAnalyticsEvent } = useProjectsAnalyticsL1();
	const project = useFragment<JswExpandableProject$key>(
		graphql`
			fragment JswExpandableProject on JiraProject {
				id
				projectKey: key @required(action: THROW)
				projectId @required(action: THROW)
				isFavourite
				projectType
				avatar @required(action: THROW) {
					large @required(action: THROW)
				}
				name @required(action: THROW)
				projectKey: key @required(action: THROW)
				webUrl @required(action: THROW)
				...ProjectActions
			}
		`,
		projectData,
	);
	const cloudId = useCloudId();
	const entryPointParamsOld = useMemo(
		() => ({ cloudId, projectId: project.id, noOfBoardsToFetch: NO_OF_BOARDS_TO_FETCH }),
		[cloudId, project.id],
	);

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const entryPointParams = useMemo(
		() => ({
			tenantContext: { cloudId },
			context: {
				match: {
					params: {
						projectKey: project.projectKey,
					},
				},
			},
		}),
		[cloudId, project.projectKey],
	) as unknown as EntryPointRouteParams;

	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const leftSidebarEPRef = usePageLayoutEntryPoint(LEFT_SIDEBAR) as LeftSidebarEntryPointConfig;
	const boardsEPRef = leftSidebarEPRef?.entryPoints?.boards;

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		nav4JSWProjectEntryPoint,
		entryPointParams,
	);
	const {
		entryPointActions: entryPointActionsOld,
		entryPointReferenceSubject: entryPointReferenceSubjectOld,
	} = useEntryPoint(asyncJswExpandableProjectQuery, entryPointParamsOld);

	const actionsOnHoverEntryPointParams = useMemo(
		() => ({ cloudId, projectId: project.id }),
		[cloudId, project.id],
	);
	const {
		entryPointActions: actionsOnHoverEntryPointActions,
		entryPointReferenceSubject: actionsOnHoverEntryPointReferenceSubject,
	} = useEntryPoint(asyncActionsOnHover, actionsOnHoverEntryPointParams);

	const triggerRef = useExpandableEntryPointTrigger(entryPointActions);
	const triggerRefOld = useExpandableEntryPointTrigger(entryPointActionsOld);

	const actionsOnHoverCollapsedRef = useHoverEntryPointTrigger(actionsOnHoverEntryPointActions);

	const { getLinkProps } = useGetLinkProps();

	// Create this component once, then keep using it FOREVER.
	// We don't want to keep switching the implementation as the user does SPA transitions.
	const [entryPointConfig] = useState(() => {
		const epContainerProps = {
			id: 'jsw-expandable-project',
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
			errorFallback: 'flag' as const,
			fallback: <SkeletonNavMenuItems />,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			runtimeProps: entryPointParams as never,
		};

		return {
			Component: () => (
				<RouteEntryPointAdapter
					epContainerProps={epContainerProps}
					jiraEPRef={entryPointReferenceSubject}
					nestedEPRef={boardsEPRef}
					placeholderEntryPoint={nav4JSWProjectEntryPoint}
				/>
			),
		};
	});

	const content = () => {
		if (fg('blu-5799-jira-software-board-entrypoint-route')) {
			return <entryPointConfig.Component />;
		}

		return (
			<JiraEntryPointContainer
				// Data stream, will subscribe to load updates.
				entryPointReferenceSubject={entryPointReferenceSubjectOld}
				// Required props for error JSErrorBoundary.
				id="jsw-expandable-project"
				packageName={PACKAGE_NAME}
				// Optional props for error JSErrorBoundary.
				errorFallback="flag"
				teamName={TEAM_NAME}
				// Fallback for `Suspense`.
				fallback={<SkeletonNavMenuItems />}
				// Props for the root component.
				runtimeProps={entryPointParamsOld}
			/>
		);
	};

	if (!project) {
		return null;
	}

	const popupItemAction = () => <ProjectActions projectFragment={project} />;
	const menuId = ENTITY_ID.PROJECT(project.projectKey);

	return (
		<Nav4ExpandableMenuItem
			menuId={menuId}
			onSystemToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					if (fg('blu-5799-jira-software-board-entrypoint-route')) {
						entryPointActions.load();
					} else {
						entryPointActionsOld.load();
					}

					if (fg('blu-6100-plan-action-menu-entrypoint')) {
						actionsOnHoverEntryPointActions.load();
					}
				}
			}}
			collapsedRef={
				fg('blu-6100-plan-action-menu-entrypoint') ? actionsOnHoverCollapsedRef : undefined
			}
			onExpansionToggle={(hasExpanded: boolean) => {
				if (hasExpanded) {
					if (fg('blu-5799-jira-software-board-entrypoint-route')) {
						entryPointActions.load();
					} else {
						entryPointActionsOld.load();
					}

					if (fg('blu-6100-plan-action-menu-entrypoint')) {
						actionsOnHoverEntryPointActions.load();
					}

					sendProjectMenuItemAnalyticsEvent({
						starred: project.isFavourite ?? false,
						recent: !project.isFavourite,
						projectId: project.projectId,
						projectType: project.projectType,
					});
				}
			}}
		>
			<OnboardingSpotlightMaybe key={project.projectKey} spotlight={spotlight}>
				<ExpandableMenuItemTrigger
					{...getLinkProps(removeBaseUrl(project.webUrl))}
					actionsOnHover={
						fg('blu-6100-plan-action-menu-entrypoint') ? (
							<JiraEntryPointContainer
								entryPointReferenceSubject={actionsOnHoverEntryPointReferenceSubject}
								id="jsw-expandable-project-actions"
								packageName={PACKAGE_NAME}
								errorFallback="flag"
								teamName={TEAM_NAME}
								runtimeProps={actionsOnHoverEntryPointParams}
							/>
						) : (
							popupItemAction()
						)
					}
					elemBefore={<Avatar src={project.avatar.large} />}
					ref={fg('blu-5799-jira-software-board-entrypoint-route') ? triggerRef : triggerRefOld}
					onClick={(ev) => {
						if (isNormalClick(ev)) {
							ev.preventDefault();
						}
					}}
					testId={getTestId(menuId)}
				>
					{project.name}
				</ExpandableMenuItemTrigger>
			</OnboardingSpotlightMaybe>
			<ExpandableMenuItemContent>{content()}</ExpandableMenuItemContent>
		</Nav4ExpandableMenuItem>
	);
};
