import React from 'react';
import ArrowUpRightIcon from '@atlaskit/icon/core/migration/arrow-up-right--open';
import Lozenge from '@atlaskit/lozenge';
import { Inline } from '@atlaskit/primitives';
import { useIntl } from '@atlassian/jira-intl';
import { ASSETS_ADAPTERS } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/item-id.tsx';
import { useFormattedMessageAndIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/utils/use-formatted-message-and-icon/index.tsx';
import { DataManagerAdaptersGlyph } from '@atlassian/jira-servicedesk-insight-landing-page/src/ui/data-manager-link/index.tsx';
import { getDataManagerAdaptersUrl } from '@atlassian/jira-servicedesk-insight-urls/src/index.tsx';
import { MenuLinkItem } from '@atlassian/navigation-system';
import { useAssetsUIAnalytics } from '../../use-assets-ui-analytics/index.tsx';
import messages from './messages.tsx';

export const DataManagerAdapterMenuItems = () => {
	const { formatMessage } = useIntl();

	const { icon: formattedAdapterIcon } = useFormattedMessageAndIcon(
		messages.jsmAssetsDataManagerAdapters,
		DataManagerAdaptersGlyph,
	);

	const { icon: formattedOpenIcon } = useFormattedMessageAndIcon(
		messages.jsmAssetsOpenDataManager,
		ArrowUpRightIcon,
	);

	const { fireUINavigationItemAnalytics } = useAssetsUIAnalytics();

	return (
		<MenuLinkItem
			elemBefore={formattedAdapterIcon}
			elemAfter={formattedOpenIcon}
			href={getDataManagerAdaptersUrl()}
			target="_blank"
			onClick={() => fireUINavigationItemAnalytics(ASSETS_ADAPTERS)}
		>
			<Inline space="space.050" alignBlock="baseline">
				{formatMessage(messages.jsmAssetsDataManagerAdapters)}
				<Lozenge appearance="new">{formatMessage(messages.assetsDataManagerBeta)}</Lozenge>
			</Inline>
		</MenuLinkItem>
	);
};
