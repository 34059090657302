import { fieldsResource } from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { navigationResource } from '@atlassian/jira-router-resources-business-navigation/src/index.tsx';
// eslint-disable-next-line camelcase
import { DEPRECATED_DO_NOT_USE_projectDetailsResource } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { projectCreatePermissionsResource } from '@atlassian/jira-router-resources-project-permissions/src/services/project-permissions/index.tsx';

export const getListProjectResources = () => [
	projectCreatePermissionsResource,
	navigationResource,
	// eslint-disable-next-line camelcase
	DEPRECATED_DO_NOT_USE_projectDetailsResource,
	fieldsResource,
];
