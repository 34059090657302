import camelCase from 'lodash/camelCase';
import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { forgeProjectModuleResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import { staticProjectSidebarConnectAddonsNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar-connect-addons/index.tsx';
import { staticProjectSidebarNavigationResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-project-sidebar/index.tsx';
import { staticSidebarShortcutsResource } from '@atlassian/jira-navigation-apps-resources/src/controllers/sidebar/static-sidebar-shortcuts/index.tsx';
import AsyncSidebarNextGen from '@atlassian/jira-navigation-apps-sidebar-next-gen/src/async.tsx';
import AsyncSidebarSoftwareClassic from '@atlassian/jira-navigation-apps-sidebar-software-classic/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { projectContextResource } from '@atlassian/jira-router-resources-project-context/src/services/project-context/index.tsx';
import { incidentExperimentTraitResource } from '@atlassian/jira-router-resources-software-incidents/src/services/index.tsx';
import { versionDetailsRelayResourceCustom } from '@atlassian/jira-router-resources-software-versions/src/controllers/index.tsx';
import { softwareVersionDetailRoute } from '@atlassian/jira-router-routes-software-versions-routes/src/softwareVersionDetailRoute.tsx';
import { VersionDetailSkeleton } from '@atlassian/jira-skeletons/src/ui/versions/VersionDetailSkeleton.tsx';
import { softwareProjectLayoutNoOnboarding } from '@atlassian/jira-software-project-layout/src/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { LazyVersionDetail, VersionDetailPage } from './ui/version-detail/index.tsx';

export const softwareVersionDetailRouteEntry: Route = createEntry(
	softwareVersionDetailRoute,
	() => ({
		group: 'software',
		ufoName: 'software.version-details-page',
		component: VersionDetailPage,
		skeleton: VersionDetailSkeleton,
		canTransitionIn: () => true,
		layout: softwareProjectLayoutNoOnboarding,
		navigation: {
			menuId: MENU_ID.PROJECTS,
			sidebar: [AsyncSidebarNextGen, AsyncSidebarSoftwareClassic],
		},
		resources: [
			...getNavigationResources(),
			// these have been expanded from getNavigationSidebarProjectResource - please examine whether your route requires these resources in nav4 and remove them with the getWillShowNav4 feature gate if it does not
			incidentExperimentTraitResource,
			projectContextResource,
			forgeProjectModuleResource,
			staticSidebarShortcutsResource,
			staticProjectSidebarNavigationResource,
			staticProjectSidebarConnectAddonsNavigationResource,
			// end of resources expanded from getNavigationSidebarProjectResource
			versionDetailsRelayResourceCustom,
			projectContextResource,
		],
		forPaint: [LazyAtlassianNavigation, LazyVersionDetail],
		meta: {
			reporting: {
				id: 'software-version-detail',
				packageName: camelCase('software-version-detail'),
				teamName: 'fusion-solaris',
			},
		},
	}),
);
