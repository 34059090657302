import {
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_MARKETPLACE_INDEX,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useRoute } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-route/index.tsx';

const GENERAL_CONNECT_ROUTES = new Set<string>([
	ROUTE_NAMES_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_INVALID,
	ROUTE_NAMES_LEGACY_CONNECT_GENERAL,
	ROUTE_NAMES_CONNECT_GENERAL_SERVICE_DESK_PROJECT,
]);

const UNCERTAIN_APPS_SECTION_ROUTES = GENERAL_CONNECT_ROUTES;

const CERTAIN_APPS_SECTION_ROUTES = new Set<string>([
	ROUTE_NAMES_FORGE_GLOBAL,
	ROUTE_NAMES_MARKETPLACE_INDEX,
]);

export const APPS_SECTION_ROUTES = new Set<string>([
	...UNCERTAIN_APPS_SECTION_ROUTES,
	...CERTAIN_APPS_SECTION_ROUTES,
]);

/**
 * @returns true when the current route is a general Connect route.
 */
export function useIsGeneralConnectRoute() {
	const route = useRoute();

	return GENERAL_CONNECT_ROUTES.has(route.name);
}
