import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'servicedesk-customer-service-common.help-center-settings-redirect.error-title',
		defaultMessage: 'This page has been moved',
		description:
			'Title of error flag shown when a user visits a help center settings page from a project settings url on a JCS project',
	},
	errorDescription: {
		id: 'servicedesk-customer-service-common.help-center-settings-redirect.error-description',
		defaultMessage: 'You can find the page you’re looking for in a service hub.',
		description:
			'Description on error flag shown when a user visits a help center settings page from a project settings url on a JCS project',
	},
});
