import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICE_DESK_ROUTE_PREFIX,
	SATISFACTION_ROUTE_SUFFIX,
	TIME_SCALE,
} from './common/constants.tsx';

export const servicedeskReportsSatisfactionTimeScaleRoute: InvariantRoute = {
	name: 'servicedesk-reports-satisfaction-time-scale',
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SATISFACTION_ROUTE_SUFFIX}${TIME_SCALE}`,
};
