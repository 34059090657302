import type { InvariantRoute } from '@atlassian/react-resource-router/src/common/types';
import {
	SERVICES_ROUTE_SUFFIX,
	SERVICE_DESK_ROUTE_PREFIX,
	SERVICE_HASHED_ARI,
} from './common/constants.tsx';

export const servicedeskServiceDetailsRoute: InvariantRoute = {
	name: 'servicedesk-service-details',
	path: `${SERVICE_DESK_ROUTE_PREFIX}${SERVICES_ROUTE_SUFFIX}${SERVICE_HASHED_ARI}`,
};
