import {
	stopCaptureGraphQlErrors,
	startCaptureGraphQlErrors,
} from '@atlassian/jira-relay-errors/src/index.tsx';
import { TrackedGraphQLError } from '@atlassian/jira-forge-ui-errors/src/utils/graphql-error/index.tsx';

import {
	getTraceIds,
	startCapturingTraceIds,
	stopCapturingTraceIds,
} from '@atlassian/relay-traceid';

export function trackGraphQLErrors(operationName: string) {
	const queryId = startCaptureGraphQlErrors();
	startCapturingTraceIds(operationName);
	return {
		stopGraphQLErrorTracking: (): void => stopCapturingTraceIds(operationName),
		getGraphQLError: (): TrackedGraphQLError | undefined => getGraphQLError(queryId, operationName),
	};
}

function getGraphQLError(key: string, operationName: string): TrackedGraphQLError | undefined {
	const errors = stopCaptureGraphQlErrors(key)
		.filter((errorWrapper) => errorWrapper.meta.operationName === operationName)
		.flatMap((errorWrapper) => errorWrapper.errors);

	if (errors.length) {
		return new TrackedGraphQLError(
			`Query ${operationName} had graphql errors`,
			errors,
			getTraceIds(operationName),
		);
	}

	return undefined;
}
