import React from 'react';
import { componentWithFG } from '@atlassian/jira-feature-gate-component/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { createLayout } from '@atlassian/jira-route-layout/src/GlobalComponent.tsx';
import {
	advancedRoadmapsPlanPageInitialChecksResource,
	advancedRoadmapsReduxStoreStateResource,
} from '@atlassian/jira-router-resources-advanced-roadmaps-plan/src/services/index.tsx';
import { themePreferenceResource } from '@atlassian/jira-router-resources-theme-preference/src/controllers/resource-erai-1615-old/index.tsx';
import { arjTimelineEmbedRoute } from '@atlassian/jira-router-routes-advanced-roadmaps-embed-routes/src/arjTimelineEmbedRoute.tsx';
import ErrorPagesNotFound from '@atlassian/jira-router-routes-common/src/ui/error-pages/not-found/index.tsx';
import type { PlanTimelineWithoutHeader as PlanTimelineType } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline/index-old.tsx';
import { markAsProductBundleReady } from '@atlassian/jira-spa/src/services/mark-as-product-bundle-ready/index.tsx';
import { LazyPage } from '@atlassian/jira-spa/src/view/lazy-page/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Route } from '@atlassian/react-resource-router';
import { createChromelessEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { planTimelineEntryPoint } from '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline/entrypoint.tsx';

const planTimelineEmbedRouteEntry = createChromelessEntryPoint({
	main: planTimelineEntryPoint,
});

// Update to default import when cleaning getWillShowNav4
// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyPlanTimelineEmbed = lazyForPaint<typeof PlanTimelineType>(
	() =>
		markAsProductBundleReady(
			import(
				/* webpackChunkName: "async-plan-timeline-embed", jiraSpaEntry: "async-plan-timeline-embed" */ '@atlassian/jira-spa-apps-advanced-roadmaps-plan/src/ui/plan-timeline/index-old.tsx'
			).then(({ PlanTimelineWithoutHeader }) => PlanTimelineWithoutHeader),
		),
	{ ssr: false },
);

const PlanTimelineEmbedPage = () => (
	<LazyPage Page={LazyPlanTimelineEmbed} pageId="plan-timeline-embed" shouldShowSpinner />
);

export const arjTimelineEmbedRouteEntry: Route = createEntry(arjTimelineEmbedRoute, () => ({
	group: 'software',
	component: componentWithFG('smart_links_for_plans', PlanTimelineEmbedPage, ErrorPagesNotFound),
	shouldOptOutConcurrentMode: fg('jira-concurrent-incremental-opt-out-timeline'),
	entryPoint: () => (fg('migrate_plans_to_entrypoints') ? planTimelineEmbedRouteEntry : undefined),
	layout: createLayout({
		isChromeless: true,
	}),

	resources: [
		advancedRoadmapsPlanPageInitialChecksResource,
		advancedRoadmapsReduxStoreStateResource,
		themePreferenceResource,
	],

	preloadOptions: {
		earlyChunks: ['advanced-roadmaps-early-entry'],
	},

	forPaint: [LazyPlanTimelineEmbed],
}));
