import { LazyAtlassianNavigation } from '@atlassian/jira-atlassian-navigation/src/async.tsx';
import { AsyncKbNav } from '@atlassian/jira-horizontal-nav-jsm-knowledge-base/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarServicedesk from '@atlassian/jira-navigation-apps-sidebar-servicedesk/src/async.tsx';
import { createEntry } from '@atlassian/jira-route-entry/src/index.tsx';
import { getConsolidationStateResource } from '@atlassian/jira-router-resources-eoc-tenant-info/src/services/index.tsx';
import { getServiceDeskNavigationResources } from '@atlassian/jira-router-resources-service-desk-common/src/index.tsx';
import { knowledgeHubDraftsRoute } from '@atlassian/jira-router-routes-servicedesk-knowledge-hub-routes/src/knowledgeHubDraftsRoute.tsx';
import { serviceProjectLayout } from '@atlassian/jira-service-project-layout/src/index.tsx';
import {
	APP_NAMES,
	toPackageName,
} from '@atlassian/jira-servicedesk-common/src/utils/app-names/index.tsx';
import type { Route } from '@atlassian/react-resource-router';
import { getKnowledgeHubResources } from './common/getKnowledgeHubResources.tsx';
import ServiceDeskKnowledgeHubDrafts, {
	LazyServicedeskKnowledgeHubDrafts,
} from './ui/drafts/index.tsx';

export const knowledgeHubDraftsRouteEntry: Route = createEntry(knowledgeHubDraftsRoute, () => ({
	group: 'servicedesk',
	component: ServiceDeskKnowledgeHubDrafts,
	layout: serviceProjectLayout,
	navigation: {
		menuId: MENU_ID.PROJECTS,
		sidebar: AsyncSidebarServicedesk,
		horizontal: AsyncKbNav,
		// TODO: Remove this during jira_nav4 cleanup
		onlyShowHorizontalOnNav4: true,
	},
	resources: [
		...getServiceDeskNavigationResources(),
		...getKnowledgeHubResources(),
		getConsolidationStateResource(),
	],
	forPaint: [LazyAtlassianNavigation, LazyServicedeskKnowledgeHubDrafts],
	perfMetricKey: 'service-management.kb-drafts',
	meta: {
		reporting: {
			id: APP_NAMES.KB_DRAFTS,
			packageName: toPackageName(APP_NAMES.KB_DRAFTS),
			teamName: 'jsm-nebula',
		},
	},
}));
